import * as React from "react";
import { useState } from "react";
import OrganizationContext from "./OrganizationContext";

const OrganizationState = (props) => {
  const [formvalue, setformvalue] = useState([]); //API -->

  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [pancardNumber, setPanCardNumber] = useState("");
  const [phone, setPhone] = useState("");
  const [gstNumber, setGstNumber] = useState("");
  const [email, setEmail] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [userName, setUserName] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [password, setPassword] = useState("");
  const [conformPassword, setConformPassword] = useState("");
  const [url, setUrl] = useState("");
  const [base, setBase] = useState("");
  const [baseUrl, setBaseUrl] = useState("");
  const [userurl, setUserUrl] = useState("");
  const [logo] = useState("");
  const [logo1] = useState("");
  const [error, setError] = useState(false);
  const [togglePassword, setTogglePassword] = useState(false);
  const [confirmtogglePassword, setconfirmTogglePassword] = useState(false);
  const [loaderShow, setLoaderShow] = useState(false);
  const [responseLoader, setResponseLoader] = useState(false);

  const [uuid, setUuid] = useState("");

  const [ahoeDataModel, setahoeDataModel] = useState(false); //SHOW ORG DETAILS MODEL -->
  const [modal, setModal] = useState(false); //ADD DATA MODEL -->
  const [addbel, setAddBel] = useState(false); //ADD ORG BEL -->
  const [pageNumber, setpageNumber] = useState(1); //PAGINATION -->
  const [recordsPerPage, setRecordsPerPage] = useState(10); //PAGINATION -->
  const [sortOrder, setSortOrder] = useState(""); //SORTING -->
  const [sortBy, setSortBy] = useState("createdAt"); //SORTING -->
  const [filter, setFilter] = useState(""); //SEARCHING FILTER -->
  const [isHovering, setIsHovering] = useState(false); //HOVWER SORTING -->
  const [isSwitchOn, setIsSwitchOn] = useState(false); //TOGGLE SWITCH -->
  const [editIndex, seteditIndex] = useState(-1); //EDIT -->
  const [totalRecord, setTotalRecord] = useState(); //TOTAL RECORD -->
  const [isAddHandle, setIsAddHandle] = useState(true); //PASS HIDE OR UNHIDE -->
  const [progress, setProgress] = useState(0);
  const [togglesubmit, setToggle] = useState(false);
  const [val, setVal] = useState("");

  return (
    <>
      <OrganizationContext.Provider
        value={{
          formvalue,
          setformvalue,

          setName,
          name,
          address,
          setAddress,
          phone,
          setPhone,
          gstNumber,
          setGstNumber,
          email,
          setEmail,
          city,
          setCity,
          state,
          setState,
          userName,
          setUserName,
          userPhone,
          setUserPhone,
          userEmail,
          setUserEmail,
          password,
          setPassword,
          conformPassword,
          setConformPassword,
          userurl,
          setUserUrl,
          base,
          setBase,
          baseUrl,
          setBaseUrl,
          pancardNumber,
          setPanCardNumber,

          ahoeDataModel,
          setahoeDataModel,
          modal,
          setModal,
          addbel,
          setAddBel,
          pageNumber,
          setpageNumber,
          recordsPerPage,
          setRecordsPerPage,
          sortOrder,
          setSortOrder,
          sortBy,
          setSortBy,
          filter,
          setFilter,
          isHovering,
          setIsHovering,
          isSwitchOn,
          setIsSwitchOn,
          url,
          setUrl,
          editIndex,
          seteditIndex,
          totalRecord,
          setTotalRecord,
          isAddHandle,
          setIsAddHandle,
          progress,
          setProgress,
          togglesubmit,
          setToggle,
          uuid,
          setUuid,
          val,
          setVal,
          logo,
          logo1,
          error,
          setError,
          togglePassword,
          setTogglePassword,
          confirmtogglePassword,
          setconfirmTogglePassword,
          loaderShow,
          setLoaderShow,
          responseLoader,
          setResponseLoader,
        }}
      >
        {props.children}
      </OrganizationContext.Provider>
    </>
  );
};

export default OrganizationState;
