import React, { Fragment, useContext, useEffect, useState } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import { Col, Container, Row } from "reactstrap";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarController,
  BarElement,
  ArcElement,
  RadialLinearScale,
} from "chart.js";
import {
  ACCOUNT_TYPE,
  ANALYSIS_TYPE,
  ERROR_CODE,
  IxAnalysis,
  RoleAccess,
  USER_ROLE,
  dashboardAccountMaster,
} from "../../constant";
import Doughnutchart from "../../utils/Doughnutchart";
import {
  Add_Donation_Master,
  Birthday,
  DeathDay,
  Donation_MasterList,
} from "../../api/DonationMasterApi/DonationMasterApi";
import { toast, Zoom } from "react-toastify";
import BirthDateDeshboard from "./BirthDateDeshboard";
import moment from "moment";
import LogOut from "../../utils/logout";
import BirthdayTempDownlod from "./birthdayTempDownlod";
import ButtonRedirect from "./ButtonRedirect";
import DonationPopup from "./Donation/DonationPopup";
import DonationMasterAddModal from "../DonationMaster/DonationMasterAddModel";
import DonationMasterContext from "../../context/DonatoinMasterContext/DonationMasterContext";
import { Temple_Trust_List } from "../../api/TempleTrustApi/TempleTrustApi";
import OrganizationContext from "../../context/OrganizationContext/OrganizationContext";
import { conditionCheck, isEmpty } from "../../utils/helper";
import ExpansePopup from "./expanse/Expance";
import { Temple_Master_List } from "../../api/TempleMasterApi/TempleMasterApi";
import DeathDateDashboard from "./DeathDateDashboard";
import OrgContext from "../../context/OrgContext/OrgContext";
import { useNavigate } from "react-router";

const Ecommerce = () => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    BarController,
    BarElement,
    ArcElement,
    RadialLinearScale
  );

  const {
    setModal,
    setToggle,
    setTrustDrop,
    setError,
    setName,
    name,
    date,
    setDate,
    amount,
    setAmount,
    trustId,
    setTrustId,
  } = useContext(DonationMasterContext);
  const { dodMonth, dodPaksha, dodDate } = useContext(OrgContext);
  const { loaderShow, setLoaderShow, setResponseLoader } =
    useContext(OrganizationContext);
  const [templeData, setTempleData] = useState([]);
  const [formData, setFormData] = useState({
    templeId: "",
    startDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    type: ANALYSIS_TYPE.day,
  });
  const [templeId, setTempleId] = useState("");
  const [inwardData, setInwardData] = useState([]);
  const [outwardData, setOutwardData] = useState([]);
  const [outwardLabel, setOutwardLabel] = useState([]);
  const [inwardLabel, setInwardLabel] = useState([]);
  const [buttonFormate, setButtonFormate] = useState([]);
  const [birthdate, setBirthDate] = useState([]);
  const [deathDate, setDeathDate] = useState([]);
  const [currentMonth, setCurrentMonth] = useState(moment().format("MM"));
  const [todatDate, setTodayDate] = useState(moment().format("DD"));
  const [pageNumber, setPageNumber] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);
  const [model, setModel] = useState(false);
  const [birthdayName, setBirthdayName] = useState("");
  const [modalExpanse, setModalExpanse] = useState(false);
  const history = useNavigate();

  const getData = async () => {
    setResponseLoader(true);
    let paramObj = {
      showAll: true,
      search: { isActive: true },
    };
    const isCheck = conditionCheck(formData.templeId);
    if (isCheck) {
      paramObj["templeId"] = formData.templeId;
    }
    try {
      const { data } = await Donation_MasterList(paramObj);
      if (data.status === 200) {
        setButtonFormate(data.payload.data);
        setInwardData([]);
        setInwardLabel([]);
        setOutwardData([]);
        setOutwardLabel([]);
        for (const i of data.payload.data) {
          if (i.type === ACCOUNT_TYPE.inward) {
            setInwardData((oldArray) => [...oldArray, i.amount || 0]);
            setInwardLabel((oldArray) => [...oldArray, i.name]);
          }
          if (i.type === ACCOUNT_TYPE.outward) {
            setOutwardData((oldArray) => [...oldArray, i.amount || 0]);
            setOutwardLabel((oldArray) => [...oldArray, i.name]);
          }
        }
        setResponseLoader(false);
      }
    } catch (error) {
      if (error?.response?.data?.status === ERROR_CODE.UNAUTHORIZED) {
        LogOut(history);
      }
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  useEffect(() => {
    const isCheck = conditionCheck(formData.templeId);
    if (
      dashboardAccountMaster.includes(+localStorage.getItem("roleId")) &&
      isCheck
    ) {
      getData();
    }
  }, [formData.templeId]);

  const getDate = async () => {
    let paramobj = {
      month: currentMonth,
      pageNumber,
    };
    if (todatDate.length) {
      paramobj["day"] = todatDate;
    }
    try {
      const { data } = await Birthday(paramobj);
      if (data.status === 200) {
        setBirthDate((prev) => [...prev, ...data.payload.data]);
        setTotalRecord(data.pager.totalRecords);
      }
    } catch (error) {
      if (error.response.data.status === ERROR_CODE.UNAUTHORIZED) {
        LogOut(history);
      }
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };
  const getDeathDate = async () => {
    let paramobj = {
      search: {},
      showAll: true,
    };
    if (dodMonth && dodPaksha && dodDate) {
      paramobj.search["dod"] = `${dodMonth}-${dodPaksha}-${dodDate}`;
    } else if (dodMonth && dodPaksha) {
      paramobj.search["dod"] = `${dodMonth}-${dodPaksha}-`;
    } else if (dodMonth) {
      paramobj.search["dod"] = `${dodMonth}-`;
    } else {
      delete paramobj.search["dod"];
    }

    try {
      const { data } = await DeathDay(paramobj);
      if (data.status === 200) {
        setDeathDate(data.payload.data);
      }
    } catch (error) {
      if (error.response.data.status === ERROR_CODE.UNAUTHORIZED) {
        LogOut(history);
      }
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };
  useEffect(() => {
    getDate();
  }, [todatDate, pageNumber]);

  useEffect(() => {
    getDeathDate();
  }, [dodMonth, dodPaksha, dodDate]);

  const showBirthdayTemplate = (name) => {
    setModel(true);
    setBirthdayName(name);
  };

  const TrustDopdown = async () => {
    setLoaderShow(true);
    try {
      const { data } = await Temple_Trust_List();
      if (data.status === 200) {
        setTrustDrop(data.payload.data);
        setLoaderShow(false);
      } else {
        setTrustDrop([]);
        setLoaderShow(false);
      }
    } catch (error) {
      if (error.response.data.status === ERROR_CODE.UNAUTHORIZED) {
        LogOut(history);
      }
      setLoaderShow(false);
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  const openDonationMasterModal = async (donationType) => {
    await TrustDopdown();
    setDate(donationType);
    setModal(true);
    setToggle(true);
  };

  const emptyFeild = () => {
    setName(null);
    setDate(null);
    setTrustId("");
    setAmount(null);
    setLoaderShow(false);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (name.length === 0) {
      setError(true);
    } else {
      let formData;
      setLoaderShow(!loaderShow);
      formData = isEmpty({
        name: name,
        type: +date,
        trustId: +trustId,
        templeId: +localStorage.getItem("templeId"),
        amount: +amount,
      });

      try {
        const { data } = await Add_Donation_Master(formData);
        if (data.status === 200) {
          toast.success(data.message, {
            autoClose: 1000,
            transition: Zoom,
          });
          setModal(false);
          getData();
          emptyFeild();
        } else {
        }
      } catch (error) {
        if (error.response.data.status === ERROR_CODE.UNAUTHORIZED) {
          LogOut(history);
        }
        toast.error(error.response.data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
        setLoaderShow(false);
      }
    }
  };

  const close = () => {
    emptyFeild();
    setModal(false);
  };

  const getDataTemple = async () => {
    try {
      let paramsObj = {
        showAll: true,
      };
      const { data } = await Temple_Master_List(paramsObj);
      if (data.status === 200) {
        setFormData((prev) => {
          return {
            ...prev,
            templeId: data.payload.data[0]?.templeId,
          };
        });
        setTempleData(data.payload.data);
      } else {
        setTempleData([]);
      }
    } catch (error) {
      if (error?.response?.data?.status === ERROR_CODE.UNAUTHORIZED) {
        LogOut(history);
      }
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };
  useEffect(() => {
    if (IxAnalysis.includes(+localStorage.getItem("roleId"))) {
      getDataTemple();
    }
  }, []);

  return (
    <Fragment>
      {USER_ROLE.ADMIN === +localStorage.getItem("roleId") ? (
        <Breadcrumb parent="Dashboard" title="Admin Dashboard" />
      ) : (
        <>
          <Breadcrumb parent="Dashboard" title="Dashboard" />
          {+localStorage.getItem("roleId") === RoleAccess.ORG_ADMIN && (
            <Row className="justify-content-center ">
              <Col md="4 mb-3">
                <select
                  className="form-select"
                  name="templeId"
                  value={formData.templeId}
                  onChange={(e) => {
                    setFormData((prev) => {
                      return {
                        ...prev,
                        [e.target.name]: e.target.value,
                      };
                    });
                    setTempleId(e.target.value);
                  }}
                >
                  <option value="">--select temple--</option>
                  {templeData.map((elem) => {
                    return (
                      <option value={elem.templeId} key={elem.templeId}>
                        {elem.templeName}
                      </option>
                    );
                  })}
                </select>
              </Col>
            </Row>
          )}

          <ButtonRedirect
            buttonFormate={buttonFormate}
            openDonationMasterModal={openDonationMasterModal}
            modalExpanse={modalExpanse}
            setModalExpanse={setModalExpanse}
            formData={formData}
            setFormData={setFormData}
            templeId={templeId}
          />
          <BirthdayTempDownlod
            model={model}
            setModel={setModel}
            birthdayName={birthdayName}
          />
          <Container fluid={true}>
            <Row>
              <DeathDateDashboard date={deathDate} />
              <BirthDateDeshboard
                date={birthdate}
                setCurrentMonth={setCurrentMonth}
                setTodayDate={setTodayDate}
                setModel={setModel}
                model={model}
                showBirthdayTemplate={showBirthdayTemplate}
                getDate={getDate}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                totalRecord={totalRecord}
                setBirthDate={setBirthDate}
              />
              {+localStorage.getItem("roleId") !==
                RoleAccess.EVENT_COORDINATOR &&
                +localStorage.getItem("roleId") !== RoleAccess.CASHIER && (
                  <>
                    <Doughnutchart
                      data={inwardData}
                      labels={inwardLabel}
                      Header={"Income"}
                    />
                    <Doughnutchart
                      data={outwardData}
                      labels={outwardLabel}
                      Header={"Expance"}
                    />
                  </>
                )}
            </Row>
            <DonationPopup />
            <DonationMasterAddModal close={close} onSubmit={onSubmit} />
            <ExpansePopup
              modalExpanse={modalExpanse}
              setModalExpanse={setModalExpanse}
            />
          </Container>
        </>
      )}
    </Fragment>
  );
};

export default Ecommerce;
