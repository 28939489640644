import { Payment } from "@material-ui/icons";
import { IconButton, Tooltip } from "@mui/material";
import React, { Fragment, useContext } from "react";
import { Edit, Eye, Trash2 } from "react-feather";
import NoteContext from "../context/NotesContext/NoteContext";

const Actions = (porps) => {
  const { params, editHandle, showHandle, deleteDoantionSLip } = porps;
  const { setPayModel, setToggle } = useContext(NoteContext);
  return (
    <Fragment>
      {params.row.noteId && (
        <>
          <Tooltip title="Edit">
            <IconButton
              aria-label="edit"
              style={{ background: "#dcf1dc", marginRight: "5px" }}
              onClick={() => editHandle(params.row.noteId)}
            >
              <Edit style={{ color: "green", width: "19px", height: "19px" }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Edit">
            <IconButton
              aria-label="edit"
              style={{ background: "#dcf1dc" }}
              onClick={() => showHandle(params.row.noteId)}
            >
              <Eye style={{ color: "green", width: "19px", height: "19px" }} />
            </IconButton>
          </Tooltip>
        </>
      )}
      {params.row.accountTransactionId && (
        <>
          <Tooltip title="Edit">
            <IconButton
              aria-label="edit"
              style={{ background: "#dcf1dc", marginRight: "5px" }}
              onClick={() =>
                editHandle(
                  params.row.accountTransactionId,
                  setPayModel(false),
                  setToggle(true)
                )
              }
            >
              <Edit style={{ color: "green", width: "19px", height: "19px" }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="View">
            <IconButton
              aria-label="view"
              style={{ background: "#dcf1dc", marginRight: "5px" }}
              onClick={() => showHandle(params.row.accountTransactionId)}
            >
              <Eye style={{ color: "green", width: "19px", height: "19px" }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="PayNow">
            <IconButton
              aria-label="paynow"
              style={{ background: "#dcf1dc", marginRight: "5px" }}
              onClick={() =>
                editHandle(params.row.accountTransactionId, setPayModel(true))
              }
            >
              <Payment
                style={{ color: "green", width: "19px", height: "19px" }}
              />
            </IconButton>
          </Tooltip>
        </>
      )}
      {params.row.slipId && (
        <>
          <Tooltip title="Delete">
            <IconButton
              aria-label="view"
              style={{ background: "rgb(255 184 184)" }}
              onClick={() => deleteDoantionSLip(params.row.slipId)}
            >
              <Trash2 style={{ color: "red", width: "19px", height: "19px" }} />
            </IconButton>
          </Tooltip>
        </>
      )}
    </Fragment>
  );
};

export default Actions;
