import * as React from "react";
import { useState } from "react";
import DonationMasterContext from "./DonationMasterContext";

const DonationMasterState = (props) => {
  const [formvalue, setformvalue] = useState([]); //API -->
  const [trustDrop, setTrustDrop] = useState([]);
  const [error, setError] = useState(false);

  const [name, setName] = useState(null);
  const [date, setDate] = useState("");
  const [showDonationMaster, setShowDonationMaster] = useState("");
  const [doantionStatement, setDonationStatement] = useState("");
  const [searchFilter, setSearchFilter] = useState("");
  const [startDateFilter, setStartDateFilter] = useState("");
  const [endDateFilter, setEndDateFilter] = useState("");
  const [trustId, setTrustId] = useState("");
  const [amount, setAmount] = useState(null);

  const [accountId, setAccountId] = useState("");

  const [ahoeDataModel, setahoeDataModel] = useState(false); //SHOW ORG DETAILS MODEL -->
  const [modal, setModal] = useState(false); //ADD DATA MODEL -->
  const [addbel, setAddBel] = useState(false); //ADD ORG BEL -->
  const [pageNumber, setpageNumber] = useState(1); //PAGINATION -->
  const [recordsPerPage, setRecordsPerPage] = useState(10); //PAGINATION -->
  const [sortOrder, setSortOrder] = useState(""); //SORTING -->
  const [sortBy, setSortBy] = useState("createdAt"); //SORTING -->
  const [filter, setFilter] = useState(""); //SEARCHING FILTER -->
  const [isHovering, setIsHovering] = useState(false); //HOVWER SORTING -->
  const [isSwitchOn, setIsSwitchOn] = useState(false); //TOGGLE SWITCH -->
  const [editIndex, seteditIndex] = useState(-1); //EDIT -->
  const [totalRecord, setTotalRecord] = useState(); //TOTAL RECORD -->
  const [isAddHandle, setIsAddHandle] = useState(true); //PASS HIDE OR UNHIDE -->
  const [progress, setProgress] = useState(0);
  const [togglesubmit, setToggle] = useState(false);
  const [val, setVal] = useState("");

  const [totalRecords, setTotalRecords] = useState(31);

  return (
    <>
      <DonationMasterContext.Provider
        value={{
          formvalue,
          setformvalue,

          setName,
          name,
          date,
          setDate,
          searchFilter,
          setSearchFilter,
          startDateFilter,
          setStartDateFilter,
          endDateFilter,
          setEndDateFilter,
          trustDrop,
          setTrustDrop,
          trustId,
          setTrustId,

          ahoeDataModel,
          setahoeDataModel,
          modal,
          setModal,
          addbel,
          setAddBel,
          pageNumber,
          setpageNumber,
          recordsPerPage,
          setRecordsPerPage,
          sortOrder,
          setSortOrder,
          sortBy,
          setSortBy,
          filter,
          setFilter,
          isHovering,
          setIsHovering,
          isSwitchOn,
          setIsSwitchOn,
          editIndex,
          seteditIndex,
          totalRecord,
          setTotalRecord,
          isAddHandle,
          setIsAddHandle,
          progress,
          setProgress,
          togglesubmit,
          setToggle,
          accountId,
          setAccountId,
          showDonationMaster,
          setShowDonationMaster,
          val,
          setVal,
          doantionStatement,
          setDonationStatement,
          amount,
          setAmount,
          error,
          setError,

          totalRecords,
          setTotalRecords,
        }}
      >
        {props.children}
      </DonationMasterContext.Provider>
    </>
  );
};

export default DonationMasterState;
