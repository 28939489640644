import React, { useContext } from 'react';
import { Edit } from 'react-feather';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  Input,
  Label,
  Media,
  Modal,
  ModalBody,
  ModalHeader,
  Row
} from 'reactstrap';
import { BUTTON_NAME, ORGANIZATION, ValidationLabel } from '../../constant';
import OrganizationContext from '../../context/OrganizationContext/OrganizationContext';
import { maxLengthCheck } from '../../utils/helper';

const OrganizationAddModel = (props) => {
  const { onSubmit, readUrl1 } = props;
  const {
    modal,
    setModal,
    setName,
    name,
    address,
    setAddress,
    phone,
    setPhone,
    gstNumber,
    setGstNumber,
    pancardNumber,
    setPanCardNumber,
    email,
    setEmail,
    city,
    setCity,
    state,
    setState,
    userName,
    setUserName,
    userPhone,
    setUserPhone,
    userEmail,
    setUserEmail,
    password,
    setPassword,
    conformPassword,
    setConformPassword,
    userurl,
    baseUrl,
    logo1,
    togglesubmit,
    isAddHandle,
    error,
    togglePassword,
    setTogglePassword,
    confirmtogglePassword,
    setconfirmTogglePassword,
    loaderShow
  } = useContext(OrganizationContext);

  return (
    <>
      <Modal size="xl" isOpen={modal} toggle={() => setModal(!modal)}>
        <ModalHeader toggle={() => setModal(!modal)}>
          {togglesubmit ? (
            <span>{ORGANIZATION.ADD_ORG}</span>
          ) : (
            <span>
              {ORGANIZATION.EDIT_ORG} <span> </span>{' '}
              <Edit style={{ width: '50px', height: '20px', color: '#7366ff' }} />
            </span>
          )}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader>
                  <h5>{ORGANIZATION.ORG_DETAILS}</h5>
                </CardHeader>
                <CardBody>
                  <Form className="needs-validation" onSubmit={(e) => onSubmit(e)}>
                    <Row>
                      <Col md="4 mb-4">
                        <Label
                          htmlFor="validationCustom01"
                          className={error && name.length <= 0 ? 'validation' : 'non-validation'}
                        >
                          {error && name.length <= 0 ? ValidationLabel.nameRequired : ValidationLabel.name}
                        </Label>
                        <Input
                          className={`${error && name.length <= 0 ? 'inputValidation' : ''} "form-control`}
                          name="name"
                          type="text"
                          placeholder="Name"
                          id="validationCustom01"
                          onChange={(e) => setName(e.target.value)}
                          value={name}
                        />
                        <div className="invalid-feedback">{'Please provide a valid ORG Name.'}</div>
                      </Col>
                      <Col md="4 mb-4">
                        <Label
                          htmlFor="validationCustom01"
                          className={error && address.length <= 0 ? 'validation' : 'non-validation'}
                        >
                          {error && address.length <= 0 ? ORGANIZATION.ADDRESS_REQUIRED : ORGANIZATION.ADDRESS}
                        </Label>
                        <Input
                          className={`${error && address.length <= 0 ? 'inputValidation' : ''} "form-control`}
                          name="address"
                          type="text"
                          placeholder="Address"
                          id="validationCustom01"
                          onChange={(e) => setAddress(e.target.value)}
                          value={address}
                        />
                        <div className="invalid-feedback">{'Please provide a valid ORG Name.'}</div>
                      </Col>
                      <Col md="4 mb-4">
                        <Label
                          htmlFor="validationCustom03"
                          className={error && phone.length <= 0 ? 'validation' : 'non-validation'}
                        >
                          {error && phone.length <= 0 ? ORGANIZATION.PHONE_REQUIRED : ORGANIZATION.PHONE}
                        </Label>
                        <Input
                          className={`${error && phone.length <= 0 ? 'inputValidation' : ''} "form-control`}
                          name="phone"
                          type="number"
                          min={0}
                          id="validationCustome22"
                          placeholder="Phone"
                          onChange={(e) => setPhone(e.target.value)}
                          onWheel={(e) => e.target.blur()}
                          value={phone}
                          maxLength={10}
                          onInput={maxLengthCheck}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6 mb-4">
                        <Label htmlFor="validationCustom03">{ORGANIZATION.GST_NUMBER}</Label>
                        <Input
                          className="form-control"
                          name="gstNumber"
                          type="text"
                          id="validationCustome22"
                          placeholder="GST Number "
                          value={gstNumber}
                          onChange={(e) => setGstNumber(e.target.value)}
                          maxLength={15}
                          onInput={maxLengthCheck}
                        />
                      </Col>
                      <Col md="6 mb-4">
                        <Label htmlFor="validationCustom03">{ORGANIZATION.PANCARD_NO}</Label>
                        <Input
                          className="form-control"
                          name="gstNumber"
                          type="text"
                          id="validationCustome22"
                          placeholder="PanCard No. "
                          onChange={(e) => setPanCardNumber(e.target.value)}
                          value={pancardNumber}
                          maxLength={10}
                          onInput={maxLengthCheck}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="4 mb-4">
                        <Label
                          htmlFor="validationCustom06"
                          className={error && email.length <= 0 ? 'validation' : 'non-validation'}
                        >
                          {error && email.length <= 0 ? ORGANIZATION.EMAIL_REQUIRED : ORGANIZATION.EMAIL}
                        </Label>
                        <Input
                          className={`${error && email.length <= 0 ? 'inputValidation' : ''} "form-control`}
                          name="email"
                          id="validationCustom06"
                          type="email"
                          placeholder="Email"
                          onChange={(e) => setEmail(e.target.value)}
                          value={email}
                        />
                        <div className="invalid-feedback">{'Please provide a valid Address 1.'}</div>
                      </Col>
                      <Col md="4 mb-4">
                        <Label htmlFor="validationCustom03">{ORGANIZATION.CITY}</Label>
                        <Input
                          className="form-control"
                          name="city"
                          type="text"
                          id="validationCustome22"
                          placeholder="City"
                          onChange={(e) => setCity(e.target.value)}
                          value={city}
                        />
                      </Col>
                      <Col md="4 mb-4">
                        <Label htmlFor="validationCustom03">{ORGANIZATION.STATE}</Label>
                        <Input
                          className="form-control"
                          name="state"
                          type="text"
                          id="validationCustome22"
                          placeholder="State"
                          onChange={(e) => setState(e.target.value)}
                          value={state}
                        />
                      </Col>
                    </Row>
                    <CardHeader>
                      <h5> {ORGANIZATION.USER_DETAILS}</h5>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col md="4 mb-4">
                          <div className="user-image">
                            <div className="avatar">
                              <Media
                                body
                                alt=""
                                src={baseUrl + userurl}
                                style={{
                                  width: '9rem',
                                  height: '9rem',
                                  borderRadius: '45rem',
                                  border: '1px solid '
                                }}
                                data-intro="This is Profile image"
                              />
                            </div>
                            <div className="icon-wrapper" data-intro="Change Profile image here">
                              <i className="icofont icofont-pencil-alt-5">
                                <Input className="upload" type="file" value={logo1} onChange={(e) => readUrl1(e)} />
                              </i>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="4 mb-4">
                          <Label
                            htmlFor="validationCustom01"
                            className={error && userName.length <= 0 ? 'validation' : 'non-validation'}
                          >
                            {error && userName.length <= 0 ? ValidationLabel.nameRequired : ValidationLabel.name}
                          </Label>
                          <Input
                            className={`${error && userName.length <= 0 ? 'inputValidation' : ''} "form-control`}
                            name="name"
                            type="text"
                            placeholder="Name"
                            id="validationCustom01"
                            onChange={(e) => setUserName(e.target.value)}
                            value={userName}
                          />
                          <div className="invalid-feedback">{'Please provide a valid ORG Name.'}</div>
                        </Col>
                        <Col md="4 mb-4">
                          <Label
                            htmlFor="validationCustom01"
                            className={error && userPhone.length <= 0 ? 'validation' : 'non-validation'}
                          >
                            {error && userPhone.length <= 0 ? ORGANIZATION.PHONE_REQUIRED : ORGANIZATION.PHONE}
                          </Label>
                          <Input
                            className={`${error && userPhone.length <= 0 ? 'inputValidation' : ''} "form-control`}
                            name="phone"
                            type="number"
                            min={0}
                            placeholder="Phone"
                            id="validationCustom01"
                            onChange={(e) => setUserPhone(e.target.value)}
                            onWheel={(e) => e.target.blur()}
                            value={userPhone}
                            maxLength={10}
                            onInput={maxLengthCheck}
                          />
                          <div className="invalid-feedback">{'Please provide a valid ORG Name.'}</div>
                        </Col>
                        <Col md="4" mb="4">
                          <Label
                            htmlFor="validationCustom03"
                            className={error && userEmail.length <= 0 ? 'validation' : 'non-validation'}
                          >
                            {error && userEmail.length <= 0 ? ORGANIZATION.EMAIL_REQUIRED : ORGANIZATION.EMAIL}
                          </Label>
                          <Input
                            className={`${error && userEmail.length <= 0 ? 'inputValidation' : ''} "form-control`}
                            name="email"
                            type="email"
                            id="validationCustome22"
                            placeholder="Email"
                            onChange={(e) => setUserEmail(e.target.value)}
                            value={userEmail}
                          />
                        </Col>
                      </Row>
                      {isAddHandle ? (
                        <Row>
                          <Col md="6" mb="4">
                            <Label
                              htmlFor="validationCustom03"
                              className={error && password.length <= 0 ? 'validation' : 'non-validation'}
                            >
                              {error && password.length <= 0
                                ? ValidationLabel.passwordRequired
                                : ValidationLabel.password}
                            </Label>
                            <Input
                              className={`${error && password.length <= 0 ? 'inputValidation' : ''} "form-control`}
                              name="password"
                              type={togglePassword ? 'text' : 'password'}
                              id="validationCustome22"
                              placeholder="Password "
                              onChange={(e) => setPassword(e.target.value)}
                              value={password}
                              required
                            />
                            <div className="show-hide" onClick={() => setTogglePassword(!togglePassword)}>
                              <span className={togglePassword ? '' : 'show'}></span>
                            </div>
                          </Col>

                          <Col md="6" mb="4">
                            <Label
                              htmlFor="validationCustom03"
                              className={
                                (error && conformPassword.length <= 0) || (error && conformPassword !== password)
                                  ? 'validation'
                                  : 'non-validation'
                              }
                            >
                              {error && conformPassword.length <= 0
                                ? ValidationLabel.confirmPasswordRequired
                                : error && conformPassword !== password
                                ? ValidationLabel.notMatch
                                : ValidationLabel.confirmPassword}
                            </Label>
                            <Input
                              className={`${
                                (error && conformPassword.length <= 0) || (error && conformPassword !== password)
                                  ? 'inputValidation'
                                  : ''
                              } "form-control`}
                              name="conformPassword"
                              type={confirmtogglePassword ? 'text' : 'password'}
                              id="validationCustome22"
                              placeholder="Password"
                              onChange={(e) => setConformPassword(e.target.value)}
                              value={conformPassword}
                              required
                            />
                            <div className="show-hide" onClick={() => setconfirmTogglePassword(!confirmtogglePassword)}>
                              <span className={confirmtogglePassword ? '' : 'show'}></span>
                            </div>
                          </Col>
                        </Row>
                      ) : null}
                    </CardBody>

                    <Row style={{ paddingTop: '30px' }}>
                      <button
                        className="btn btn-primary"
                        type="submit"
                        value="Submit"
                        disabled={loaderShow === true ? 'desabled' : ''}
                      >
                        {BUTTON_NAME.SUBMIT}
                      </button>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
};

export default OrganizationAddModel;
