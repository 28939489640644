import React, { useContext } from "react";
import {
  Card,
  CardBody,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import SocketContext from "../../context/SocketContext/SocketContext";
import QRCode from "react-qr-code";
import QrAuthenticatedSuccess from "./QrAuthenticatedSuccess";
import LoadingScreen from "./LoadingScreen";

const QrModal = () => {
  const { qrModal, setQrModal, qr } = useContext(SocketContext);
  return (
    <Modal size="sm" isOpen={qrModal}>
      <ModalHeader toggle={() => setQrModal(false)}>
        Scan Qr for whats app
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col sm="12">
            <Card className="mb-0 ">
              <CardBody className="p-0">
                {qr && (
                  <div>
                    <QRCode
                      size={200}
                      style={{
                        height: "auto",
                        maxWidth: "100%",
                        width: "100%",
                      }}
                      value={qr}
                      viewBox={`0 0 256 256`}
                    />
                  </div>
                )}
                <QrAuthenticatedSuccess />
                <LoadingScreen />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default QrModal;
