import axios from "axios";
import {
  ADD_SUPPLIAR_LIST,
  EDIT_SUPPLIAR_LIST,
  FILE,
  SUPPLIARLIST_DITAILS,
  SUPPLIAR_LIST,
} from "../../constant/API constant";

export const SuppliarList = (paramsObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${SUPPLIAR_LIST}`, {
    headers: { Authorization: jwt_token },
    params: paramsObj,
  });
};

export const Add_SuppliarList = (formData) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(
    `${process.env.REACT_APP_API_URL}${ADD_SUPPLIAR_LIST}`,
    formData,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

export const SUPPLIAR_LIST_DITAILS = (uuid) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${SUPPLIARLIST_DITAILS}`, {
    headers: { Authorization: jwt_token },
    params: { uuid },
  });
};

export const editSuppliarList = (formData, uuid) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${EDIT_SUPPLIAR_LIST}`,
    formData,
    {
      headers: { Authorization: jwt_token },
      params: { uuid },
    }
  );
};

export const FILE_API = (formData) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(`${process.env.REACT_APP_API_URL}${FILE}`, formData, {
    headers: { Authorization: jwt_token },
  });
};
