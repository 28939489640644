import React from "react";
import Routers from "./route";
import { Provider } from "react-redux";
import store from "./store";
// import { Auth0Provider } from "@auth0/auth0-react";
// import { auth0 } from "./data/config";
import UserState from "./context/UserState";
import AdminDeshboardState from "./context/AdminDeshboardContext/AdminDeshboardState";
import OrgState from "./context/OrgContext/OrgState";
import ProductState from "./context/ProductContext/ProductState";
import OrderState from "./context/OrderContext/OrderState";
import NoteState from "./context/NotesContext/NoteState";
import NotesState from "./context/NoteContext/NoteState";
import OrganizationState from "./context/OrganizationContext/OrganizationState";
import PrivillageMasterState from "./context/PrivillageMasterContext/PrivillageMasterState";
import ModualManagerState from "./context/ModuleManagerContext/ModualManagerState";
import DonationMasterState from "./context/DonatoinMasterContext/DonationMasterState";
import SuppliarState from "./context/SuppliarContext/SuppliarState";
import TempleState from "./context/TempleContext/TempleState";
import TempleTrustState from "./context/TempleTrustContext/TempleTrustState";
import PublicPageState from "./context/PublicPageContext/PublicState";
import LoaderResoinse from "./loader/loader";
import SocketState from "./context/SocketContext/SocketState";

const App = () => (
  <div className="App">
    {/* <Auth0Provider
      domain={auth0.domain}
      clientId={auth0.clientId}
      redirectUri={auth0.redirectUri}
    > */}
    <Provider store={store}>
      <SocketState>
        <PublicPageState>
          <TempleTrustState>
            <TempleState>
              <NotesState>
                <SuppliarState>
                  <DonationMasterState>
                    <ModualManagerState>
                      <PrivillageMasterState>
                        <OrganizationState>
                          <NoteState>
                            <OrderState>
                              <ProductState>
                                <OrgState>
                                  <AdminDeshboardState>
                                    <UserState>
                                      <Routers />
                                      <LoaderResoinse />
                                    </UserState>
                                  </AdminDeshboardState>
                                </OrgState>
                              </ProductState>
                            </OrderState>
                          </NoteState>
                        </OrganizationState>
                      </PrivillageMasterState>
                    </ModualManagerState>
                  </DonationMasterState>
                </SuppliarState>
              </NotesState>
            </TempleState>
          </TempleTrustState>
        </PublicPageState>
      </SocketState>
    </Provider>
    {/* </Auth0Provider> */}
  </div>
);

export default App;
