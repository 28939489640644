import axios from "axios";
import {
  CREATE_PERSONE_MASETR_PUBLIC,
  CREATE_TRANSECTION_SLIP,
  CREATE_TRANSECTION_SLIP_PUBLIC,
  DELETE_OTP_SLIP,
  DONATION_BILL_DETAILS,
  DONATION_BILL_DETAILS_PUBLIC,
  DONATION_BILL_LIST,
  DOWNLOAD_SLIP,
  SHOW_SLIP,
  UPDATE_STATUS_TRANSECTION_SLIP,
  UPDATE_TRANSECTION_SLIP,
} from "../../constant/API constant";

export const Donation_bill_Details = (paramObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${DONATION_BILL_DETAILS}`, {
    headers: { Authorization: jwt_token },
    params: paramObj,
  });
};
export const Donation_bill_Details_Public = (contactNo, orgId, temple_uuid) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(
    `${process.env.REACT_APP_API_URL}${DONATION_BILL_DETAILS_PUBLIC}`,
    {
      headers: { Authorization: jwt_token },
      params: {
        mobile: contactNo,
        orgId: orgId,
      },
    }
  );
};

export const Donation_bill_List = (paramsObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${DONATION_BILL_LIST}`, {
    headers: { Authorization: jwt_token },
    params: paramsObj,
  });
};

export const CreateTrasectionSlip = (formData) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(
    `${process.env.REACT_APP_API_URL}${CREATE_TRANSECTION_SLIP}`,
    formData,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

export const UpdateTrasectionSlip = (formData, slipId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${UPDATE_TRANSECTION_SLIP}/${slipId}`,
    formData,
    {
      headers: { Authorization: jwt_token },
    }
  );
};
export const UpdateTrasectionStatusSlip = (formData, slipId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${UPDATE_STATUS_TRANSECTION_SLIP}`,
    formData,
    {
      headers: { Authorization: jwt_token },
      params: { slipId },
    }
  );
};
export const CreateTrasectionSlip_Public = (formData) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}${CREATE_TRANSECTION_SLIP_PUBLIC}`,
    formData
  );
};
export const CreatePersoneMaster_Public = (formData) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}${CREATE_PERSONE_MASETR_PUBLIC}`,
    formData
  );
};

export const Show_Slip = (slipId, isResent) => {
  const jwt_token = localStorage.getItem("token");
  let paramObj = {
    slipId,
  };
  if (isResent) {
    paramObj["isResent"] = isResent;
  }
  return axios.get(`${process.env.REACT_APP_API_URL}${SHOW_SLIP}`, {
    headers: { Authorization: jwt_token },
    params: paramObj,
  });
};
export const Download_Slip = (formData) => {
  const jwt_token = localStorage.getItem("token");
  return axios.delete(
    `${process.env.REACT_APP_API_URL}${DOWNLOAD_SLIP}`,

    {
      headers: { Authorization: jwt_token },
      params: formData,
    }
  );
};
export const generateOtp = (formData) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(
    `${process.env.REACT_APP_API_URL}${DELETE_OTP_SLIP}/${formData.slipId}`,
    {
      headers: { Authorization: jwt_token },
      params: formData,
    }
  );
};
