import React, { useContext, useEffect, useState } from "react";
import { Edit } from "react-feather";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { DONATION_FEILD, INCOME, PaymentDropDown } from "../../constant";
import NoteContext from "../../context/NotesContext/NoteContext";
import OrganizationContext from "../../context/OrganizationContext/OrganizationContext";
import LoaderResoinse from "../../loader/loader";
import CreatableSelect from "react-select/creatable";
import ProductContext from "../../context/ProductContext/ProductContext";
import { maxLengthCheck } from "../../utils/helper";
import { DialCodeList } from "../../api/DialCodeApi/DialCodeApi";

const OrganizationAddModel = (props) => {
  const { onSubmit, close, handleCreateDonar } = props;
  const [inputValue, setInputValue] = useState("");
  const {
    modal,
    setDonar,
    donar,
    bank,
    setBank,
    donation,
    setDonation,
    amount,
    setAmount,
    paidamount,
    setPaidAmount,
    setNote,
    note,
    togglesubmit,
    accountDrop,
    personDrop,
    bankDrop,
    dueAmount,
    trustDrop,
    setTrustId,
    trustId,
    payModel,
    setPaymentMode,
    paymentMode,
    personDropdown,
    setPersonSearch,
    personSearch,
    dialCode,
    setDialCode,
  } = useContext(NoteContext);
  const { loaderShow } = useContext(OrganizationContext);
  const { setContact } = useContext(ProductContext);
  const [dialCodeJson, setDialCodeJson] = useState([]);

  const handleChangeBank = (e) => {
    setBank(e.target.value);
  };

  const searchDonar = (value) => {
    let searchValue = `${dialCode} ${value}`;
    personDropdown(null, searchValue, true, setInputValue);
    setPersonSearch(value);
    if (personDrop.length) {
      for (let a of personDrop) {
        if (value !== a.mobile && value.length === 10) {
          setInputValue(value);
        } else {
          setInputValue("");
        }
      }
    } else {
      setInputValue(searchValue);
    }
  };

  useEffect(() => {
    setDialCodeJson(DialCodeList());
  }, []);
  return (
    <>
      <Modal
        size="lg"
        isOpen={modal}
        toggle={() => close(setPersonSearch, setInputValue)}
      >
        {loaderShow === true && <LoaderResoinse />}
        <ModalHeader toggle={() => close(setPersonSearch, setInputValue)}>
          {togglesubmit ? (
            <span>{INCOME.ADD_INCOME}</span>
          ) : payModel ? (
            <span>
              {INCOME.PAY_NOW} <span> </span>{" "}
            </span>
          ) : (
            <span>
              {INCOME.EDIT_INCOME} <span> </span>{" "}
              <Edit
                style={{ width: "50px", height: "20px", color: "#7366ff" }}
              />
            </span>
          )}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader>
                  <h5>{INCOME.INCOME_DETAILS}</h5>
                </CardHeader>
                <CardBody>
                  <Form
                    className="needs-validation"
                    onSubmit={(e) => onSubmit(e)}
                  >
                    <Row>
                      <Col md="6 mb-4">
                        <Label htmlFor="validationCustom01">
                          {"Search Donar Mobile"}
                        </Label>
                        <div className="d-flex">
                          <select
                            className="form-control"
                            style={{ width: "67px" }}
                            onChange={(e) => setDialCode(e.target.value)}
                            value={dialCode}
                          >
                            <option value={""}>{"-- select --"}</option>{" "}
                            {dialCodeJson.map((elem) => {
                              return (
                                <option value={elem.code}>
                                  {elem.country}
                                </option>
                              );
                            })}
                          </select>
                          <Input
                            className="form-control"
                            name="amount"
                            type="number"
                            placeholder="Search ..."
                            id="validationCustom01"
                            value={personSearch}
                            onChange={(e) => searchDonar(e.target.value)}
                            min={0}
                            maxLength={10}
                            onInput={maxLengthCheck}
                            disabled={
                              togglesubmit === false || payModel === true
                            }
                            onWheel={(e) => e.target.blur()}
                            required
                          />
                        </div>
                        <div className="invalid-feedback">
                          {"Please provide a valid ORG Name."}
                        </div>
                      </Col>
                      <Col md="6 mb-4">
                        <Label htmlFor="validationCustom01">
                          {DONATION_FEILD.DONAR}
                        </Label>
                        {/* <select
                          className="form-select"
                          name="orderStatus"
                          typeof="number"
                          value={donar}
                          onChange={(e) => setDonar(e.target.value)}
                          disabled={togglesubmit === false || payModel === true}
                          required
                        >
                          <option value="">--select--</option>
                          {personDrop.map((elem) => (
                            <option
                              value={elem.personId}
                              selected={
                                elem.personId === donar ? "selected" : ""
                              }
                            >
                              {elem.name}
                            </option>
                          ))}
                        </select> */}
                        <CreatableSelect
                          aria-labelledby="aria-label"
                          inputId="aria-example-input"
                          name="aria-live-color"
                          onChange={(value) => setDonar(value)}
                          options={personDrop.map((elem, i) => {
                            return {
                              value: elem.personId,
                              label: elem.name,
                            };
                          })}
                          value={donar}
                          onCreateOption={handleCreateDonar}
                          isSearchable={true}
                          inputValue={inputValue}
                          isDisabled={
                            togglesubmit === false || payModel === true
                          }
                        />
                        <div className="invalid-feedback">
                          {"Please provide a valid ORG Name."}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6 mb-4">
                        <Label htmlFor="validationCustom01">
                          {DONATION_FEILD.TRUST}
                        </Label>
                        <select
                          className="form-select"
                          name="orderStatus"
                          typeof="number"
                          value={trustId}
                          onChange={(e) => setTrustId(e.target.value)}
                          disabled={payModel === true}
                          required
                        >
                          <option value="">--select--</option>
                          {trustDrop.map((elem) => (
                            <option
                              value={elem.trustId}
                              key={elem.trustId}
                              selected={
                                elem.trustId === trustId ? "selected" : ""
                              }
                            >
                              {elem.trustName}
                            </option>
                          ))}
                        </select>
                        <div className="invalid-feedback">
                          {"Please provide a valid ORG Name."}
                        </div>
                      </Col>

                      <Col md="6 mb-4">
                        <Label htmlFor="validationCustom01">
                          {DONATION_FEILD.DONATION}
                        </Label>
                        <select
                          className="form-select"
                          name="orderStatus"
                          typeof="number"
                          value={donation}
                          onChange={(e) => setDonation(e.target.value)}
                          disabled={payModel === true}
                          required
                        >
                          <option value="">--select--</option>
                          {accountDrop.map((elem) => (
                            <option
                              value={elem.accountId}
                              selected={
                                elem.accountId === donation ? "selected" : ""
                              }
                            >
                              {elem.name}
                            </option>
                          ))}
                        </select>
                        <div className="invalid-feedback">
                          {"Please provide a valid ORG Name."}
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="6 mb-4">
                        <Label htmlFor="validationCustom01">
                          {DONATION_FEILD.AMOUNT}
                        </Label>
                        <Input
                          className="form-control"
                          name="amount"
                          type="number"
                          placeholder="Amount"
                          id="validationCustom01"
                          onChange={(e) => setAmount(e.target.value)}
                          value={amount}
                          onWheel={(e) => e.target.blur()}
                          // disabled={togglesubmit ? "" : "disabled"}
                          required
                        />
                        <div className="invalid-feedback">
                          {"Please provide a valid ORG Name."}
                        </div>
                      </Col>
                      <Col md="6 mb-4">
                        <Label htmlFor="validationCustom01">
                          {DONATION_FEILD.DUE_AMOUNT}
                        </Label>
                        <Input
                          className="form-control"
                          name="amount"
                          type="number"
                          placeholder="Due Amount"
                          id="validationCustom01"
                          // onChange={(e) => setDouAmount(e.target.value)}
                          value={dueAmount}
                          onWheel={(e) => e.target.blur()}
                          disabled
                        />
                        <div className="invalid-feedback">
                          {"Please provide a valid ORG Name."}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="4 mb-4">
                        <Label htmlFor="validationCustom01">
                          {DONATION_FEILD.PAID_AMOUNT}
                        </Label>
                        <Input
                          className="form-control"
                          name="amount"
                          type="number"
                          placeholder="Paid Amount"
                          id="validationCustom01"
                          onChange={(e) => setPaidAmount(e.target.value)}
                          onWheel={(e) => e.target.blur()}
                          value={paidamount}
                          disabled={
                            togglesubmit === true
                              ? "disabled"
                              : payModel === true
                              ? ""
                              : "disabled"
                          }
                        />
                        <div className="invalid-feedback">
                          {"Please provide a valid ORG Name."}
                        </div>
                      </Col>
                      <Col md="4 mb-4">
                        <Label htmlFor="validationCustom01">
                          {DONATION_FEILD.BANK}
                        </Label>
                        <select
                          className="form-select"
                          name="orderStatus"
                          typeof="number"
                          value={bank}
                          onChange={(e) => handleChangeBank(e)}
                          disabled={paidamount === "" || togglesubmit === true}
                          required
                        >
                          <option value="">--select--</option>
                          {bankDrop.map((elem) => (
                            <option
                              value={elem.bankId}
                              selected={elem.bankId === bank ? "selected" : ""}
                            >
                              {elem.name}
                            </option>
                          ))}
                        </select>
                        <div className="invalid-feedback">
                          {"Please provide a valid ORG Name."}
                        </div>
                      </Col>
                      <Col md="4 mb-4">
                        <Label htmlFor="validationCustom01">
                          {DONATION_FEILD.PAYMENTMODE}
                        </Label>
                        <select
                          className="form-select"
                          name="orderStatus"
                          typeof="number"
                          value={paymentMode}
                          onChange={(e) => setPaymentMode(e.target.value)}
                          // required
                        >
                          <option value="">--select--</option>
                          {PaymentDropDown.map((elem) => (
                            <option
                              value={elem.label}
                              selected={
                                elem.label === paymentMode ? "selected" : ""
                              }
                            >
                              {elem.label}
                            </option>
                          ))}
                        </select>
                        <div className="invalid-feedback">
                          {"Please provide a valid ORG Name."}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12" mb="4">
                        <Label htmlFor="validationCustom03">
                          {DONATION_FEILD.NOTE}
                        </Label>
                        <Input
                          className="form-control"
                          name="Note"
                          type="textarea"
                          id="validationCustome22"
                          placeholder="Note"
                          rows="5"
                          onChange={(e) => setNote(e.target.value)}
                          value={note}
                        />
                      </Col>
                    </Row>

                    <Row style={{ paddingTop: "30px" }}>
                      <button
                        className="btn btn-primary"
                        type="submit"
                        value="Submit"
                      >
                        {"Submit"}
                      </button>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
};

export default OrganizationAddModel;
