import { NoteAddSharp } from "@material-ui/icons";
import BloodtypeIcon from "@mui/icons-material/Bloodtype";
import SynagogueIcon from "@mui/icons-material/Synagogue";
import TempleHinduIcon from "@mui/icons-material/TempleHindu";
import {
  Home,
  Users,
  Table,
  Key,
  Briefcase,
  DollarSign,
  TrendingUp,
  TrendingDown,
} from "react-feather";

export const MENUITEMS = [
  {
    menutitle: "Jay Swaminarayan",

    Items: [
      {
        path: `${process.env.PUBLIC_URL}/dashboard/ecommerce`,
        icon: Home,
        title: "Dashboard",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/Organization/Organizationa`,
        icon: Table,
        title: "organization",
        type: "link",
      },
      {
        title: "Roles & Privillages",
        icon: Key,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/PrivillageManager/PrivillageManager`,
            title: "Privillage Manager",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/ModualManager/ModualManager`,
            title: "Module Manager",
            type: "link",
          },
        ],
      },
    ],
  },
];

export const SELLER_MENUITEMS = [
  {
    menutitle: "Jay Swaminarayan",

    Items: [
      {
        path: `${process.env.PUBLIC_URL}/dashboard/ecommerce`,
        icon: Home,
        title: "Dashboard",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/Temple/temple`,
        icon: SynagogueIcon,
        title: "Tample Master",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/DonarMaster/donar`,
        icon: Users,
        title: "Donar (Party)",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/Notes/notes`,
        icon: NoteAddSharp,
        title: "Notes",
        type: "link",
      },
    ],
  },
];
export const EVENT_COORDINATOR = [
  {
    menutitle: "Jay Swaminarayan",

    Items: [
      {
        path: `${process.env.PUBLIC_URL}/dashboard/ecommerce`,
        icon: Home,
        title: "Dashboard",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/DonarMaster/donar`,
        icon: Users,
        title: "Birthday Profile",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/Notes/notes`,
        icon: NoteAddSharp,
        title: "Notes",
        type: "link",
      },
    ],
  },
];
export const TEMPLE = [
  {
    menutitle: localStorage.getItem("templeName")
      ? localStorage.getItem("templeName")
      : "Jay Swaminarayan",

    Items: [
      {
        path: `${process.env.PUBLIC_URL}/dashboard/ecommerce`,
        icon: Home,
        title: "Dashboard",
        type: "link",
      },
      {
        title: "Donation",
        icon: BloodtypeIcon,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/Donation/income`,
            title: "Donation List",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/Donation/DonationAdd`,
            title: "Donation Add",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/Expanse/expanse`,
            title: "Expanse List",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/Donation/ExpanseAdd`,
            title: "Expanse Add",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/Income/income`,
            title: "Pay Installment",
            type: "link",
          },
        ],
      },
      {
        path: `${process.env.PUBLIC_URL}/Temple_Trust/templeTrust`,
        icon: TempleHinduIcon,
        title: "Temple Trust",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/BankMaster/bankMaster`,
        icon: Briefcase,
        title: "Bank Master",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/DonationMaster/DonationMaster`,
        icon: DollarSign,
        title: "Donation Master",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/DonarMaster/donar`,
        icon: Users,
        title: "Donar (Party)",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/Income/income`,
        icon: TrendingUp,
        title: "Income",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/Expance/expance`,
        icon: TrendingDown,
        title: "Expance",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/Notes/notes`,
        icon: NoteAddSharp,
        title: "Notes",
        type: "link",
      },
    ],
  },
];

export const CASHIER = [
  {
    menutitle: localStorage.getItem("templeName")
      ? localStorage.getItem("templeName")
      : "Jay Swaminarayan",

    Items: [
      {
        path: `${process.env.PUBLIC_URL}/dashboard/ecommerce`,
        icon: Home,
        title: "Dashboard",
        type: "link",
      },
      {
        title: "Donation",
        icon: BloodtypeIcon,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/Donation/income`,
            title: "Donation List",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/Donation/DonationAdd`,
            title: "Donation Add",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/Expanse/expanse`,
            title: "Expanse List",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/Donation/ExpanseAdd`,
            title: "Expanse Add",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/Income/income`,
            title: "Pay Installment",
            type: "link",
          },
        ],
      },
      {
        path: `${process.env.PUBLIC_URL}/DonarMaster/donar`,
        icon: Users,
        title: "Donar (Party)",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/Income/income`,
        icon: TrendingUp,
        title: "Income",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/Expance/expance`,
        icon: TrendingDown,
        title: "Expance",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/Notes/notes`,
        icon: NoteAddSharp,
        title: "Notes",
        type: "link",
      },
    ],
  },
];
