import React, { useContext } from "react";
import { useNavigate, useParams } from "react-router";
import { Card, CardBody, CardHeader, Col, Container } from "reactstrap";
import PublicPageContext from "../../context/PublicPageContext/PublicPageContext";

const ThankyouPage = () => {
  const { bankId, trustUuid, orgId, templeUuid } = useParams();
  const history = useNavigate();
  const { publicPageThankYou } = useContext(PublicPageContext);
  const redirect = () => {
    if (publicPageThankYou === true) {
      history(`/donation/${orgId}/${templeUuid}/${trustUuid}/${bankId}`);
    } else {
      history(`/DonarPage/${orgId}/${templeUuid}`);
    }
  };

  return (
    <Container fluid={true}>
      <div style={{ backgroundColor: "#e3dbf7c0", height: "100vh" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <Col sm="6" md="6">
            <Card>
              <CardHeader>
                <h5>{"Thank You 😊"}</h5>
              </CardHeader>
              <CardBody>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <p className="mb-0" style={{ fontSize: "20px" }}>
                    {publicPageThankYou === true
                      ? "Donation Accepted ✅"
                      : "Donar Created ✅"}
                  </p>
                </div>

                <div
                  style={{
                    paddingTop: "2rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <button
                    className="btn btn-primary"
                    onClick={() => redirect()}
                  >
                    Submit another response
                  </button>
                </div>
              </CardBody>
            </Card>
          </Col>
        </div>
      </div>
    </Container>
  );
};

export default ThankyouPage;
