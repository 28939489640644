import React, { useContext } from "react";
import { Edit } from "react-feather";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { NOTES, NOTE_CONTEXT } from "../../constant";
import NoteContext from "../../context/NoteContext/NoteContext";
import OrganizationContext from "../../context/OrganizationContext/OrganizationContext";
import LoaderResoinse from "../../loader/loader";

const NotesAddModel = (props) => {
  const { onSubmit, close } = props;
  const { modal, title, setTitle, note, setNote, togglesubmit } =
    useContext(NoteContext);
  const { loaderShow } = useContext(OrganizationContext);

  return (
    <>
      <Modal size="lg" isOpen={modal} toggle={() => close()}>
        {loaderShow === true && <LoaderResoinse />}
        <ModalHeader toggle={() => close()}>
          {togglesubmit ? (
            <span>{NOTES.ADD_NOTES}</span>
          ) : (
            <span>
              {NOTES.EDIT_NOTES} <span> </span>{" "}
              <Edit
                style={{ width: "50px", height: "20px", color: "#7366ff" }}
              />
            </span>
          )}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader>
                  <h5> {NOTES.NOTES_DETAILS}</h5>
                </CardHeader>
                <CardBody>
                  <Form
                    className="needs-validation"
                    onSubmit={(e) => onSubmit(e)}
                  >
                    <Row>
                      <Col md="12 mb-4">
                        <Label htmlFor="validationCustom01">
                          {NOTE_CONTEXT.TITLE}
                        </Label>
                        <Input
                          className="form-control"
                          name="fristName"
                          type="text"
                          placeholder="Title"
                          id="validationCustom01"
                          onChange={(e) => setTitle(e.target.value)}
                          value={title}
                          required
                        />
                        <div className="invalid-feedback">
                          {"Please provide a valid ORG Name."}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12 mb-4">
                        <Label htmlFor="validationCustom02">
                          {NOTE_CONTEXT.NOTE}
                        </Label>
                        <textarea
                          className="form-control"
                          name="address"
                          id="validationCustom09"
                          type="text"
                          placeholder="Note"
                          onChange={(e) => setNote(e.target.value)}
                          value={note}
                          rows="10"
                          required
                        />
                        <div className="invalid-feedback">
                          {"Please provide a valid Email."}
                        </div>
                      </Col>
                    </Row>

                    <Row style={{ paddingTop: "30px" }}>
                      <button
                        className="btn btn-primary"
                        type="submit"
                        value="Submit"
                        disabled={loaderShow === true ? "desabled" : ""}
                      >
                        {"Submit"}
                      </button>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
};

export default NotesAddModel;
