import React, { useContext } from 'react';
import { Edit } from 'react-feather';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  Input,
  Label,
  Media,
  Modal,
  ModalBody,
  ModalHeader,
  Row
} from 'reactstrap';
import { ORGANIZATION, TEMPLE_MASTER, ValidationLabel } from '../../constant';
import OrganizationContext from '../../context/OrganizationContext/OrganizationContext';
import TempleContext from '../../context/TempleContext/TempleContext';
import LoaderResoinse from '../../loader/loader';
import { maxLengthCheck } from '../../utils/helper';

const TempleAddModel = (props) => {
  const { onSubmit, readUrl, close, error } = props;
  const {
    modal,
    togglesubmit,
    templeName,
    setTempleName,
    mobile,
    setMobile,
    email,
    setEmail,
    panCardNo,
    setPanCardNo,
    gstNumber,
    setGstNumber,
    address,
    setAddress,
    city,
    setCity,
    state,
    setState,
    country,
    setCountry,
    image,
    phone,
    setPhone,
    userEmail,
    setuserEmail,
    password,
    setPassword,
    confirmPassword,
    setconfirmPassword,
    setName,
    name
  } = useContext(TempleContext);
  const { loaderShow } = useContext(OrganizationContext);
  return (
    <>
      <Modal size="lg" isOpen={modal} toggle={() => close()}>
        {loaderShow === true && <LoaderResoinse />}
        <ModalHeader toggle={() => close()}>
          {togglesubmit ? (
            <span>{TEMPLE_MASTER.ADD_TEMPLE_MASTER}</span>
          ) : (
            <span>
              {TEMPLE_MASTER.EDIT_TEMPLE_MASTER} <span> </span>{' '}
              <Edit style={{ width: '50px', height: '20px', color: '#7366ff' }} />
            </span>
          )}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader>
                  <h5> {TEMPLE_MASTER.TEMPLE_MASTER_DETAILS}</h5>
                </CardHeader>
                <CardBody>
                  <Form className="needs-validation" onSubmit={(e) => onSubmit(e)}>
                    <Row>
                      <Col md="4 mb-4">
                        <div className="user-image">
                          <div className="avatar">
                            <Media
                              body
                              alt=""
                              src={image}
                              style={{
                                width: '9rem',
                                height: '9rem',
                                borderRadius: '45rem',
                                border: '1px solid '
                              }}
                              data-intro="This is Profile image"
                            />
                          </div>
                          <div className="icon-wrapper" data-intro="Change Profile image here">
                            <i className="icofont icofont-pencil-alt-5">
                              <Input
                                className="upload"
                                type="file"
                                name="templeImage"
                                onChange={(e) => readUrl(e)}
                                style={{
                                  border: '2px solid red',
                                  width: '10rem',
                                  position: 'absolute',
                                  bottom: '-1rem',
                                  height: '12rem'
                                }}
                              />
                            </i>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12 mb-4">
                        <Label
                          htmlFor="validationCustom01"
                          className={error && templeName.length <= 0 ? 'validation' : 'non-validation'}
                        >
                          {error && templeName.length <= 0
                            ? ValidationLabel.templeNameRequired
                            : ValidationLabel.templeName}
                        </Label>
                        <Input
                          className={`${error && name.length <= 0 ? 'inputValidation' : ''} "form-control`}
                          name="templeName"
                          type="text"
                          placeholder="Temple Name"
                          id="validationCustom01"
                          onChange={(e) => setTempleName(e.target.value)}
                          value={templeName}
                        />
                        <div className="invalid-feedback">{'Please provide a valid ORG Name.'}</div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6 mb-4">
                        <Label htmlFor="validationCustom03">{'Email'}</Label>
                        <Input
                          className="form-control"
                          name="email"
                          type="email"
                          id="validationCustome22"
                          placeholder="Email"
                          onChange={(e) => setEmail(e.target.value)}
                          value={email}
                        />
                      </Col>
                      <Col md="6 mb-4">
                        <Label htmlFor="validationCustom01">{'Mobile'}</Label>
                        <Input
                          className={'form-control'}
                          name="mobile"
                          type="number"
                          id="validationCustome22"
                          placeholder="Mobile Number"
                          onChange={(e) => setMobile(e.target.value)}
                          value={mobile}
                          min={'0'}
                          maxLength={10}
                          onInput={maxLengthCheck}
                          onWheel={(e) => e.target.blur()}
                        />
                        <div className="invalid-feedback">{'Please provide a valid ORG Name.'}</div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6 mb-4">
                        <Label htmlFor="validationCustom03">{'Pancard'}</Label>
                        <Input
                          className={'form-control'}
                          name="account_no"
                          type="text"
                          id="validationCustome22"
                          placeholder="Account No."
                          onChange={(e) => setPanCardNo(e.target.value)}
                          value={panCardNo}
                          min={'0'}
                          maxLength={10}
                          onInput={maxLengthCheck}
                        />
                      </Col>
                      <Col md="6 mb-4">
                        <Label htmlFor="validationCustom06">{'GST Number'}</Label>
                        <Input
                          className="form-control"
                          name="gstNumber"
                          id="validationCustom06"
                          type="text"
                          placeholder="GST Number"
                          onChange={(e) => setGstNumber(e.target.value)}
                          value={gstNumber}
                          min={'0'}
                          maxLength={15}
                          onInput={maxLengthCheck}
                        />
                        <div className="invalid-feedback">{'Please provide a valid Address 1.'}</div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="4 mb-4">
                        <Label htmlFor="validationCustom06">{'City'}</Label>
                        <Input
                          className="form-control"
                          name="city"
                          id="validationCustom06"
                          type="text"
                          placeholder="City"
                          onChange={(e) => setCity(e.target.value)}
                          value={city}
                        />
                        <div className="invalid-feedback">{'Please provide a valid Address 1.'}</div>
                      </Col>
                      <Col md="4 mb-4">
                        <Label htmlFor="validationCustom06">{'State'}</Label>
                        <Input
                          className="form-control"
                          name="state"
                          id="validationCustom06"
                          type="text"
                          placeholder="State"
                          onChange={(e) => setState(e.target.value)}
                          value={state}
                        />
                        <div className="invalid-feedback">{'Please provide a valid Address 1.'}</div>
                      </Col>
                      <Col md="4 mb-4">
                        <Label htmlFor="validationCustom06">{'Country'}</Label>
                        <Input
                          className="form-control"
                          name="country"
                          id="validationCustom06"
                          type="text"
                          placeholder="Country"
                          onChange={(e) => setCountry(e.target.value)}
                          value={country}
                        />
                        <div className="invalid-feedback">{'Please provide a valid Address 1.'}</div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12 mb-4">
                        <Label htmlFor="validationCustom09">{'Address'}</Label>
                        <textarea
                          className="form-control"
                          name="address"
                          id="validationCustom09"
                          type="text"
                          placeholder="Address"
                          onChange={(e) => setAddress(e.target.value)}
                          value={address}
                        />
                        <div className="invalid-feedback">{'Please provide a valid State.'}</div>
                      </Col>
                    </Row>
                    <CardHeader>
                      <h5> User Details</h5>
                    </CardHeader>
                    <Row style={{ paddingTop: '20px' }}>
                      <Col md="4 mb-4">
                        <Label
                          htmlFor="validationCustom01"
                          className={error && name.length <= 0 ? 'validation' : 'non-validation'}
                        >
                          {error && name.length <= 0 ? ValidationLabel.nameRequired : ValidationLabel.name}
                        </Label>
                        <Input
                          className={`${error && name.length <= 0 ? 'inputValidation' : ''} "form-control`}
                          name="name"
                          type="text"
                          placeholder="Name"
                          id="validationCustom01"
                          onChange={(e) => setName(e.target.value)}
                          value={name}
                        />
                        <div className="invalid-feedback">{'Please provide a valid ORG Name.'}</div>
                      </Col>
                      <Col md="4 mb-4">
                        <Label
                          htmlFor="validationCustom01"
                          className={error && phone.length <= 0 ? 'validation' : 'non-validation'}
                        >
                          {error && phone.length <= 0 ? ORGANIZATION.PHONE_REQUIRED : ORGANIZATION.PHONE}
                        </Label>
                        <Input
                          className={`${error && phone.length <= 0 ? 'inputValidation' : ''} "form-control`}
                          name="phone"
                          type="number"
                          min={0}
                          placeholder="Phone"
                          id="validationCustom01"
                          onChange={(e) => setPhone(e.target.value)}
                          value={phone}
                          maxLength={10}
                          onInput={maxLengthCheck}
                          onWheel={(e) => e.target.blur()}
                        />
                        <div className="invalid-feedback">{'Please provide a valid ORG Name.'}</div>
                      </Col>
                      <Col md="4" mb="4">
                        <Label htmlFor="validationCustom03">{'Email'}</Label>
                        <Input
                          className="form-control"
                          name="email"
                          type="email"
                          id="validationCustome22"
                          placeholder="Email"
                          onChange={(e) => setuserEmail(e.target.value)}
                          value={userEmail}
                        />
                      </Col>
                    </Row>
                    {togglesubmit && (
                      <Row>
                        <Col md="6" mb="4">
                          <Label
                            htmlFor="validationCustom03"
                            className={
                              (error && password.length <= 0) || (error && password.length <= 8)
                                ? 'validation'
                                : 'non-validation'
                            }
                          >
                            {error && password.length <= 0
                              ? ValidationLabel.passwordRequired
                              : error && password.length <= 8
                              ? ValidationLabel.passwordMaxLength
                              : ValidationLabel.password}
                          </Label>
                          <Input
                            className={`${
                              (error && password.length <= 0) || (error && password.length <= 8)
                                ? 'inputValidation'
                                : ''
                            } "form-control`}
                            name="password"
                            type="password"
                            id="validationCustome22"
                            placeholder="Password "
                            onChange={(e) => setPassword(e.target.value)}
                            value={password}
                          />
                        </Col>

                        <Col md="6" mb="4">
                          <Label
                            htmlFor="validationCustom03"
                            className={
                              (error && confirmPassword.length <= 0) || (error && confirmPassword.length <= 8)
                                ? 'validation'
                                : 'non-validation'
                            }
                          >
                            {error && confirmPassword.length <= 0
                              ? ValidationLabel.confirmPasswordRequired
                              : error && confirmPassword.length <= 8
                              ? ValidationLabel.confirmPasswordRMaxLangth
                              : ValidationLabel.confirmPassword}
                          </Label>
                          <Input
                            className={`${
                              (error && confirmPassword.length <= 0) || (error && confirmPassword.length <= 8)
                                ? 'inputValidation'
                                : ''
                            } "form-control`}
                            name="conformPassword"
                            type="password"
                            id="validationCustome22"
                            placeholder="Password"
                            onChange={(e) => setconfirmPassword(e.target.value)}
                            value={confirmPassword}
                          />
                        </Col>
                      </Row>
                    )}
                    <Row style={{ paddingTop: '30px' }}>
                      <button
                        className="btn btn-primary"
                        type="submit"
                        value="Submit"
                        disabled={loaderShow === true ? 'desabled' : ''}
                      >
                        {'Submit'}
                      </button>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
};

export default TempleAddModel;
