import Skeleton from "react-loading-skeleton";
// import "react-loading-skeleton/dist/skeleton.css";
import { Card, CardBody, Col, Row } from "reactstrap";

export const DashboardSkeleton = () => {
  let length = 8;
  let sekeleton = [];
  for (let i = 0; i < length; i++) {
    sekeleton.push(
      <Col xl="1 xl-50 box-col-6" md="6" style={{ cursor: "pointer" }}>
        <Card className="ecommerce-widget">
          <CardBody className="support-ticket-font" style={{ padding: "10px" }}>
            <Row style={{ flexDirection: "column" }}>
              <div>
                <p
                  className="total-num counter"
                  style={{
                    fontSize: "12px",
                    fontWeight: 600,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  <Skeleton width={135} />
                </p>
              </div>
              <div>
                <div>
                  <ul>
                    <li>
                      <span className="product-stts txt-success ms-2 margin-zero">
                        <Skeleton height={12} />
                      </span>
                      <div>
                        <span>{<Skeleton height={12} />}</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </Row>
          </CardBody>
        </Card>
      </Col>
    );
  }
  return sekeleton.length && sekeleton;
};
