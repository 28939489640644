import React, { useContext } from "react";
import { Button, Col } from "reactstrap";
import { SelectOption } from "./helper";
import DonationMasterContext from "../context/DonatoinMasterContext/DonationMasterContext";

const PaginationForTable = () => {
  const {
    pageNumber,
    setpageNumber,
    recordsPerPage,
    setRecordsPerPage,
    totalRecords,
    setTotalRecords,
  } = useContext(DonationMasterContext);

  const handleRecord = (e) => {
    setRecordsPerPage(e.target.value);
    if (pageNumber * e.target.value >= totalRecords) {
      setpageNumber(Math.ceil(totalRecords / e.target.value));
    }
  };

  return (
    <>
      <div className="d-flex justify-content-end pt-2">
        <Col md="2 mb-4">
          <div className="d-flex gap-1">
            <div>
              <select
                name=""
                id=""
                className="form-select"
                onChange={(e) => handleRecord(e)}
              >
                {SelectOption.map((elm, index) => (
                  <option key={index} value={elm.value}>
                    {elm.name}
                  </option>
                ))}
              </select>
            </div>
            <Button
              onClick={() => setpageNumber(pageNumber - 1)}
              disabled={1 === pageNumber}
            >
              Prev
            </Button>
            <Button
              onClick={() => setpageNumber(pageNumber + 1)}
              disabled={Math.ceil(totalRecords / recordsPerPage) === pageNumber}
            >
              Next
            </Button>
          </div>
        </Col>
      </div>
    </>
  );
};

export default PaginationForTable;
