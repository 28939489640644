import React, { useContext } from "react";
import {
  Card,
  CardBody,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import { DONAR } from "../../constant";
import ProductContext from "../../context/ProductContext/ProductContext";
import { getFormattedDate } from "../../utils/helper";

const OrganizationDetailsModel = () => {
  const { ahoeDataModel, setahoeDataModel, donarDetails } =
    useContext(ProductContext);
  return (
    <>
      <Modal
        size="lg"
        isOpen={ahoeDataModel}
        toggle={() => setahoeDataModel(!ahoeDataModel)}
      >
        <ModalHeader toggle={() => setahoeDataModel(!ahoeDataModel)}>
          {DONAR.VIEW_DONER}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col sm="12">
              <Card>
                <CardBody style={{ padding: "0" }}>
                  <Card>
                    <div className="table-responsive">
                      <Table>
                        <tbody>
                          <tr>
                            <th scope="row">{"DOB"}</th>
                            <td>{donarDetails.dob}</td>
                          </tr>
                          <tr>
                            <th scope="row">{"DOD"}</th>
                            <td>
                              {donarDetails.dod
                                ? getFormattedDate(donarDetails.dod)
                                : "--"}
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">{"Name"}</th>
                            <td>{donarDetails.name}</td>
                          </tr>
                          <tr>
                            <th scope="row">{"Phone"}</th>
                            <td>{donarDetails.mobile}</td>
                          </tr>
                          <tr>
                            <th scope="row">{"Village"}</th>
                            <td>
                              {donarDetails.village === ""
                                ? "--"
                                : donarDetails.village}
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">{"Aadharcard"}</th>
                            <td>
                              {donarDetails.aadhaarCard === ""
                                ? "--"
                                : donarDetails.aadhaarCard}
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">{"Address"}</th>
                            <td>
                              {donarDetails.address === ""
                                ? "--"
                                : donarDetails.address}
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">{"Pan Card"}</th>
                            <td>
                              {donarDetails.panCardNo === ""
                                ? "--"
                                : donarDetails.panCardNo}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </Card>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
};

export default OrganizationDetailsModel;
