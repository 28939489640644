import React from "react";

const LoadingScreen = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      className="d-none"
      id="loading_qr"
    >
      <img src="/loading.gif" alt="" />
    </div>
  );
};

export default LoadingScreen;
