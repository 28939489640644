import React, { useContext, useEffect, useState } from "react";
import TempleItem from "./templeItem";
import TempleDetailsModel from "./templeDetailsModel";
import TempleAddModel from "./templeAddModel";
import { toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-phone-number-input/style.css";
import TopLoadingBar from "../topLoadingBar/topLoadingBar";
import { CLIENT_TYPE, ERROR_CODE } from "../../constant";
import {
  BankMaster_List_Enable_Desable,
  FILE_API,
} from "../../api/Bank Master api/BankMasterAPi";
import TempleContext from "../../context/TempleContext/TempleContext";
import {
  Create_Temple_Master_List,
  Detail_Temple_Master_List,
  Edit_Temple_Master_List,
  Temple_Master_List,
} from "../../api/TempleMasterApi/TempleMasterApi";
import OrganizationContext from "../../context/OrganizationContext/OrganizationContext";
import LogOut from "../../utils/logout";
import { useNavigate } from "react-router";

const Temple = () => {
  const {
    setformvalue,
    setahoeDataModel,
    setModal,
    modal,
    sortOrder,
    setSortOrder,
    sortBy,
    setSortBy,
    filter,
    editIndex,
    seteditIndex,
    bankId,
    setBankId,
    setIsAddHandle,
    progress,
    setProgress,
    togglesubmit,
    setToggle,
    setShowHanlde,
    isSwitchOn,
    setIsSwitchOn,
    templeName,
    setTempleName,
    mobile,
    setMobile,
    email,
    setEmail,
    panCardNo,
    setPanCardNo,
    gstNumber,
    setGstNumber,
    address,
    setAddress,
    city,
    setCity,
    state,
    setState,
    country,
    setCountry,
    image,
    setImage,
    phone,
    setPhone,
    userEmail,
    setuserEmail,
    password,
    setPassword,
    confirmPassword,
    setconfirmPassword,
    setName,
    name,
  } = useContext(TempleContext);

  const { loaderShow, setLoaderShow, responseLoader, setResponseLoader } =
    useContext(OrganizationContext);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [record, setRecord] = useState(0);
  const [error, setError] = useState(false);
  const history = useNavigate()

  /**
   * get bank data api call and deta fatch and useaEffect use to call getData() function call
   */
  const getData = async () => {
    if (sortOrder === "ASC" || sortOrder === "DSC" || filter.length) {
    } else {
      setResponseLoader(!responseLoader);
    }
    try {
      let paramsObj = {
        pageNumber: page,
        recordsPerPage: pageSize,
        sortOrder,
        sortBy,
      };
      if (filter.length) {
        delete paramsObj["pageNumber"];
        delete paramsObj["recordsPerPage"];
        delete paramsObj["sortOrder"];
        delete paramsObj["sortBy"];
      }
      if (filter.length && typeof filter === "string") {
        paramsObj["search"] = { name: filter };
      }
      if (filter >= 1 && typeof Number(filter) === "number") {
        paramsObj["search"] = { accountNumber: +filter };
      }
      const { data } = await Temple_Master_List(paramsObj);
      if (data.status === 200) {
        setformvalue(data.payload.data);
        setRecord(data.pager.totalRecords);
        setResponseLoader(false);
      } else {
        setformvalue([]);
      }
    } catch (error) {
      if (error?.response?.data?.status === ERROR_CODE.UNAUTHORIZED) {
        LogOut(history);
      }
      setResponseLoader(false);
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };
  useEffect(() => {
    getData();
  }, [page, pageSize, sortOrder, sortBy, filter]);

  useEffect(() => {
    setProgress(100);
  }, []);

  /**
   * Empty Feild function
   */

  const EmptyFeild = () => {
    setTempleName("");
    setMobile("");
    setEmail("");
    setPanCardNo("");
    setGstNumber("");
    setAddress("");
    setCity("");
    setCountry("");
    setState("");
    setImage("");
    setName("");
    setPhone("");
    setuserEmail("");
    setPassword("");
    setconfirmPassword("");
    setError(false);
    setLoaderShow(false);
  };

  /**
   * Fill Feild function
   */

  const FillFeild = () => {
    setTempleName(templeName);
    setMobile(mobile);
    setEmail(email);
    setPanCardNo(panCardNo);
    setGstNumber(gstNumber);
    setAddress(address);
    setCity(city);
    setCountry(country);
    setState(state);
    setImage(image);
    setName(name);
    setPhone(phone);
    setuserEmail(userEmail);
    setPassword(password);
    setconfirmPassword(confirmPassword);
  };

  /**
   * Add Model to click to open model
   */

  const AddModel = () => {
    setModal(true);
    setToggle(true);
  };

  /**
   * Add image api call function
   */

  const pageItemUploadFile = async ({ files, type }) => {
    let formData = new FormData();
    formData.append("type", type);
    formData.append("file", files[0]);
    try {
      const { data } = await FILE_API(formData);
      if (data.status === 200) {
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
        setImage(data.payload.imageUrl);
        return data.payload;
      } else {
        setformvalue([]);
      }
    } catch (error) {
      if (error?.response?.data?.status === ERROR_CODE.UNAUTHORIZED) {
        LogOut(history);
      }
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  const readUrl = async (event) => {
    if (event.target.files.length === 0) return;
    var mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      setImage(reader.result);
    };
  };

  /**
   * Edit time get data to call api function call
   */

  const editHandle = async (templeId) => {
    setBankId(templeId);
    setLoaderShow(!loaderShow);
    try {
      const { data } = await Detail_Temple_Master_List(templeId);
      if (data.status === 200) {
        setToggle(false);
        setIsAddHandle(false);
        seteditIndex(templeId);
        const payload = data.payload.data;
        const adminUser = payload.adminUser;
        setTempleName(payload ? payload.templeName : "");
        setMobile(payload ? payload.mobile : "");
        setEmail(payload ? payload.email : "");
        setPanCardNo(payload ? payload.panCardNo : "");
        setGstNumber(payload ? payload.gstNumber : "");
        setAddress(payload ? payload.address : "");
        setCity(payload ? payload.city : "");
        setState(payload ? payload.state : "");
        setCountry(payload ? payload.country : "");
        setName(adminUser ? adminUser.name : "");
        setPhone(adminUser ? adminUser.phone : "");
        setuserEmail(adminUser ? adminUser.email : "");
        setImage(payload ? payload.image : "");
        setLoaderShow(false);
        setModal(true);
      } else {
        setformvalue([]);
      }
    } catch (error) {
      if (error?.response?.data?.status === ERROR_CODE.UNAUTHORIZED) {
        LogOut(history);
      }
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
      setLoaderShow(false);
    }
  };

  /**
   * Add bank master and edit bank master api call
   */
  const onSubmit = async (e) => {
    e.preventDefault();
    if (
      togglesubmit
        ? templeName.length === 0 ||
          password.length === 0 ||
          password.length <= 8 ||
          confirmPassword.length === 0 ||
          confirmPassword.length <= 8 ||
          name.length === 0 ||
          phone.length === 0
        : templeName.length === 0
    ) {
      setError(true);
    } else {
      let formData;
      if (editIndex !== -1 && !togglesubmit) {
        setLoaderShow(!loaderShow);
        try {
          formData = {
            templeName,
            mobile,
            email,
            panCardNo: panCardNo,
            gstNumber: gstNumber,
            address,
            city,
            state,
            country,
            adminUser: {
              name,
              email: userEmail,
              phone,
              password,
              confirmPassword,
            },
          };
          if (e.target.templeImage && e.target.templeImage.files.length > 0) {
            const { imageUrl } = await pageItemUploadFile({
              files: e.target.templeImage.files,
              type: CLIENT_TYPE.ORGANIZATION,
            });
            formData["image"] = imageUrl;
          }
          const { data } = await Edit_Temple_Master_List(formData, bankId);
          if (data.status === 200) {
            toast.success(data.message, {
              autoClose: 1000,
            });
            getData();
            setModal(false);
            EmptyFeild();
          } else {
            FillFeild();
          }
        } catch (error) {
          if (error?.response?.data?.status === ERROR_CODE.UNAUTHORIZED) {
            LogOut(history);
          }
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,
            transition: Zoom,
          });
          setLoaderShow(false);
        }
      } else {
        setLoaderShow(!loaderShow);
        try {
          formData = {
            templeName,
            mobile,
            email,
            panCardNo,
            gstNumber,
            address,
            city,
            state,
            country,
            image,
            adminUser: {
              name,
              email,
              phone,
              password,
              confirmPassword,
            },
          };
          if (e.target.templeImage && e.target.templeImage.files.length > 0) {
            const { imageUrl } = await pageItemUploadFile({
              files: e.target.templeImage.files,
              type: CLIENT_TYPE.ORGANIZATION,
            });
            formData["image"] = imageUrl;
          }
          const { data } = await Create_Temple_Master_List(formData);
          if (data.status === 200) {
            toast.success(data.message, {
              autoClose: 1000,
              transition: Zoom,
            });
            getData();
            setModal(false);
            EmptyFeild();
          } else {
            FillFeild();
          }
        } catch (error) {
          if (error?.response?.data?.status === ERROR_CODE.UNAUTHORIZED) {
            LogOut(history);
          }
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,
            transition: Zoom,
          });
          setLoaderShow(false);
        }
      }
    }
  };

  /**
   *Show model open and details api call
   */

  const showHandle = async (templeId) => {
    setBankId(templeId);
    setLoaderShow(!loaderShow);
    try {
      const { data } = await Detail_Temple_Master_List(templeId);
      if (data.status === 200) {
        setahoeDataModel(true);
        setShowHanlde(data.payload.data);
        setLoaderShow(false);
      } else {
        setformvalue([]);
      }
    } catch (error) {
      if (error?.response?.data?.status === ERROR_CODE.UNAUTHORIZED) {
        LogOut(history);
      }
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
      setLoaderShow(false);
    }
  };

  /**
   * Enable and Desable bank master
   */

  const switch_onChange_handle = async (bankId, isActive) => {
    let formData = {
      bankId: bankId,
      isActive: isActive ? false : true,
    };
    try {
      const { data } = await BankMaster_List_Enable_Desable(formData);
      if (data.status === 200) {
        isActive
          ? toast.error(data.message, {
              autoClose: 1000,
              transition: Zoom,
            })
          : toast.success(data.message, {
              autoClose: 1000,
              transition: Zoom,
            });
        setIsSwitchOn(!isSwitchOn);
        getData();
      } else {
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
      }
    } catch (error) {
      if (error?.response?.data?.status === ERROR_CODE.UNAUTHORIZED) {
        LogOut(history);
      }
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  /**
   * Shorting Function call
   */

  const sorting = (col) => {
    if (sortOrder === "") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("ASC");
    }
    if (sortOrder === "ASC") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("DSC");
    }
    if (sortOrder === "DSC") {
      setSortBy();
      setSortOrder("");
    }
  };

  /**
   * Search filter function call
   */

  const handleFilter = (e) => {
    if (e.key === "Enter" || (e.key === "Backspace" && filter.length <= 1)) {
      getData();
    }
  };

  /**
   * popup Close function call
   */

  const close = () => {
    setModal(!modal);
    EmptyFeild();
  };

  return (
    <>
      <div>
        <TopLoadingBar progress={progress} setProgress={setProgress} />
        <TempleAddModel
          onSubmit={onSubmit}
          readUrl={readUrl}
          close={close}
          error={error}
        />
        <TempleDetailsModel />
        <TempleItem
          handleFilter={handleFilter}
          sorting={sorting}
          editHandle={editHandle}
          showHandle={showHandle}
          AddModel={AddModel}
          setPage={setPage}
          setPageSize={setPageSize}
          pageSize={pageSize}
          page={page}
          record={record}
          readUrl={readUrl}
          switch_onChange_handle={switch_onChange_handle}
        />
      </div>
    </>
  );
};
export default Temple;
