import * as React from "react";
import { useState } from "react";
import AdminDeshboardContext from "./AdminDeshboardContext";

const AdminDeshboardState = (props) => {
  const [val, setVal] = useState("");
  const [modal, setModal] = useState(false);
  const [isIncome, setIsIncome] = useState(false);
  const [accountId, setAccountId] = useState("");
  const [amount, setAmount] = useState("");
  const [paidAmount, setPaidAmount] = useState("");

  const [trustDrop, setTrustDrop] = useState([]);
  const [accountDrop, setAccountDrop] = useState([]);
  const [bankDrop, setBankDrop] = useState([]);

  const [formData, setFormData] = useState({
    mobile: "",
    name: "",
    village: "",
    panCardNo: "",
    aadhaarCard: "",
    byHand: "",
    dob: "",
    address: "",
    trustId: "",
    donationArray: [
      {
        accountId: "",
        amount: "",
      },
    ],
    bankId: "",
    paymentType: "",
    transactionNo: "",
    note: "",
  });

  return (
    <>
      <AdminDeshboardContext.Provider
        value={{
          val,
          setVal,
          modal,
          setModal,
          formData,
          setFormData,
          accountId,
          setAccountId,
          amount,
          setAmount,
          trustDrop,
          setTrustDrop,
          accountDrop,
          setAccountDrop,
          bankDrop,
          setBankDrop,
          isIncome,
          setIsIncome,
          paidAmount,
          setPaidAmount,
        }}
      >
        {props.children}
      </AdminDeshboardContext.Provider>
    </>
  );
};

export default AdminDeshboardState;
