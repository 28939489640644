import React from "react";

const QrAuthenticatedSuccess = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      className="d-none"
      id="success_qr"
    >
      <img src="/verified.gif" alt="" />
    </div>
  );
};

export default QrAuthenticatedSuccess;
