import React, { useContext } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Media,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import { BANK_MASTER } from "../../constant";
import OrgContext from "../../context/OrgContext/OrgContext";

const OrganizationDetailsModel = (props) => {
  const { ahoeDataModel, setahoeDataModel, showHandle } =
    useContext(OrgContext);

  return (
    <>
      <Modal
        size="lg"
        isOpen={ahoeDataModel}
        toggle={() => setahoeDataModel(!ahoeDataModel)}
      >
        <ModalHeader toggle={() => setahoeDataModel(!ahoeDataModel)}>
          {BANK_MASTER.VIWE_BANK_MASTER}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col sm="12">
              <Card>
                <CardBody style={{ padding: "0" }}>
                  <Card>
                    <div className="table-responsive">
                      <Table>
                        <CardHeader
                          className="flex-shrink-1"
                          style={{ display: "flex", padding: "0" }}
                        >
                          <th>
                            <Media
                              body
                              alt=""
                              src={showHandle.logo}
                              style={{
                                width: "5rem",
                                height: "5rem",
                                borderRadius: "45rem",
                                border: "1px solid #f0f0f0",
                              }}
                              data-intro="This is Profile image"
                            />
                          </th>
                          <h5 style={{ paddingTop: "33px" }}>
                            {BANK_MASTER.BANK_MASTER_DETAILS}
                          </h5>
                        </CardHeader>
                        <tbody>
                          <tr>
                            <th scope="row">{"Name"}</th>
                            <td>{showHandle.name}</td>
                          </tr>
                          <tr>
                            <th scope="row">{"Branch"}</th>
                            <td>{showHandle.branch}</td>
                          </tr>
                          <tr>
                            <th scope="row">{"IFSC Code"}</th>
                            <td>{showHandle.ifscCode}</td>
                          </tr>
                          <tr>
                            <th scope="row">{"Account No."}</th>
                            <td>{showHandle.accountNumber}</td>
                          </tr>
                          <tr>
                            <th scope="row">{"Address"}</th>
                            <td>{showHandle.address}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </Card>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
};

export default OrganizationDetailsModel;
