import React, { useContext } from 'react';
import { Temple_Trust_List } from '../../api/TempleTrustApi/TempleTrustApi';
import { ERROR_CODE } from '../../constant';
import LogOut from '../logout';
import { toast, Zoom } from 'react-toastify';
import AdminDeshboardContext from '../../context/AdminDeshboardContext/AdminDeshboardContext';
import { useNavigate } from 'react-router';

const useTempleTrustList = () => {
  const history = useNavigate();
  const { setTrustDrop } = useContext(AdminDeshboardContext);
  const TrustDopdown = async () => {
    let paramsObj = {
      showAll: true
    };
    try {
      const { data } = await Temple_Trust_List(paramsObj);
      if (data.status === 200) {
        setTrustDrop(data.payload.data);
      } else {
        setTrustDrop([]);
      }
    } catch (error) {
      if (error.response.data.status === ERROR_CODE.UNAUTHORIZED) {
        LogOut(history);
      }

      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom
      });
    }
  };
  return TrustDopdown;
};

export default useTempleTrustList;
