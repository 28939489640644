import React, { useContext } from "react";
import {
  Card,
  CardBody,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import { DonationAdd, DONATION_MASTER } from "../../constant";
import DonationMasterContext from "../../context/DonatoinMasterContext/DonationMasterContext";

const OrganizationDetailsModel = (props) => {
  const { ahoeDataModel, setahoeDataModel, showDonationMaster } = useContext(
    DonationMasterContext
  );
  return (
    <>
      <Modal
        size="lg"
        isOpen={ahoeDataModel}
        toggle={() => setahoeDataModel(!ahoeDataModel)}
      >
        <ModalHeader toggle={() => setahoeDataModel(!ahoeDataModel)}>
          {DONATION_MASTER.VIEW_DONATION_MASTER}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col sm="12">
              <Card>
                <CardBody style={{ padding: "0" }}>
                  <Card>
                    <div className="table-responsive">
                      <Table>
                        <tbody>
                          <tr>
                            <th scope="row">{DonationAdd.NAME}</th>
                            <td>{showDonationMaster.name}</td>
                          </tr>
                          <tr>
                            <th scope="row">{DonationAdd.TYPE}</th>
                            <td>
                              {showDonationMaster.type === 1
                                ? "Inward"
                                : "Outward"}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </Card>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
};

export default OrganizationDetailsModel;
