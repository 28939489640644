import React, { useContext, useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import AdminDeshboardContext from "../../context/AdminDeshboardContext/AdminDeshboardContext";
import OrgContext from "../../context/OrgContext/OrgContext";
import { Temple_Trust_List } from "../../api/TempleTrustApi/TempleTrustApi";
import {
  ACCOUNT_TYPE,
  ANALYSIS_TYPE_ARR,
  ERROR_CODE,
  IxAnalysis,
  RoleAccess,
} from "../../constant";
import LogOut from "../../utils/logout";
import { Zoom, toast } from "react-toastify";
import { Donation_MasterList } from "../../api/DonationMasterApi/DonationMasterApi";
import { BankMasterList } from "../../api/Bank Master api/BankMasterAPi";
import { useNavigate } from "react-router";
import DainamicButtons from "./CommonDeshboardButtons/DainamicButtons";
import StatisButtons from "./CommonDeshboardButtons/StatisButtons";
import OrganizationContext from "../../context/OrganizationContext/OrganizationContext";
import { DashboardSkeleton } from "../../loader/Skeleton";
import { DatePicker } from "antd";
import moment from "moment";
import { get_Income_Expanse_Analysis } from "../../api/Admin Deshboard/AdminDeshboardApi";
import {
  calculateProfitLossPercentage,
  conditionCheck,
  stringToColour,
  stringToLightColour,
} from "../../utils/helper";
import { Bar } from "react-chartjs-2";

const ButtonRedirect = (props) => {
  const { RangePicker } = DatePicker;
  const {
    buttonFormate,
    openDonationMasterModal,
    setModalExpanse,
    formData,
    setFormData,
  } = props;
  const {
    setModal,
    setAccountId,
    setAmount,
    setTrustDrop,
    setAccountDrop,
    setBankDrop,
    setIsIncome,
  } = useContext(AdminDeshboardContext);

  const [getData, setGetData] = useState([]);
  const [windowProperty, setWindowProperty] = useState({});
  const [chartDataIncome, setChartDataIncome] = useState({
    labels: "",
    datasets: [],
    plugins: {
      datalabels: {
        display: false,
        color: "white",
      },
    },
  });
  const defaultDateRange = [new Date(), new Date()];
  const [date, setDate] = useState(defaultDateRange);
  const [chartDataExpanse, setChartDataExpanse] = useState({
    labels: "",
    datasets: [],
    plugins: {
      datalabels: {
        display: false,
        color: "white",
      },
    },
  });

  const { setLoaderShow, responseLoader } = useContext(OrganizationContext);

  const { setTrustId } = useContext(OrgContext);

  const history = useNavigate();

  useEffect(() => {
    setWindowProperty({ innerWidth: window.innerWidth });
  }, [window.innerWidth]);

  const accountDropDown = async (trustId, type) => {
    let paramsObj = {
      search: { type: type, trustId: +trustId },
      showAll: true,
    };
    setLoaderShow(true);
    try {
      const { data } = await Donation_MasterList(paramsObj);
      if (data.status === 200) {
        setAccountDrop(data.payload.data);
        setLoaderShow(false);
      } else {
        setAccountDrop([]);
        setLoaderShow(false);
      }
    } catch (error) {
      if (error.response.data.status === ERROR_CODE.UNAUTHORIZED) {
        LogOut(history);
      }
      setLoaderShow(false);
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  const barChartOptions = {
    maintainAspectRatio: true,
    legend: {
      display: false,
    },
    plugins: {
      datalabels: {
        display: false,
      },
    },
  };

  const getIncomeExpanseAnalysis = async () => {
    let newFormData = {
      ...formData,
    };
    delete newFormData.templeId;
    if (
      formData.templeId !== "" &&
      formData.templeId !== null &&
      formData.templeId !== undefined
    ) {
      newFormData["templeId"] = formData.templeId;
    }
    try {
      const { data } = await get_Income_Expanse_Analysis(newFormData);
      if (data.status === 200) {
        setGetData(data.payload.data);
        setChartDataIncome({
          labels: data.payload.data?.xAxisData,
          datasets: Object?.keys(data.payload.data.yAxisData).map((key) => {
            let resObj = {
              label: key,
              data: data.payload.data.yAxisData[key].income,
              borderColor: stringToColour(key),
              backgroundColor: stringToLightColour(key),
              highlightFill: stringToLightColour(key),
              highlightStroke: stringToColour(key),
              borderWidth: 2,
              categoryPercentage: 0.3,
              barPercentage: 0.7,
            };
            if (key === "total") {
              resObj["order"] = 1;
              resObj["grouped"] = false;
              resObj["categoryPercentage"] = 0.6;
            }
            return resObj;
          }),

          plugins: {
            datalabels: {
              display: false,
              color: "white",
            },
          },
        });
        setChartDataExpanse({
          labels: data.payload.data?.xAxisData,
          datasets: Object?.keys(data.payload.data.yAxisData).map((key) => {
            let resObj = {
              label: key,
              data: data.payload.data.yAxisData[key].expense,
              borderColor: stringToColour(key),
              backgroundColor: stringToLightColour(key),
              highlightFill: stringToLightColour(key),
              highlightStroke: stringToColour(key),
              borderWidth: 2,
              categoryPercentage: 0.3,
              barPercentage: 0.7,
            };
            if (key === "total") {
              resObj["order"] = 1;
              resObj["grouped"] = false;
              resObj["categoryPercentage"] = 0.6;
            }
            return resObj;
          }),

          plugins: {
            datalabels: {
              display: false,
              color: "white",
            },
          },
        });
      } else {
      }
    } catch (error) {
      if (error.response.data.status === ERROR_CODE.UNAUTHORIZED) {
        LogOut(history);
      }
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  useEffect(() => {
    const isCheck = conditionCheck(formData.templeId);
    if (
      IxAnalysis.includes(+localStorage.getItem("roleId")) &&
      formData?.templeId &&
      isCheck
    ) {
      getIncomeExpanseAnalysis();
    }
  }, [formData]);

  const TrustDopdown = async () => {
    let paramsObj = {
      showAll: true,
    };
    setLoaderShow(true);
    try {
      const { data } = await Temple_Trust_List(paramsObj);
      if (data.status === 200) {
        setTrustDrop(data.payload.data);
        setLoaderShow(false);
      } else {
        setTrustDrop([]);
        setLoaderShow(false);
      }
    } catch (error) {
      if (error.response.data.status === ERROR_CODE.UNAUTHORIZED) {
        LogOut(history);
      }
      setLoaderShow(false);
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  const bankMaster = async (trustId) => {
    let paramObj = {
      search: { trustId: +trustId },
      showAll: true,
    };
    setLoaderShow(true);
    try {
      const { data } = await BankMasterList(paramObj);
      if (data.status === 200) {
        setBankDrop(data.payload.data);
        setLoaderShow(false);
      } else {
        setBankDrop([]);
        setLoaderShow(false);
      }
    } catch (error) {
      if (error.response.data.status === ERROR_CODE.UNAUTHORIZED) {
        LogOut(history);
      }
      setLoaderShow(false);
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  const onOpenModal = async (trustId, defaultAmount, accountId) => {
    setTrustId(trustId);
    setAmount(defaultAmount);
    await accountDropDown(trustId, ACCOUNT_TYPE.inward);
    await bankMaster(trustId);
    await TrustDopdown();
    setAccountId(accountId);
    setModal(true);
    setIsIncome(false);
  };
  const onOpenExpanseModal = async (trustId, defaultAmount, accountId) => {
    setTrustId(trustId);
    setAmount(defaultAmount);
    await accountDropDown(trustId, ACCOUNT_TYPE.outward);
    await bankMaster(trustId);
    await TrustDopdown();
    setAccountId(accountId);
    setModalExpanse(true);
    setIsIncome(false);
  };

  const openMultipelDonation = () => {
    history(`${process.env.PUBLIC_URL}/Donation/DonationAdd/Hisab_Kitab`);
  };
  const openMultipelExpanse = () => {
    history(`${process.env.PUBLIC_URL}/Donation/ExpanseAdd/Hisab_Kitab`);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleDateChange = (value) => {
    setDate(value);
    setFormData((prev) => {
      return {
        ...prev,
        startDate:
          value && value?.length
            ? moment(value[0]?.$d)?.startOf("D")?.format("YYYY-MM-DD HH:mm:SS")
            : moment()?.startOf("D")?.format("YYYY-MM-DD HH:mm:SS"),
        endDate:
          value && value?.length
            ? moment(value[1]?.$d)?.endOf("D")?.format("YYYY-MM-DD HH:mm:SS")
            : moment()?.endOf("D")?.format("YYYY-MM-DD HH:mm:SS"),
      };
    });
  };

  return (
    <Container fluid={true}>
      {IxAnalysis.includes(+localStorage.getItem("roleId")) && (
        <Card>
          <CardHeader>
            <div className="d-flex justify-content-between flex-wrap gap-5 ">
              <h5>Income & Expanse Analysis</h5>

              <div className="d-flex gap-4 chart-filter">
                {/* <RangePicker
                  onChange={(value) => handleDateChange(value)}
                  value={date}
                  name="rang"
                /> */}
                <input
                  className="form-control colorBlack"
                  type="date"
                  value={formData.startDate}
                  onChange={(e) => {
                    setFormData((prev) => {
                      return {
                        ...prev,
                        startDate: moment(e.target.value).format("YYYY-MM-DD"),
                      };
                    });
                  }}
                />
                <input
                  type="date"
                  className="form-control colorBlack"
                  value={formData.endDate}
                  onChange={(e) => {
                    setFormData((prev) => {
                      return {
                        ...prev,
                        endDate: moment(e.target.value).format("YYYY-MM-DD"),
                      };
                    });
                  }}
                />
                <div className="card-header-right-icon" style={{ height: "0" }}>
                  <select
                    className="button btn btn-primary"
                    onChange={(e) => handleChange(e)}
                    name="type"
                    value={formData.type}
                    style={{ height: "30px", padding: "0" }}
                  >
                    {ANALYSIS_TYPE_ARR.map((elem) => {
                      return <option value={elem.value}>{elem.name}</option>;
                    })}
                  </select>
                </div>
              </div>
            </div>
          </CardHeader>

          <Row style={{ padding: "30px 30px 0px 30px" }}>
            <Col md="6 mb-3">
              <Bar
                data={chartDataIncome}
                options={barChartOptions}
                width={1000}
                height={windowProperty.innerWidth > 406 ? 250 : 500}
              />
            </Col>
            <Col md="6 mb-3">
              <Bar
                data={chartDataExpanse}
                options={barChartOptions}
                width={1000}
                height={windowProperty.innerWidth > 406 ? 250 : 500}
              />
            </Col>
          </Row>
        </Card>
      )}

      <Card>
        <CardHeader>
          <Row
            style={{ padding: "30px 30px 0px 30px" }}
            className="justify-content-around "
          >
            {+localStorage.getItem("roleId") !== RoleAccess.ORG_ADMIN && (
              <StatisButtons
                click={openMultipelDonation}
                name={"Add Multipal Doantion"}
                xl={2}
              />
            )}
            {+localStorage.getItem("roleId") !== RoleAccess.ORG_ADMIN && (
              <StatisButtons
                click={() => openDonationMasterModal(1)}
                name={"Add New Donation"}
                xl={2}
              />
            )}
            {+localStorage.getItem("roleId") !== RoleAccess.ORG_ADMIN && (
              <StatisButtons
                click={openMultipelExpanse}
                name={"Add Multipal Expanse"}
                xl={2}
              />
            )}
            {+localStorage.getItem("roleId") !== RoleAccess.ORG_ADMIN && (
              <StatisButtons
                click={() => openDonationMasterModal(2)}
                name={"Add New Expanse"}
                xl={2}
              />
            )}
          </Row>
        </CardHeader>
      </Card>

      {+localStorage.getItem("roleId") !== RoleAccess.EVENT_COORDINATOR && (
        <Card>
          <CardHeader>
            <h5>Income</h5>
          </CardHeader>
          <Row style={{ padding: "30px 30px 0px 30px" }}>
            {+localStorage.getItem("roleId") !== RoleAccess.ORG_ADMIN && (
              <StatisButtons
                click={openMultipelDonation}
                name={"Add Multipal Doantion"}
              />
            )}
            {!responseLoader ? (
              buttonFormate.map((elem) => {
                return (
                  elem.type === 1 && (
                    <DainamicButtons
                      onOpenModal={
                        +localStorage.getItem("roleId") !==
                          RoleAccess.ORG_ADMIN && onOpenModal
                      }
                      accountId={elem.accountId}
                      trustId={elem.trustId}
                      defaultAmount={elem.defaultAmount}
                      name={elem.name}
                      amount={elem.amount}
                    />
                  )
                );
              })
            ) : (
              <DashboardSkeleton />
            )}
            {+localStorage.getItem("roleId") !== RoleAccess.ORG_ADMIN && (
              <StatisButtons
                click={() => openDonationMasterModal(1)}
                name={"Add New Donation"}
              />
            )}
          </Row>
        </Card>
      )}

      {+localStorage.getItem("roleId") !== RoleAccess.EVENT_COORDINATOR && (
        <Card>
          <CardHeader>
            <h5>Expanse</h5>
          </CardHeader>
          <Row style={{ padding: "30px 30px 0px 30px" }}>
            {+localStorage.getItem("roleId") !== RoleAccess.ORG_ADMIN && (
              <StatisButtons
                click={openMultipelExpanse}
                name={"Add Multipal Expanse"}
              />
            )}
            {!responseLoader ? (
              buttonFormate.map((elem) => {
                return (
                  elem.type === 2 && (
                    <DainamicButtons
                      onOpenModal={
                        +localStorage.getItem("roleId") !==
                          RoleAccess.ORG_ADMIN && onOpenExpanseModal
                      }
                      accountId={elem.accountId}
                      trustId={elem.trustId}
                      defaultAmount={elem.defaultAmount}
                      name={elem.name}
                      amount={elem.amount}
                    />
                  )
                );
              })
            ) : (
              <DashboardSkeleton />
            )}
            {+localStorage.getItem("roleId") !== RoleAccess.ORG_ADMIN && (
              <StatisButtons
                click={() => openDonationMasterModal(2)}
                name={"Add New Expanse"}
              />
            )}
          </Row>
        </Card>
      )}
    </Container>
  );
};

export default ButtonRedirect;
