import * as React from 'react';
import { useState } from 'react';
import ProductContext from './ProductContext';

const ProductState = (props) => {
  const [formvalue, setformvalue] = useState([]); //API -->

  const [name, setName] = useState(null);
  const [contact, setContact] = useState(null);
  const [village, setVillage] = useState(null);
  const [address, setAddress] = useState(null);
  const [pancard, setPancard] = useState(null);
  const [adharcard, setAdharcard] = useState(null);
  const [dialCode, setDialCode] = useState('+91');
  const [personId, setPersonId] = useState('');
  const [donarDetails, setDonarDetails] = useState('');
  const [date, setDate] = useState(null);

  const [ahoeDataModel, setahoeDataModel] = useState(false); //SHOW ORG DETAILS MODEL -->
  const [modal, setModal] = useState(false); //ADD DATA MODEL -->
  const [addbel, setAddBel] = useState(false); //ADD ORG BEL -->
  const [pageNumber, setpageNumber] = useState(1); //PAGINATION -->
  const [recordsPerPage, setRecordsPerPage] = useState(10); //PAGINATION -->
  const [sortOrder, setSortOrder] = useState('DESC'); //SORTING -->
  const [sortBy, setSortBy] = useState('createdAt'); //SORTING -->
  const [filter, setFilter] = useState(''); //SEARCHING FILTER -->
  const [isHovering, setIsHovering] = useState(false); //HOVWER SORTING -->
  const [isSwitchOn, setIsSwitchOn] = useState(false); //TOGGLE SWITCH -->
  const [url, setUrl] = useState('');
  const [editIndex, seteditIndex] = useState(-1); //EDIT -->
  const [totalRecord, setTotalRecord] = useState(); //TOTAL RECORD -->
  const [isAddHandle, setIsAddHandle] = useState(true); //PASS HIDE OR UNHIDE -->
  const [progress, setProgress] = useState(0);
  const [togglesubmit, setToggle] = useState(false);
  const [val, setVal] = useState('');

  return (
    <>
      <ProductContext.Provider
        value={{
          formvalue,
          setformvalue,
          setName,
          name,
          contact,
          setContact,
          village,
          setVillage,
          address,
          setAddress,
          pancard,
          setPancard,
          adharcard,
          setAdharcard,
          date,
          setDate,

          ahoeDataModel,
          setahoeDataModel,
          modal,
          setModal,
          addbel,
          setAddBel,
          pageNumber,
          setpageNumber,
          recordsPerPage,
          setRecordsPerPage,
          sortOrder,
          setSortOrder,
          sortBy,
          setSortBy,
          filter,
          setFilter,
          isHovering,
          setIsHovering,
          isSwitchOn,
          setIsSwitchOn,
          url,
          setUrl,
          editIndex,
          seteditIndex,
          totalRecord,
          setTotalRecord,
          isAddHandle,
          setIsAddHandle,
          progress,
          setProgress,
          togglesubmit,
          setToggle,
          personId,
          setPersonId,
          val,
          setVal,
          donarDetails,
          setDonarDetails,
          dialCode,
          setDialCode
        }}
      >
        {props.children}
      </ProductContext.Provider>
    </>
  );
};

export default ProductState;
