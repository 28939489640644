export const SERVICES = {
  STOCK: "/stock-management",
  HISAB_KITAB: "/hisab-kitab",
  _PUBLIC: "/hisab-kitab/public",
  GETWAY: "/gateway/admin",
};

export const V1 = "/api/v1";

/** LOGIN */

export const LOGIN_ = `${SERVICES._PUBLIC}${V1}/login`;
export const PASSWORD_CHANGE = `${SERVICES.HISAB_KITAB}${V1}/user/change-password`;

/** LOGIN */

/** USER_LIST */

export const USER_LIST = `${SERVICES.STOCK}${V1}/user`;

/** USER_LIST */

/** SUPPLIAR LIST*/
export const SUPPLIAR_LIST = `${SERVICES.STOCK}${V1}/client`;
export const ADD_SUPPLIAR_LIST = `${SERVICES.STOCK}${V1}/client`;
export const SUPPLIARLIST_DITAILS = `${SERVICES.STOCK}${V1}/client/detail`;
export const EDIT_SUPPLIAR_LIST = `${SERVICES.STOCK}${V1}/client`;
export const FILE = `${SERVICES._PUBLIC}${V1}/file`;
/** SUPPLIAR LIST*/

/**DONATION BILL */
export const DONATION_BILL_DETAILS = `${SERVICES.HISAB_KITAB}${V1}/personMaster/detail`;
export const DONATION_BILL_DETAILS_PUBLIC = `${SERVICES._PUBLIC}${V1}/personMaster/detail`;
export const DONATION_BILL_LIST = `${SERVICES.HISAB_KITAB}${V1}/transaction/slip`;
export const CREATE_TRANSECTION_SLIP = `${SERVICES.HISAB_KITAB}${V1}/transaction/slip`;
export const UPDATE_TRANSECTION_SLIP = `${SERVICES.HISAB_KITAB}${V1}/transaction/slip`;
export const UPDATE_STATUS_TRANSECTION_SLIP = `${SERVICES.HISAB_KITAB}${V1}/transaction/slip/status`;
export const CREATE_TRANSECTION_SLIP_PUBLIC = `${SERVICES._PUBLIC}${V1}/slip`;
export const CREATE_PERSONE_MASETR_PUBLIC = `${SERVICES._PUBLIC}${V1}/personMaster`;
export const SHOW_SLIP = `${SERVICES.HISAB_KITAB}${V1}/transaction/slip/details`;
export const DOWNLOAD_SLIP = `${SERVICES.HISAB_KITAB}${V1}/transaction/slip`;
export const DELETE_OTP_SLIP = `${SERVICES.HISAB_KITAB}${V1}/transaction/slip/otp/generate`;
/**DONATION BILL */

/** BANK_MASTER LIST*/
export const BANK_MASTER_LIST = `${SERVICES.HISAB_KITAB}${V1}/bankMaster`;
export const BANK_MASTER_STATEMENT = `${SERVICES.HISAB_KITAB}${V1}/bankMaster/statement`;
export const ADD_BANK_MASTER_LIST = `${SERVICES.HISAB_KITAB}${V1}/bankMaster`;
export const BANK_MASTER_DITAILS = `${SERVICES.HISAB_KITAB}${V1}/bankMaster/detail`;
export const EDIT_BANK_MASTER_LIST = `${SERVICES.HISAB_KITAB}${V1}/bankMaster`;
export const ENABLE_DESABLE_BANK_MASTER_LIST = `${SERVICES.HISAB_KITAB}${V1}/bankMaster/enableDisable`;
/** BANK_MASTER LIST*/

/** BANK_MASTER LIST*/
export const DONAR_LIST = `${SERVICES.HISAB_KITAB}${V1}/personMaster`;
export const ADD_DONAR_LIST = `${SERVICES.HISAB_KITAB}${V1}/personMaster`;
export const DONAR_DITAILS = `${SERVICES.HISAB_KITAB}${V1}/personMaster/detail`;
export const DONAR_STATEMENT = `${SERVICES.HISAB_KITAB}${V1}/personMaster/statement`;
export const EDIT_DONAR_LIST = `${SERVICES.HISAB_KITAB}${V1}/personMaster`;
export const ENABLE_DESABLE_DONAR_LIST = `${SERVICES.HISAB_KITAB}${V1}/personMaster/enableDisable`;
/** BANK_MASTER LIST*/

/**DONATION_MASTER LIST */
export const DONATION_MASTER_LIST = `${SERVICES.HISAB_KITAB}${V1}/accountMaster`;
export const BIRTHDAY = `${SERVICES.HISAB_KITAB}${V1}/personMaster/birthday`;
export const DEATHDAY = `${SERVICES.HISAB_KITAB}${V1}/personMaster/deathday`;
export const DONATION_MASTER_LIST_PUBLIC = `${SERVICES._PUBLIC}${V1}/accountMaster`;
export const DONATION_MASTER_STATEMENT = `${SERVICES.HISAB_KITAB}${V1}/accountMaster/statement`;
export const ADD_DONATION_MASTER_LIST = `${SERVICES.HISAB_KITAB}${V1}/accountMaster`;
export const DONATION_MASTER_DITAILS = `${SERVICES.HISAB_KITAB}${V1}/accountMaster/detail`;
export const EDIT_DONATION_MASTER_LIST = `${SERVICES.HISAB_KITAB}${V1}/accountMaster`;
export const ENABLE_DESABLE_LIST = `${SERVICES.HISAB_KITAB}${V1}/accountMaster/enableDisable`;
/**DONATION_MASTER LIST */

/**DONATION_ LIST */
export const DONATION_LIST = `${SERVICES.HISAB_KITAB}${V1}/transaction`;
export const DONATION_EXPORT = `${SERVICES.HISAB_KITAB}${V1}/transaction/export`;
export const ADD_DONATION_LIST = `${SERVICES.HISAB_KITAB}${V1}/transaction`;
export const DONATION_DITAILS = `${SERVICES.HISAB_KITAB}${V1}/transaction/detail`;
export const EDIT_DONATION_LIST = `${SERVICES.HISAB_KITAB}${V1}/transaction`;
/**DONATION_ LIST */

/**TEMPLE MASTER LIST */
export const TEMPLE_MASTER_LIST = `${SERVICES.HISAB_KITAB}${V1}/templeTrust`;
export const CREATE_TEMPLE_MASTER_LIST = `${SERVICES.HISAB_KITAB}${V1}/templeTrust`;
export const EDIT_TEMPLE_MASTER_LIST = `${SERVICES.HISAB_KITAB}${V1}/templeTrust`;
export const DETAIL_TEMPLE_MASTER_LIST = `${SERVICES.HISAB_KITAB}${V1}/templeTrust/detail`;
/**TEMPLE MASTER LIST */

/**TEMPLE TRUST LIST */
export const TEMPLE_TRUST_LIST = `${SERVICES.HISAB_KITAB}${V1}/templeTrust/trust`;
export const CREATE_TEMPLE_TRUST_LIST = `${SERVICES.HISAB_KITAB}${V1}/templeTrust/trust`;
export const EDIT_TEMPLE_TRUST_LIST = `${SERVICES.HISAB_KITAB}${V1}/templeTrust/trust`;
export const DETAIL_TEMPLE_TRUST_LIST = `${SERVICES.HISAB_KITAB}${V1}/templeTrust/trust/detail`;
/**TEMPLE TRUST LIST */

/**Note List */
export const NOTE_LIST = `${SERVICES.HISAB_KITAB}${V1}/my-notes`;
export const ADD_NOTE_LIST = `${SERVICES.HISAB_KITAB}${V1}/my-notes`;
export const NOTE_DITAILS = `${SERVICES.HISAB_KITAB}${V1}/my-notes/detail`;
export const EDIT_NOTE_LIST = `${SERVICES.HISAB_KITAB}${V1}/my-notes`;
/**Note List */

/** PRODUCT LIST*/
export const PRODUCT_LIST = `${SERVICES.STOCK}${V1}/product`;
export const ADD_PRODUCT_LIST = `${SERVICES.STOCK}${V1}/product`;
export const EDIT_PRODUCT_LIST = `${SERVICES.STOCK}${V1}/product`;
export const DITAILS_PRODUCT_LIST = `${SERVICES.STOCK}${V1}/product/detail`;
/** PRODUCT LIST*/

/** ORDER LIST*/
export const ORDER_LIST = `${SERVICES.STOCK}${V1}/order`;
export const ADD_ORDER_LIST = `${SERVICES.STOCK}${V1}/order`;
export const DETAIL_ORDER_LIST = `${SERVICES.STOCK}${V1}/order/detail`;
export const EDIT_ORDER_LIST = `${SERVICES.STOCK}${V1}/order`;
/** PRODUCT LIST*/

/** DASHBOARD*/
export const SUPER_ADMIN_DESHBOARD = `${SERVICES.STOCK}${V1}/dashboard/detail`;
export const GET_INCOME_EXPANSE_ANALYSIS = `${SERVICES.HISAB_KITAB}${V1}/templeTrust/income-expense-report`;
export const SEND_WHATSAPP_MSG = `${SERVICES.HISAB_KITAB}${V1}/personMaster/wish-birthday`;
/** DASHBOARD*/

/** ORGANIZATION LIST*/
export const ORGANIZATION_LIST = `${SERVICES.HISAB_KITAB}${V1}/organization`;
export const ADD_ORGANIZATION_LIST = `${SERVICES.HISAB_KITAB}${V1}/organization`;
export const EDIT_ORGANIZATION_LIST = `${SERVICES.HISAB_KITAB}${V1}/organization`;
export const DETAILS_ORGANIZATION_LIST = `${SERVICES.HISAB_KITAB}${V1}/organization/detail`;
export const ORG_ENABLE_DESABLE = `${SERVICES.HISAB_KITAB}${V1}/organization/enableDisable`;
/** ORGANIZATION LIST*/

/** PRIVILLAGE MASTER LIST*/
export const PRIVILLAGE_LIST = `${SERVICES.GETWAY}${V1}/privilege`;
export const ADD_PRIVILLAGE_LIST = `${SERVICES.GETWAY}${V1}/privilege`;
export const EDIT_PRIVILLAGE_LIST = `${SERVICES.GETWAY}${V1}/privilege`;
export const DELETE_PRIVILLAGE_LIST = `${SERVICES.GETWAY}${V1}/privilege`;
export const DETAILS_PRIVILLAGE_LIST = `${SERVICES.GETWAY}${V1}/privilege/detail`;
export const MODULE_ACCESS_LIST = `${SERVICES.GETWAY}${V1}/privilege/module/access`;
/** PRIVILLAGE MASTER LIST*/

/** MODUAL MASTER LIST*/
export const MODULE_LIST = `${SERVICES.GETWAY}${V1}/privilege/module`;
export const ADD_MODULE_LIST = `${SERVICES.GETWAY}${V1}/privilege/module`;
export const DETAILS_MODULE_LIST = `${SERVICES.GETWAY}${V1}/privilege/module/detail`;
export const EDIT_MODULE_LIST = `${SERVICES.GETWAY}${V1}/privilege/module`;
export const DELETE_MODULE_LIST = `${SERVICES.GETWAY}${V1}/privilege/module`;
/** MODUAL MASTER LIST*/

/** DASHBOARD*/
// export const SUPER_ADMIN_DESHBOARD = `${SERVICES.BYTEQR}${V1}/super_admin_dashboard/detail`;
// export const SELLER_DESHBOARD = `${SERVICES.BYTEQR}${V1}/seller_dashboard/detail`;
// export const NFC__LIST_STATUS_UPDATE = `${SERVICES.BYTEQR}${V1}/nfc_order/status`;
// export const NFC_LIST_DETAILS = `${SERVICES.BYTEQR}${V1}/nfc_order/generated-nfc-detail`;
// export const NFC_ORDER_LIST_DETAILS = `${SERVICES.BYTEQR}${V1}/nfc_order/detail`;
// export const NFC_ORDER_LIST_TITLE_UPDATE = `${SERVICES.BYTEQR}${V1}/nfc_order`;
/** DASHBOARD*/

/** SELLER PASSWORD CHANGE */
// export const PASSWORD_CHANGE = `${SERVICES.BYTEQR}${V1}/user/change-password`;
/** SELLER PASSWORD CHANGE */
