import React, { useContext } from 'react';
import { Edit } from 'react-feather';
import { Card, CardBody, CardHeader, Col, Form, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { ACCOUNT_TYPE, DonationAdd, DONATION_MASTER, ValidationLabel } from '../../constant';
import DonationMasterContext from '../../context/DonatoinMasterContext/DonationMasterContext';
import OrganizationContext from '../../context/OrganizationContext/OrganizationContext';
import LoaderResoinse from '../../loader/loader';

const OrganizationAddModel = (props) => {
  const { onSubmit, error, close } = props;
  const { modal, setName, name, date, setDate, togglesubmit, trustDrop, trustId, setTrustId, setAmount, amount } =
    useContext(DonationMasterContext);
  const { loaderShow } = useContext(OrganizationContext);

  return (
    <>
      <Modal size="lg" isOpen={modal} toggle={() => close()}>
        {loaderShow === true && <LoaderResoinse />}
        <ModalHeader toggle={() => close()}>
          {togglesubmit ? (
            <span>{DONATION_MASTER.ADD_DONATION_MASTER}</span>
          ) : (
            <span>
              {DONATION_MASTER.EDIT_DONATION_MASTER} <span> </span>{' '}
              <Edit style={{ width: '50px', height: '20px', color: '#7366ff' }} />
            </span>
          )}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader>
                  <h5> {DONATION_MASTER.DONATION_MASTER_DETAILS}</h5>
                </CardHeader>
                <CardBody>
                  <Form className="needs-validation" onSubmit={(e) => onSubmit(e)}>
                    <Row>
                      <Col md="6 mb-4">
                        <Label htmlFor="validationCustom01">{DonationAdd.TRUST}</Label>
                        <select
                          className="form-select"
                          name="orderStatus"
                          typeof="number"
                          value={trustId}
                          onChange={(e) => setTrustId(e.target.value)}
                        >
                          <option value="">--select--</option>
                          {trustDrop.map((elem) => {
                            return (
                              <option
                                value={elem.trustId}
                                key={elem.trustId}
                                selected={elem.trustId === trustId ? 'selected' : ''}
                              >
                                {elem.trustName}
                              </option>
                            );
                          })}
                        </select>
                        <div className="invalid-feedback">{'Please provide a valid ORG Name.'}</div>
                      </Col>
                      <Col md="6 mb-4">
                        <Label
                          htmlFor="validationCustom01"
                          className={error && name.length <= 0 ? 'validation' : 'non-validation'}
                        >
                          {error && name.length <= 0 ? ValidationLabel.nameRequired : ValidationLabel.name}
                        </Label>
                        <Input
                          className={`${error && name.length <= 0 ? 'inputValidation' : ''} "form-control`}
                          name="fristName"
                          type="text"
                          placeholder="Name"
                          id="validationCustom01"
                          onChange={(e) => setName(e.target.value)}
                          value={name}
                        />
                        <div className="invalid-feedback">{'Please provide a valid ORG Name.'}</div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6 mb-4">
                        <Label htmlFor="validationCustom01">{'Amount'}</Label>
                        <Input
                          className="form-control colorBlack"
                          name="transectionno"
                          type="number"
                          id="transectionno"
                          placeholder={'Amount'}
                          value={amount}
                          onChange={(e) => setAmount(e.target.value)}
                          onWheel={(e) => e.target.blur()}
                        />
                        <div className="invalid-feedback">{'Please provide a valid ORG Name.'}</div>
                      </Col>
                      <Col md="6 mb-4">
                        <Label htmlFor="validationCustom02">{DonationAdd.TYPE}</Label>
                        <select
                          className="form-select"
                          name="orderStatus"
                          typeof="number"
                          value={date}
                          onChange={(e) => setDate(e.target.value)}
                          required
                        >
                          <option value="">--select--</option>
                          <option value={ACCOUNT_TYPE.inward} selected={ACCOUNT_TYPE.inward === date ? 'selected' : ''}>
                            Inward
                          </option>
                          <option
                            value={ACCOUNT_TYPE.outward}
                            selected={ACCOUNT_TYPE.outward === date ? 'selected' : ''}
                          >
                            Outward
                          </option>
                        </select>
                        <div className="invalid-feedback">{'Please provide a valid Email.'}</div>
                      </Col>
                    </Row>
                    <Row style={{ paddingTop: '30px' }}>
                      <button
                        className="btn btn-primary"
                        type="submit"
                        value="Submit"
                        disabled={loaderShow === true ? 'disabled' : ''}
                      >
                        {'Submit'}
                      </button>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
};

export default OrganizationAddModel;
