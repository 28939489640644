import React, { useContext, useState } from 'react';
import './SlipDownloadeTemplateCss.css';
import { inWords } from '../../utils/helper';
import OrgContext from '../../context/OrgContext/OrgContext';
import moment from 'moment';
import { BANK_TYPE, SLIP_DOWNLOADE } from '../../constant';
import { NewWindow } from 'react-window-open';

const SlipDownloadeTemplatePrint = () => {
  const { slipData, setIsOpen, isOpen, setSlipData } = useContext(OrgContext);
  // const [slipData, setslipData] = useState(...slipData);

  let amount = 0;

  for (let index = 0; index < 7 - slipData.transactionDetails.length; index++) {
    setSlipData((prev) => {
      return { ...prev, transactionDetails: [...prev.transactionDetails, {}] };
    });
    // slipNewData.transactionDetails.push({});
  }

  return (
    <>
      {isOpen ? (
        <>
          <NewWindow
            onClose={() => setIsOpen(false)}
            width={600}
            height={800}
            // top={100}
            left={500}
          >
            <div id="printableArea">
              <div
                className="main-div"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <div className="second-div" id="invoice" style={{ width: '500.93px' }}>
                  <div
                    className="thard-div"
                    style={{
                      margin: '8px 8px 8px 8px',
                      backgroundColor: 'white',
                      paddingBottom: '0.1rem',
                      fontFamily: 'sans-serif'
                    }}
                  >
                    <div
                      className="fourth-div"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: 'red',
                        fontWeight: 'bold',
                        letterSpacing: '1px',
                        padding: '8ox 10px 8px 10px',
                        height: '2in'
                      }}
                    >
                      {/* <p style={{ margin: "0px" }}>
                        श्री स्वामिनारायणो विजयतेतराम्
                      </p> */}
                    </div>
                    {/* 
                    <div
                      className="main-perent"
                      style={{ margin: "0px 15px 15px 15px" }}
                    >
                      <div
                        className="main_content"
                        style={{
                          backgroundColor: "hsl(219deg 100% 95%)",
                          borderRadius: "18px",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          fontSize: "16px",
                        }}
                      >
                        <p style={{ margin: "0px" }}>
                          <h5 style={{ margin: "10px 0px 10px 0px" }}>
                            {slipData.temple.templeName
                              ? slipData.temple.templeName
                              : "--"}
                          </h5>
                        </p>
                        <p
                          className="main-content_p-tag"
                          style={{
                            padding: "0px 0px 5px 0px",
                            letterSpacing: "1px",
                            fontWeight: "600",
                            color: "black",
                            margin: "0px",
                            fontSize: "14px",
                          }}
                        >
                          {slipData.trust.trustName
                            ? slipData.trust.trustName
                            : "--"}
                        </p>
                        <p
                          className="main-content_p-tag"
                          style={{
                            padding: "0px 0px 5px 0px",
                            letterSpacing: "1px",
                            fontWeight: "600",
                            color: "black",
                            margin: "0px",
                            fontSize: "14px",
                          }}
                        >
                          {SLIP_DOWNLOADE.MOBILE}:{" "}
                          {slipData.temple.mobile
                            ? slipData.temple.mobile
                            : "--"}
                          | {SLIP_DOWNLOADE.EMAIL}:{" "}
                          {slipData.temple.email ? slipData.temple.email : "--"}
                        </p>
                        <p
                          className="main-content_p-tag"
                          style={{
                            padding: "0px 0px 5px 0px",
                            letterSpacing: "1px",
                            fontWeight: "600",
                            color: "black",
                            margin: "0px",
                            fontSize: "14px",
                          }}
                        >
                          {SLIP_DOWNLOADE.TRUST_REG}:{" "}
                          {slipData.trust.trustRegNo
                            ? slipData.trust.trustRegNo
                            : ""}
                          | {SLIP_DOWNLOADE.PAN_NO}:
                          {slipData.trust.panNo ? slipData.trust.panNo : "--"} |{" "}
                          {SLIP_DOWNLOADE.CERTI_NO}:
                          {slipData.trust.certNo ? slipData.trust.certNo : "--"}
                        </p>
                      </div>
                    </div> */}

                    {/* <!-- Table Slip Html and Css --> */}

                    <div
                      className="content-div"
                      style={{
                        border: '2px solid gray',
                        borderRadius: '18px',
                        height: '550px',
                        position: 'relative'
                      }}
                    >
                      <div style={{ margin: '12px 12px -12px 12px' }}>
                        <div
                          className="grid-container"
                          style={{
                            border: '1px solid gray',
                            borderBottom: 'none',
                            display: 'grid',
                            gridTemplateColumns: '80px 150px 90px 2fr',
                            gridGap: '1px',
                            width: 'auto',
                            margin: '0 auto',
                            padding: '8px 10px'
                          }}
                        >
                          <div
                            className="grid-item"
                            style={{
                              textAlign: 'center',
                              padding: '5px 0',
                              textAlign: 'start',
                              fontSize: '11px',
                              fontWeight: 600
                            }}
                          >
                            {SLIP_DOWNLOADE.NAME}
                          </div>
                          <div
                            className="grid-item"
                            style={{
                              textAlign: 'center',
                              padding: '5px 0',
                              textAlign: 'start',
                              fontSize: '11px',
                              fontWeight: 600,
                              gridColumn: '2 / span 3'
                            }}
                          >
                            {`: ${
                              slipData.transactionDetails[0].accountTransactionDetail.personDetail
                                ? slipData.transactionDetails[0].accountTransactionDetail.personDetail.name
                                : '--'
                            }`}
                          </div>
                          <div
                            className="grid-item"
                            style={{
                              textAlign: 'center',
                              padding: '5px 0',
                              textAlign: 'start',
                              fontSize: '11px',
                              fontWeight: 600
                            }}
                          >
                            {SLIP_DOWNLOADE.MOBILE_NO}
                          </div>
                          <div
                            className="grid-item"
                            style={{
                              textAlign: 'center',
                              padding: '5px 0',
                              textAlign: 'start',
                              fontSize: '11px',
                              fontWeight: 600
                            }}
                          >
                            {`: ${
                              slipData.transactionDetails[0].accountTransactionDetail.personDetail
                                ? slipData.transactionDetails[0].accountTransactionDetail.personDetail.mobile
                                : '--'
                            }`}
                          </div>
                          <div
                            className="grid-item"
                            style={{
                              textAlign: 'center',
                              padding: '5px 0',
                              textAlign: 'start',
                              fontSize: '11px',
                              fontWeight: 600
                            }}
                          >
                            {SLIP_DOWNLOADE.RECEIPT_DATE}
                          </div>
                          <div
                            className="grid-item"
                            style={{
                              textAlign: 'center',
                              padding: '5px 0',
                              textAlign: 'start',
                              fontSize: '11px',
                              fontWeight: 600
                            }}
                          >
                            {`: ${slipData.createdAt ? moment(slipData.createdAt).format('DD MMM, YYYY') : '--'}`}
                          </div>
                          <div
                            className="grid-item"
                            style={{
                              textAlign: 'center',
                              padding: '5px 0',
                              textAlign: 'start',
                              fontSize: '11px',
                              fontWeight: 600
                            }}
                          >
                            {SLIP_DOWNLOADE.PAN_NO}
                          </div>
                          <div
                            className="grid-item"
                            style={{
                              textAlign: 'center',
                              padding: '5px 0',
                              textAlign: 'start',
                              fontSize: '11px',
                              fontWeight: 600
                            }}
                          >
                            {`: ${
                              slipData.transactionDetails[0].accountTransactionDetail.personDetail?.panCardNo
                                ? slipData.transactionDetails[0].accountTransactionDetail.personDetail.panCardNo
                                : '--'
                            }`}
                          </div>
                          <div
                            className="grid-item"
                            style={{
                              textAlign: 'center',
                              padding: '5px 0',
                              textAlign: 'start',
                              fontSize: '11px',
                              fontWeight: 600
                            }}
                          >
                            {SLIP_DOWNLOADE.RECEIPT_NO}
                          </div>
                          <div
                            className="grid-item"
                            style={{
                              textAlign: 'center',
                              padding: '5px 0',
                              textAlign: 'start',
                              fontSize: '11px',
                              fontWeight: 600
                            }}
                          >
                            : {slipData.slipNo}
                          </div>
                          <div
                            className="grid-item"
                            style={{
                              textAlign: 'center',
                              padding: '5px 0',
                              textAlign: 'start',
                              fontSize: '11px',
                              fontWeight: 600
                            }}
                          >
                            {SLIP_DOWNLOADE.ADDRESS}
                          </div>
                          <div
                            className="grid-item"
                            style={{
                              textAlign: 'center',
                              padding: '5px 0',
                              textAlign: 'start',
                              fontSize: '11px',
                              fontWeight: 600,
                              gridColumn: '2 / span 3'
                            }}
                          >
                            {`: ${
                              slipData.transactionDetails[0].accountTransactionDetail.personDetail
                                ? slipData.transactionDetails[0].accountTransactionDetail.personDetail.village
                                : '--'
                            }`}
                          </div>

                          <div
                            className="grid-item"
                            style={{
                              textAlign: 'center',
                              padding: '5px 0',
                              textAlign: 'start',
                              fontSize: '11px',
                              fontWeight: 600
                            }}
                          >
                            {SLIP_DOWNLOADE.BY_HEND}
                          </div>
                          <div
                            className="grid-item"
                            style={{
                              textAlign: 'center',
                              padding: '5px 0',
                              textAlign: 'start',
                              fontSize: '11px',
                              fontWeight: 600,
                              gridColumn: '2 / span 2'
                            }}
                          >
                            {`: ${slipData.byHand ? slipData.byHand : '--'}`}
                          </div>
                          <div
                            className="grid-item"
                            style={{
                              textAlign: 'center',
                              padding: '5px 0',
                              textAlign: 'start',
                              fontSize: '11px',
                              fontWeight: 600
                            }}
                          >
                            {
                              <p
                                style={{
                                  fontSize: '9px',
                                  margin: '0px'
                                }}
                                className="font-sizing"
                              >
                                <span>
                                  {slipData.transactionDetails[0].bankDetail.type === BANK_TYPE.CASE
                                    ? BANK_TYPE.CASE_LABALE
                                    : BANK_TYPE.BANK_LABALE}
                                </span>
                                <span style={{ margin: '5px' }}>
                                  {slipData?.transactionNo ? `( ${slipData?.transactionNo} )` : ''}
                                </span>
                              </p>
                            }
                          </div>
                        </div>
                      </div>
                      <div style={{ margin: '12px 12px 12px 12px' }}>
                        <table
                          className="tabel"
                          style={{
                            width: '100%',
                            borderCollapse: 'collapse',
                            border: '1px solid gray',
                            overflow: 'auto'
                          }}
                        >
                          {/* <!-- Table Head --> */}

                          {/* <!-- Table Body --> */}

                          <tr style={{ borderStyle: 'none' }}>
                            <td
                              className="text-center font-bold  p-b letter-spacing td font-sizing"
                              style={{
                                textAlign: 'center',
                                fontWeight: '600',
                                letterSpacing: '1px',
                                border: '1px solid gray',
                                borderCollapse: 'collapse',
                                fontSize: '11px',
                                width: '80%',
                                height: '30px'
                              }}
                            >
                              {SLIP_DOWNLOADE.PURPOSE}
                            </td>
                            <td
                              className="text-right font-bold p-b letter-spacing p-r td font-sizing"
                              style={{
                                textAlign: 'right',
                                fontWeight: '600',
                                letterSpacing: '1px',
                                paddingRight: '1rem',
                                border: '1px solid gray',
                                borderCollapse: 'collapse',
                                fontSize: '11px'
                              }}
                            >
                              {SLIP_DOWNLOADE.AMOUNT}
                            </td>
                          </tr>
                          {slipData.transactionDetails.map((elem) => {
                            amount += elem.amount || 0;
                            return (
                              <tr style={{ borderStyle: 'none', height: '30px' }}>
                                <td
                                  className="p-l p-b font-bold letter-spacing font-sizing td "
                                  style={{
                                    paddingTop: '0.5rem',
                                    paddingLeft: '1rem',
                                    paddingBottom: '0.3rem',
                                    fontWeight: '600',
                                    letterSpacing: '1px',
                                    border: '1px solid gray',
                                    borderCollapse: 'collapse',
                                    fontSize: '11px'
                                  }}
                                >
                                  {elem.accountTransactionDetail
                                    ? elem.accountTransactionDetail.AccountDetail.name
                                    : ''}
                                </td>
                                <td
                                  className="text-right p-r font-bold font-sizing td"
                                  style={{
                                    textAlign: 'right',
                                    paddingRight: '1rem',
                                    fontWeight: '600',
                                    letterSpacing: '1px',
                                    fontSize: '11px',
                                    border: '1px solid gray',
                                    borderCollapse: 'collapse'
                                  }}
                                >
                                  {elem.amount ? elem.amount : ''}
                                </td>
                              </tr>
                            );
                          })}
                          <tr style={{ borderStyle: 'none', height: '30px' }}>
                            <td
                              className="border-none p-l p-b letter-spacing td font-sizing"
                              style={{
                                fontWeight: 'bold',
                                color: 'black',
                                border: 'none',
                                paddingLeft: '1rem',
                                fontSize: '11px',
                                borderCollapse: 'collapse'
                              }}
                            >
                              Amount In Word : {inWords(amount)}
                            </td>
                            <td
                              className="text-right p-l p-b p-r font-bold font-sizing td"
                              style={{
                                paddingTop: '0.5rem',
                                textAlign: 'right',
                                paddingLeft: '1rem',
                                paddingBottom: '0.3rem',
                                paddingRight: '1rem',
                                fontWeight: '600',
                                letterSpacing: '1px',
                                fontSize: '11px',
                                border: '1px solid gray',
                                borderCollapse: 'collapse'
                              }}
                            >
                              {amount}
                            </td>
                          </tr>
                        </table>
                      </div>

                      {/* <!-- Bottom --> */}
                      <div>
                        <div
                          className="bottom-1"
                          style={{
                            textAlign: 'end',
                            fontWeight: '700',
                            margin: '10px 12px 10px 12px',
                            letterSpacing: '1px'
                          }}
                        >
                          <p className="font-sizing" style={{ fontSize: '11px' }}>
                            For, Temple {slipData.temple ? slipData.temple.templeName : ''}
                          </p>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between'
                            }}
                          >
                            {slipData.transactionDetails &&
                            slipData.transactionDetails[0].accountTransactionDetail.dueAmount !== 0 ? (
                              <p
                                style={{
                                  color: '#ff0000bf',
                                  fontSize: '9px',
                                  marginBottom: '0px'
                                }}
                                className="font-sizing"
                              >
                                Due Amount :
                                {slipData.transactionDetails
                                  ? slipData.transactionDetails[0].accountTransactionDetail.dueAmount
                                  : '--'}{' '}
                              </p>
                            ) : (
                              <p style={{ fontSize: '0px' }}>.</p>
                            )}

                            <p className="font-sizing" style={{ fontSize: '9px', margin: '0px' }}>
                              Received By {slipData.creator ? slipData.creator.name : ''}
                            </p>
                          </div>
                        </div>
                        <div
                          className="p-l p-b lettnpx update-browserslist-db@latester-spacing"
                          style={{
                            paddingLeft: '1rem',
                            paddingBottom: '0.2rem',
                            letterSpacing: '1px',
                            fontSize: '11px',
                            height: '35px'
                          }}
                        >
                          <b>Note :- </b>
                          {slipData.transactionDetails[0]?.accountTransactionDetail?.note || '--'}
                        </div>
                        <div
                          className="p-l p-b lettnpx update-browserslist-db@latester-spacing"
                          style={{
                            paddingLeft: '1rem',
                            paddingBottom: '0.2rem',
                            letterSpacing: '1px'
                          }}
                        >
                          <p className="font-sizing" style={{ fontSize: '9px' }}>
                            (This is Syatem Generated Receipt, it Dose Not Require Signature)
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </NewWindow>
        </>
      ) : null}
    </>
  );
};

export default SlipDownloadeTemplatePrint;
