import React, { useContext, useEffect, useState } from 'react';
import OrganizationItem from './incomeItem';
import OrganizationDetailsModel from './incomeDetailsModel';
import OrganizationAddModel from './incomeAddModel';
import DonarAddModel from '../DonarMaster/donarAddModel';
import { toast, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-phone-number-input/style.css';
import TopLoadingBar from '../topLoadingBar/topLoadingBar';
import NoteContext from '../../context/NotesContext/NoteContext';
import {
  Add_Donation,
  Donation_Edit_List,
  Donation_List,
  Donation_List_Details
} from '../../api/DonationApi/DonationApi';
import { ACCOUNT_TYPE, ERROR_CODE } from '../../constant';
import { Temple_Trust_List } from '../../api/TempleTrustApi/TempleTrustApi';
import OrganizationContext from '../../context/OrganizationContext/OrganizationContext';
import LogOut from '../../utils/logout';
import { isEmpty } from '../../utils/helper';
import ProductContext from '../../context/ProductContext/ProductContext';
import { Add_Donar } from '../../api/Donar Api/DonarApi';
import { useNavigate } from 'react-router';

const Organization = () => {
  const {
    setformvalue,
    setahoeDataModel,
    setModal,
    modal,
    sortOrder,
    setSortOrder,
    sortBy,
    setSortBy,
    filter,
    setFilter,
    editIndex,
    seteditIndex,
    accountId,
    setAccountId,
    setShowDonation,
    note,
    setNote,
    donar,
    setDonar,
    bank,
    setBank,
    donation,
    setDonation,
    amount,
    setAmount,
    paidamount,
    setPaidAmount,
    setDouAmount,
    paymentMode,
    setIsAddHandle,
    progress,
    setProgress,
    togglesubmit,
    setToggle,
    accountDropDown,
    personDropdown,
    bankDropdown,
    setTrustDrop,
    trustId,
    setTrustId,
    payModel,
    setPersonSearch,
    setPayModel,
    startDate,
    endDate,
    pageSize,
    setPageSize,
    page,
    setPage,
    record,
    setRecord
  } = useContext(NoteContext);
  const {
    setName,
    name,
    contact,
    setContact,
    village,
    setVillage,
    address,
    setAddress,
    pancard,
    setPancard,
    adharcard,
    setAdharcard,
    date,
    setDate
  } = useContext(ProductContext);
  const { loaderShow, setLoaderShow, responseLoader, setResponseLoader } = useContext(OrganizationContext);
  const history = useNavigate();
  const [accountDetail, setAccountDetail] = useState('');
  const [personDetail, setPersonDetail] = useState('');
  const [transactionDetails, setTransactionDetails] = useState([]);
  const [selected, setSelected] = useState([]);
  const [error, setError] = useState(false);
  const [donarPopupShow, setDonarPopupShow] = useState(false);

  /**
   * empty Feild function call
   */

  const emptyFeild = () => {
    setNote(null);
    setBank(null);
    setDonation(null);
    setAmount(null);
    setPaidAmount(null);
    setDonar(null);
    setDouAmount(null);
    setTrustId('');
    setLoaderShow(false);
  };

  const fillFeild = () => {
    setNote(note);
    setBank(bank);
    setDonation(donation);
    setAmount(amount);
    setPaidAmount(paidamount);
    setDonar(donar);
    setTrustId(trustId);
  };

  /**
   * get donation list and function call and useEffect use to function call
   */

  const getData = async () => {
    if (sortOrder === 'ASC' || sortOrder === 'DSC' || filter.length) {
    } else {
      setResponseLoader(!responseLoader);
    }
    try {
      let paramsObj = {
        pageNumber: page,
        recordsPerPage: pageSize,
        sortOrder,
        sortBy,
        search: {
          type: ACCOUNT_TYPE.inward
        }
      };
      if (
        startDate !== 'Invalid date' &&
        startDate !== null &&
        startDate !== undefined &&
        startDate !== '' &&
        startDate
      ) {
        paramsObj['search'] = { ...paramsObj.search, startDate };
      }
      if (endDate !== 'Invalid date' && endDate !== null && endDate !== undefined && endDate !== '' && endDate) {
        paramsObj['search'] = { ...paramsObj.search, endDate };
      }
      if (selected[0]) {
        paramsObj['search'] = {
          ...paramsObj.search,
          accountName: selected[0].label
        };
      }
      if (filter.length && typeof filter === 'string') {
        paramsObj['search'] = {
          ...paramsObj.search,
          type: ACCOUNT_TYPE.inward,
          name: filter
        };
      }
      if (filter >= 1 && typeof Number(filter) === 'number') {
        paramsObj['search'] = {
          ...paramsObj.search,
          type: ACCOUNT_TYPE.inward,
          mobile: +filter,
          name: ''
        };
      }
      const { data } = await Donation_List(paramsObj);
      if (data.status === 200) {
        setformvalue({ data: data.payload.data, report: data.payload.report });
        setRecord(data.pager.totalRecords);
        setResponseLoader(false);
      } else {
        setformvalue([]);
      }
    } catch (error) {
      if (error?.response?.data?.status === ERROR_CODE.UNAUTHORIZED) {
        LogOut(history);
      }
      setResponseLoader(false);
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom
      });
    }
  };
  useEffect(() => {
    getData();
    setProgress(100);
  }, [page, pageSize, sortOrder, sortBy, selected, filter, startDate, endDate]);

  /**
   * add donation model and open popup function call
   */

  const TrustDopdown = async () => {
    let paramObj = {
      showAll: true
    };
    try {
      const { data } = await Temple_Trust_List(paramObj);
      if (data.status === 200) {
        setTrustDrop(data.payload.data);
      } else {
        setTrustDrop([]);
      }
    } catch (error) {
      if (error?.response?.data?.status === ERROR_CODE.UNAUTHORIZED) {
        LogOut(history);
      }
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom
      });
    }
  };

  const AddModel = () => {
    TrustDopdown();
    setModal(true);
    setToggle(true);
    accountDropDown({ type: ACCOUNT_TYPE.inward });
    personDropdown();
    bankDropdown();
  };

  /**
   * fill feild data get to edit time and function call
   */

  const editHandle = async (accountTransactionId) => {
    setAccountId(accountTransactionId);
    TrustDopdown();
    setLoaderShow(!loaderShow);
    try {
      const { data } = await Donation_List_Details(accountTransactionId);
      if (data.status === 200) {
        setToggle(false);
        setIsAddHandle(false);
        seteditIndex(accountTransactionId);
        accountDropDown({ type: ACCOUNT_TYPE.inward });
        personDropdown(page);
        bankDropdown();
        let payload = data.payload.data;
        setDonar(
          payload?.personId && payload?.personDetail?.name
            ? { value: payload?.personId, label: payload?.personDetail?.name }
            : null
        );
        setDonation(payload.accountId ? payload.accountId : null);
        setAmount(payload.amount ? payload.amount : null);
        setTrustId(payload.trustId ? payload.trustId : null);
        setBank(payload.transactionDetails.length ? payload.transactionDetails[0].bankId : null);
        setPersonSearch(payload?.personDetail?.mobile);
        setDouAmount(payload.dueAmount ? payload.dueAmount : null);
        setNote(payload.note ? payload.note : null);
        setLoaderShow(false);
        setModal(true);
      } else {
        setformvalue([]);
      }
    } catch (error) {
      if (error?.response?.data?.status === ERROR_CODE.UNAUTHORIZED) {
        LogOut(history);
      }
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom
      });
      setLoaderShow(false);
    }
  };

  /**
   * add and edit form submit and api call function call
   */

  const onSubmit = async (e) => {
    e.preventDefault();
    let formData;
    if ((editIndex !== -1 && !togglesubmit) || (editIndex !== -1 && payModel === true)) {
      setLoaderShow(!loaderShow);
      formData = isEmpty({
        accountId: donation,
        personId: donar.value,
        amount: +amount,
        paidAmount: +paidamount,
        bankId: bank,
        note: note,
        trustId: +trustId,
        templeId: +localStorage.getItem('templeId'),
        paymentMode
      });
      try {
        const { data } = await Donation_Edit_List(formData, accountId);
        if (data.status === 200) {
          toast.success(data.message, {
            autoClose: 1000
          });
          setModal(false);
          getData();
          emptyFeild();
        } else {
          fillFeild();
        }
      } catch (error) {
        if (error?.response?.data?.status === ERROR_CODE.UNAUTHORIZED) {
          LogOut(history);
        }
        toast.error(error?.response?.data?.message, {
          autoClose: 1000,
          transition: Zoom
        });
        setLoaderShow(false);
      }
    } else {
      setLoaderShow(!loaderShow);
      formData = isEmpty({
        accountId: donation,
        personId: donar.value,
        amount: +amount,
        paidAmount: +paidamount,
        bankId: bank > 0 ? +bank : null,
        note: note,
        trustId: +trustId,
        templeId: +localStorage.getItem('templeId'),
        paymentMode
      });

      try {
        const { data } = await Add_Donation(formData);
        if (data.status === 200) {
          toast.success(data.message, {
            autoClose: 1000,
            transition: Zoom
          });
          setModal(false);
          getData();
          emptyFeild();
        } else {
          fillFeild();
        }
      } catch (error) {
        if (error?.response?.data?.status === ERROR_CODE.UNAUTHORIZED) {
          LogOut(history);
        }
        toast.error(error?.response?.data?.message, {
          autoClose: 1000,
          transition: Zoom
        });
        setLoaderShow(false);
      }
    }
  };

  /**
   * show donation api call adn function call for data fatch
   */

  const showHandle = async (accountTransactionId) => {
    setAccountId(accountTransactionId);
    setLoaderShow(!loaderShow);
    try {
      const { data } = await Donation_List_Details(accountTransactionId);
      if (data.status === 200) {
        setShowDonation(data.payload.data);
        setAccountDetail(data.payload.data.AccountDetail);
        setTransactionDetails(data.payload.data.transactionDetails);
        setPersonDetail(data.payload.data.personDetail);
        setLoaderShow(false);
        setahoeDataModel(true);
      } else {
        setformvalue([]);
      }
    } catch (error) {
      if (error?.response?.data?.status === ERROR_CODE.UNAUTHORIZED) {
        LogOut(history);
      }
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom
      });
      setLoaderShow(false);
    }
  };

  /**
   * sorting fucntion call
   */

  const sorting = (col) => {
    if (sortOrder === '') {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder('ASC');
    }
    if (sortOrder === 'ASC') {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder('DSC');
    }
    if (sortOrder === 'DSC') {
      setSortBy();
      setSortOrder('');
    }
  };

  /**
   * hanlde search to function call
   */

  const handleFilter = (e) => {
    setFilter(e.target.value);
  };

  const close = (setPersonSearch, setInputValue) => {
    setModal(!modal);
    emptyFeild();
    setPersonSearch(null);
    setInputValue('');
    setPayModel(false);
    setToggle(false);
  };

  const donarPopupEmpty = () => {
    setAddress(null);
    setContact(null);
    setName(null);
    setVillage(null);
    setAdharcard(null);
    setPancard(null);
    setAddress(null);
    setDate(null);
    setLoaderShow(false);
    setError(false);
    setDonarPopupShow(false);
  };
  const donarPopupfill = () => {
    setAddress(address);
    setContact(contact);
    setName(name);
    setVillage(village);
    setAdharcard(adharcard);
    setPancard(pancard);
    setAddress(address);
    setDate(date);
    setLoaderShow(false);
    setError(false);
    setDonarPopupShow(false);
  };

  const handleCreateDonar = async () => {
    setDonarPopupShow(true);
    if (name.length === 0 || contact.length === 0) {
      setError(true);
    } else {
      setLoaderShow(!loaderShow);
      let formData = isEmpty({
        name: name,
        mobile: contact,
        village,
        panCardNo: pancard,
        aadhaarCard: adharcard,
        address: address,
        dob: date
      });

      try {
        const { data } = await Add_Donar(formData);
        if (data.status === 200) {
          toast.success(data.message, {
            autoClose: 1000,
            transition: Zoom
          });
          setDonarPopupShow(false);
          getData();
          donarPopupEmpty();
        } else {
          donarPopupfill();
        }
      } catch (error) {
        if (error?.response?.data?.status === ERROR_CODE.UNAUTHORIZED) {
          LogOut(history);
        }
        toast.error(error?.response?.data?.message, {
          autoClose: 1000,
          transition: Zoom
        });
        setLoaderShow(false);
      }
    }
  };

  const createDonar = async (e) => {
    e.preventDefault();
    if (name === null || contact === null) {
      setError(true);
    } else {
      setLoaderShow(!loaderShow);
      let formData = isEmpty({
        name: name,
        mobile: contact,
        village,
        panCardNo: pancard,
        aadhaarCard: adharcard,
        address: address,
        dob: date
      });

      try {
        const { data } = await Add_Donar(formData);
        if (data.status === 200) {
          toast.success(data.message, {
            autoClose: 1000,
            transition: Zoom
          });
          setDonarPopupShow(false);
          getData();
          donarPopupEmpty();
        } else {
          donarPopupfill();
        }
      } catch (error) {
        if (error?.response?.data?.status === ERROR_CODE.UNAUTHORIZED) {
          LogOut(history);
        }
        toast.error(error?.response?.data?.message, {
          autoClose: 1000,
          transition: Zoom
        });
        setLoaderShow(false);
      }
    }
  };

  return (
    <>
      <div>
        <TopLoadingBar progress={progress} setProgress={setProgress} />
        <OrganizationAddModel onSubmit={onSubmit} close={close} handleCreateDonar={handleCreateDonar} />
        <OrganizationDetailsModel
          transactionDetails={transactionDetails}
          accountDetail={accountDetail}
          personDetail={personDetail}
        />
        <DonarAddModel onSubmit={createDonar} error={error} close={donarPopupEmpty} modal={donarPopupShow} />
        <OrganizationItem
          handleFilter={handleFilter}
          sorting={sorting}
          editHandle={editHandle}
          showHandle={showHandle}
          AddModel={AddModel}
          setPage={setPage}
          setPageSize={setPageSize}
          pageSize={pageSize}
          page={page}
          record={record}
          setFilter={setFilter}
          selected={selected}
          setSelected={setSelected}
        />
      </div>
    </>
  );
};
export default Organization;
