import React, { useContext, useEffect, useState } from "react";
import TempleTrustItem from "./templeTrustItem";
import TempleTrustDetailsModel from "./templeTeustDetailsModel";
import TempleTrustAddModel from "./templeTrustAddModel";
import { toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-phone-number-input/style.css";
import TopLoadingBar from "../topLoadingBar/topLoadingBar";
import {
  Create_Temple_Trust_List,
  Detail_Temple_Trust_List,
  Edit_Temple_Trust_List,
  Temple_Trust_List,
} from "../../api/TempleTrustApi/TempleTrustApi";
import TempleTrustContext from "../../context/TempleTrustContext/TempleTrustContext";
import OrganizationContext from "../../context/OrganizationContext/OrganizationContext";
import { ERROR_CODE } from "../../constant";
import LogOut from "../../utils/logout";
import { isEmpty } from "../../utils/helper";
import { useNavigate } from "react-router";

const TempleTrust = () => {
  const {
    setformvalue,
    setahoeDataModel,
    setModal,
    modal,
    sortOrder,
    setSortOrder,
    sortBy,
    setSortBy,
    filter,
    editIndex,
    seteditIndex,
    bankId,
    setBankId,
    setIsAddHandle,
    progress,
    setProgress,
    togglesubmit,
    setToggle,
    setShowHanlde,
    templeName,
    setTempleName,
    trustRegNo,
    panNo,
    setPanNo,
    certNo,
    setCertNo,
    setTrustRegNo,
  } = useContext(TempleTrustContext);
  const { loaderShow, setLoaderShow, responseLoader, setResponseLoader } =
    useContext(OrganizationContext);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [record, setRecord] = useState(0);
  const [error, setError] = useState(false);
  const history = useNavigate();

  /**
   * get bank data api call and deta fatch and useaEffect use to call getData() function call
   */
  const getData = async () => {
    if (sortOrder === "ASC" || sortOrder === "DSC" || filter.length) {
    } else {
      setResponseLoader(!responseLoader);
    }
    try {
      let paramsObj = {
        pageNumber: page,
        recordsPerPage: pageSize,
        sortOrder,
        sortBy,
      };
      if (filter.length) {
        delete paramsObj["pageNumber"];
        delete paramsObj["recordsPerPage"];
        delete paramsObj["sortOrder"];
        delete paramsObj["sortBy"];
      }
      if (filter.length) {
        paramsObj["search"] = { trustName: filter };
      }
      const { data } = await Temple_Trust_List(paramsObj);
      if (data.status === 200) {
        setformvalue(data.payload.data);
        setRecord(data.pager.totalRecords);
        setResponseLoader(false);
      } else {
        setformvalue([]);
      }
    } catch (error) {
      if (error?.response?.data?.status === ERROR_CODE.UNAUTHORIZED) {
        LogOut(history);
      }
      setResponseLoader(false);
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };
  useEffect(() => {
    getData();
  }, [page, pageSize, sortOrder, sortBy, filter]);

  useEffect(() => {
    setProgress(100);
  }, []);

  /**
   * Empty Feild function
   */

  const EmptyFeild = () => {
    setTempleName(null);
    setPanNo(null);
    setCertNo(null);
    setTrustRegNo(null);
    setLoaderShow(false);
  };

  /**
   * Fill Feild function
   */

  const FillFeild = () => {
    setTempleName(templeName);
    setPanNo(panNo);
    setCertNo(certNo);
    setTrustRegNo(trustRegNo);
  };

  /**
   * Add Model to click to open model
   */

  const AddModel = () => {
    setModal(true);
    setToggle(true);
  };

  /**
   * Edit time get data to call api function call
   */

  const editHandle = async (trustId) => {
    setBankId(trustId);
    setLoaderShow(!loaderShow);
    try {
      const { data } = await Detail_Temple_Trust_List(trustId);
      if (data.status === 200) {
        setToggle(false);
        setIsAddHandle(false);
        seteditIndex(trustId);
        //ORG EDIT
        let payload = data.payload.data;
        setTempleName(payload.trustName ? payload.trustName : null);
        setPanNo(payload.panNo ? payload.panNo : null);
        setCertNo(payload.certNo ? payload.certNo : null);
        setTrustRegNo(payload.trustRegNo ? payload.trustRegNo : null);
        setLoaderShow(false);
        setModal(true);
      } else {
        setformvalue([]);
      }
    } catch (error) {
      if (error?.response?.data?.status === ERROR_CODE.UNAUTHORIZED) {
        LogOut(history);
      }
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
      setLoaderShow(false);
    }
  };

  /**
   * Add bank master and edit bank master api call
   */
  const onSubmit = async (e) => {
    e.preventDefault();
    if (templeName.length === 0) {
      setError(true);
    } else {
      let formData;
      if (editIndex !== -1 && !togglesubmit) {
        try {
          setLoaderShow(!loaderShow);
          formData = isEmpty({
            trustName: templeName,
            templeId: localStorage.getItem("templeId"),
            panNo,
            certNo,
            trustRegNo,
          });
          const { data } = await Edit_Temple_Trust_List(formData, bankId);
          if (data.status === 200) {
            toast.success(data.message, {
              autoClose: 1000,
            });
            setModal(false);
            getData();
            EmptyFeild();
          } else {
            FillFeild();
          }
        } catch (error) {
          if (error?.response?.data?.status === ERROR_CODE.UNAUTHORIZED) {
            LogOut(history);
          }
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,
            transition: Zoom,
          });
        }
      } else {
        try {
          setLoaderShow(!loaderShow);
          formData = isEmpty({
            trustName: templeName,
            templeId: localStorage.getItem("templeId"),
            panNo,
            certNo,
            trustRegNo,
          });

          const { data } = await Create_Temple_Trust_List(formData);
          if (data.status === 200) {
            toast.success(data.message, {
              autoClose: 1000,
              transition: Zoom,
            });
            setModal(false);
            getData();
            EmptyFeild();
          } else {
            FillFeild();
          }
        } catch (error) {
          if (error?.response?.data?.status === ERROR_CODE.UNAUTHORIZED) {
            LogOut(history);
          }
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,
            transition: Zoom,
          });
        }
      }
    }
  };

  /**
   *Show model open and details api call
   */

  const showHandle = async (trustId) => {
    setBankId(trustId);
    setLoaderShow(!loaderShow);
    try {
      const { data } = await Detail_Temple_Trust_List(trustId);
      if (data.status === 200) {
        setShowHanlde(data.payload.data);
        setLoaderShow(false);
        setahoeDataModel(true);
      } else {
        setformvalue([]);
      }
    } catch (error) {
      if (error?.response?.data?.status === ERROR_CODE.UNAUTHORIZED) {
        LogOut(history);
      }
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
      setLoaderShow(false);
    }
  };

  /**
   * Shorting Function call
   */

  const sorting = (col) => {
    if (sortOrder === "") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("ASC");
    }
    if (sortOrder === "ASC") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("DSC");
    }
    if (sortOrder === "DSC") {
      setSortBy();
      setSortOrder("");
    }
  };

  /**
   * popup Close function call
   */

  const close = () => {
    setModal(!modal);
    EmptyFeild();
  };

  return (
    <>
      <div>
        <TopLoadingBar progress={progress} setProgress={setProgress} />
        <TempleTrustAddModel onSubmit={onSubmit} close={close} error={error} />
        <TempleTrustDetailsModel />
        <TempleTrustItem
          sorting={sorting}
          editHandle={editHandle}
          showHandle={showHandle}
          AddModel={AddModel}
          setPage={setPage}
          setPageSize={setPageSize}
          pageSize={pageSize}
          page={page}
          record={record}
        />
      </div>
    </>
  );
};
export default TempleTrust;
