import axios from "axios";
import {
  CREATE_TEMPLE_MASTER_LIST,
  DETAIL_TEMPLE_MASTER_LIST,
  EDIT_TEMPLE_MASTER_LIST,
  TEMPLE_MASTER_LIST,
} from "../../constant/API constant";

export const Temple_Master_List = (paramsObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${TEMPLE_MASTER_LIST}`, {
    headers: { Authorization: jwt_token },
    params: paramsObj,
  });
};
export const Create_Temple_Master_List = (formData) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(
    `${process.env.REACT_APP_API_URL}${CREATE_TEMPLE_MASTER_LIST}`,
    formData,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

export const Edit_Temple_Master_List = (formData, templeId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${EDIT_TEMPLE_MASTER_LIST}`,
    formData,
    {
      headers: { Authorization: jwt_token },
      params: { templeId },
    }
  );
};

export const Detail_Temple_Master_List = (templeId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(
    `${process.env.REACT_APP_API_URL}${DETAIL_TEMPLE_MASTER_LIST}`,
    {
      headers: { Authorization: jwt_token },
      params: { templeId },
    }
  );
};
