import * as React from "react";
import { useState } from "react";
import PublicPageContext from "./PublicPageContext";

const PublicPageState = (props) => {
  const [formvalue, setformvalue] = useState([]); //API -->
  const [formvalueSlip, setformvalueSlip] = useState([]); //API -->
  const [trustDrop, setTrustDrop] = useState([]);

  const [url, setUrl] = useState("");
  const [isfcCode, setIsfcCode] = useState("");
  const [accountNo, setAccountNo] = useState("");
  const [confirmAaccountNo, setConfirmAccountNo] = useState("");
  const [branch, setBranch] = useState("");
  const [bankId, setBankId] = useState("");
  const [name, setName] = useState(null);
  const [trustId, setTrustId] = useState("");
  const [type, setType] = useState("");
  const [personId, setPersonaId] = useState("");
  const [paymentMode, setPaymentMode] = useState("");
  const [address, setAddress] = useState(null);
  const [contactNo, setContactNo] = useState(null);
  const [village, setVillage] = useState(null);
  const [pancard, setPanCard] = useState(null);
  const [aadhaarCard, setAadhaarCard] = useState(null);
  const [byHand, setByhand] = useState(null);
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [dialCode, setDialCode] = useState("+91");
  const [paymenName, setPaymentName] = useState("");
  const [note, setNote] = useState(null);
  const [transectionNo, setTransectionNo] = useState(null);
  const [paymentType, setPaymentType] = useState("");
  const [slipId, setSlipId] = useState("");
  const [slipData, setSlipData] = useState("");
  const [date, setDate] = useState(null);
  const [publicPageThankYou, setPublicPageThankYou] = useState(false);
  const [donarPageThankYou, setDonarPageThankYou] = useState(false);

  const [showHandle, setShowHanlde] = useState("");

  const [ahoeDataModel, setahoeDataModel] = useState(false); //SHOW ORG DETAILS MODEL -->
  const [modal, setModal] = useState(false); //ADD DATA MODEL -->
  const [model, setModel] = useState(false); //ADD DATA MODEL -->
  const [addbel, setAddBel] = useState(false); //ADD ORG BEL -->
  const [pageNumber, setpageNumber] = useState(1); //PAGINATION -->
  const [recordsPerPage, setRecordsPerPage] = useState(10); //PAGINATION -->
  const [sortOrder, setSortOrder] = useState(""); //SORTING -->
  const [sortBy, setSortBy] = useState("createdAt"); //SORTING -->
  const [filter, setFilter] = useState(""); //SEARCHING FILTER -->
  const [isHovering, setIsHovering] = useState(false); //HOVWER SORTING -->
  const [isSwitchOn, setIsSwitchOn] = useState(false); //TOGGLE SWITCH -->
  const [editIndex, seteditIndex] = useState(-1); //EDIT -->
  const [totalRecord, setTotalRecord] = useState(); //TOTAL RECORD -->
  const [isAddHandle, setIsAddHandle] = useState(true); //PASS HIDE OR UNHIDE -->
  const [progress, setProgress] = useState(0);
  const [togglesubmit, setToggle] = useState(false);

  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [record, setRecord] = useState(0);

  return (
    <>
      <PublicPageContext.Provider
        value={{
          formvalue,
          setformvalue,
          isfcCode,
          setIsfcCode,
          accountNo,
          setAccountNo,
          branch,
          setBranch,
          confirmAaccountNo,
          setConfirmAccountNo,
          showHandle,
          setShowHanlde,
          formvalueSlip,
          setformvalueSlip,
          slipId,
          setSlipId,
          slipData,
          setSlipData,

          address,
          setAddress,
          name,
          setName,
          contactNo,
          setContactNo,
          village,
          setVillage,
          pancard,
          setPanCard,
          aadhaarCard,
          setAadhaarCard,
          byHand,
          setByhand,
          city,
          setCity,
          state,
          setState,
          country,
          setCountry,
          personId,
          setPersonaId,
          paymentMode,
          setPaymentMode,
          paymenName,
          setPaymentName,
          note,
          setNote,
          transectionNo,
          setTransectionNo,
          paymentType,
          setPaymentType,
          trustId,
          setTrustId,
          type,
          setType,
          date,
          setDate,
          dialCode,
          setDialCode,

          ahoeDataModel,
          setahoeDataModel,
          modal,
          setModal,
          model,
          setModel,
          addbel,
          setAddBel,
          pageNumber,
          setpageNumber,
          recordsPerPage,
          setRecordsPerPage,
          sortOrder,
          setSortOrder,
          sortBy,
          setSortBy,
          filter,
          setFilter,
          isHovering,
          setIsHovering,
          isSwitchOn,
          setIsSwitchOn,
          url,
          setUrl,
          editIndex,
          seteditIndex,
          totalRecord,
          setTotalRecord,
          isAddHandle,
          setIsAddHandle,
          progress,
          setProgress,
          togglesubmit,
          setToggle,
          bankId,
          setBankId,

          pageSize,
          setPageSize,
          page,
          setPage,
          record,
          setRecord,
          trustDrop,
          setTrustDrop,
          publicPageThankYou,
          setPublicPageThankYou,
          donarPageThankYou,
          setDonarPageThankYou,
        }}
      >
        {props.children}
      </PublicPageContext.Provider>
    </>
  );
};

export default PublicPageState;
