import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { Fragment, useContext } from "react";
import { Plus } from "react-feather";
import {
  Card,
  CardHeader,
  Col,
  Container,
  Input,
  Row,
  Table,
} from "reactstrap";
import { DONAR, Style } from "../../constant";
import ProductContext from "../../context/ProductContext/ProductContext";
import DataNotFound from "../../DataNoteFound/DataNotFound";
import Breadcrumbs from "../../layout/breadcrumb";
import TableMenuItem from "../../utils/TableMenuItem";
import TableSkeleton from "../../loader/TableSkeleton";
import OrganizationContext from "../../context/OrganizationContext/OrganizationContext";
import { MuiTabelStyle, getFormattedDate } from "../../utils/helper";

const OrganizationItem = (props) => {
  const {
    editHandle,
    showHandle,
    sorting,
    AddModel,
    setPage,
    setPageSize,
    pageSize,
    page,
    record,
    handleFilter,
    StatementRedirect,
  } = props;
  const { formvalue, filter } = useContext(ProductContext);
  const { responseLoader } = useContext(OrganizationContext);
  const defaultColumns = [
    {
      flex: 0.2,
      minWidth: 250,
      field: "name",
      headerName: "Name",
      renderCell: (params) => (params.row.name ? params.row.name : "--"),
    },
    {
      flex: 0.1,
      minWidth: 100,
      field: "mobile",
      headerName: "Phone",
      renderCell: (params) => (params.row.mobile ? params.row.mobile : "--"),
    },
    {
      flex: 0.1,
      minWidth: 100,
      field: "kinMobile",
      headerName: "Relative No",
      renderCell: (params) =>
        params.row.kinMobile ? params.row.kinMobile : "--",
    },
    {
      flex: 0.1,
      minWidth: 250,
      field: "village",
      headerName: "Village",
      renderCell: (params) => (params.row.village ? params.row.village : "--"),
    },
    {
      flex: 0.1,
      minWidth: 100,
      field: "aadhaarCard",
      headerName: "AadharCard",
      renderCell: (params) =>
        params.row.aadhaarCard ? params.row.aadhaarCard : "--",
    },
    {
      flex: 0.1,
      minWidth: 100,
      field: "panCardNo",
      headerName: "Pan Card",
      renderCell: (params) =>
        params.row.panCardNo ? params.row.panCardNo : "--",
    },
    {
      flex: 0.1,
      minWidth: 100,
      field: "dob",
      headerName: "DOB",
      renderCell: (params) => (params.row.dob ? params.row.dob : "--"),
    },
    {
      flex: 0.1,
      minWidth: 100,
      field: "dod",
      headerName: "DOD (તિથિ)",
      renderCell: (params) =>
        params.row.dod ? getFormattedDate(params.row.dod) : "--",
    },

    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      renderCell: (params) => (
        <>
          <TableMenuItem
            params={params}
            editHandle={editHandle}
            showHandle={showHandle}
            StatementRedirect={StatementRedirect}
          />
        </>
      ),
    },
  ];

  return (
    <>
      <Fragment>
        <Breadcrumbs parent={DONAR.DONAR_PARENT} />
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <h3>{DONAR.DONAR_PARENT}</h3>
                  <div
                    aria-label="add"
                    className="btn btn-primary"
                    onClick={AddModel}
                    style={Style}
                  >
                    <Plus />
                  </div>
                </CardHeader>
                <Table>
                  <thead>
                    <tr>
                      <th>
                        <Row>
                          <Col md="2 mb-2">
                            <Input
                              className="form-control"
                              type="text"
                              placeholder="Donar Search..."
                              value={filter}
                              onInput={(e) => handleFilter(e)}
                            />
                          </Col>
                        </Row>
                      </th>
                    </tr>
                  </thead>
                </Table>
                <Box
                  sx={{
                    "& .even": {
                      backgroundColor: "#f1f1f1",
                    },
                    "& .odd": {
                      backgroundColor: "white",
                    },
                  }}
                >
                  {responseLoader === true ? (
                    <TableSkeleton />
                  ) : formvalue.length && formvalue.length ? (
                    <DataGrid
                      sx={MuiTabelStyle}
                      autoHeight
                      pagination
                      rows={formvalue}
                      rowCount={record}
                      pageSize={pageSize}
                      getRowId={(row) => row.personId}
                      columns={defaultColumns}
                      page={page - 1}
                      rowsPerPageOptions={[7, 10, 25, 50]}
                      onPageSizeChange={(newPageSize) =>
                        setPageSize(newPageSize)
                      }
                      onPageChange={(newPageNo) => setPage(newPageNo + 1)}
                      paginationMode="server"
                      disableSelectionOnClick
                      checkboxSelection={false}
                      sortingMode="server"
                      onSortModelChange={sorting}
                      getRowClassName={(params) =>
                        params.indexRelativeToCurrentPage % 2 === 0
                          ? "even"
                          : "odd"
                      }
                    />
                  ) : (
                    <DataNotFound />
                  )}
                </Box>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fragment>
    </>
  );
};

export default OrganizationItem;
