import React, { useContext } from "react";
import {
  Card,
  CardBody,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import { NOTES, NOTE_CONTEXT } from "../../constant";
import NoteContext from "../../context/NoteContext/NoteContext";

const NotesDetailsModel = (props) => {
  const { ahoeDataModel, setahoeDataModel, showNote } = useContext(NoteContext);
  return (
    <>
      <Modal
        size="lg"
        isOpen={ahoeDataModel}
        toggle={() => setahoeDataModel(!ahoeDataModel)}
      >
        <ModalHeader toggle={() => setahoeDataModel(!ahoeDataModel)}>
          {NOTES.NOTES_DETAILS}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col sm="12">
              <Card>
                <CardBody style={{ padding: "0" }}>
                  <Card>
                    <div className="table-responsive">
                      <Table>
                        <tbody>
                          <tr>
                            <th scope="row">{NOTE_CONTEXT.TITLE}</th>
                            <td>{showNote.title}</td>
                          </tr>
                          <tr>
                            <th scope="row">{NOTE_CONTEXT.NOTE}</th>
                            <td>{showNote.notes}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </Card>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
};

export default NotesDetailsModel;
