import React, { useContext, useEffect, useState } from "react";
import OrganizationItem from "./DonationMasterItem";
import OrganizationDetailsModel from "./DonationMasterDetailsModel";
import OrganizationAddModel from "./DonationMasterAddModel";
import { toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-phone-number-input/style.css";
import TopLoadingBar from "../topLoadingBar/topLoadingBar";
import DonationMasterContext from "../../context/DonatoinMasterContext/DonationMasterContext";
import {
  Add_Donation_Master,
  DonationMaster_List_Enable_Desable,
  Donation_MasterList,
  Donation_Master_Edit_List,
  Donation_Master_List_Details,
} from "../../api/DonationMasterApi/DonationMasterApi";
import { Temple_Trust_List } from "../../api/TempleTrustApi/TempleTrustApi";
import OrganizationContext from "../../context/OrganizationContext/OrganizationContext";
import { ERROR_CODE } from "../../constant";
import LogOut from "../../utils/logout";
import { isEmpty } from "../../utils/helper";
import { useNavigate } from "react-router";

const Organization = () => {
  const {
    setformvalue,
    setahoeDataModel,
    setModal,
    modal,
    sortOrder,
    setSortOrder,
    sortBy,
    setSortBy,
    filter,
    setFilter,
    editIndex,
    seteditIndex,
    accountId,
    setAccountId,
    setShowDonationMaster,
    setName,
    name,
    date,
    setDate,
    amount,
    setAmount,
    setIsAddHandle,
    progress,
    setProgress,
    togglesubmit,
    setToggle,
    setTrustDrop,
    trustId,
    setTrustId,
    error,
    setError,
    isSwitchOn,
    setIsSwitchOn,
  } = useContext(DonationMasterContext);
  const { loaderShow, setLoaderShow, responseLoader, setResponseLoader } =
    useContext(OrganizationContext);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [record, setRecord] = useState(0);
  const history = useNavigate()

  /**
   * Empty feild function call
   */

  const emptyFeild = () => {
    setName(null);
    setDate(null);
    setTrustId("");
    setAmount(null);
    setLoaderShow(false);
  };
  const FillFeild = () => {
    setName(name);
    setDate(date);
    setTrustId(trustId);
    setAmount(amount);
  };

  /**
   * Donation Master List api call function and useEffect use to call function
   */

  const getData = async (enableLoader) => {
    if (
      enableLoader === false ||
      sortOrder === "ASC" ||
      sortOrder === "DSC" ||
      filter.length
    ) {
    } else {
      setResponseLoader(!responseLoader);
    }
    try {
      let paramsObj = {
        pageNumber: page,
        recordsPerPage: pageSize,
        sortOrder,
        sortBy,
      };
      if (filter.length) {
        delete paramsObj["pageNumber"];
        delete paramsObj["recordsPerPage"];
        delete paramsObj["sortOrder"];
        delete paramsObj["sortBy"];
      }
      if (filter.length) {
        paramsObj["search"] = { name: filter };
      }
      const { data } = await Donation_MasterList(paramsObj);
      if (data.status === 200) {
        setformvalue(data.payload.data);
        setRecord(data.pager.totalRecords);
        setResponseLoader(false);
      } else {
        setformvalue([]);
      }
    } catch (error) {
      if (error?.response?.data?.status === ERROR_CODE.UNAUTHORIZED) {
        LogOut(history);
      }
      setResponseLoader(false);
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };
  useEffect(() => {
    getData();
  }, [page, pageSize, sortOrder, sortBy, filter]);

  useEffect(() => {
    setProgress(100);
  }, []);

  const TrustDopdown = async () => {
    try {
      const { data } = await Temple_Trust_List();
      if (data.status === 200) {
        setTrustDrop(data.payload.data);
      } else {
        setTrustDrop([]);
      }
    } catch (error) {
      if (error?.response?.data?.status === ERROR_CODE.UNAUTHORIZED) {
        LogOut(history);
      }
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  /**
   * Add donation master model open function call
   */

  const AddModel = () => {
    TrustDopdown();
    setModal(true);
    setToggle(true);
  };

  /**
   * edit donation master details get api call
   */

  const editHandle = async (accountId) => {
    setAccountId(accountId);
    TrustDopdown();
    setLoaderShow(!loaderShow);
    try {
      const { data } = await Donation_Master_List_Details(accountId);
      if (data.status === 200) {
        setToggle(false);
        setIsAddHandle(false);
        seteditIndex(accountId);
        let payload = data.payload.data;
        setName(payload.name ? payload.name : null);
        setDate(payload.type ? payload.type : null);
        setTrustId(payload.trustId ? payload.trustId : null);
        setAmount(payload.amount ? payload.amount : null);
        setLoaderShow(false);
        setModal(true);
      } else {
        setformvalue([]);
      }
    } catch (error) {
      if (error?.response?.data?.status === ERROR_CODE.UNAUTHORIZED) {
        LogOut(history);
      }
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
      setLoaderShow(false);
    }
  };

  /**
   * edit donation master and add donation master submit and api call function call
   */

  const onSubmit = async (e) => {
    e.preventDefault();
    if (name.length === 0) {
      setError(true);
    } else {
      let formData;
      if (editIndex !== -1 && !togglesubmit) {
        setLoaderShow(!loaderShow);
        formData = isEmpty({
          name: name,
          type: +date,
          trustId: +trustId,
          templeId: +localStorage.getItem("templeId"),
          amount: +amount,
        });

        try {
          const { data } = await Donation_Master_Edit_List(formData, accountId);
          if (data.status === 200) {
            toast.success(data.message, {
              autoClose: 1000,
            });
            setModal(false);
            getData();
            emptyFeild();
          } else {
            FillFeild();
          }
        } catch (error) {
          if (error?.response?.data?.status === ERROR_CODE.UNAUTHORIZED) {
            LogOut(history);
          }
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,
            transition: Zoom,
          });
          setLoaderShow(false);
        }
      } else {
        setLoaderShow(!loaderShow);
        formData = isEmpty({
          name: name,
          type: +date,
          trustId: +trustId,
          templeId: +localStorage.getItem("templeId"),
          amount: +amount,
        });

        try {
          const { data } = await Add_Donation_Master(formData);
          if (data.status === 200) {
            toast.success(data.message, {
              autoClose: 1000,
              transition: Zoom,
            });
            setModal(false);
            getData();
            emptyFeild();
          } else {
            setformvalue([]);
            FillFeild();
          }
        } catch (error) {
          if (error?.response?.data?.status === ERROR_CODE.UNAUTHORIZED) {
            LogOut(history);
          }
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,
            transition: Zoom,
          });
          setLoaderShow(false);
        }
      }
    }
  };

  /**
   * Show donation master api call funtion call
   */

  const showHandle = async (accountId) => {
    setAccountId(accountId);
    setLoaderShow(!loaderShow);
    try {
      const { data } = await Donation_Master_List_Details(accountId);
      if (data.status === 200) {
        setShowDonationMaster(data.payload.data);
        setLoaderShow(false);
        setahoeDataModel(true);
      } else {
        setformvalue([]);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
      setLoaderShow(false);
    }
  };

  /**
   * Enable and Desable bank master
   */

  const switch_onChange_handle = async (accountId, isActive) => {
    let formData = {
      accountId: accountId,
      isActive: isActive ? false : true,
    };
    try {
      const { data } = await DonationMaster_List_Enable_Desable(formData);
      if (data.status === 200) {
        isActive
          ? toast.error(data.message, {
              autoClose: 1000,
              transition: Zoom,
            })
          : toast.success(data.message, {
              autoClose: 1000,
              transition: Zoom,
            });
        setIsSwitchOn(!isSwitchOn);
        let enableLoader = false;
        getData(enableLoader);
      } else {
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
      }
    } catch (error) {
      if (error?.response?.data?.status === ERROR_CODE.UNAUTHORIZED) {
        LogOut(history);
      }
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  /**
   * sorting funcion call
   */

  const sorting = (col) => {
    if (sortOrder === "") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("ASC");
    }
    if (sortOrder === "ASC") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("DSC");
    }
    if (sortOrder === "DSC") {
      setSortBy();
      setSortOrder("");
    }
  };

  /**
   * Search function call
   */
  const handleFilter = (e) => {
    if (e.target.value === "") {
      setFilter(e.target.value);
    } else {
      setFilter(e.target.value);
    }
  };

  /**
   * close popup to emplty feild
   */

  const close = () => {
    setModal(!modal);
    emptyFeild();
  };

  return (
    <>
      <div>
        <TopLoadingBar progress={progress} setProgress={setProgress} />
        <OrganizationAddModel onSubmit={onSubmit} error={error} close={close} />
        <OrganizationDetailsModel />
        <OrganizationItem
          handleFilter={handleFilter}
          sorting={sorting}
          editHandle={editHandle}
          showHandle={showHandle}
          AddModel={AddModel}
          setPage={setPage}
          setPageSize={setPageSize}
          pageSize={pageSize}
          page={page}
          record={record}
          switch_onChange_handle={switch_onChange_handle}
        />
      </div>
    </>
  );
};
export default Organization;
