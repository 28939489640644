import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { Fragment, useContext } from "react";
import { Plus } from "react-feather";
import {
  Card,
  CardHeader,
  Col,
  Container,
  Input,
  Row,
  Table,
} from "reactstrap";
import { Style, TEMPLE_TRUST } from "../../constant";
import DataNotFound from "../../DataNoteFound/DataNotFound";
import Breadcrumbs from "../../layout/breadcrumb";
import { useNavigate } from "react-router";
import { classes } from "../../data/layouts";
import DonationMasterContext from "../../context/DonatoinMasterContext/DonationMasterContext";
import TableMenuItem from "../../utils/TableMenuItem";
import ProductContext from "../../context/ProductContext/ProductContext";
import TempleTrustContext from "../../context/TempleTrustContext/TempleTrustContext";
import moment from "moment";
import OrganizationContext from "../../context/OrganizationContext/OrganizationContext";
import TableSkeleton from "../../loader/TableSkeleton";
import { MuiTabelStyle } from "../../utils/helper";

const TempleTrustItem = (props) => {
  const {
    editHandle,
    showHandle,
    sorting,
    AddModel,
    setPage,
    setPageSize,
    pageSize,
    page,
    record,
  } = props;
  const { setpageNumber, setAccountId } = useContext(DonationMasterContext);
  const { setPersonId } = useContext(ProductContext);
  const { formvalue, setBankId, filter, setFilter } =
    useContext(TempleTrustContext);
  const { responseLoader } = useContext(OrganizationContext);
  const history = useNavigate();
  const defaultLayoutObj = classes.find(
    (item) => Object.values(item).pop(1) === "compact-wrapper"
  );
  const layout =
    localStorage.getItem("layout") || Object.keys(defaultLayoutObj).pop();

  const StatementRedirect = (bankId) => {
    setAccountId("");
    setPersonId("");
    history(`/Statement/Statement/${layout}`);
    setBankId(bankId);
    setpageNumber(1);
  };

  const defaultColumns = [
    {
      flex: 0.1,
      minWidth: 250,
      field: "trustName",
      headerName: "Trust Name",
      renderCell: (params) =>
        params.row.trustName ? params.row.trustName : "--",
    },
    {
      flex: 0.1,
      minWidth: 250,
      field: "trustRegNo",
      headerName: "Reg No.",
      renderCell: (params) =>
        params.row.trustRegNo ? params.row.trustRegNo : "--",
    },
    {
      flex: 0.1,
      minWidth: 250,
      field: "panNo",
      headerName: "Pan No.",
      renderCell: (params) => (params.row.panNo ? params.row.panNo : "--"),
    },
    {
      flex: 0.1,
      minWidth: 250,
      field: "certNo",
      headerName: "Cert No.",
      renderCell: (params) => (params.row.certNo ? params.row.certNo : "--"),
    },
    {
      flex: 0.1,
      minWidth: 130,
      field: "createdAt",
      headerName: "Date & Time",
      renderCell: (params) =>
        moment(params.row.createdAt).format("DD MMM, YYYY HH:MM"),
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      renderCell: (params) => (
        <>
          <TableMenuItem
            params={params}
            editHandle={editHandle}
            showHandle={showHandle}
            StatementRedirect={StatementRedirect}
          />
        </>
      ),
    },
  ];

  return (
    <>
      <Fragment>
        <Breadcrumbs parent={TEMPLE_TRUST.TEMPL_TRUST_PERENT} />
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <h3>{TEMPLE_TRUST.TEMPL_TRUST_PERENT}</h3>
                  <div
                    aria-label="add"
                    className="btn btn-primary"
                    onClick={AddModel}
                    style={Style}
                  >
                    <Plus />
                  </div>
                </CardHeader>
                <Table>
                  <thead>
                    <tr>
                      <th>
                        <Row>
                          <Col md="2 mb-2">
                            <Input
                              className="form-control no-outline"
                              type="text"
                              placeholder="Temple Trust Search..."
                              value={filter}
                              onChange={(e) => setFilter(e.target.value)}
                            />
                          </Col>
                        </Row>
                      </th>
                    </tr>
                  </thead>
                </Table>
                <Box
                  sx={{
                    "& .even": {
                      backgroundColor: "#f1f1f1",
                    },
                    "& .odd": {
                      backgroundColor: "white",
                    },
                  }}
                >
                  {responseLoader === true ? (
                    <TableSkeleton />
                  ) : formvalue.length && formvalue.length ? (
                    <DataGrid
                      sx={MuiTabelStyle}
                      autoHeight
                      pagination
                      rows={formvalue}
                      rowCount={record}
                      pageSize={pageSize}
                      getRowId={(row) => row.trustId}
                      columns={defaultColumns}
                      page={page - 1}
                      rowsPerPageOptions={[7, 10, 25, 50]}
                      onPageSizeChange={(newPageSize) =>
                        setPageSize(newPageSize)
                      }
                      onPageChange={(newPageNo) => setPage(newPageNo + 1)}
                      paginationMode="server"
                      disableSelectionOnClick
                      checkboxSelection={false}
                      sortingMode="server"
                      onSortModelChange={sorting}
                      getRowClassName={(params) =>
                        params.indexRelativeToCurrentPage % 2 === 0
                          ? "even"
                          : "odd"
                      }
                    />
                  ) : (
                    <DataNotFound />
                  )}
                </Box>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fragment>
    </>
  );
};

export default TempleTrustItem;
