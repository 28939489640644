import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const TableSkeleton = () => {
  const NumberOfTabelRow = 7;

  const newArray = [];
  for (let i = 0; i < NumberOfTabelRow; i++) {
    newArray.push(
      <div
        style={{
          width: "250px",
          paddingLeft: "15px",
          paddingTop: "13px",
          paddingRight: "13px",
        }}
      >
        <Skeleton count={10} height={15} style={{ marginBottom: "10px" }} />
      </div>
    );
  }

  return (
    <>
      <div style={{ display: "flex" }}>{newArray}</div>
    </>
  );
};

export default TableSkeleton;

export const DeshboardResponceLoader = () => {
  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingBottom: "18px",
        }}
      >
        <div style={{ width: "85px", height: "10px", paddingLeft: "5px" }}>
          <Skeleton />
        </div>
        <div style={{ width: "85px", height: "10px", paddingLeft: "5px" }}>
          <Skeleton />
        </div>
        <div style={{ width: "85px", height: "10px", paddingLeft: "5px" }}>
          <Skeleton />
        </div>
      </div>
      <div
        style={{
          height: "20rem",
          textAlign: "center",
        }}
      >
        <Skeleton circle height="100%" width="45%" />
      </div>
    </>
  );
};
