import React, { useContext, useEffect, useRef } from "react";
import { Modal, ModalBody } from "reactstrap";
import { OTP_EXPIRY_TIME } from "../constant";
import OrgContext from "../context/OrgContext/OrgContext";

const OtpComp = (props) => {
  const {
    otpModel,
    setOtpModel,
    resendOtpApi,
    idForOtp,
    otpSubmitHandler,
    showOtpError,
    getSucOtp,
  } = props;
  const { otp, setOtp, timer, setTimer } = useContext(OrgContext);
  const refs = [useRef(), useRef(), useRef(), useRef(), useRef(), useRef()];

  useEffect(() => {
    let intervalId;
    if (getSucOtp) {
      if (timer > 0) {
        intervalId = setInterval(() => {
          setTimer((prevTimer) => prevTimer - 1);
        }, 1000);
      }
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [timer, getSucOtp]);

  const handleChange = (index, event) => {
    const newOtp = [...otp];
    newOtp[index] = event.target.value;

    if (event.target.value === "") {
      if (index > 0) {
        refs[index - 1].current.focus();
      }
    } else {
      if (index < 5) {
        refs[index + 1].current.focus();
      }
    }

    setOtp(newOtp);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const enteredOtp = otp.join("");
    otpSubmitHandler({ enteredOtp });
  };

  const handleCancel = () => {
    setOtp(["", "", "", "", "", ""]);
    setTimer(OTP_EXPIRY_TIME); // Reset timer
    refs[0].current.focus();
    setOtpModel(!otpModel);
  };

  const handleResend = () => {
    resendOtpApi(idForOtp);
  };
  const close = () => {
    setOtpModel(!otpModel);
  };
  return (
    <Modal isOpen={otpModel} toggle={() => close()}>
      <ModalBody>
        <div className="container d-flex justify-content-center align-items-center  flex-column ">
          <h2>Enter OTP</h2>
          <form onSubmit={handleSubmit}>
            {otp.map((digit, index) => (
              <input
                key={index}
                type="text"
                maxLength="1"
                value={digit}
                onChange={(event) => handleChange(index, event)}
                className="otp-input"
                pattern="[0-9]"
                required
                ref={refs[index]}
              />
            ))}
            <br />
            <br />
            <div className="d-flex gap-2 justify-content-center align-items-center ">
              <button
                type="button"
                onClick={handleCancel}
                className="cancel-btn"
              >
                Cancel
              </button>
              <button type="submit" className="submit-btn">
                Submit
              </button>
            </div>
            {timer === 0 && (
              <div
                onClick={handleResend}
                className="pt-3"
                style={{ cursor: "pointer" }}
              >
                Resend
              </div>
            )}
            {timer > 0 && (
              <div className="timer pt-3">Resend in {timer} seconds</div>
            )}
            {showOtpError && <p className="text-danger">{showOtpError}</p>}
          </form>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default OtpComp;
