import { Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React, { Fragment, useContext } from 'react';
import { Plus } from 'react-feather';
import { Card, CardHeader, Col, Container, Input, Row, Table } from 'reactstrap';
import { ACCOUNT_TYPE, DONATION_MASTER, Style } from '../../constant';
import DonationMasterContext from '../../context/DonatoinMasterContext/DonationMasterContext';
import DataNotFound from '../../DataNoteFound/DataNotFound';
import Switch from 'react-js-switch';
import Breadcrumbs from '../../layout/breadcrumb';
import { useNavigate } from 'react-router';
import { classes } from '../../data/layouts';
import OrgContext from '../../context/OrgContext/OrgContext';
import TableMenuItem from '../../utils/TableMenuItem';
import ProductContext from '../../context/ProductContext/ProductContext';
import OrganizationContext from '../../context/OrganizationContext/OrganizationContext';
import TableSkeleton from '../../loader/TableSkeleton';
import { currencyFormat, MuiTabelStyle } from '../../utils/helper';

const OrganizationItem = (props) => {
  const {
    editHandle,
    showHandle,
    sorting,
    AddModel,
    setPage,
    setPageSize,
    pageSize,
    page,
    record,
    handleFilter,
    switch_onChange_handle
  } = props;
  const { formvalue, setAccountId, filter, setpageNumber } = useContext(DonationMasterContext);
  const { setBankId } = useContext(OrgContext);
  const { setPersonId } = useContext(ProductContext);
  const { responseLoader } = useContext(OrganizationContext);
  const history = useNavigate();
  const defaultLayoutObj = classes.find((item) => Object.values(item).pop(1) === 'compact-wrapper');
  const layout = localStorage.getItem('layout') || Object.keys(defaultLayoutObj).pop();

  const StatementRedirect = (accountId) => {
    setBankId('');
    setPersonId('');
    history(`/Statement/Statement/${accountId}/account/${layout}`);
    setAccountId(accountId);
    setpageNumber(1);
  };

  const defaultColumns = [
    {
      flex: 0.1,
      minWidth: 250,
      field: 'name',
      headerName: 'Name',
      renderCell: (params) => (params.row.name ? params.row.name : '--')
    },
    {
      flex: 0.1,
      minWidth: 250,
      field: 'amount',
      headerName: 'Amount',
      renderCell: (params) => (
        <div style={{ color: '#578b57', fontWeight: 'bold' }}>
          {params.row.amount ? currencyFormat(params.row.amount) : '--'}
        </div>
      )
    },
    {
      flex: 0.1,
      minWidth: 250,
      field: 'dueAmount',
      headerName: 'Due Amount',
      renderCell: (params) => (
        <div
          style={{
            color: '#ff5f5f',
            fontWeight: 'bold'
          }}
        >
          {params.row.dueAmount ? currencyFormat(params.row.dueAmount) : '--'}
        </div>
      )
    },
    {
      flex: 0.1,
      minWidth: 80,
      field: 'type',
      headerName: 'Type',
      renderCell: (params) =>
        params.row.type == ACCOUNT_TYPE.inward ? ACCOUNT_TYPE.inward_name : ACCOUNT_TYPE.outward_name
    },
    {
      flex: 0.1,
      minWidth: 80,
      field: 'isActive',
      headerName: 'Status',
      renderCell: (params) => (
        <Switch
          value={params.row.isActive ? true : false}
          onChange={() => switch_onChange_handle(params.row.accountId, params.row.isActive)}
        />
      )
    },

    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      renderCell: (params) => (
        <>
          {/* <RowOptions params={params} /> */}
          <TableMenuItem
            params={params}
            editHandle={editHandle}
            showHandle={showHandle}
            StatementRedirect={StatementRedirect}
          />
        </>
      )
    }
  ];

  return (
    <>
      <Fragment>
        <Breadcrumbs parent={DONATION_MASTER.DONATION_MASTER_PARENT} />
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between'
                  }}
                >
                  <h3>{DONATION_MASTER.DONATION_MASTER_PARENT}</h3>
                  <div aria-label="add" className="btn btn-primary" onClick={AddModel} style={Style}>
                    <Plus />
                  </div>
                </CardHeader>
                <Table>
                  <thead>
                    <tr>
                      <th>
                        <Row>
                          <Col md="2 mb-2">
                            <Input
                              className="form-control"
                              type="text"
                              placeholder="Donation Master Search..."
                              value={filter}
                              onChange={(e) => handleFilter(e)}
                            />
                          </Col>
                        </Row>
                      </th>
                    </tr>
                  </thead>
                </Table>
                <Box
                  sx={{
                    '& .even': {
                      backgroundColor: '#f1f1f1'
                    },
                    '& .odd': {
                      backgroundColor: 'white'
                    }
                  }}
                >
                  {responseLoader === true ? (
                    <TableSkeleton />
                  ) : formvalue.length && formvalue.length ? (
                    <DataGrid
                      autoHeight
                      pagination
                      sx={MuiTabelStyle}
                      rows={formvalue}
                      rowCount={record}
                      pageSize={pageSize}
                      getRowId={(row) => row.accountId}
                      columns={defaultColumns}
                      page={page - 1}
                      rowsPerPageOptions={[7, 10, 25, 50]}
                      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                      onPageChange={(newPageNo) => setPage(newPageNo + 1)}
                      paginationMode="server"
                      disableSelectionOnClick
                      checkboxSelection={false}
                      sortingMode="server"
                      onSortModelChange={sorting}
                      getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
                    />
                  ) : (
                    <DataNotFound />
                  )}
                </Box>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fragment>
    </>
  );
};

export default OrganizationItem;
