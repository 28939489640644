import React, { useContext, useEffect, useState } from "react";
import OrganizationItem from "./OrganizationItem";
import OrganizationDetailsModel from "./OrganizationDetailsModel";
import OrganizationAddModel from "./OrganizationAddModel";
import { toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-phone-number-input/style.css";
import { useForm } from "react-hook-form";
import TopLoadingBar from "../topLoadingBar/topLoadingBar";
import { FILE_API } from "../../api/Organization Api/OrganizationApi";
import { CLIENT_TYPE, ERROR_CODE } from "../../constant";
import OrganizationContext from "../../context/OrganizationContext/OrganizationContext";
import {
  Add_OrganizationList,
  Detail_OrganizationList,
  Edit_OrganizationList,
  OrganizationList,
  Org_Enable_Desable,
} from "../../api/OrgApi/OrganizationApi";
import LogOut from "../../utils/logout";
import { useNavigate } from "react-router";

const Organization = () => {
  const {
    setformvalue,
    setahoeDataModel,
    setModal,
    sortOrder,
    setSortOrder,
    sortBy,
    setSortBy,
    filter,
    setFilter,
    editIndex,
    seteditIndex,
    uuid,
    setUuid,
    setName,
    name,
    address,
    setAddress,
    phone,
    setPhone,
    gstNumber,
    setGstNumber,
    email,
    setEmail,
    city,
    setCity,
    state,
    setState,
    userName,
    setUserName,
    userPhone,
    setUserPhone,
    userEmail,
    setUserEmail,
    password,
    setPassword,
    conformPassword,
    setConformPassword,
    userurl,
    setUserUrl,
    url,
    setUrl,
    pancardNumber,
    setPanCardNumber,
    setBase,
    setBaseUrl,
    setIsAddHandle,
    progress,
    setProgress,
    togglesubmit,
    setToggle,
    val,
    isSwitchOn,
    setIsSwitchOn,
    setError,
    loaderShow,
    setLoaderShow,
    responseLoader,
    setResponseLoader,
  } = useContext(OrganizationContext);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [record, setRecord] = useState(0);
  const history = useNavigate()

  /**
   * submit time empty feild function call
   */

  const emptyFeild = () => {
    setUserName("");
    setUserPhone("");
    setUserEmail("");
    setName("");
    setAddress("");
    setPhone("");
    setGstNumber("");
    setEmail("");
    setCity("");
    setState("");
    setPassword("");
    setConformPassword("");
    setUrl("");
    setUserUrl("");
    setPanCardNumber("");
  };

  const fillFeild = () => {
    setUserName(userName);
    setUserPhone(userPhone);
    setUserEmail(userEmail);
    setPassword(password);
    setConformPassword(conformPassword);
    setName(name);
    setAddress(address);
    setPhone(phone);
    setGstNumber(gstNumber);
    setEmail(email);
    setCity(city);
    setState(state);
  };

  /**
   * organization list fatch data and useEffect to use of  call function
   */

  const getData = async (enableLoader) => {
    if (
      enableLoader === false ||
      sortOrder === "ASC" ||
      sortOrder === "DSC" ||
      filter.length
    ) {
    } else {
      setResponseLoader(!responseLoader);
    }
    try {
      let paramsObj = {
        pageNumber: page,
        recordsPerPage: pageSize,
        sortOrder,
        sortBy,
        search: val,
      };
      if (filter.length) {
        delete paramsObj["pageNumber"];
        delete paramsObj["recordsPerPage"];
        delete paramsObj["sortOrder"];
        delete paramsObj["sortBy"];
      }
      if (filter.length) {
        paramsObj["search"] = { phone: filter };
      }
      const { data } = await OrganizationList(paramsObj);
      if (data.status === 200) {
        setResponseLoader(false);
        setformvalue(data.payload.data);
        setRecord(data.pager.totalRecords);
      } else {
        setformvalue([]);
      }
    } catch (error) {
      if (error?.response?.data?.status === ERROR_CODE.UNAUTHORIZED) {
        LogOut(history);
      }
      setResponseLoader(false);
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  useEffect(() => {
    getData();
  }, [page, pageSize, sortOrder, sortBy, filter]);

  useEffect(() => {
    setProgress(100);
  }, []);

  /**
   * open add model function call
   */

  const AddModel = () => {
    setModal(true);
    setToggle(true);
  };

  const readUrl = async (event) => {
    if (event.target.files.length === 0) return;
    var mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      setUrl(reader.result);
    };
    let formData = new FormData();
    formData.append("type", CLIENT_TYPE.ORGANIZATION);
    formData.append("file", event.target.files[0]);
    try {
      const { data } = await FILE_API(formData);
      if (data.status === 200) {
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
        setUrl(data.payload.imageUrl);
        setBase(data.payload.baseUrl);
      } else {
        setformvalue([]);
      }
    } catch (error) {
      if (error?.response?.data?.status === ERROR_CODE.UNAUTHORIZED) {
        LogOut(history);
      }
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  const readUrl1 = async (event) => {
    if (event.target.files.length === 0) return;
    var mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      setUserUrl(reader.result);
    };
    let formData = new FormData();
    formData.append("type", CLIENT_TYPE.USER);
    formData.append("file", event.target.files[0]);
    try {
      const { data } = await FILE_API(formData);
      if (data.status === 200) {
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
        setUserUrl(data.payload.imageUrl);
        setBaseUrl(data.payload.baseUrl);
      } else {
        setformvalue([]);
      }
    } catch (error) {
      if (error?.response?.data?.status === ERROR_CODE.UNAUTHORIZED) {
        LogOut(history);
      }
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  const editHandle = async (orgId) => {
    setUuid(orgId);
    setLoaderShow(!loaderShow);
    try {
      const { data } = await Detail_OrganizationList(orgId);
      if (data.status === 200) {
        setToggle(false);
        setIsAddHandle(false);
        seteditIndex(orgId);
        setUserName(data.payload.organizationAdminDetail.name);
        setUserPhone(data.payload.organizationAdminDetail.phone);
        setUserEmail(data.payload.organizationAdminDetail.email);
        setUserUrl(data.payload.organizationAdminDetail.image);
        setName(data.payload.data.name);
        setAddress(data.payload.data.address);
        setPhone(data.payload.data.phone);
        setGstNumber(data.payload.data.gstNumber);
        setEmail(data.payload.data.email);
        setCity(data.payload.data.city);
        setState(data.payload.data.state);
        setUrl(data.payload.data.image);
        setLoaderShow(false);
        setModal(true);
      } else {
        setformvalue([]);
      }
    } catch (error) {
      if (error?.response?.data?.status === ERROR_CODE.UNAUTHORIZED) {
        LogOut(history);
      }
      toast.error(error.response, {
        autoClose: 1000,
        transition: Zoom,
      });
      setLoaderShow(false);
    }
  };

  const { register } = useForm();

  const onSubmit = async (e) => {
    e.preventDefault();
    if (
      togglesubmit
        ? name.length === 0 ||
          address.length === 0 ||
          phone.length === 0 ||
          email.length === 0 ||
          userName.length === 0 ||
          userPhone.length === 0 ||
          userEmail.length === 0 ||
          password.length === 0 ||
          conformPassword.length === 0 ||
          conformPassword !== password
        : name.length === 0 ||
          address.length === 0 ||
          phone.length === 0 ||
          email.length === 0 ||
          userName.length === 0 ||
          userPhone.length === 0 ||
          userEmail.length === 0
    ) {
      setError(true);
    } else {
      let formData;
      if (editIndex !== -1 && !togglesubmit) {
        setLoaderShow(!loaderShow);
        let UserUrl = userurl.split("hisab-kitab/").pop();
        let UrlImg = url.split("hisab-kitab/").pop();
        formData = {
          AdminUser: {
            name: userName,
            phone: userPhone,
            email: userEmail,
            password: password,
            confirmPassword: conformPassword,
            image: UserUrl,
          },
          name: name,
          address: address,
          phone: phone,
          gstNumber: gstNumber,
          email: email,
          panCardNo: pancardNumber,
          city: city,
          state: state,
          image: UrlImg,
        };

        try {
          const { data } = await Edit_OrganizationList(formData, uuid);
          if (data.status === 200) {
            toast.success(data.message, {
              autoClose: 1000,
            });
            setModal(false);
            getData();
            setLoaderShow(false);
            emptyFeild();
          } else {
            fillFeild();
          }
        } catch (error) {
          if (error?.response?.data?.status === ERROR_CODE.UNAUTHORIZED) {
            LogOut(history);
          }
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,
            transition: Zoom,
          });
          setLoaderShow(false);
        }
      } else {
        setLoaderShow(!loaderShow);
        formData = {
          AdminUser: {
            name: userName,
            phone: userPhone,
            email: userEmail,
            password: password,
            confirmPassword: conformPassword,
            image: userurl,
          },
          name: name,
          address: address,
          phone: phone,
          gstNumber: gstNumber,
          panCardNo: pancardNumber,
          email: email,
          city: city,
          state: state,
          image: url,
        };

        try {
          const { data } = await Add_OrganizationList(formData);
          if (data.status === 200) {
            toast.success(data.message, {
              autoClose: 1000,
              transition: Zoom,
            });
            setModal(false);
            getData();
            setLoaderShow(false);
            emptyFeild();
          } else {
            fillFeild();
          }
        } catch (error) {
          if (error?.response?.data?.status === ERROR_CODE.UNAUTHORIZED) {
            LogOut(history);
          }
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,
            transition: Zoom,
          });
          setLoaderShow(false);
        }
      }
    }
  };

  const showHandle = async (orgId) => {
    setUuid(orgId);
    setLoaderShow(!loaderShow);
    try {
      const { data } = await Detail_OrganizationList(orgId);
      if (data.status === 200) {
        setUserName(data.payload.organizationAdminDetail.name);
        setUserPhone(data.payload.organizationAdminDetail.phone);
        setUserEmail(data.payload.organizationAdminDetail.email);
        setUserUrl(data.payload.organizationAdminDetail.image);
        setName(data.payload.data.name);
        setAddress(data.payload.data.address);
        setPhone(data.payload.data.phone);
        setGstNumber(data.payload.data.gstNumber);
        setEmail(data.payload.data.email);
        setCity(data.payload.data.city);
        setState(data.payload.data.state);
        setUrl(data.payload.data.image);
        setLoaderShow(false);
        setahoeDataModel(true);
      } else {
        setformvalue([]);
      }
    } catch (error) {
      if (error?.response?.data?.status === ERROR_CODE.UNAUTHORIZED) {
        LogOut(history);
      }
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  const sorting = (col) => {
    if (sortOrder === "") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("ASC");
    }
    if (sortOrder === "ASC") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("DSC");
    }
    if (sortOrder === "DSC") {
      setSortBy();
      setSortOrder("");
    }
  };

  const handleFilter = (e) => {
    if (e.target.value === "") {
      setFilter(e.target.value);
    } else {
      setFilter(e.target.value);
    }
  };

  const switch_onChange_handle = async (orgId, status) => {
    let formData;
    formData = {
      orgId: orgId,
      isActive: status ? false : true,
    };
    try {
      const { data } = await Org_Enable_Desable(formData, orgId);
      if (data.status === 200) {
        status
          ? toast.error(data.message, {
              autoClose: 1000,
              transition: Zoom,
            })
          : toast.success(data.message, {
              autoClose: 1000,
              transition: Zoom,
            });
        setIsSwitchOn(!isSwitchOn);
        let enableLoader = false;
        getData(enableLoader);
      } else {
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
        setformvalue([]);
      }
    } catch (error) {
      if (error?.response?.data?.status === ERROR_CODE.UNAUTHORIZED) {
        LogOut(history);
      }
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  return (
    <>
      <div>
        <TopLoadingBar progress={progress} setProgress={setProgress} />
        <OrganizationAddModel
          onSubmit={onSubmit}
          readUrl={readUrl}
          readUrl1={readUrl1}
          register={register}
        />
        <OrganizationDetailsModel />
        <OrganizationItem
          handleFilter={handleFilter}
          sorting={sorting}
          editHandle={editHandle}
          showHandle={showHandle}
          AddModel={AddModel}
          setPage={setPage}
          setPageSize={setPageSize}
          pageSize={pageSize}
          page={page}
          record={record}
          readUrl={readUrl}
          readUrl1={readUrl1}
          switch_onChange_handle={switch_onChange_handle}
        />
      </div>
    </>
  );
};
export default Organization;
