import React, { useContext } from "react";
import "./SlipDownloadeTemplateCss.css";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { inWords } from "../../utils/helper";
import OrgContext from "../../context/OrgContext/OrgContext";
import moment from "moment";
import html2pdf from "html2pdf-jspdf2";
import { useNavigate } from "react-router";
import { classes } from "../../data/layouts";
import { BANK_TYPE, SLIP_DOWNLOADE } from "../../constant";

const SlipDownloadeTemplate = (props) => {
  const { slipData, model, setModel } = useContext(OrgContext);
  const history = useNavigate();
  const defaultLayoutObj = classes.find(
    (item) => Object.values(item).pop(1) === "compact-wrapper"
  );
  const layout =
    localStorage.getItem("layout") || Object.keys(defaultLayoutObj).pop();

  let amount = 0;
  const opt = {
    filename: `${
      slipData &&
      slipData.transactionDetails[0].accountTransactionDetail.personDetail
        ? slipData.transactionDetails[0].accountTransactionDetail.personDetail
            .name +
          "-" +
          `(${slipData.slipNo})`
        : "--"
    }.pdf`,
  };

  const close = () => {
    setModel(!model);
  };
  const Print = () => {
    const invoice = document.getElementById("invoice");
    html2pdf().set(opt).from(invoice).save();
    setModel(!model);
    history(`/Donation/income/${layout}`);
  };

  return (
    <>
      <Modal size="lg" isOpen={model} toggle={() => close()}>
        <ModalHeader toggle={() => close()}></ModalHeader>
        <ModalBody>
          {slipData ? (
            <div>
              <div className="main-div">
                {/* <div className="second-div" id="invoice"> */}
                {/* <div className="second-div" id="printablediv"> */}
                <div className="second-div" id="invoice">
                  <div className="thard-div ">
                    {/* <!-- Main Heading Html And Css --> */}

                    <div className="fourth-div">
                      <p>श्री स्वामिनारायणो विजयतेतराम्</p>
                    </div>

                    {/* <!-- Second Header and names html and css --> */}

                    <div className="main-perent">
                      <div className="main_content">
                        <p>
                          <h5>
                            {slipData.temple.templeName
                              ? slipData.temple.templeName
                              : "--"}
                          </h5>
                        </p>
                        <p className="main-content_p-tag">
                          {slipData.trust.trustName
                            ? slipData.trust.trustName
                            : "--"}
                        </p>
                        <p className="main-content_p-tag">
                          {SLIP_DOWNLOADE.MOBILE}:{" "}
                          {slipData.temple.mobile
                            ? slipData.temple.mobile
                            : "--"}
                          | {SLIP_DOWNLOADE.EMAIL}:{" "}
                          {slipData.temple.email ? slipData.temple.email : "--"}
                        </p>
                        <p className="main-content_p-tag">
                          {SLIP_DOWNLOADE.TRUST_REG}:{" "}
                          {slipData.trust.trustRegNo
                            ? slipData.trust.trustRegNo
                            : ""}
                          | {SLIP_DOWNLOADE.PAN_NO}:
                          {slipData.trust.panNo ? slipData.trust.panNo : "--"} |{" "}
                          {SLIP_DOWNLOADE.CERTI_NO}:
                          {slipData.trust.certNo ? slipData.trust.certNo : "--"}
                        </p>
                      </div>
                    </div>

                    {/* <!-- Table Slip Html and Css --> */}
                    <div className="content-div">
                      <div style={{ margin: " 12px 12px 12px 12px" }}>
                        <table className="tabel">
                          {/* <!-- Table Head --> */}
                          <tr>
                            <td
                              className="border-none p-t p-l tabel-Head-title"
                              // colSpan={2}
                            >
                              {SLIP_DOWNLOADE.NAME}
                            </td>
                            <td
                              className="border-none font-bold p-t p-l font-sizing "
                              colSpan="2"
                            >
                              {slipData.transactionDetails[0]
                                .accountTransactionDetail.personDetail
                                ? slipData.transactionDetails[0]
                                    .accountTransactionDetail.personDetail.name
                                : "--"}
                            </td>
                          </tr>
                          {/* <tr>
                            <td className="border-none tabel-Head-title ">
                              {SLIP_DOWNLOADE.RECEIPT_NO}
                            </td>
                            <td className="border-none font-bold font-sizing">
                              :{slipData.slipNo}
                            </td>
                          </tr> */}
                          <tr>
                            <td className="border-none p-t p-l tabel-Head-title">
                              {SLIP_DOWNLOADE.MOBILE_NO}
                            </td>
                            <td
                              className="border-none font-bold p-t p-l font-sizing"
                              colSpan="2"
                            >
                              {`: ${
                                slipData.transactionDetails[0]
                                  .accountTransactionDetail.personDetail
                                  ? slipData.transactionDetails[0]
                                      .accountTransactionDetail.personDetail
                                      .mobile
                                  : "--"
                              }`}
                            </td>

                            <td
                              className="border-none tabel-Head-title"
                              style={{ width: "9rem" }}
                            >
                              {SLIP_DOWNLOADE.RECEIPT_DATE}
                            </td>
                            <td
                              className="border-none font-bold font-sizing"
                              style={{ width: "9rem" }}
                            >
                              {`: ${
                                slipData.createdAt
                                  ? moment(slipData.createdAt).format(
                                      "DD MMM, YYYY"
                                    )
                                  : "--"
                              }`}
                            </td>
                          </tr>

                          <tr>
                            <td className="border-none p-t p-l p-b tabel-Head-title">
                              {SLIP_DOWNLOADE.PAN_NO}
                            </td>
                            <td
                              className="border-none font-bold p-t p-l p-b font-sizing"
                              colSpan="2"
                            >
                              {`: ${
                                slipData.transactionDetails[0]
                                  .accountTransactionDetail.personDetail
                                  ? slipData.transactionDetails[0]
                                      .accountTransactionDetail.personDetail
                                      .panCardNo
                                  : "--"
                              }`}
                            </td>

                            <td className="border-none tabel-Head-title ">
                              {SLIP_DOWNLOADE.RECEIPT_NO}
                            </td>
                            <td className="border-none font-bold font-sizing">
                              : {slipData.slipNo}
                            </td>
                          </tr>
                          <tr>
                            <td className="border-none p-t p-l tabel-Head-title">
                              {SLIP_DOWNLOADE.ADDRESS}
                            </td>

                            <td
                              className="border-none font-bold p-t p-l font-sizing"
                              colSpan="4"
                            >
                              {`: ${
                                slipData.transactionDetails[0]
                                  .accountTransactionDetail.personDetail
                                  ? slipData.transactionDetails[0]
                                      .accountTransactionDetail.personDetail
                                      .village
                                  : "--"
                              }`}
                            </td>
                          </tr>

                          {/* <!-- Table Body --> */}

                          <tr>
                            <td
                              colSpan="4"
                              className="text-center font-bold  p-b letter-spacing td font-sizing"
                              style={{ paddingTop: "0.5rem" }}
                            >
                              {SLIP_DOWNLOADE.PURPOSE}
                            </td>
                            <td
                              className="text-right font-bold p-b letter-spacing p-r td font-sizing"
                              style={{ paddingTop: "0.5rem" }}
                            >
                              {SLIP_DOWNLOADE.AMOUNT}
                            </td>
                          </tr>
                          {slipData.transactionDetails.map((elem) => {
                            amount += elem.amount;
                            return (
                              <tr>
                                <td
                                  colSpan="4"
                                  className="p-l p-b font-bold letter-spacing font-sizing td "
                                  style={{ paddingTop: "0.5rem" }}
                                >
                                  {elem.accountTransactionDetail
                                    ? elem.accountTransactionDetail
                                        .AccountDetail.name
                                    : "--"}
                                </td>
                                <td className="text-right p-r font-bold font-sizing td">
                                  {elem.amount ? elem.amount : "--"}
                                </td>
                              </tr>
                            );
                          })}
                          <tr>
                            <td
                              className="border-none p-l p-b letter-spacing td font-sizing"
                              style={{
                                fontWeight: "bold",
                                color: "black",
                                paddingTop: "0.5rem",
                              }}
                            >
                              Amount In Word
                            </td>
                            <td
                              colSpan="3"
                              className="border-none p-l p-b font-bold letter-spacing font-sizing td"
                              style={{ paddingTop: "0.5rem" }}
                            >
                              :{inWords(amount)}
                            </td>
                            <td
                              className="text-right p-l p-b p-r font-bold font-sizing td"
                              style={{ paddingTop: "0.5rem" }}
                            >
                              {amount}
                            </td>
                          </tr>
                          <tr className="text-center">
                            <td
                              className="border-bottom letter-spacing font-sizing td"
                              style={{
                                fontWeight: "bold",
                                color: "black",
                                paddingTop: "0.5rem",
                              }}
                            >
                              Mode
                            </td>
                            <td
                              className="border-bottom letter-spacing font-sizing td"
                              style={{
                                fontWeight: "bold",
                                color: "black",
                                paddingTop: "0.5rem",
                              }}
                            >
                              Cheque/DD No
                            </td>
                            {/* <td
                              className="border-bottom letter-spacing font-sizing td"
                              style={{
                                fontSize: "14px",
                                fontWeight: "bold",
                                color: "gray",
                                paddingTop: "0.5rem",
                              }}
                            >
                              Date
                            </td> */}
                            <td
                              style={{
                                fontWeight: "bold",
                                color: "black",
                                paddingTop: "0.5rem",
                              }}
                              className="border-bottom letter-spacing font-sizing td"
                              colSpan={2}
                            >
                              Bank
                            </td>
                            <td
                              style={{
                                fontWeight: "bold",
                                color: "black",
                                paddingTop: "0.5rem",
                              }}
                              className="border-bottom letter-spacing font-sizing td"
                            >
                              Branch
                            </td>
                          </tr>
                          <tr className="text-center">
                            <td
                              className="border-top letter-spacing font-bold p-b font-sizing td"
                              style={{ paddingTop: "0.5rem" }}
                            >
                              {slipData.transactionDetails[0].bankDetail
                                .type === BANK_TYPE.CASE
                                ? BANK_TYPE.CASE_LABALE
                                : BANK_TYPE.BANK_LABALE}
                            </td>
                            <td
                              className="border-top letter-spacing font-bold p-b font-sizing td"
                              style={{ paddingTop: "0.5rem" }}
                            >
                              {slipData.PaymentType
                                ? slipData.PaymentType
                                : "-"}
                            </td>
                            <td
                              className="border-top letter-spacing font-bold p-b font-sizing td"
                              style={{ paddingTop: "0.5rem" }}
                              colSpan={2}
                            >
                              {slipData.transactionDetails
                                ? slipData.transactionDetails[0].bankDetail.name
                                : "--"}
                            </td>
                            <td
                              className="border-top letter-spacing font-bold p-b font-sizing td"
                              style={{ paddingTop: "0.5rem" }}
                            >
                              {slipData.transactionDetails
                                ? slipData.transactionDetails[0].bankDetail
                                    .branch
                                : "--"}
                            </td>
                          </tr>
                        </table>
                      </div>

                      {/* <!-- Bottom --> */}

                      <div className="bottom-1">
                        <p className="font-sizing">
                          For, Temple{" "}
                          {slipData.temple ? slipData.temple.templeName : ""}
                        </p>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          {slipData.transactionDetails &&
                          slipData.transactionDetails[0]
                            .accountTransactionDetail.dueAmount !== 0 ? (
                            <>
                              {" "}
                              <p
                                style={{ color: "#ff0000bf" }}
                                className="font-sizing"
                              >
                                Due Amount :
                                {slipData.transactionDetails
                                  ? slipData.transactionDetails[0]
                                      .accountTransactionDetail.dueAmount
                                  : "--"}{" "}
                              </p>
                            </>
                          ) : (
                            <p style={{ fontSize: "0px" }}>.</p>
                          )}
                          <p className="font-sizing">
                            Received By{" "}
                            {slipData.creator ? slipData.creator.name : ""}
                          </p>
                        </div>
                      </div>
                      <div className="p-l p-b letter-spacing">
                        <p className="font-sizing">
                          (This is Syatem Generated Receipt, it Dose Not Require
                          Signature)
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              paddingTop: "15px",
            }}
          >
            <Button primary={true} onClick={Print}>
              Download
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default SlipDownloadeTemplate;
