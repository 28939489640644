import React from "react";
import { Col, Card, CardBody, CardHeader } from "reactstrap";
import { Doughnut } from "react-chartjs-2";
import { stringToColour } from "./helper";
// import Skeleton from "react-loading-skeleton";
// import { DeshboardResponceLoader } from "../loader/TableSkeleton";

const Doughnutchart = (props) => {
  const { data, labels, Header } = props;

  const backgroundColor = labels.map((label) => {
    return stringToColour(label);
  });

  const doughnutData = {
    labels,
    datasets: [
      {
        data,
        backgroundColor,
      },
    ],
  };
  const doughnutOption = {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    plugins: {
      datalabels: {
        display: false,
        color: "white",
      },
    },
  };
  return (
    <>
      <Col xl="6" md="12">
        <Card>
          <CardHeader>
            <h5>{Header}</h5>
          </CardHeader>
          {/* {data.length ? ( */}
          <CardBody className="chart-block">
            <Doughnut
              data={doughnutData}
              options={doughnutOption}
              width={778}
              height={400}
            />
          </CardBody>
          {/* ) : ( */}
          {/* <CardBody className="chart-block">
              <DeshboardResponceLoader />
            </CardBody> */}
          {/* )} */}
        </Card>
      </Col>
    </>
  );
};

export default Doughnutchart;
