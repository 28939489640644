import React, { useContext } from "react";
import { Edit } from "react-feather";
import Select from "react-select";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { PRIVILLAGE_OPTIONS } from "../../constant";
import PrivillageMasterContext from "../../context/PrivillageMasterContext/PrivillageMasterContext";

const OrganizationAddModel = (props) => {
  const {
    onSubmit,
    handleChange,
    handleInputChange,
    onMenuOpen,
    onMenuClose,
    handleChaildInputChange1,
    handleChaildChange1,
    handleModualAccessChange2,
    onModualAccessChange,
    close,
  } = props;

  const {
    modal,
    name,
    setName,
    method,
    setMethod,
    urls,
    setUrls,
    togglesubmit,
    moduleList,
    moduleChaidList,
    moduleAccessList,
    perModule,
    perModuleName,
    chiModuleName,
    chiModule,
    modualeName,
  } = useContext(PrivillageMasterContext);

  return (
    <>
      <Modal size="lg" isOpen={modal} toggle={() => close()}>
        <ModalHeader toggle={() => close()}>
          {togglesubmit ? (
            <span>Add Privillage</span>
          ) : (
            <span>
              Edit Privillage <span> </span>{" "}
              <Edit
                style={{ width: "50px", height: "20px", color: "#7366ff" }}
              />
            </span>
          )}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader>
                  <h5> Privillage Details</h5>
                </CardHeader>
                <CardBody>
                  <Form
                    className="needs-validation"
                    onSubmit={(e) => onSubmit(e)}
                  >
                    <Row>
                      <Col md="6 mb-4">
                        <Label htmlFor="validationCustom01">{"Name"}</Label>
                        <Input
                          className="form-control"
                          name="name"
                          type="text"
                          placeholder="Name"
                          id="validationCustom01"
                          onChange={(e) => setName(e.target.value)}
                          value={name}
                          required
                        />
                        <div className="invalid-feedback">
                          {"Please provide a valid ORG Name."}
                        </div>
                      </Col>
                      <Col md="6" mb="4">
                        <Label htmlFor="validationCustom03">{"Method"}</Label>

                        <select
                          className="form-select"
                          value={method}
                          onChange={(e) => setMethod(e.target.value)}
                        >
                          <option value="">--select--</option>
                          {PRIVILLAGE_OPTIONS.map((elem) => {
                            return (
                              <option value={elem.value}>{elem.name}</option>
                            );
                          })}
                        </select>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12 mb-4">
                        <Label htmlFor="validationCustom01">{"URLs"}</Label>
                        <Input
                          className="form-control"
                          name="name"
                          type="text"
                          placeholder="Name"
                          id="validationCustom01"
                          onChange={(e) => setUrls(e.target.value)}
                          value={urls}
                          required
                        />
                        <div className="invalid-feedback">
                          {"Please provide a valid ORG Name."}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6 mb-4">
                        <Label htmlFor="validationCustom01">
                          {"Parent Modules"}
                        </Label>
                        <Select
                          aria-labelledby="aria-label"
                          inputId="aria-example-input"
                          name="aria-live-color"
                          defaultValue={{
                            value: +perModule,
                            label: perModuleName[0],
                          }}
                          onMenuOpen={onMenuOpen}
                          onMenuClose={onMenuClose}
                          placeholder={perModule}
                          onChange={handleChange}
                          onInputChange={handleInputChange}
                          options={moduleList.map((elem, i) => {
                            return {
                              value: elem.id,
                              label: elem.name,
                            };
                          })}
                        />
                        <div className="invalid-feedback">
                          {"Please provide a valid ORG Name."}
                        </div>
                      </Col>
                      <Col md="6 mb-4">
                        <Label htmlFor="validationCustom01">
                          {"Child Module"}
                        </Label>
                        <Select
                          aria-labelledby="aria-label"
                          inputId="aria-example-input"
                          name="aria-live-color"
                          defaultValue={{
                            value: +chiModule,
                            label: chiModuleName[0],
                          }}
                          onMenuOpen={onMenuOpen}
                          onMenuClose={onMenuClose}
                          onChange={handleChaildChange1}
                          onInputChange={handleChaildInputChange1}
                          options={moduleChaidList.map((elem, i) => {
                            return {
                              value: elem.id,
                              label: elem.name,
                            };
                          })}
                        />
                        <div className="invalid-feedback">
                          {"Please provide a valid ORG Name."}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12 mb-4">
                        <Label htmlFor="validationCustom01">
                          {"Module Access"}
                        </Label>
                        <Select
                          aria-labelledby="aria-label"
                          inputId="aria-example-input"
                          name="aria-live-color"
                          isMulti
                          defaultValue={modualeName}
                          isClearable
                          isSearchable
                          onMenuOpen={onMenuOpen}
                          onMenuClose={onMenuClose}
                          onChange={handleModualAccessChange2}
                          onInputChange={onModualAccessChange}
                          options={moduleAccessList.map((elem, i) => {
                            return {
                              value: elem.id,
                              label: elem.accessName,
                            };
                          })}
                        />
                        <div className="invalid-feedback">
                          {"Please provide a valid ORG Name."}
                        </div>
                      </Col>
                    </Row>

                    <Row style={{ paddingTop: "30px" }}>
                      <button
                        className="btn btn-primary"
                        type="submit"
                        value="Submit"
                      >
                        {"Submit"}
                      </button>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
};

export default OrganizationAddModel;
