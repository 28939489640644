import React, { useContext, useEffect, useState } from 'react';
import { Edit, LogOut } from 'react-feather';
import { Card, CardBody, CardHeader, Col, Form, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { DONAR, ERROR_CODE, ValidationLabel } from '../../constant';
import OrganizationContext from '../../context/OrganizationContext/OrganizationContext';
import ProductContext from '../../context/ProductContext/ProductContext';
import LoaderResoinse from '../../loader/loader';
import { DateFormateChange, maxLengthCheck } from '../../utils/helper';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Donation_bill_Details } from '../../api/DonationBillApi/DonationCillapi';
import { Zoom, toast } from 'react-toastify';
import { DialCodeList } from '../../api/DialCodeApi/DialCodeApi';
import DonationUserDatails from '../dashboard/Donation/DonationUserDatails';
import { useNavigate } from 'react-router';

const OrganizationAddModel = (props) => {
  const { onSubmit, error, close, modal } = props;
  const {
    setName,
    name,
    contact,
    setContact,
    village,
    setVillage,
    address,
    setAddress,
    pancard,
    setPancard,
    adharcard,
    setAdharcard,
    togglesubmit,
    date,
    setDate,
    setDonarDetails,
    dialCode,
    setDialCode
  } = useContext(ProductContext);
  const { loaderShow } = useContext(OrganizationContext);
  const [dialCodeJson, setDialCodeJson] = useState([]);
  const history = useNavigate()

  const showHandle = async (mobile) => {
    try {
      let paramObj = {
        mobile
      };

      const { data } = await Donation_bill_Details(paramObj);
      if (data.status === 200) {
        let payload = data.payload.data;
        setVillage(payload && payload?.village ? payload?.village : '');
        setName(payload && payload?.name ? payload?.name : '');
        setPancard(payload && payload?.panCardNo ? payload?.panCardNo : '');
        setAdharcard(payload && payload?.aadhaarCard ? payload?.aadhaarCard : '');
        setDate(payload && payload?.dob ? new Date(DateFormateChange(payload?.dob)) : '');
        setAddress(payload && payload?.address ? payload?.address : '');
      } else {
      }
    } catch (error) {
      if (error?.response?.data?.status === ERROR_CODE.UNAUTHORIZED) {
        LogOut(history);
      }
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom
      });
    }
  };

  const handleSearch = (e) => {
    setContact(e.target.value);
    if (togglesubmit) {
      if (e.target.value.length >= 10) {
        let joinValue = `${dialCode} ${e.target.value}`;
        showHandle(joinValue);
      }
    }
  };

  useEffect(() => {
    setDialCodeJson(DialCodeList());
  }, []);

  return (
    <>
      <Modal size="xl" isOpen={modal} toggle={() => close()}>
        {loaderShow === true && <LoaderResoinse />}
        <ModalHeader toggle={() => close()}>
          {togglesubmit ? (
            <span>{DONAR.ADD_DONAR}</span>
          ) : (
            <span>
              {DONAR.EDIT_DONAR} <span> </span> <Edit style={{ width: '50px', height: '20px', color: '#7366ff' }} />
            </span>
          )}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader>
                  <h5> {DONAR.DONAR_DETAILS}</h5>
                </CardHeader>
                <CardBody>
                  <Form className="needs-validation" onSubmit={(e) => onSubmit(e)}>
                    <Row style={{ marginBottom: '1rem' }}>
                      <DonationUserDatails isHide={true} isEdit={!togglesubmit} />
                    </Row>
                    <Row style={{ paddingTop: '30px' }}>
                      <button
                        className="btn btn-primary"
                        type="submit"
                        value="Submit"
                        disabled={loaderShow === true ? 'desabled' : ''}
                      >
                        {'Submit'}
                      </button>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
};

export default OrganizationAddModel;
