import React, { useState } from "react";
import { Card, CardBody, Col, Row, Tooltip } from "reactstrap";
import { formatMoneyValue, truncateString } from "../../../utils/helper";

const DainamicButtons = (props) => {
  const { onOpenModal, accountId, trustId, defaultAmount, name, amount } =
    props;
  const [basictooltip, setbasictooltip] = useState(false);
  const toggle = () => setbasictooltip(!basictooltip);
  return (
    <Col
      xl="1 xl-50 box-col-6"
      md="6"
      key={accountId}
      onClick={() => onOpenModal(trustId, defaultAmount, accountId)}
      style={{ cursor: "pointer" }}
    >
      <Card className="ecommerce-widget" id={`TooltipExample-${accountId}`}>
        <CardBody className="support-ticket-font" style={{ padding: "10px" }}>
          <Row style={{ flexDirection: "column" }}>
            <div>
              <p
                className="total-num counter"
                style={{
                  fontSize: "12px",
                  fontWeight: 600,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {name}
              </p>
            </div>
            <div>
              <div>
                <ul>
                  <li
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexWrap: "wrap",
                    }}
                  >
                    <span className="product-stts txt-success ms-2 margin-zero">
                      {defaultAmount}
                    </span>
                    <div>
                      <span>{amount ? formatMoneyValue(amount) : "0"}</span>

                      <div className="btn-showcase">
                        <Tooltip
                          placement="top"
                          isOpen={basictooltip}
                          target={`TooltipExample-${accountId}`}
                          toggle={toggle}
                        >
                          {name}
                        </Tooltip>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};

export default DainamicButtons;
