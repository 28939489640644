import { IconButton } from '@material-ui/core';
import { AddCircle, DeleteForever } from '@material-ui/icons';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { isEmpty, randomStr } from '../../utils/helper';
import { Card, CardBody, CardHeader, Col, Container, Form, Input, Label, Row } from 'reactstrap';
import {
  ACCOUNT_TYPE,
  DONATION,
  DONATION_ADD,
  ERROR_CODE,
  OTP_EXPIRY_TIME,
  PaymentDropDown,
  PaymentDropDownCaseOnly
} from '../../constant';
import Breadcrumbs from '../../layout/breadcrumb';
import { toast, Zoom } from 'react-toastify';
import { BankMasterList } from '../../api/Bank Master api/BankMasterAPi';
import { Donation_MasterList } from '../../api/DonationMasterApi/DonationMasterApi';
import Select from 'react-select';
import OrgContext from '../../context/OrgContext/OrgContext';
import {
  CreateTrasectionSlip,
  Show_Slip,
  UpdateTrasectionSlip,
  generateOtp
} from '../../api/DonationBillApi/DonationCillapi';
import SlipDownloadeTemplate from '../SlipDownloadeTemplate/SlipDownloadeTemplate';
import { useNavigate } from 'react-router';
import { classes } from '../../data/layouts';
import { Temple_Trust_List } from '../../api/TempleTrustApi/TempleTrustApi';
import OrganizationContext from '../../context/OrganizationContext/OrganizationContext';
import LogOut from '../../utils/logout';
import DonationUserDatails from '../dashboard/Donation/DonationUserDatails';
import { PaymentTypeChackBoxes } from '../dashboard/Donation/DonationUserDatails';
import moment from 'moment';
import OtpComp from '../../common/OtpComp';
import { Slip1, Slip2 } from './Slip';

const DonationAdd = () => {
  const {
    address,
    setAddress,
    name,
    setName,
    contactNo,
    setContactNo,
    village,
    setVillage,
    pancard,
    setPanCard,
    aadhaarCard,
    setAadhaarCard,
    byHand,
    setByhand,
    setDob,
    dob,
    city,
    setCity,
    state,
    setState,
    country,
    setCountry,
    paymentType,
    setPaymentType,
    personId,
    setPersonaId,
    paymentMode,
    setPaymentMode,
    paymenName,
    setPaymentName,
    note,
    setNote,
    transectionNo,
    setTransectionNo,
    getData,
    setSlipId,
    setSlipData,
    setTrustId,
    trustId,
    dialCode,
    setDialCode,
    setIsOpen,
    feilds,
    setFeilds,
    isEdit,
    setIsEdit,
    slipId,
    isAksharvashi,
    dodDate,
    setDodDate,
    dodMonth,
    setDodMonth,
    dodPaksha,
    setdodPaksha,
    dialCodeKin,
    contactNoKin,
    setContactNoKin,
    setIsAksharvashi,
    otpModel,
    setOtpModel,
    idForOtp,
    showOtpError,
    setShowOtpError,
    getSucOtp,
    setGetSucOtp,
    setTimer
  } = useContext(OrgContext);
  const { loaderShow, setLoaderShow } = useContext(OrganizationContext);
  const [accountDrop, setAccountDrop] = useState([]);
  const [accountDropCopy, setAccountDropCopy] = useState([]);
  const [formValue, setformvalue] = useState([]);
  const [trustDrop, setTrustDrop] = useState([]);
  const history = useNavigate();
  const defaultLayoutObj = classes.find((item) => Object.values(item).pop(1) === 'compact-wrapper');
  const layout = localStorage.getItem('layout') || Object.keys(defaultLayoutObj).pop();
  const emptyFeilds = () => {
    setIsEdit(false);
    setPersonaId('');
    setContactNo('');
    setName('');
    setVillage('');
    setPanCard('');
    setAadhaarCard('');
    setAddress('');
    setFeilds([
      {
        id: randomStr(5),
        accountId: Number(''),
        amount: Number(''),
        name: ''
      }
    ]);
    setPaymentMode('');
    setNote('');
    setTransectionNo('');
    setByhand('');
    setCity('');
    setState('');
    setCountry('');
    setPaymentType('');
    setDob('');
    setLoaderShow(false);
    setDialCode('+91');
    setIsAksharvashi(false);
    setContactNoKin('');
    setDodDate('');
    setDodMonth('');
    setdodPaksha('');
  };

  /**
   * get bank dropdown function call
   */

  const bankMaster = async (trustId) => {
    let paramObj = {
      search: { trustId: +trustId },
      showAll: true
    };
    try {
      const { data } = await BankMasterList(paramObj);
      if (data.status === 200) {
        setformvalue(data.payload.data);
      } else {
        setformvalue([]);
      }
    } catch (error) {
      if (error?.response?.data?.status === ERROR_CODE.UNAUTHORIZED) {
        LogOut(history);
      }
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom
      });
    }
  };
  const accountDropDown = async (trustId) => {
    let paramsObj = {
      search: { type: ACCOUNT_TYPE.inward, trustId: +trustId, isActive: true },
      showAll: true
    };
    try {
      const { data } = await Donation_MasterList(paramsObj);
      if (data.status === 200) {
        let newObj = data.payload.data.map((ele, index) => ({
          value: ele.accountId,
          label: ele.name,
          defaultAmount: ele.defaultAmount
        }));
        setAccountDrop(newObj);
        setAccountDropCopy(newObj);
      } else {
        setAccountDrop([]);
        setAccountDropCopy([]);
      }
    } catch (error) {
      if (error?.response?.data?.status === ERROR_CODE.UNAUTHORIZED) {
        LogOut(history);
      }
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom
      });
    }
  };

  const handleChangeTrust = (e) => {
    setTrustId(e.target.value);
    bankMaster(e.target.value);
    accountDropDown(e.target.value);
    setFeilds([
      {
        id: randomStr(5),
        accountId: Number(''),
        amount: Number(''),
        name: ''
      }
    ]);
    setformvalue([]);
    setPaymentMode('');
  };

  useEffect(() => {
    accountDropDown(trustId);
    bankMaster(trustId);
  }, [trustId]);

  /**
   * Trust Dropdown function call
   */

  const TrustDopdown = async () => {
    let paramsObj = {
      showAll: true
    };
    try {
      const { data } = await Temple_Trust_List(paramsObj);
      if (data.status === 200) {
        setTrustDrop(data.payload.data);
      } else {
        setTrustDrop([]);
      }
    } catch (error) {
      if (error?.response?.data?.status === ERROR_CODE.UNAUTHORIZED) {
        LogOut(history);
      }
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom
      });
    }
  };

  useEffect(() => {
    TrustDopdown();
  }, []);

  const handleInputChange = (i, e) => {
    const values = [...feilds];
    values[i][e.target.name] = e.target.value;
    setFeilds(values);
  };

  const handleChange1 = (param, index) => {
    feilds[index].accountId = param.value;
    feilds[index].amount = param.defaultAmount;
    setAccountDrop(accountDrop.filter((data) => data.value !== param.value));
    setAccountDrop(accountDropCopy.filter((data) => !feilds.map((v) => v.accountId).includes(data.value)));
  };

  const TotalAmount = () => {
    let total = 0;
    for (let amount of feilds) {
      total += +amount.amount;
    }
    return total;
  };

  const handleAdd = (e) => {
    e.preventDefault();
    setFeilds([
      ...feilds,
      {
        id: randomStr(5),
        accountId: Number(''),
        amount: Number(''),
        name: ''
      }
    ]);
    setAccountDrop(accountDropCopy.filter((data) => !feilds.map((v) => v.accountId).includes(data.value)));
  };
  const hanldeRemove = async (i, e) => {
    e.preventDefault();
    const values = [...feilds];
    values.splice(i, 1);
    setFeilds(values);
    setAccountDrop(accountDropCopy.filter((data) => !values.map((v) => v.accountId).includes(data.value)));
  };

  const paymentModeChange = (opt) => {
    setPaymentMode(opt);
    if (opt.type === 1) {
      setPaymentType('CASH');
    } else {
      setPaymentType('UPI');
    }
  };

  const accountChange = (opt, i) => {
    setPaymentName(opt);
    feilds[i].name = paymenName;
  };

  const otpApiHandler = async (slipId) => {
    try {
      setLoaderShow(true);
      let formData = {
        type: 'edit',
        slipId
      };
      const { data } = await generateOtp(formData);
      if (data.status === 200) {
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom
        });
        setTimer(OTP_EXPIRY_TIME);
        setGetSucOtp(true);
        setLoaderShow(false);
        setOtpModel(true);
      }
    } catch (error) {
      setLoaderShow(false);
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom
      });
    }
  };

  const updateHandler = async ({ enteredOtp }) => {
    let donationArray = feilds.map(({ accountId, amount }) => ({
      accountId,
      amount
    }));
    try {
      setLoaderShow(!loaderShow);
      let formData = isEmpty({
        personId: personId,
        mobile: `${dialCode} ${contactNo}`,
        name,
        village: village,
        panCardNo: pancard,
        aadhaarCard,
        address,
        donationArray,
        bankId: paymentMode.value,
        note,
        transactionNo: transectionNo,
        byHand: byHand,
        city,
        state,
        country,
        isDeath: isAksharvashi,
        paymentType: paymentType,
        trustId: +trustId,
        templeId: +localStorage.getItem('templeId'),
        otp: enteredOtp
      });
      if (dob) formData.dob = moment(dob).format('yyyy-MM-DD');
      if (dodMonth && dodPaksha && dodDate) formData.dod = `${dodMonth}-${dodPaksha}-${dodDate}`;
      if (dialCodeKin && contactNoKin) formData.kinMobile = `${dialCodeKin} ${contactNoKin}`;

      const { data } = await UpdateTrasectionSlip(formData, slipId);
      if (data.status === 200) {
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom
        });
        emptyFeilds();
        setSlipId(data.payload.data.slipId);
        // ShowSlip(data.payload.data.slipId);
        // setTimeout(() => {
        document.getElementById('donationReceipt').innerHTML =
          +localStorage.getItem('templeId') === 47
            ? `${Slip2({
                slipData: data.payload.data
              })}`
            : `${Slip1({
                slipData: data.payload.data
              })}`;
        window.print();
        // }, 300);
        getData();
        setOtpModel(false);
        setShowOtpError('');
        setGetSucOtp(false);
        // history(`/Donation/income/${layout}`);
      }
    } catch (error) {
      if (error?.response?.data?.status === ERROR_CODE.UNAUTHORIZED) {
        LogOut(history);
      }
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom
      });
      setShowOtpError(error?.response?.data?.message);
      setLoaderShow(false);
    }
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    if (isEdit && slipId > -1) {
      otpApiHandler(slipId);
    } else {
      let donationArray = feilds.map(({ accountId, amount }) => ({
        accountId,
        amount
      }));
      try {
        setLoaderShow(!loaderShow);
        let formData = isEmpty({
          personId: personId,
          mobile: `${dialCode} ${contactNo}`,
          name,
          village: village,
          panCardNo: pancard,
          aadhaarCard,
          address,
          donationArray,
          bankId: paymentMode.value,
          note,
          transactionNo: transectionNo,
          byHand: byHand,
          city,
          state,
          country,
          dob: dob ? moment(dob).format('yyyy-MM-DD') : '',
          paymentType: paymentType,
          trustId: +trustId,
          templeId: +localStorage.getItem('templeId'),
          isDeath: isAksharvashi
        });
        if (dob) formData.dob = moment(dob).format('yyyy-MM-DD');
        if (dodMonth && dodPaksha && dodDate) formData.dod = `${dodMonth}-${dodPaksha}-${dodDate}`;
        if (dialCodeKin && contactNoKin) formData.kinMobile = `${dialCodeKin} ${contactNoKin}`;
        const { data } = await CreateTrasectionSlip(formData);
        if (data.status === 200) {
          let inputContact = document.getElementById('contact-no');
          if (inputContact) {
            inputContact.focus();
          }
          setSlipId(data.payload.data.slipId);
          // setTimeout(() => {
          document.getElementById('donationReceipt').innerHTML =
            +localStorage.getItem('templeId') === 47
              ? `${Slip2({
                  slipData: data.payload.data
                })}`
              : `${Slip1({
                  slipData: data.payload.data
                })}`;
          window.print();
          // }, 300);
          getData();
          // history(`/Donation/income/${layout}`);
          emptyFeilds();
        }
      } catch (error) {
        if (error?.response?.data?.status === ERROR_CODE.UNAUTHORIZED) {
          LogOut(history);
        }
        toast.error(error?.response?.data?.message, {
          autoClose: 1000,
          transition: Zoom
        });
        setLoaderShow(false);
      }
    }
  };

  return (
    <Fragment>
      <Breadcrumbs parent={DONATION.ADD_DONATION} />
      <div id="donationReceipt"></div>
      <Container fluid={true}>
        {/* <button onClick={handlePrint}>Print</button> */}
        <Row>
          <SlipDownloadeTemplate />
          <Col sm="12">
            <Card>
              <CardHeader
                style={{
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <h3>{DONATION.ADD_DONATION}</h3>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col sm="12">
                    <Form className="needs-validation" onSubmit={(e) => onSubmit(e)}>
                      <Row>
                        <DonationUserDatails isEdit={isEdit} />
                      </Row>
                      <hr style={{ color: 'cadetblue' }} />
                      <Row>
                        <Col md="6 mb-4">
                          <Label htmlFor="validationCustom01">{'Trust'}</Label>
                          <select
                            className="form-select"
                            name="orderStatus"
                            typeof="number"
                            value={trustId}
                            onChange={(e) => handleChangeTrust(e)}
                          >
                            <option value="">--select--</option>
                            {trustDrop.map((elem) => {
                              return (
                                <option value={elem.trustId} key={elem.trustId}>
                                  {elem.trustName}
                                </option>
                              );
                            })}
                          </select>
                          <div className="invalid-feedback">{'Please provide a valid ORG Name.'}</div>
                        </Col>
                      </Row>
                      {feilds.map((elem, i) => (
                        <>
                          <div key={elem.id}>
                            <Row>
                              <Col md="5 mb-4">
                                <Label htmlFor="validationCustom01">{DONATION_ADD.DONTION_TYPE}</Label>
                                <Select
                                  aria-labelledby="aria-label"
                                  inputId="aria-example-input"
                                  name="donationtype"
                                  onChange={(opt) => {
                                    handleChange1(opt, i);
                                  }}
                                  value={accountDropCopy.find((ele) => ele.value === elem.accountId)}
                                  onInputChange={(opt) => accountChange(opt, i)}
                                  options={accountDrop}
                                />
                                <div className="invalid-feedback">{'Please provide a valid ORG Name.'}</div>
                              </Col>
                              <Col md="5 mb-4">
                                <Label htmlFor="validationCustom01">{DONATION_ADD.AMOUNT}</Label>
                                <Input
                                  className="form-control colorBlack"
                                  name="amount"
                                  type="number"
                                  id="amount"
                                  value={elem.amount === 0 ? '' : elem.amount}
                                  onWheel={(e) => e.target.blur()}
                                  onChange={(e) => handleInputChange(i, e)}
                                  placeholder={DONATION_ADD.AMOUNT}
                                />
                                <div className="invalid-feedback">{'Please provide a valid ORG Name.'}</div>
                              </Col>
                              <Col
                                md="2 mb-4"
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  top: '12px'
                                }}
                              >
                                <IconButton
                                  aria-label="delete"
                                  style={{ background: '#dcf1dc' }}
                                  onClick={(e) => handleAdd(e)}
                                >
                                  <AddCircle style={{ color: 'green' }} />
                                </IconButton>
                                <IconButton
                                  aria-label="delete"
                                  style={{
                                    background: '#e1d7d7',
                                    marginLeft: '45px'
                                  }}
                                  disabled={i === 0}
                                  onClick={(e) => hanldeRemove(i, e)}
                                >
                                  <DeleteForever style={{ color: 'red' }} />
                                </IconButton>

                                <div className="invalid-feedback">{'Please provide a valid ORG Name.'}</div>
                              </Col>
                            </Row>
                          </div>
                        </>
                      ))}
                      <Row>
                        <Col md="3 mb-4">
                          <Label htmlFor="validationCustom01">{DONATION_ADD.PAYMENT_MODE}</Label>
                          <Select
                            aria-labelledby="aria-label"
                            inputId="aria-example-input"
                            name="aria-live-color"
                            onChange={(opt) => {
                              paymentModeChange(opt);
                            }}
                            options={formValue.map((elem, i) => {
                              return {
                                value: elem.bankId,
                                label: elem.name,
                                type: elem.type
                              };
                            })}
                            value={paymentMode}
                          />
                          <div className="invalid-feedback">{'Please provide a valid ORG Name.'}</div>
                        </Col>
                        <Col md="3 mb-4">
                          <Label htmlFor="validationCustom01">{DONATION_ADD.TOTAL_AMOUNT}</Label>
                          <Input
                            className="form-control colorBlack"
                            name="totalamount"
                            type="text"
                            id="totalamount"
                            placeholder={TotalAmount()}
                            disabled
                          />
                          <div className="invalid-feedback">{'Please provide a valid ORG Name.'}</div>
                        </Col>
                        <Col md="3 mb-4">
                          <Label htmlFor="validationCustom01">{DONATION_ADD.TRANSECTION_NO}</Label>
                          <Input
                            className="form-control colorBlack"
                            name="transectionno"
                            type="text"
                            id="transectionno"
                            placeholder={DONATION_ADD.TRANSECTION_NO}
                            value={transectionNo}
                            onChange={(e) => setTransectionNo(e.target.value)}
                          />
                          <div className="invalid-feedback">{'Please provide a valid ORG Name.'}</div>
                        </Col>
                      </Row>

                      <PaymentTypeChackBoxes
                        setPaymentType={setPaymentType}
                        paymentType={paymentType}
                        PaymentDropDown={
                          paymentMode.type === 2
                            ? PaymentDropDown
                            : paymentMode.type === 1
                            ? PaymentDropDownCaseOnly
                            : []
                        }
                      />
                      <Row>
                        <Col md="12 mb-4">
                          <Label htmlFor="validationCustom01">{DONATION_ADD.NOTE}</Label>
                          <textarea
                            className="form-control colorBlack"
                            name="note"
                            id="note"
                            placeholder={DONATION_ADD.NOTE}
                            rows="3"
                            value={note}
                            onChange={(e) => setNote(e.target.value)}
                          />
                          <div className="invalid-feedback">{'Please provide a valid ORG Name.'}</div>
                        </Col>
                      </Row>
                      <Row style={{ paddingTop: '30px' }}>
                        <button className="btn btn-primary" type="submit" value="Submit">
                          {'Submit'}
                        </button>
                      </Row>
                    </Form>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <OtpComp
        otpModel={otpModel}
        setOtpModel={setOtpModel}
        idForOtp={idForOtp}
        resendOtpApi={otpApiHandler}
        otpSubmitHandler={updateHandler}
        showOtpError={showOtpError}
        getSucOtp={getSucOtp}
      />
    </Fragment>
  );
};

export default DonationAdd;
