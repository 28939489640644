import moment from "moment";
import { BANK_TYPE, SLIP_DOWNLOADE } from "../../constant";
import { inWords } from "../../utils/helper";

const sumAmount = ({ slipData }) => {
  let amount = 0;
  slipData?.transactionDetails.forEach((element) => {
    amount += element.amount || 0;
  });
  return amount;
};

const addTable = ({ slipData }) => {
  let str = "";
  slipData?.transactionDetails.map((elem) => {
    str += `
        <tr style="border-style: none; height: 25px">
          <td  style="
          padding-left: 1rem;
          font-weight: 500;
          letter-spacing: 1px;
          border: 1px solid gray;
          border-collapse: collapse;
          font-size: 11px;
        ">
            ${
              elem.accountTransactionDetail
                ? elem.accountTransactionDetail.AccountDetail.name
                : ""
            }
          </td>
          <td style="
          text-align: right;
          padding-right: 1rem;
          font-weight: 600;
          letter-spacing: 1px;
          font-size: 11px;
          border: 1px solid gray;
          border-collapse: collapse;
          height: 25px;
        ">
            ${elem.amount ? elem.amount : ""}
          </td>
        </tr>`;
  });
  return str;
};

export const Slip1 = ({ slipData }) => {
  let amount = sumAmount({ slipData });
  return `
    <div
    style="display: flex; align-items: center; justify-content: center; padding-top: 25px;"
  >
    <div id="invoice" style="width: 730px; border: 1px solid #000; border-radius: 10px;">
      <div
      style="
      margin: 8px;
      background-color: #fff;
      padding-bottom: 0.1rem;
      font-family: sans-serif;
    "
      >
        <div
        style="display: flex; align-items: center; justify-content: center; font-weight: 700; letter-spacing: 1px;"
        >
          <p  style="margin: 0px; font-size: 10px;">
            श्री स्वामिनारायणो विजयतेतराम्
          </p>
        </div>
     
      <div
      style="display: flex; justify-content: space-around; background-color: #e6eeff; border-radius: 10px; align-items: center; margin: 5px auto;"
          >
            <div>
              <img
                src="${
                  process.env.REACT_APP_BUCKET_URL
                }/hisab-kitab/Organization/41631707724009195.png"
                alt=""
                width="50px"
                style="mix-blend-mode: multiply;"
              />
            </div>
            <div style="margin: 0px 15px 5px;">
              <div
              style="background-color: #e6eeff; border-radius: 10px; display: flex; flex-direction: column; align-items: center; justify-content: center; font-size: 14px;"
              >
                  <h5 style="margin: 5px 0; font-size: 14px;font-weight: 700;">
                    ${
                      slipData?.temple?.templeName
                        ? slipData?.temple?.templeName
                        : "--"
                    }
                  </h5>
                <p
                style="
                padding: 0 0 5px;
                letter-spacing: 1px;
                color: #000;
                margin: 0;
                font-size: 10px;
              "
                >
                  ${SLIP_DOWNLOADE.MOBILE}: ${
    slipData?.temple.mobile ? slipData?.temple.mobile : "--"
  } | ${SLIP_DOWNLOADE.EMAIL}: ${
    slipData?.temple.email ? slipData?.temple.email : "--"
  }
                </p>
                <p
                style="
                padding: 0 0 5px;
                letter-spacing: 1px;
                color: #000;
                margin: 0;
                font-size: 10px;
              "
                >
                  ${SLIP_DOWNLOADE.TRUST_REG}: ${
    slipData?.trust.trustRegNo ? slipData?.trust.trustRegNo : ""
  } | ${SLIP_DOWNLOADE.PAN_NO}: ${
    slipData?.trust.panNo ? slipData?.trust.panNo : "--"
  } | ${SLIP_DOWNLOADE.CERTI_NO}: ${
    slipData?.trust.certNo ? slipData?.trust.certNo : "--"
  }
                </p>
              </div>
            </div>
            <div>
              <img
                src="${
                  process.env.REACT_APP_BUCKET_URL
                }/hisab-kitab/Organization/41631707724009195.png"
                alt=""
                width="50px"
                style="mix-blend-mode: multiply;"
              />
            </div>
          </div>
          <div
          style="
          border: 2px solid gray;
          border-radius: 10px;
          height: auto;
          position: relative;
          z-index: 9;
        "
        >
          <div
          style="
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-90px, -50%);
          opacity: 0.3;
          filter: grayscale(1);
        "
          >
            <img src="${localStorage.getItem(
              "templeLogo"
            )}" style="width: 166px;" />
          </div>
          <div style="margin: 12px 12px -12px">
            <div
            style="
            border-top: 1px solid gray;
            border-right: 1px solid gray;
            border-bottom: none;
            border-left: 1px solid gray;
            border-image: initial;
            display: grid;
            grid-template-columns: 80px 157px 80px 157px 80px 157px;
            gap: 1px;
            width: auto;
            margin: 0 auto;
            padding: 8px 10px;
          "
            >
              <div
              style="text-align: start; font-size: 11px; font-weight: 600"
              >
                ${SLIP_DOWNLOADE.NAME}
              </div>
              <div
              style="
              text-align: start;
              font-size: 12px;
              font-weight: 500;
              grid-column: 2 / span 3;
            "
              >
                : ${
                  slipData?.transactionDetails[0].accountTransactionDetail
                    .personDetail
                    ? slipData?.transactionDetails[0].accountTransactionDetail
                        .personDetail.name
                    : "--"
                }
              </div>
              <div
              style="text-align: start; font-size: 11px; font-weight: 600"
              >
                ${SLIP_DOWNLOADE.MOBILE_NO}
              </div>
              <div
              style="text-align: start; font-size: 12px; font-weight: 500"
              >
                : ${
                  slipData?.transactionDetails[0].accountTransactionDetail
                    .personDetail
                    ? slipData?.transactionDetails[0].accountTransactionDetail
                        .personDetail.mobile
                    : "--"
                }
              </div>
              <div
              style="
              text-align: start;
              padding: 3px 0;
              font-size: 11px;
              font-weight: 600;
            "
              >
                ${SLIP_DOWNLOADE.RECEIPT_DATE}
              </div>
              <div
              style="
              text-align: start;
              padding: 0 0 2px;
              font-size: 12px;
              font-weight: 500;
            "
              >
                : ${
                  slipData?.createdAt
                    ? moment(slipData?.createdAt).format("DD MMM, YYYY")
                    : "--"
                }
              </div>
              <div
              style="
              text-align: start;
              padding: 0 0 2px;
              font-size: 11px;
              font-weight: 600;
            "
              >
                ${SLIP_DOWNLOADE.PAN_NO}
              </div>
              <div
              style="
              text-align: start;
              padding: 0 0 2px;
              font-size: 12px;
              font-weight: 500;
            "
              >
                : ${
                  slipData?.transactionDetails[0].accountTransactionDetail
                    .personDetail?.panCardNo
                    ? slipData?.transactionDetails[0].accountTransactionDetail
                        .personDetail.panCardNo
                    : "--"
                }
              </div>
              <div
              style="
              text-align: start;
              padding: 0 0 2px;
              font-size: 11px;
              font-weight: 600;
            "
              >
                ${SLIP_DOWNLOADE.RECEIPT_NO}
              </div>
              <div
              style="
              text-align: start;
              padding: 0 0 2px;
              font-size: 12px;
              font-weight: 500;
            "
              >
                : ${slipData?.slipNo}
              </div>
              <div
              style="text-align: start; font-size: 11px; font-weight: 600"
              >
                ${SLIP_DOWNLOADE.ADDRESS}
              </div>
              <div
              style="text-align: start; font-size: 12px; font-weight: 500"
              >
                : ${
                  slipData?.transactionDetails[0].accountTransactionDetail
                    .personDetail
                    ? slipData?.transactionDetails[0].accountTransactionDetail
                        .personDetail.village
                    : "--"
                }
              </div>

              <div
              style="text-align: start; font-size: 11px; font-weight: 600"
              >
                ${SLIP_DOWNLOADE.BY_HEND}
              </div>
              <div
              style="text-align: start; font-size: 12px; font-weight: 500"
              >
                : ${slipData?.byHand ? slipData?.byHand : "--"}
              </div>
              <div
              style="text-align: start; font-size: 11px; font-weight: 600"
              >
                  <p
                    style="margin: 0px;"
                  >
                    <span>
                      ${
                        slipData?.transactionDetails[0].bankDetail.type ===
                        BANK_TYPE.CASE
                          ? BANK_TYPE.CASE_LABALE
                          : BANK_TYPE.BANK_LABALE
                      }
                    </span>
                  </p>
              </div>
              <div
              style="text-align: start; font-size: 11px; font-weight: 500"
              >
                ${
                  slipData?.transactionNo ? `: ${slipData?.transactionNo} ` : ""
                }
              </div>
            </div>
          </div>
          <div style="margin: 12px">
            <table
            style="
            width: 100%;
            border-collapse: collapse;
            border: 1px solid gray;
            overflow: auto;
          "
            >
              <tr style="border-style: none;">
                <td
                style="
                text-align: center;
                font-weight: 600;
                letter-spacing: 1px;
                border: 1px solid gray;
                border-collapse: collapse;
                font-size: 11px;
                width: 80%;
                height: 25px;
              "
                >
                  ${SLIP_DOWNLOADE.PURPOSE}
                </td>
                <td
                style="
                text-align: right;
                font-weight: 600;
                letter-spacing: 1px;
                padding-right: 1rem;
                border: 1px solid gray;
                border-collapse: collapse;
                font-size: 11px;
              "
                >
                  ${SLIP_DOWNLOADE.AMOUNT}
                </td>
              </tr>
              ${addTable({ slipData })}
              <tr style="border-style: none; height: 25px">
                <td
                style="
                font-weight: 700;
                color: #000;
                border: none;
                padding-left: 1rem;
                font-size: 11px;
                border-collapse: collapse;
              "
                >
                  Amount In Word : ${inWords(amount)}
                </td>
                <td
                style="
                text-align: right;
                padding-left: 1rem;
                padding-right: 1rem;
                font-weight: 600;
                letter-spacing: 1px;
                font-size: 11px;
                border: 1px solid gray;
                border-collapse: collapse;
              "
                >
                  ${amount}
                </td>
              </tr>
            </table>
          </div>

          <div>
            <div
            style="
            text-align: end;
            font-weight: 700;
            margin: 10px 12px;
            letter-spacing: 1px;
          "
            >
              <p style="font-size: 9px">
                For, Temple
                ${slipData?.temple ? slipData?.temple.templeName : ""}
              </p>
              <div
              style="display: flex; justify-content: space-between"
              >
                ${
                  slipData?.transactionDetails &&
                  slipData?.transactionDetails[0].accountTransactionDetail
                    .dueAmount !== 0
                    ? ` <p style="color: #ff0000bf;font-size: 9px;margin-bottom: 0px;">
                      Due Amount :
                      ${
                        slipData?.transactionDetails
                          ? slipData?.transactionDetails[0]
                              .accountTransactionDetail.dueAmount
                          : "--"
                      }
                    </p>`
                    : ` <p style="font-size: 0px;">.</p>`
                }

                <p
                style="font-size: 8px; margin: 0; font-weight: 500"
                >
                  Received By
                  ${slipData?.creator ? slipData?.creator.name : ""}
                </p>
              </div>
            </div>
            <div
            style="padding-left: 1rem; letter-spacing: 1px; font-size: 10px"
            >
              <b>Note :- </b>
              ${
                slipData?.transactionDetails[0]?.accountTransactionDetail
                  ?.note || "--"
              }
            </div>
            <div
            style="padding-left: 1rem; letter-spacing: 1px"
            >
              <p style="font-size: 8px;">
                (This is Syatem Generated Receipt, it Dose Not Require
                Signature)
              </p>
            </div>
          </div>
        </div>
    </div>
  </div>
  </div>
    `;
};

const addTableSlip2 = ({ slipData }) => {
  let str = "";
  let transactionDetails = [...slipData?.transactionDetails];
  for (let index = 0; index < 7 - slipData.transactionDetails.length; index++) {
    transactionDetails.push({});
  }
  transactionDetails.map((elem) => {
    str += `
    <tr style="border-style: none; height: 30px">
            <td  style="
            padding-top: 0.5rem;
            padding-left: 1rem;
            padding-bottom: 0.3rem;
            font-weight: 600;
            letter-spacing: 1px;
            border: 1px solid gray;
            border-collapse: collapse;
            font-size: 11px;
          "
          ">
              ${
                elem.accountTransactionDetail
                  ? elem.accountTransactionDetail.AccountDetail.name
                  : ""
              }
            </td>
            <td  style="
            text-align: right;
            padding-right: 1rem;
            font-weight: 600;
            letter-spacing: 1px;
            font-size: 11px;
            border: 1px solid gray;
            border-collapse: collapse;
          "
          ">
              ${elem.amount ? elem.amount : ""}
            </td>
          </tr>`;
  });
  return str;
};
export const Slip2 = ({ slipData }) => {
  let amount = sumAmount({ slipData });
  return `  <div
  style="display: flex; align-items: center; justify-content: center"
>
  <div  id="invoice" style="width: 500.93px">
    <div
      style="
        margin: 8px;
        background-color: white;
        padding-bottom: 0.1rem;
        font-family: sans-serif;
      "
    >
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          color: red;
          font-weight: bold;
          letter-spacing: 1px;
          height: 1.5in;
        "
      ></div>
      <div
        style="
          border: 2px solid gray;
          border-radius: 18px;
          height: 550px;
          position: relative;
        "
      >
        <div style="margin: 12px 12px -12px">
          <div
            style="
              border-top: 1px solid gray;
              border-right: 1px solid gray;
              border-bottom: none;
              border-left: 1px solid gray;
              border-image: initial;
              display: grid;
              grid-template-columns: 80px 150px 90px 2fr;
              gap: 1px;
              width: auto;
              margin: 0px auto;
              padding: 8px 10px;
            "
          >
            <div
              style="
                text-align: start;
                padding: 5px 0px;
                font-size: 11px;
                font-weight: 600;
              "
            >
              Name
            </div>
            <div
              style="
                text-align: start;
                padding: 5px 0px;
                font-size: 11px;
                font-weight: 600;
                grid-column: 2 / span 3;
              "
            >
              : ${
                slipData?.transactionDetails[0].accountTransactionDetail
                  .personDetail
                  ? slipData?.transactionDetails[0].accountTransactionDetail
                      .personDetail.name
                  : "--"
              }
            </div>
            <div
              style="
                text-align: start;
                padding: 5px 0px;
                font-size: 11px;
                font-weight: 600;
              "
            >
              Mobile No.
            </div>
            <div
              style="
                text-align: start;
                padding: 5px 0px;
                font-size: 11px;
                font-weight: 600;
              "
            >
              : ${
                slipData?.transactionDetails[0].accountTransactionDetail
                  .personDetail
                  ? slipData?.transactionDetails[0].accountTransactionDetail
                      .personDetail.mobile
                  : "--"
              }
            </div>
            <div
              style="
                text-align: start;
                padding: 5px 0px;
                font-size: 11px;
                font-weight: 600;
              "
            >
              Receipt Date
            </div>
            <div
              style="
                text-align: start;
                padding: 5px 0px;
                font-size: 11px;
                font-weight: 600;
              "
            >
              : ${
                slipData?.createdAt
                  ? moment(slipData?.createdAt).format("DD MMM, YYYY")
                  : "--"
              }
            </div>
            <div
              style="
                text-align: start;
                padding: 5px 0px;
                font-size: 11px;
                font-weight: 600;
              "
            >
              Pan No.
            </div>
            <div
              style="
                text-align: start;
                padding: 5px 0px;
                font-size: 11px;
                font-weight: 600;
              "
            >
              : ${
                slipData?.transactionDetails[0].accountTransactionDetail
                  .personDetail?.panCardNo
                  ? slipData?.transactionDetails[0].accountTransactionDetail
                      .personDetail.panCardNo
                  : "--"
              }
            </div>
            <div
              style="
                text-align: start;
                padding: 5px 0px;
                font-size: 11px;
                font-weight: 600;
              "
            >
              Receipt No.
            </div>
            <div
              style="
                text-align: start;
                padding: 5px 0px;
                font-size: 11px;
                font-weight: 600;
              "
            >
              : ${slipData?.slipNo}
            </div>
            <div
              style="
                text-align: start;
                padding: 5px 0px;
                font-size: 11px;
                font-weight: 600;
              "
            >
              Address
            </div>
            <div
              style="
                text-align: start;
                padding: 5px 0px;
                font-size: 11px;
                font-weight: 600;
                grid-column: 2 / span 3;
              "
            >
              : ${
                slipData?.transactionDetails[0].accountTransactionDetail
                  .personDetail
                  ? slipData?.transactionDetails[0].accountTransactionDetail
                      .personDetail.village
                  : "--"
              }
            </div>
            <div
              style="
                text-align: start;
                padding: 5px 0px;
                font-size: 11px;
                font-weight: 600;
              "
            >
              By Hand
            </div>
            <div
              style="
                text-align: start;
                padding: 5px 0px;
                font-size: 11px;
                font-weight: 600;
                grid-column: 2 / span 2;
              "
            >
              : ${slipData?.byHand ? slipData?.byHand : "--"}
            </div>
            <div
              style="
                text-align: start;
                padding: 5px 0px;
                font-size: 11px;
                font-weight: 600;
              "
            >
              <p  style="font-size: 9px; margin: 0px">
                <span>${
                  slipData?.transactionDetails[0].bankDetail.type ===
                  BANK_TYPE.CASE
                    ? BANK_TYPE.CASE_LABALE
                    : BANK_TYPE.BANK_LABALE
                }</span><span style="margin: 5px">
                ${
                  slipData?.transactionNo ? `: ${slipData?.transactionNo} ` : ""
                }</span>
              </p>
            </div>
          </div>
        </div>
        <div style="margin: 12px">
          <table
            style="
              width: 100%;
              border-collapse: collapse;
              border: 1px solid gray;
              overflow: auto;
            "
          >
            <tr style="border-style: none">
              <td
                style="
                  text-align: center;
                  font-weight: 600;
                  letter-spacing: 1px;
                  border: 1px solid gray;
                  border-collapse: collapse;
                  font-size: 11px;
                  width: 80%;
                  height: 30px;
                "
              >
                Account
              </td>
              <td
                style="
                  text-align: right;
                  font-weight: 600;
                  letter-spacing: 1px;
                  padding-right: 1rem;
                  border: 1px solid gray;
                  border-collapse: collapse;
                  font-size: 11px;
                "
              >
                Amount
              </td>
            </tr>
           ${addTableSlip2({ slipData })}
            <tr style="border-style: none; height: 30px">
              <td
                style="
                  font-weight: bold;
                  color: black;
                  border: none;
                  padding-left: 1rem;
                  font-size: 11px;
                  border-collapse: collapse;
                "
              >
                Amount In Word : ${inWords(amount)}
              </td>
              <td
                style="
                  padding: 0.5rem 1rem 0.3rem;
                  text-align: right;
                  font-weight: 600;
                  letter-spacing: 1px;
                  font-size: 11px;
                  border: 1px solid gray;
                  border-collapse: collapse;
                "
              >
              ${amount}
              </td>
            </tr>
          </table>
        </div>
        <div>
          <div
            style="
              text-align: end;
              font-weight: 700;
              margin: 10px 12px;
              letter-spacing: 1px;
            "
          >
            <p  style="font-size: 11px">
              For, Temple ${slipData?.temple ? slipData?.temple.templeName : ""}
            </p>
            <div style="display: flex; justify-content: space-between">
              <p style="font-size: 0px">.</p>
              <p  style="font-size: 9px; margin: 0px">
                Received By  ${slipData?.creator ? slipData?.creator.name : ""}
              </p>
            </div>
          </div>
          <div
            style="
              padding-left: 1rem;
              padding-bottom: 0.2rem;
              letter-spacing: 1px;
              font-size: 11px;
              height: 35px;
            "
          >
            <b>Note :- </b> ${
              slipData?.transactionDetails[0]?.accountTransactionDetail?.note ||
              "--"
            }
          </div>
          <div
            style="
              padding-left: 1rem;
              padding-bottom: 0.2rem;
              letter-spacing: 1px;
            "
          >
            <p style="font-size: 9px">
              (This is Syatem Generated Receipt, it Dose Not Require
              Signature)
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>`;
};
