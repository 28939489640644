import React, { useContext, useEffect, useState } from "react";
import OrganizationItem from "./donarItem";
import OrganizationDetailsModel from "./donarDetailsModel";
import OrganizationAddModel from "./donarAddModel";

import { toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "react-phone-number-input/style.css";

import TopLoadingBar from "../topLoadingBar/topLoadingBar";
import ProductContext from "../../context/ProductContext/ProductContext";
import {
  Add_Donar,
  DonarList,
  Donar_List_Details,
  Donar_List_Enable_Desable,
  Edit_Donar_List,
} from "../../api/Donar Api/DonarApi";
import { useNavigate } from "react-router";
import { classes } from "../../data/layouts";
import DonationMasterContext from "../../context/DonatoinMasterContext/DonationMasterContext";
import OrgContext from "../../context/OrgContext/OrgContext";
import OrganizationContext from "../../context/OrganizationContext/OrganizationContext";
import { ERROR_CODE } from "../../constant";
import LogOut from "../../utils/logout";
import { DateFormateChange, isEmpty, isValidDate } from "../../utils/helper";
import moment from "moment";

const Organization = () => {
  const {
    setformvalue,
    setahoeDataModel,
    setModal,
    modal,
    sortOrder,
    setSortOrder,
    sortBy,
    setSortBy,
    filter,
    setFilter,
    editIndex,
    seteditIndex,
    personId,
    setPersonId,
    setName,
    name,
    contact,
    setContact,
    village,
    setVillage,
    address,
    setAddress,
    pancard,
    setPancard,
    adharcard,
    setAdharcard,
    setIsAddHandle,
    progress,
    setProgress,
    togglesubmit,
    setToggle,
    val,
    setDonarDetails,
    isSwitchOn,
    setIsSwitchOn,
    date,
    setDate,
    dialCode,
    formValue,
    setDialCode,
  } = useContext(ProductContext);
  const { setpageNumber, setAccountId } = useContext(DonationMasterContext);
  const {
    setBankId,
    dialCode: dialCodeDonar,
    setDialCode: setDialCodeDonar,
    contactNo,
    setContactNo,
    setContactNoKin,
    isAksharvashi,
    name: donarName,
    setName: setDonarName,
    village: donarVillage,
    setVillage: setDonarVillage,
    pancard: donarPanCard,
    setPanCard: setDonarPanCard,
    aadhaarCard,
    setAadhaarCard,
    byHand,
    dob,
    setDob,
    address: donarAddress,
    setAddress: setDonarAddress,
    dodDate,
    dodMonth,
    dodPaksha,
    dialCodeKin,
    contactNoKin,
    setDodMonth,
    setdodPaksha,
    setDodDate,
    setIsAksharvashi,
  } = useContext(OrgContext);
  const { loaderShow, setLoaderShow, responseLoader, setResponseLoader } =
    useContext(OrganizationContext);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [record, setRecord] = useState(0);
  const [error, setError] = useState(false);
  const history = useNavigate();
  const defaultLayoutObj = classes.find(
    (item) => Object.values(item).pop(1) === "compact-wrapper"
  );
  const layout =
    localStorage.getItem("layout") || Object.keys(defaultLayoutObj).pop();

  /**
   * empty feild function call
   */

  const emptyFeild = () => {
    setAddress("");
    setContact("");
    setName("");
    setVillage("");
    setAdharcard("");
    setPancard("");
    setAddress("");
    setDate("");
    setLoaderShow(false);
    setError(false);
    setDialCode("+91");
    setDonarName("");
    setDialCodeDonar("+91");
    setContactNo("");
    setContactNoKin("");
    setDonarVillage("");
    setDonarPanCard("");
    setAadhaarCard("");
    setDonarAddress("");
    setDob("");
    setIsAksharvashi(false);
  };
  const fillFeid = () => {
    setAddress(address);
    setContact(contact);
    setName(name);
    setVillage(village);
    setAdharcard(adharcard);
    setPancard(pancard);
    setAddress(address);
    setDate(date);
  };

  /**
   * gat data to doner list to function call and use effect to use of function call
   */

  const getData = async () => {
    if (sortOrder === "ASC" || sortOrder === "DSC" || filter.length) {
    } else {
      setResponseLoader(!responseLoader);
    }
    try {
      let paramsObj = {
        pageNumber: page,
        recordsPerPage: pageSize,
        sortOrder,
        sortBy,
      };
      if (val?.length) {
        paramsObj["search"] = val;
      }
      if (filter.length) {
        delete paramsObj["pageNumber"];
        delete paramsObj["recordsPerPage"];
        delete paramsObj["sortOrder"];
        delete paramsObj["sortBy"];
      }
      if (isNaN(filter) && filter?.length > 0) {
        paramsObj["search"] = { name: filter };
      } else if (filter?.length > 0 && !isNaN(filter)) {
        paramsObj["search"] = { mobile: +filter };
      }
      const { data } = await DonarList(paramsObj);
      if (data.status === 200) {
        setformvalue(data.payload.data);

        setRecord(data.pager.totalRecords);
        setResponseLoader(false);
      } else {
        setformvalue([]);
      }
    } catch (error) {
      if (error?.response?.data?.status === ERROR_CODE.UNAUTHORIZED) {
        LogOut(history);
      }
      setResponseLoader(false);
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  useEffect(() => {
    getData();
    setProgress(100);
  }, [page, pageSize, sortOrder, sortBy, filter]);
  /**
   * open to add model frction call
   */

  const AddModel = () => {
    setModal(true);
    setToggle(true);
  };

  /**
   * get data to function call to fill feild as edit time to function call
   */

  const editHandle = async (personId) => {
    setPersonId(personId);
    setLoaderShow(!loaderShow);
    try {
      const { data } = await Donar_List_Details(personId);

      if (data.status === 200) {
        setToggle(false);
        setIsAddHandle(false);
        seteditIndex(personId);
        //ORG EDIT
        const payload = data.payload.data;
        setIsAksharvashi(payload.isDeath);
        setDonarName(payload.name ? payload.name : null);
        setDialCodeDonar(
          payload.mobile ? payload.mobile.split(" ").shift() : null
        );
        setContactNo(payload.mobile ? payload.mobile.split(" ").pop() : null);
        setContactNoKin(
          payload.kinMobile ? payload.kinMobile.split(" ").pop() : null
        );
        setDonarVillage(payload.village ? payload.village : null);
        setDonarPanCard(payload.panCardNo ? payload.panCardNo : null);
        setAadhaarCard(payload.aadhaarCard ? payload.aadhaarCard : null);
        setDonarAddress(payload.address ? payload.address : null);
        setDob(
          moment(payload.dob).isValid()
            ? new Date(DateFormateChange(payload.dob))
            : null
        );
        setDodMonth(payload.dod?.split("-")[0]);
        setdodPaksha(payload.dod?.split("-")[1]);
        setDodDate(payload.dod?.split("-")[2]);
        setLoaderShow(false);
        setModal(true);
      } else {
        setformvalue([]);
      }
    } catch (error) {
      if (error?.response?.data?.status === ERROR_CODE.UNAUTHORIZED) {
        LogOut(history);
      }
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
      setLoaderShow(false);
    }
  };
  /**
   * submit to edit model and add model and edit and add api call to function call
   */
  const onSubmit = async (e) => {
    e.preventDefault();
    if (donarName.length === 0) {
      setError(true);
    } else {
      let formData;
      if (editIndex !== -1 && !togglesubmit) {
        setLoaderShow(!loaderShow);
        formData = isEmpty({
          name: donarName,
          mobile: `${dialCodeDonar} ${contactNo}`,
          village: donarVillage,
          panCardNo: donarPanCard,
          aadhaarCard: aadhaarCard,
          address: donarAddress,
          isDeath: isAksharvashi,
        });
        if (dob) formData.dob = moment(dob).format("yyyy-MM-DD");
        if (dodMonth && dodPaksha && dodDate)
          formData.dod = `${dodMonth}-${dodPaksha}-${dodDate}`;
        if (dialCodeKin && contactNoKin)
          formData.kinMobile = `${dialCodeKin} ${contactNoKin}`;
        try {
          const { data } = await Edit_Donar_List(formData, personId);
          if (data.status === 200) {
            toast.success(data.message, {
              autoClose: 1000,
            });
            setModal(false);
            getData();
            emptyFeild();
          } else {
            fillFeid();
          }
        } catch (error) {
          if (error?.response?.data?.status === ERROR_CODE.UNAUTHORIZED) {
            LogOut(history);
          }
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,
            transition: Zoom,
          });
          setLoaderShow(false);
        }
      } else {
        setLoaderShow(!loaderShow);

        formData = isEmpty({
          name: donarName,
          mobile: `${dialCodeDonar} ${contactNo}`,
          village: donarVillage,
          panCardNo: donarPanCard,
          aadhaarCard: aadhaarCard,
          address: donarAddress,
          isDeath: isAksharvashi,
          // dob: dob ? moment(dob).format("yyyy-MM-DD") : "",
          // dod: `${dodMonth} ${dodPaksha} ${dodDate}`,
          // kinMobile: `${dialCodeKin} ${contactNoKin}`,
        });
        if (dob) formData.dob = moment(dob).format("yyyy-MM-DD");
        if (dodMonth && dodPaksha && dodDate)
          formData.dod = `${dodMonth}-${dodPaksha}-${dodDate}`;
        if (dialCodeKin && contactNoKin)
          formData.kinMobile = `${dialCodeKin} ${contactNoKin}`;
        try {
          const { data } = await Add_Donar(formData);
          if (data.status === 200) {
            toast.success(data.message, {
              autoClose: 1000,
              transition: Zoom,
            });
            setModal(false);
            getData();
            emptyFeild();
          } else {
            fillFeid();
          }
        } catch (error) {
          if (error?.response?.data?.status === ERROR_CODE.UNAUTHORIZED) {
            LogOut(history);
          }
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,
            transition: Zoom,
          });
          setLoaderShow(false);
        }
      }
    }
  };

  /**
   * show model and api call to fatch data
   */
  const showHandle = async (personId) => {
    setPersonId(personId);
    setLoaderShow(!loaderShow);
    // <<<<-----ORG DETAILS SHOW API------>>>>>>
    try {
      const { data } = await Donar_List_Details(personId);
      if (data.status === 200) {
        setDonarDetails(data.payload.data);
        setLoaderShow(false);
        setahoeDataModel(true);
      } else {
        setformvalue([]);
      }
    } catch (error) {
      if (error?.response?.data?.status === ERROR_CODE.UNAUTHORIZED) {
        LogOut(history);
      }
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
      setLoaderShow(false);
    }
  };

  /**
   * Enable and desable donar
   */

  const switch_onChange_handle = async (personId, isActive) => {
    let formData = {
      personId: personId,
      isActive: isActive ? false : true,
    };
    try {
      const { data } = await Donar_List_Enable_Desable(formData);
      if (data.status === 200) {
        isActive
          ? toast.error(data.message, {
              autoClose: 1000,
              transition: Zoom,
            })
          : toast.success(data.message, {
              autoClose: 1000,
              transition: Zoom,
            });
        setIsSwitchOn(!isSwitchOn);
        getData();
      } else {
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
      }
    } catch (error) {
      if (error?.response?.data?.status === ERROR_CODE.UNAUTHORIZED) {
        LogOut(history);
      }
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  /**
   *sorting function call
   */
  const sorting = (col) => {
    if (sortOrder === "") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("ASC");
    }
    if (sortOrder === "ASC") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("DSC");
    }
    if (sortOrder === "DSC") {
      setSortBy();
      setSortOrder("");
    }
  };

  /**
   * handle filter to call function
   */
  const handleFilter = (e) => {
    if (e.target.value === "") {
      setFilter(e.target.value);
    } else {
      setFilter(e.target.value);
    }
  };

  const StatementRedirect = (personId) => {
    setAccountId("");
    setBankId("");
    history(`/Statement/Statement/${personId}/person/${layout}`);
    setPersonId(personId);
    setpageNumber(1);
  };

  const close = () => {
    emptyFeild();
    setModal(!modal);
  };

  return (
    <>
      <div>
        <TopLoadingBar progress={progress} setProgress={setProgress} />
        <OrganizationAddModel
          onSubmit={onSubmit}
          error={error}
          close={close}
          modal={modal}
        />
        <OrganizationDetailsModel />
        <OrganizationItem
          handleFilter={handleFilter}
          sorting={sorting}
          editHandle={editHandle}
          showHandle={showHandle}
          AddModel={AddModel}
          setPage={setPage}
          setPageSize={setPageSize}
          pageSize={pageSize}
          page={page}
          record={record}
          switch_onChange_handle={switch_onChange_handle}
          StatementRedirect={StatementRedirect}
        />
      </div>
    </>
  );
};
export default Organization;
