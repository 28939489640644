import React, { useContext, useEffect, useState } from 'react';
import { Card, CardBody, Col, Form, Input, Label, Row } from 'reactstrap';
import { DateFormateChange, isEmpty, maxLengthCheck } from '../../utils/helper';
import './DonarPublicPage.css';
import { toast, Zoom } from 'react-toastify';
import PublicPageContext from '../../context/PublicPageContext/PublicPageContext';
import { CreatePersoneMaster_Public, Donation_bill_Details_Public } from '../../api/DonationBillApi/DonationCillapi';
import { useNavigate, useParams } from 'react-router';
import LoaderResoinse from '../../loader/loader';
import DonarPublicHeaderComponent from '../../common/commonComp';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { DialCodeList } from '../../api/DialCodeApi/DialCodeApi';

const DonarPublicPage = () => {
  const {
    address,
    setAddress,
    name,
    setName,
    date,
    setDate,
    contactNo,
    setContactNo,
    village,
    setVillage,
    setPanCard,
    setAadhaarCard,
    setByhand,
    city,
    setCity,
    state,
    setState,
    country,
    setCountry,
    setPersonaId,
    setTrustId,
    setNote,
    setTransectionNo,
    dialCode,
    setDialCode
  } = useContext(PublicPageContext);
  const history = useNavigate();
  const [click, setClick] = useState(false);
  const { orgId, templeUuid } = useParams();
  const [dialCodeJson, setDialCodeJson] = useState([]);

  const showHandle = async (mobile) => {
    try {
      const { data } = await Donation_bill_Details_Public(mobile, orgId);
      if (data.status === 200) {
        let payload = data.payload.data;
        if (payload !== null && payload !== undefined) {
          setPersonaId(payload.personId ? payload.personId : null);
          setName(payload.name ? payload.name : null);
          setVillage(payload.village ? payload.village : null);
          setPanCard(payload.panCardNo ? payload.panCardNo : null);
          setAadhaarCard(payload.aadhaarCard ? payload.aadhaarCard : null);
          setCity(payload.city ? payload.city : null);
          setState(payload.state ? payload.state : null);
          setCountry(payload.country ? payload.country : null);
          setAddress(payload.address ? payload.address : null);
          setDate(payload.dob ? new Date(DateFormateChange(payload.dob)) : null);
        } else {
          return true;
        }
      }
    } catch (error) {
      toast.error(error.response, {
        autoClose: 1000,
        transition: Zoom
      });
    }
  };

  useEffect(() => {
    setDialCodeJson(DialCodeList());
  }, []);

  const emptyUserDetails = () => {
    setName('');
    setVillage('');
    setPanCard('');
    setAadhaarCard('');
    setCity('');
    setState('');
    setCountry('');
    setAddress('');
  };

  const handleContactChange = async (e) => {
    setContactNo(e.target.value);
    if (e.target.value.length === 10) {
      showHandle(`${dialCode} ${e.target.value}`);
    } else {
      emptyUserDetails();
    }
  };

  const handleChangeDialCode = async (e) => {
    const { value } = e.target;
    if (value && contactNo?.length === 10) {
      const isEmpty = await showHandle(`${value} ${contactNo}`);
      if (isEmpty && typeof isEmpty === 'boolean') {
        emptyUserDetails();
      }
    }
    setDialCode(value);
  };

  const emplyFeild = () => {
    setPersonaId('');
    setContactNo('');
    setName('');
    setVillage('');
    setPanCard('');
    setAadhaarCard('');
    setAddress('');
    setNote('');
    setTransectionNo('');
    setByhand('');
    setCity('');
    setState('');
    setCountry('');
    setTrustId('');
    setDate('');
    setDialCode('+91');
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      let formData = isEmpty({
        mobile: `${dialCode} ${contactNo}`,
        name,
        village: village,
        address,
        city,
        orgId,
        state,
        country,
        dob: moment(date).format('yyyy-MM-DD'),
        templeUuid
      });
      const { data } = await CreatePersoneMaster_Public(formData);
      if (data.status === 200) {
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom
        });
        emplyFeild();
        history(`/Thankyou/${orgId}/${templeUuid}`);
        setClick(false);
      } else {
      }
    } catch (error) {
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom
      });
    }
  };

  return (
    <div className="panel">
      {click === true && <LoaderResoinse />}
      <div className="donarbackGround">
        {/* <div className="contentStyles"> */}
        <DonarPublicHeaderComponent secondTitle="blue" therdTitle="black" />
        <Col
          sm="12"
          className="donarcontainer"
          style={{
            paddingTop: '1rem',
            paddingBottom: '4rem',
            paddingRight: '1rem',
            paddingLeft: '1rem'
          }}
        >
          <Card className="doanrbackgroundcolor">
            <CardBody>
              <Row>
                <Col sm="12">
                  <Form className="needs-validation" onSubmit={(e) => onSubmit(e)}>
                    <Row>
                      <Col md="4 mb-4">
                        <Label htmlFor="validationCustom01">{'Contact No.*'}</Label>
                        <div className="d-flex">
                          <select
                            className="form-control"
                            style={{ width: '67px' }}
                            onChange={(e) => handleChangeDialCode(e)}
                            value={dialCode}
                          >
                            <option value={''}>{'-- select --'}</option>{' '}
                            {dialCodeJson.map((elem) => {
                              return <option value={elem.code}>{elem.country}</option>;
                            })}
                          </select>
                          <Input
                            className="form-control colorBlack"
                            name="contactno"
                            type="number"
                            id="contactno"
                            placeholder={'Contact No.'}
                            min={0}
                            maxLength={10}
                            onInput={maxLengthCheck}
                            value={contactNo}
                            onChange={(e) => handleContactChange(e)}
                            onWheel={(e) => e.target.blur()}
                            required
                          />
                        </div>
                        <div className="invalid-feedback">{'Please provide a valid ORG Name.'}</div>
                      </Col>
                      <Col md="4 mb-4">
                        <Label htmlFor="validationCustom01">{'Name*'}</Label>
                        <Input
                          className="form-control colorBlack"
                          name="contactno"
                          type="text"
                          id="contactno"
                          placeholder={'Name'}
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          required
                        />
                        <div className="invalid-feedback">{'Please provide a valid ORG Name.'}</div>
                      </Col>
                      <Col md="4 mb-4">
                        <Label htmlFor="validationCustom01">{'Birthdate*'}</Label>
                        <DatePicker
                          className="form-control colorBlack"
                          selected={date}
                          onChange={(date) => setDate(date)}
                          dateFormat="dd-MM-yyyy"
                          placeholderText="DD-MM-YYYY"
                        />
                        <div className="invalid-feedback">{'Please provide a valid ORG Name.'}</div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="3 mb-4">
                        <Label htmlFor="validationCustom01">{'Village'}</Label>
                        <Input
                          className="form-control colorBlack"
                          name="contactno"
                          type="text"
                          id="contactno"
                          placeholder={'Village'}
                          value={village}
                          onChange={(e) => setVillage(e.target.value)}
                        />
                        <div className="invalid-feedback">{'Please provide a valid ORG Name.'}</div>
                      </Col>
                      <Col md="3 mb-4">
                        <Label htmlFor="validationCustom01">{'City'}</Label>
                        <Input
                          className="form-control colorBlack"
                          name="contactno"
                          type="text"
                          id="contactno"
                          placeholder={'City'}
                          value={city}
                          onChange={(e) => setCity(e.target.value)}
                        />
                        <div className="invalid-feedback">{'Please provide a valid ORG Name.'}</div>
                      </Col>
                      <Col md="3 mb-4">
                        <Label htmlFor="validationCustom01">{'State'}</Label>
                        <Input
                          className="form-control colorBlack"
                          name="contactno"
                          type="text"
                          id="contactno"
                          placeholder={'State'}
                          value={state}
                          onChange={(e) => setState(e.target.value)}
                        />
                        <div className="invalid-feedback">{'Please provide a valid ORG Name.'}</div>
                      </Col>
                      <Col md="3 mb-4">
                        <Label htmlFor="validationCustom01">{'Country'}</Label>
                        <Input
                          className="form-control colorBlack"
                          name="contactno"
                          type="text"
                          id="contactno"
                          placeholder={'Country'}
                          value={country}
                          onChange={(e) => setCountry(e.target.value)}
                        />
                        <div className="invalid-feedback">{'Please provide a valid ORG Name.'}</div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12 mb-4">
                        <Label htmlFor="validationCustom01">{'Address'}</Label>
                        <Input
                          className="form-control colorBlack"
                          name="contactno"
                          type="textarea"
                          id="contactno"
                          Col={5}
                          placeholder={'Address'}
                          value={address}
                          onChange={(e) => setAddress(e.target.value)}
                        />
                        <div className="invalid-feedback">{'Please provide a valid ORG Name.'}</div>
                      </Col>
                    </Row>

                    <Row style={{ paddingTop: '30px' }}>
                      <button
                        className="btn btn-primary"
                        type="submit"
                        value="Submit"
                        disabled={click === true ? 'desabled' : ''}
                      >
                        {'Submit'}
                      </button>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        {/* </div> */}
      </div>
    </div>
  );
};

export default DonarPublicPage;
