import React, { useContext } from "react";
import {
  Card,
  CardBody,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import { TEMPLE_TRUST } from "../../constant";
import TempleTrustContext from "../../context/TempleTrustContext/TempleTrustContext";

const TempleTrustDetailsModel = () => {
  const { ahoeDataModel, setahoeDataModel, showHandle } =
    useContext(TempleTrustContext);

  return (
    <>
      <Modal
        size="lg"
        isOpen={ahoeDataModel}
        toggle={() => setahoeDataModel(!ahoeDataModel)}
      >
        <ModalHeader toggle={() => setahoeDataModel(!ahoeDataModel)}>
          {TEMPLE_TRUST.TEMPL_TRUST_DETAILS}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col sm="12">
              <Card>
                <CardBody style={{ padding: "0" }}>
                  <Card>
                    <div className="table-responsive">
                      <Table>
                        <tbody>
                          <tr>
                            <th scope="row">{"Teust Name"}</th>
                            <td>{showHandle.trustName}</td>
                          </tr>
                          <tr>
                            <th scope="row">{"Cart No."}</th>
                            <td>{showHandle.certNo}</td>
                          </tr>
                          <tr>
                            <th scope="row">{"Pan No."}</th>
                            <td>{showHandle.panNo}</td>
                          </tr>
                          <tr>
                            <th scope="row">{"Trust Reg No."}</th>
                            <td>{showHandle.trustRegNo}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </Card>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
};

export default TempleTrustDetailsModel;
