import React, { useContext, useEffect, useState } from "react";
import { toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-phone-number-input/style.css";
import TopLoadingBar from "../topLoadingBar/topLoadingBar";
import NoteContext from "../../context/NoteContext/NoteContext";
import NotesAddModel from "./notesAddModel";
import NotesDetailsModel from "./notesDetailsModel";
import NotesItem from "./notesItem";
import {
  Add_Notes,
  NotesList,
  Notes_Edit_List,
  Notes_List_Details,
} from "../../api/NoteApi/NoteApi";
import OrganizationContext from "../../context/OrganizationContext/OrganizationContext";
import { ERROR_CODE } from "../../constant";
import LogOut from "../../utils/logout";
import { isEmpty } from "../../utils/helper";
import { useNavigate } from "react-router";

const Notes = () => {
  const {
    setformvalue,
    setahoeDataModel,
    setModal,
    modal,
    sortOrder,
    setSortOrder,
    sortBy,
    setSortBy,
    filter,
    setFilter,
    editIndex,
    seteditIndex,
    accountId,
    title,
    setTitle,
    note,
    setNote,
    setIsAddHandle,
    progress,
    setProgress,
    togglesubmit,
    setToggle,
    setShowNote,
    noteId,
    setNoteId,
  } = useContext(NoteContext);
  const { loaderShow, setLoaderShow, responseLoader, setResponseLoader } =
    useContext(OrganizationContext);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [record, setRecord] = useState(0);
  const history = useNavigate()

  /**
   * Empty feild function call
   */

  const emptyFeild = () => {
    setTitle(null);
    setNote(null);
    setLoaderShow(false);
  };
  const FillFeild = () => {
    setTitle(title);
    setNote(note);
  };

  /**
   * Donation Master List api call function and useEffect use to call function
   */

  const getData = async () => {
    setResponseLoader(!responseLoader);
    try {
      let paramsObj = {
        pageNumber: page,
        recordsPerPage: pageSize,
        sortOrder,
        sortBy,
      };
      const { data } = await NotesList(paramsObj);
      if (data.status === 200) {
        setformvalue(data.payload.data);
        setRecord(data.pager.totalRecords);
        setResponseLoader(false);
      } else {
        setformvalue([]);
      }
    } catch (error) {
      if (error?.response?.data?.status === ERROR_CODE.UNAUTHORIZED) {
        LogOut(history);
      }
      setResponseLoader(false);
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  useEffect(() => {
    getData();
    setProgress(100);
  }, [page, pageSize, sortOrder, sortBy, filter]);

  /**
   * Add donation master model open function call
   */

  const AddModel = () => {
    setModal(true);
    setToggle(true);
  };

  /**
   * edit donation master details get api call
   */

  const editHandle = async (noteId) => {
    setNoteId(noteId);
    setLoaderShow(!loaderShow);
    try {
      const { data } = await Notes_List_Details(noteId);
      if (data.status === 200) {
        setToggle(false);
        setIsAddHandle(false);
        seteditIndex(accountId);
        let payload = data.payload.data;
        setTitle(payload.title ? payload.title : null);
        setNote(payload.notes ? payload.notes : null);
        setLoaderShow(false);
        setModal(true);
      } else {
        setformvalue([]);
      }
    } catch (error) {
      if (error?.response?.data?.status === ERROR_CODE.UNAUTHORIZED) {
        LogOut(history);
      }
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
      setLoaderShow(false);
    }
  };

  /**
   * edit donation master and add donation master submit and api call function call
   */

  const onSubmit = async (e) => {
    e.preventDefault();
    let formData;
    if (editIndex !== -1 && !togglesubmit) {
      setLoaderShow(!loaderShow);
      formData = isEmpty({
        title: title,
        notes: note,
      });

      try {
        const { data } = await Notes_Edit_List(formData, noteId);
        if (data.status === 200) {
          toast.success(data.message, {
            autoClose: 1000,
          });
          setModal(false);
          getData();
          emptyFeild();
        } else {
          FillFeild();
        }
      } catch (error) {
        if (error?.response?.data?.status === ERROR_CODE.UNAUTHORIZED) {
          LogOut(history);
        }
        toast.error(error?.response?.data?.message, {
          autoClose: 1000,
          transition: Zoom,
        });
        setLoaderShow(false);
      }
    } else {
      setLoaderShow(!loaderShow);
      formData = isEmpty({
        title: title,
        notes: note,
      });

      try {
        const { data } = await Add_Notes(formData);
        if (data.status === 200) {
          toast.success(data.message, {
            autoClose: 1000,
            transition: Zoom,
          });
          setModal(false);
          getData();
          emptyFeild();
        } else {
          setformvalue([]);
          FillFeild();
        }
      } catch (error) {
        if (error?.response?.data?.status === ERROR_CODE.UNAUTHORIZED) {
          LogOut(history);
        }
        toast.error(error?.response?.data?.message, {
          autoClose: 1000,
          transition: Zoom,
        });
        setLoaderShow(false);
      }
    }
  };

  /**
   * Show donation master api call funtion call
   */

  const showHandle = async (noteId) => {
    setNoteId(noteId);
    setLoaderShow(!loaderShow);
    try {
      const { data } = await Notes_List_Details(noteId);
      if (data.status === 200) {
        setShowNote(data.payload.data);
        setLoaderShow(false);
        setahoeDataModel(true);
      } else {
        setformvalue([]);
      }
    } catch (error) {
      if (error?.response?.data?.status === ERROR_CODE.UNAUTHORIZED) {
        LogOut(history);
      }
      toast.error(error.response, {
        autoClose: 1000,
        transition: Zoom,
      });
      setLoaderShow(false);
    }
  };

  /**
   * sorting funcion call
   */

  const sorting = (col) => {
    if (sortOrder === "") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("ASC");
    }
    if (sortOrder === "ASC") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("DSC");
    }
    if (sortOrder === "DSC") {
      setSortBy();
      setSortOrder("");
    }
  };

  /**
   * Search function call
   */
  const handleFilter = (e) => {
    if (e.target.value === "") {
      setFilter(e.target.value);
    } else {
      setFilter(e.target.value);
    }
  };

  /**
   * Model Close Function
   */

  const close = () => {
    emptyFeild();
    setModal(!modal);
  };

  return (
    <>
      <div>
        <TopLoadingBar progress={progress} setProgress={setProgress} />
        <NotesAddModel onSubmit={onSubmit} close={close} />
        <NotesDetailsModel />
        <NotesItem
          handleFilter={handleFilter}
          sorting={sorting}
          editHandle={editHandle}
          showHandle={showHandle}
          AddModel={AddModel}
          setPage={setPage}
          setPageSize={setPageSize}
          pageSize={pageSize}
          page={page}
          record={record}
        />
      </div>
    </>
  );
};
export default Notes;
