import Ecommerce from "../components/dashboard/ecommerce";
import Donation from "../components/Donation/donation";
import BankMaster from "../components/BankMaster/bankMaster";
import DonationMaster from "../components/DonationMaster/DonationMaster";
import Donar from "../components/DonarMaster/donar";
import Income from "../components/Income/income";
import Organization from "../components/Organization/Organizationa";
import PrivillageManager from "../components/PrivillageManager/PrivillageManager";
import ModualManager from "../components/ModualManager/ModualManager";
import Notes from "../components/Notes/notes";
import Statement from "../components/Statement/Statement";
import Expace from "../components/Expance/expance";
import SlipDownloadeTemplate from "../components/SlipDownloadeTemplate/SlipDownloadeTemplate";
import DonationAdd from "../components/Donation/DonationAdd";
import Temple from "../components/Temple/temple";
import TempleTrust from "../components/Temple_Trust/templeTrust";
import ExpanseAdd from "../components/Donation/ExpanseAdd";

export const routes = [
  {
    path: `${process.env.PUBLIC_URL}/dashboard/ecommerce/:layout/`,
    Component: <Ecommerce />,
  },
  {
    path: `${process.env.PUBLIC_URL}/Donation/:type/:layout/`,
    Component: <Donation />,
  },
  {
    path: `${process.env.PUBLIC_URL}/Expanse/:type/:layout/`,
    Component: <Donation />,
  },
  {
    path: `${process.env.PUBLIC_URL}/Donation/DonationAdd/:layout/`,
    Component: <DonationAdd />,
  },
  {
    path: `${process.env.PUBLIC_URL}/Donation/ExpanseAdd/:layout/`,
    Component: <ExpanseAdd />,
  },
  {
    path: `${process.env.PUBLIC_URL}/BankMaster/bankMaster/:layout/`,
    Component: <BankMaster />,
  },
  {
    path: `${process.env.PUBLIC_URL}/Temple/temple/:layout/`,
    Component: <Temple />,
  },
  {
    path: `${process.env.PUBLIC_URL}/Temple_Trust/templeTrust/:layout/`,
    Component: <TempleTrust />,
  },
  {
    path: `${process.env.PUBLIC_URL}/DonationMaster/DonationMaster/:layout/`,
    Component: <DonationMaster />,
  },
  {
    path: `${process.env.PUBLIC_URL}/DonarMaster/donar/:layout/`,
    Component: <Donar />,
  },
  {
    path: `${process.env.PUBLIC_URL}/Statement/Statement/:personId/:statement/:layout/`,
    Component: <Statement />,
  },
  {
    path: `${process.env.PUBLIC_URL}/Notes/notes/:layout/`,
    Component: <Notes />,
  },
  {
    path: `${process.env.PUBLIC_URL}/Income/income/:layout/`,
    Component: <Income />,
  },
  {
    path: `${process.env.PUBLIC_URL}/Expance/expance/:layout/`,
    Component: <Expace />,
  },
  {
    path: `${process.env.PUBLIC_URL}/SlipDownloadeTemplate/SlipDownloadeTemplate/:layout/`,
    Component: <SlipDownloadeTemplate />,
  },
  {
    path: `${process.env.PUBLIC_URL}/Organization/Organizationa/:layout/`,
    Component: <Organization />,
  },
  {
    path: `${process.env.PUBLIC_URL}/PrivillageManager/PrivillageManager/:layout/`,
    Component: <PrivillageManager />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ModualManager/ModualManager/:layout/`,
    Component: <ModualManager />,
  },
];
