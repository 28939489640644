import { Box, IconButton, Tooltip } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment/moment";
import React, { Fragment, useContext } from "react";
import { Edit, Eye, Plus } from "react-feather";
import Switch from "react-js-switch";
import {
  Card,
  CardHeader,
  Col,
  Container,
  Input,
  Row,
  Table,
} from "reactstrap";
import { PRODUCT_IMAHE, Style, TIME_ZONE } from "../../constant";
import OrganizationContext from "../../context/OrganizationContext/OrganizationContext";
import DataNotFound from "../../DataNoteFound/DataNotFound";
import Breadcrumbs from "../../layout/breadcrumb";
import TableSkeleton from "../../loader/TableSkeleton";
import { MuiTabelStyle } from "../../utils/helper";

const OrganizationItem = (props) => {
  const {
    editHandle,
    showHandle,
    sorting,
    AddModel,
    setPage,
    setPageSize,
    pageSize,
    page,
    record,
    handleFilter,
    switch_onChange_handle,
  } = props;
  const { formvalue, filter, responseLoader } = useContext(OrganizationContext);
  const defaultColumns = [
    {
      flex: 0.1,
      minWidth: 180,
      field: "image",
      headerName: "Image",
      sortable: false,
      renderCell: (params) => (
        <input
          type="image"
          img="true"
          src={params.row.orgUser ? params.row.orgUser[0].image : PRODUCT_IMAHE}
          alt="photo"
          style={{ height: "2rem" }}
        />
      ),
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "name",
      headerName: "Name",
      renderCell: (params) => (params.row.name ? params.row.name : "--"),
    },
    {
      flex: 0.1,
      minWidth: 250,
      field: "email",
      headerName: "Email",
      renderCell: (params) => (params.row.email ? params.row.email : "--"),
    },
    {
      flex: 0.1,
      minWidth: 120,
      field: "phone",
      headerName: "Phone",
      renderCell: (params) => (params.row.phone ? params.row.phone : "--"),
    },
    {
      flex: 0.1,
      minWidth: 150,
      field: "gstNumber",
      headerName: "GST Number",
      renderCell: (params) =>
        params.row.gstNumber ? params.row.gstNumber : "--",
    },
    {
      flex: 0.1,
      minWidth: 200,
      field: "address",
      headerName: "Address",
      renderCell: (params) => (params.row.address ? params.row.address : "--"),
    },
    {
      flex: 0.1,
      minWidth: 100,
      field: "city",
      headerName: "City",
      renderCell: (params) => (params.row.city ? params.row.city : "--"),
    },
    {
      flex: 0.1,
      minWidth: 100,
      field: "state",
      headerName: "State",
      renderCell: (params) => (params.row.state ? params.row.state : "--"),
    },
    {
      flex: 0.1,
      minWidth: 100,
      field: "isActive",
      headerName: "Status",
      sortable: false,
      renderCell: (params) => (
        <Switch
          value={params.row.isActive ? true : false}
          onChange={() =>
            switch_onChange_handle(params.row.orgId, params.row.isActive)
          }
        />
      ),
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "createdAt",
      headerName: "CreatedAt",
      sortable: false,
      renderCell: (params) =>
        moment(params.row.createdAt)
          .utcOffset(TIME_ZONE)
          .format("DD MMM, YYYY hh:mm"),
    },
    {
      flex: 0.1,
      minWidth: 100,
      field: "actions",
      headerName: "Actions",
      sortable: false,
      renderCell: (params) => (
        <>
          <div style={{ paddingRight: "5px" }}>
            <Tooltip title="Edit">
              <IconButton
                aria-label="edit"
                onClick={() => editHandle(params.row.orgId)}
                style={{ width: "35px" }}
              >
                <Edit style={{ color: "#7366ff" }} />
              </IconButton>
            </Tooltip>
          </div>
          <div>
            <Tooltip title="Show">
              <IconButton
                aria-label="Show"
                onClick={() => showHandle(params.row.orgId)}
                style={{ width: "35px" }}
              >
                <Eye style={{ color: "#7366ff" }} />
              </IconButton>
            </Tooltip>
          </div>
        </>
      ),
    },
  ];

  return (
    <>
      <Fragment>
        <Breadcrumbs parent="Organization" />
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <h3>Organization</h3>

                  <div
                    aria-label="add"
                    className="btn btn-primary"
                    onClick={AddModel}
                    style={Style}
                  >
                    <Plus />
                  </div>
                </CardHeader>
                <Table>
                  <thead>
                    <tr>
                      <th>
                        <Row>
                          <Col md="2 mb-2">
                            <Input
                              className="form-control"
                              type="text"
                              placeholder="Organization Search..."
                              value={filter}
                              onInput={(e) => handleFilter(e)}
                              style={{
                                border: "1px solid #ecf3fa",
                                borderRadius: "8px",
                              }}
                            />
                          </Col>
                        </Row>
                      </th>
                    </tr>
                  </thead>
                </Table>
                <Box
                  sx={{
                    "& .even": {
                      backgroundColor: "#f1f1f1",
                    },
                    "& .odd": {
                      backgroundColor: "white",
                    },
                  }}
                >
                  {responseLoader === true ? (
                    <TableSkeleton />
                  ) : formvalue.length ? (
                    <DataGrid
                      sx={MuiTabelStyle}
                      autoHeight
                      pagination
                      rows={formvalue}
                      rowCount={record}
                      pageSize={pageSize}
                      getRowId={(row) => row.orgId}
                      columns={defaultColumns}
                      page={page - 1}
                      rowsPerPageOptions={[7, 10, 25, 50]}
                      onPageSizeChange={(newPageSize) =>
                        setPageSize(newPageSize)
                      }
                      onPageChange={(newPageNo) => setPage(newPageNo + 1)}
                      paginationMode="server"
                      disableSelectionOnClick
                      checkboxSelection={false}
                      sortingMode="server"
                      onSortModelChange={sorting}
                      getRowClassName={(params) =>
                        params.indexRelativeToCurrentPage % 2 === 0
                          ? "even"
                          : "odd"
                      }
                    />
                  ) : (
                    <DataNotFound />
                  )}
                </Box>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fragment>
    </>
  );
};

export default OrganizationItem;
