import React from "react";
import { Button, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import downloadjs from "downloadjs";
import html2canvas from "html2canvas";
import birthdayImg from "../../assets/images/imgpsh_fullsize_anim.png";

const BirthdayTempDownlod = (props) => {
  const { model, setModel, birthdayName } = props;

  const handleCaptureClick = async () => {
    const canvas = await html2canvas(document.getElementById("imagessss"));
    const dataURL = canvas.toDataURL("image/png");
    downloadjs(dataURL, "download.png", "image/png");
  };
  return (
    <Modal size="lg" isOpen={model} toggle={() => setModel(!model)}>
      <ModalHeader toggle={() => setModel(!model)}>Happy Birthday</ModalHeader>
      <ModalBody>
        <Row id="imagessss">
          <img src={birthdayImg} alt="" />
          <div className="imageContainer">
            <p className="fontSize">{birthdayName}</p>
          </div>
        </Row>
        {/* </div> */}
        <Button onClick={handleCaptureClick} style={{ marginTop: "12px" }}>
          Download
        </Button>
      </ModalBody>
    </Modal>
  );
};

export default BirthdayTempDownlod;
