import React, { useContext } from "react";
import { Edit } from "react-feather";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { BANKTYPE, BANK_MASTER, ValidationLabel } from "../../constant";
import OrganizationContext from "../../context/OrganizationContext/OrganizationContext";
import OrgContext from "../../context/OrgContext/OrgContext";
import LoaderResoinse from "../../loader/loader";
import { maxLengthCheck } from "../../utils/helper";

const OrganizationAddModel = (props) => {
  const { onSubmit, close, error } = props;

  const {
    modal,
    setName,
    name,
    isfcCode,
    setIsfcCode,
    setAddress,
    address,
    accountNo,
    setAccountNo,
    branch,
    setBranch,
    confirmAaccountNo,
    setConfirmAccountNo,
    togglesubmit,
    trustDrop,
    trustId,
    setTrustId,
    setType,
    type,
  } = useContext(OrgContext);

  const { loaderShow } = useContext(OrganizationContext);

  return (
    <>
      <Modal size="lg" isOpen={modal} toggle={() => close()}>
        {loaderShow === true && <LoaderResoinse />}
        <ModalHeader toggle={() => close()}>
          {togglesubmit ? (
            <span>{BANK_MASTER.ADD_BANK_MASTER}</span>
          ) : (
            <span>
              {BANK_MASTER.EDIT_BANK_MASTER} <span> </span>{" "}
              <Edit
                style={{ width: "50px", height: "20px", color: "#7366ff" }}
              />
            </span>
          )}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader>
                  <h5> {BANK_MASTER.BANK_MASTER_DETAILS}</h5>
                </CardHeader>
                <CardBody>
                  <Form
                    className="needs-validation"
                    onSubmit={(e) => onSubmit(e)}
                  >
                    <Row>
                      <Col md="6 mb-4">
                        <Label htmlFor="validationCustom01">{"Trust"}</Label>
                        <select
                          className="form-select"
                          name="orderStatus"
                          typeof="number"
                          value={trustId}
                          onChange={(e) => setTrustId(e.target.value)}
                        >
                          <option value="">--select--</option>
                          {trustDrop.map((elem) => {
                            return (
                              <option
                                value={elem.trustId}
                                key={elem.trustId}
                                selected={
                                  elem.trustId === trustId ? "selected" : ""
                                }
                              >
                                {elem.trustName}
                              </option>
                            );
                          })}
                        </select>
                        <div className="invalid-feedback">
                          {"Please provide a valid ORG Name."}
                        </div>
                      </Col>
                      <Col md="6 mb-4">
                        <Label htmlFor="validationCustom01">{"Type"}</Label>
                        <select
                          className="form-select"
                          name="orderStatus"
                          typeof="number"
                          value={type}
                          onChange={(e) => setType(e.target.value)}
                        >
                          <option value="">--select--</option>
                          {BANKTYPE.map((elem) => {
                            return (
                              <option
                                value={elem.id}
                                key={elem.id}
                                selected={elem.id === type ? "selected" : ""}
                              >
                                {elem.name}
                              </option>
                            );
                          })}
                        </select>
                        <div className="invalid-feedback">
                          {"Please provide a valid ORG Name."}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6 mb-4">
                        <Label
                          htmlFor="validationCustom01"
                          className={
                            error && name?.length <= 0
                              ? "validation"
                              : "non-validation"
                          }
                        >
                          {error && name?.length <= 0
                            ? ValidationLabel.nameRequired
                            : ValidationLabel.name}
                        </Label>
                        <Input
                          className={`${
                            error && name?.length <= 0 ? "inputValidation" : ""
                          } "form-control`}
                          name="fristName"
                          type="text"
                          placeholder="Name"
                          id="validationCustom01"
                          onChange={(e) => setName(e.target.value)}
                          value={name}
                        />
                        <div className="invalid-feedback">
                          {"Please provide a valid ORG Name."}
                        </div>
                      </Col>
                      <Col md="6 mb-4">
                        <Label htmlFor="validationCustom03">
                          {"ISFC Code"}
                        </Label>
                        <Input
                          className="form-control"
                          name="text"
                          type="text"
                          id="validationCustome22"
                          placeholder="ISFC"
                          onChange={(e) => setIsfcCode(e.target.value)}
                          value={isfcCode}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6 mb-4">
                        <Label
                          htmlFor="validationCustom01"
                          className={
                            error && accountNo?.length <= 0
                              ? "validation"
                              : "non-validation"
                          }
                        >
                          {error && accountNo.length <= 0
                            ? ValidationLabel.accountNoRequired
                            : ValidationLabel.accountNo}
                        </Label>
                        <Input
                          className={`${
                            error && accountNo?.length <= 0
                              ? "inputValidation"
                              : ""
                          } "form-control`}
                          name="account_no"
                          type="number"
                          id="validationCustome22"
                          placeholder="Account No."
                          onChange={(e) => setAccountNo(e.target.value)}
                          onWheel={(e) => e.target.blur()}
                          value={accountNo}
                          min={"0"}
                          maxLength={25}
                          onInput={maxLengthCheck}
                        />
                        <div className="invalid-feedback">
                          {"Please provide a valid ORG Name."}
                        </div>
                      </Col>
                      <Col md="6 mb-4">
                        <Label
                          htmlFor="validationCustom03"
                          className={
                            error && confirmAaccountNo?.length <= 0
                              ? "validation"
                              : "non-validation"
                          }
                        >
                          {error && confirmAaccountNo?.length <= 0
                            ? ValidationLabel.confirmAccountRequired
                            : ValidationLabel.confirmAccount}
                        </Label>
                        <Input
                          className={`${
                            error && confirmAaccountNo?.length <= 0
                              ? "inputValidation"
                              : ""
                          } "form-control`}
                          name="account_no"
                          type="password"
                          id="validationCustome22"
                          placeholder="Account No."
                          onChange={(e) => setConfirmAccountNo(e.target.value)}
                          value={confirmAaccountNo}
                          min={"0"}
                          maxLength={25}
                          onInput={maxLengthCheck}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12 mb-4">
                        <Label htmlFor="validationCustom06">Branch</Label>
                        <Input
                          className="form-control"
                          name="branck"
                          id="validationCustom06"
                          type="text"
                          placeholder="Branch"
                          onChange={(e) => setBranch(e.target.value)}
                          value={branch}
                        />
                        <div className="invalid-feedback">
                          {"Please provide a valid Address 1."}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12 mb-4">
                        <Label htmlFor="validationCustom09">{"Address"}</Label>
                        <textarea
                          className="form-control"
                          name="address"
                          id="validationCustom09"
                          type="text"
                          placeholder="Address"
                          onChange={(e) => setAddress(e.target.value)}
                          value={address}
                        />
                        <div className="invalid-feedback">
                          {"Please provide a valid State."}
                        </div>
                      </Col>
                    </Row>

                    <Row style={{ paddingTop: "30px" }}>
                      <button
                        className="btn btn-primary"
                        type="submit"
                        value="Submit"
                        disabled={loaderShow === true ? "disabled" : ""}
                      >
                        {"Submit"}
                      </button>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
};

export default OrganizationAddModel;
