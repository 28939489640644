import React, { useContext } from "react";
import {
  Card,
  CardBody,
  Col,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import AdminDeshboardContext from "../../../context/AdminDeshboardContext/AdminDeshboardContext";
import { DONATION_ADD, ERROR_CODE } from "../../../constant";
import OrgContext from "../../../context/OrgContext/OrgContext";
import LogOut from "../../../utils/logout";
import { Zoom, toast } from "react-toastify";
import { isEmpty } from "../../../utils/helper";
import OrganizationContext from "../../../context/OrganizationContext/OrganizationContext";
import moment from "moment";
import DonationUserDatails from "../Donation/DonationUserDatails";
import { Add_Donation } from "../../../api/DonationApi/DonationApi";
import { useNavigate } from "react-router";

const ExpansePopup = (props) => {
  const { modalExpanse, setModalExpanse } = props;
  const {
    accountId,
    setAccountId,
    amount,
    setAmount,
    trustDrop,
    accountDrop,
    bankDrop,
  } = useContext(AdminDeshboardContext);
  const {
    address,
    setAddress,
    name,
    setName,
    contactNo,
    dialCode,
    setContactNo,
    village,
    setVillage,
    pancard,
    setPanCard,
    aadhaarCard,
    setAadhaarCard,
    byHand,
    setByhand,
    setDob,
    dob,
    setCity,
    setState,
    country,
    setCountry,
    setPaymentType,
    personId,
    setPersonaId,
    paymentMode,
    setPaymentMode,
    note,
    setNote,
    transectionNo,
    setTransectionNo,
    setTrustId,
    trustId,
  } = useContext(OrgContext);
  const { setLoaderShow } = useContext(OrganizationContext);
  const history = useNavigate()

  const emptyFeilds = () => {
    setPersonaId("");
    setContactNo("");
    setName("");
    setVillage("");
    setPanCard("");
    setAadhaarCard("");
    setAddress("");
    setAccountId("");
    setAmount("");
    setPaymentMode("");
    setNote("");
    setTransectionNo("");
    setByhand("");
    setCity("");
    setState("");
    setCountry("");
    setPaymentType("");
    setDob("");
    setModalExpanse(false);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoaderShow(true);
    try {
      let formData = isEmpty({
        personId: personId,
        mobile: `${dialCode} ${contactNo}`,
        name,
        village: village,
        panCardNo: pancard,
        aadhaarCard,
        address,
        accountId: accountId,
        amount: amount,
        note,
        transactionNo: transectionNo,
        byHand: byHand,
        country,
        dob: dob ? moment(dob).format("yyyy-MM-DD") : "",
        trustId: +trustId,
        bankId: paymentMode,
        templeId: +localStorage.getItem("templeId"),
      });

      const { data } = await Add_Donation(formData);
      if (data.status === 200) {
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
        setLoaderShow(false);
        emptyFeilds();
      } else {
        setLoaderShow(false);
      }
    } catch (error) {
      if (error.response.data.status === ERROR_CODE.UNAUTHORIZED) {
        LogOut(history);
      }
      setLoaderShow(false);
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  return (
    <Modal size="xl" isOpen={modalExpanse} toggle={() => emptyFeilds()}>
      <ModalHeader toggle={() => emptyFeilds()}>Add Expanse</ModalHeader>
      <ModalBody>
        <Card>
          <CardBody>
            <Form className="needs-validation" onSubmit={(e) => onSubmit(e)}>
              <Row>
                <DonationUserDatails />
                <hr style={{ color: "cadetblue" }} />
              </Row>
              <Row>
                <Col md="6 mb-4">
                  <Label htmlFor="validationCustom01">{"Trust"}</Label>
                  <select
                    className="form-select"
                    name="orderStatus"
                    value={trustId}
                    onChange={(e) => setTrustId(e.target.value)}
                    disabled
                  >
                    <option value={""}>--select--</option>
                    {trustDrop.map((elem) => {
                      return (
                        <option value={elem.trustId} key={elem.trustId}>
                          {elem.trustName}
                        </option>
                      );
                    })}
                  </select>
                  <div className="invalid-feedback">
                    {"Please provide a valid ORG Name."}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="6 mb-4">
                  <Label htmlFor="validationCustom01">{"Expanse Type"}</Label>
                  <select
                    className="form-select"
                    name="orderStatus"
                    value={accountId}
                    onChange={(e) => setAccountId(e.target.value)}
                    disabled
                  >
                    <option value={""}>--select--</option>
                    {accountDrop.map((elem) => {
                      return (
                        <option value={elem.accountId} key={elem.accountId}>
                          {elem.name}
                        </option>
                      );
                    })}
                  </select>
                  <div className="invalid-feedback">
                    {"Please provide a valid ORG Name."}
                  </div>
                </Col>
                <Col md="6 mb-4">
                  <Label htmlFor="validationCustom01">
                    {DONATION_ADD.AMOUNT}
                  </Label>
                  <Input
                    className="form-control colorBlack"
                    name="accountId"
                    type="text"
                    id="accountId"
                    placeholder={DONATION_ADD.AMOUNT}
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                  />
                  <div className="invalid-feedback">
                    {"Please provide a valid ORG Name."}
                  </div>
                </Col>
                <Col md="4 mb-4">
                  <Label htmlFor="validationCustom01">
                    {DONATION_ADD.PAYMENT_MODE}
                  </Label>
                  <select
                    className="form-select"
                    name="orderStatus"
                    value={paymentMode}
                    onChange={(e) => setPaymentMode(e.target.value)}
                    required
                  >
                    <option value={""}>--select--</option>
                    {bankDrop.map((elem) => {
                      return (
                        <option value={elem.bankId} key={elem.bankId}>
                          {elem.name}
                        </option>
                      );
                    })}
                  </select>
                  <div className="invalid-feedback">
                    {"Please provide a valid ORG Name."}
                  </div>
                </Col>
                <Col md="4 mb-4">
                  <Label htmlFor="validationCustom01">{"Pay Amount"}</Label>
                  <Input
                    className="form-control colorBlack"
                    name="transactionNo"
                    type="text"
                    id="transactionNo"
                    placeholder={"Pay Amount"}
                    value={transectionNo}
                    onChange={(e) => setTransectionNo(e.target.value)}
                  />
                  <div className="invalid-feedback">
                    {"Please provide a valid ORG Name."}
                  </div>
                </Col>
              </Row>
              {/* <PaymentTypeChackBoxes
                setPaymentType={setPaymentType}
                paymentType={paymentType}
              /> */}

              <Row>
                <Col md="12 mb-4">
                  <Label htmlFor="validationCustom01">
                    {DONATION_ADD.NOTE}
                  </Label>
                  <Input
                    className="form-control colorBlack"
                    name="note"
                    type="textarea"
                    id="note"
                    placeholder={DONATION_ADD.NOTE}
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                  />
                  <div className="invalid-feedback">
                    {"Please provide a valid ORG Name."}
                  </div>
                </Col>
              </Row>
              <Row style={{ paddingTop: "30px" }}>
                <button
                  className="btn btn-primary"
                  type="submit"
                  value="Submit"
                >
                  {"Submit"}
                </button>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </ModalBody>
    </Modal>
  );
};

export default ExpansePopup;
