import * as React from "react";
import { useState } from "react";
import { toast, Zoom } from "react-toastify";
import { BankMasterList } from "../../api/Bank Master api/BankMasterAPi";
import { DonarList } from "../../api/Donar Api/DonarApi";
import { Donation_MasterList } from "../../api/DonationMasterApi/DonationMasterApi";
import NoteContext from "./NoteContext";
import moment from "moment";

const NoteState = (props) => {
  const [formvalue, setformvalue] = useState([]); //API -->

  const [donar, setDonar] = useState(null);
  const [bank, setBank] = useState(null);
  const [dueAmount, setDouAmount] = useState("");
  const [donation, setDonation] = useState(null);
  const [amount, setAmount] = useState(null);
  const [paidamount, setPaidAmount] = useState(null);
  const [note, setNote] = useState(null);
  const [accountId, setAccountId] = useState("");
  const [showDonation, setShowDonation] = useState("");
  const [accountDrop, setAccountDrop] = useState([]);
  const [personDrop, setPersonDrop] = useState([]);
  const [bankDrop, setBankDrop] = useState([]);
  const [trustDrop, setTrustDrop] = useState([]);
  const [trustId, setTrustId] = useState("");
  const [paymentMode, setPaymentMode] = useState("");
  const [payModel, setPayModel] = useState(false);
  const [personSearch, setPersonSearch] = useState(null);
  const [personId, setPersonaId] = useState(null);
  const [dialCode, setDialCode] = useState("+91");

  const accountDropDown = async (type) => {
    let paramsObj = {
      search: type,
      showAll: true,
    };
    try {
      const { data } = await Donation_MasterList(paramsObj);
      if (data.status === 200) {
        setAccountDrop(data.payload.data);
        // setRecord(data.pager.totalRecords);
      } else {
        setAccountDrop([]);
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  const personDropdown = async (page, value, search, setInputValue) => {
    const paramsObj = {
      pageNumber: page,
      showAll: true,
      search: { mobile: value },
    };
    try {
      const { data } = await DonarList(paramsObj);
      if (data.status === 200) {
        setPersonDrop(data.payload.data);
        if (search && data.payload.data.length) {
          setInputValue("");
        }
        // setRecord(data.pager.totalRecords);
      } else {
        setPersonDrop([]);
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  const bankDropdown = async () => {
    try {
      const { data } = await BankMasterList();
      if (data.status === 200) {
        setBankDrop(data.payload.data);
        // setRecord(data.pager.totalRecords);
      } else {
        setBankDrop([]);
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  const [ahoeDataModel, setahoeDataModel] = useState(false); //SHOW ORG DETAILS MODEL -->
  const [modal, setModal] = useState(false); //ADD DATA MODEL -->
  const [addbel, setAddBel] = useState(false); //ADD ORG BEL -->
  const [pageNumber, setpageNumber] = useState(1); //PAGINATION -->
  const [recordsPerPage, setRecordsPerPage] = useState(10); //PAGINATION -->
  const [sortOrder, setSortOrder] = useState(""); //SORTING -->
  const [sortBy, setSortBy] = useState("createdAt"); //SORTING -->
  const [filter, setFilter] = useState(""); //SEARCHING FILTER -->
  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD")); //SEARCHING FILTER -->
  const [endDate, setEndDate] = useState(""); //SEARCHING FILTER -->
  const [isHovering, setIsHovering] = useState(false); //HOVWER SORTING -->
  const [isSwitchOn, setIsSwitchOn] = useState(false); //TOGGLE SWITCH -->
  const [url, setUrl] = useState("");
  const [editIndex, seteditIndex] = useState(-1); //EDIT -->
  const [totalRecord, setTotalRecord] = useState(); //TOTAL RECORD -->
  const [isAddHandle, setIsAddHandle] = useState(true); //PASS HIDE OR UNHIDE -->
  const [progress, setProgress] = useState(0);
  const [togglesubmit, setToggle] = useState(false);
  const [val, setVal] = useState("");

  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [record, setRecord] = useState(0);

  const [startExpDate, setStartExpDate] = useState(moment().format("YYYY-MM-DD")); //SEARCHING FILTER -->
  const [endExpDate, setEndExpDate] = useState("");

  return (
    <>
      <NoteContext.Provider
        value={{
          formvalue,
          setformvalue,

          donar,
          setDonar,
          bank,
          setBank,
          donation,
          setDonation,
          amount,
          setAmount,
          paidamount,
          setPaidAmount,
          note,
          setNote,
          dueAmount,
          setDouAmount,
          showDonation,
          setShowDonation,
          trustDrop,
          setTrustDrop,
          trustId,
          setTrustId,
          payModel,
          setPayModel,
          paymentMode,
          setPaymentMode,
          dialCode,
          setDialCode,

          ahoeDataModel,
          setahoeDataModel,
          modal,
          setModal,
          addbel,
          setAddBel,
          pageNumber,
          setpageNumber,
          recordsPerPage,
          setRecordsPerPage,
          sortOrder,
          setSortOrder,
          sortBy,
          setSortBy,
          filter,
          setFilter,
          isHovering,
          setIsHovering,
          isSwitchOn,
          setIsSwitchOn,
          url,
          setUrl,
          editIndex,
          seteditIndex,
          totalRecord,
          setTotalRecord,
          isAddHandle,
          setIsAddHandle,
          progress,
          setProgress,
          togglesubmit,
          setToggle,
          accountId,
          setAccountId,
          val,
          setVal,
          accountDrop,
          setAccountDrop,
          accountDropDown,
          personDrop,
          setPersonDrop,
          personDropdown,
          bankDrop,
          setBankDrop,
          bankDropdown,
          personSearch,
          setPersonSearch,
          personId,
          setPersonaId,

          startDate,
          setStartDate,
          endDate,
          setEndDate,

          pageSize,
          setPageSize,
          page,
          setPage,
          record,
          setRecord,
          startExpDate,
          setStartExpDate,
          endExpDate,
          setEndExpDate,
        }}
      >
        {props.children}
      </NoteContext.Provider>
    </>
  );
};

export default NoteState;
