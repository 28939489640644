import React, { useContext } from "react";
import { Edit } from "react-feather";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { TEMPLE_TRUST } from "../../constant";
import OrganizationContext from "../../context/OrganizationContext/OrganizationContext";
import TempleTrustContext from "../../context/TempleTrustContext/TempleTrustContext";
import LoaderResoinse from "../../loader/loader";

const TempleTrustAddModel = (props) => {
  const { onSubmit, close, error } = props;
  const {
    modal,
    name,
    togglesubmit,
    templeName,
    setTempleName,
    trustRegNo,
    setTrustRegNo,
    panNo,
    setPanNo,
    certNo,
    setCertNo,
  } = useContext(TempleTrustContext);

  const { loaderShow } = useContext(OrganizationContext);

  return (
    <>
      <Modal size="lg" isOpen={modal} toggle={() => close()}>
        {loaderShow === true && <LoaderResoinse />}
        <ModalHeader toggle={() => close()}>
          {togglesubmit ? (
            <span>{TEMPLE_TRUST.ADD_TEMPL_TRUST_}</span>
          ) : (
            <span>
              {TEMPLE_TRUST.EDI_TRUST_TEMPL_TRUST_} <span> </span>{" "}
              <Edit
                style={{ width: "50px", height: "20px", color: "#7366ff" }}
              />
            </span>
          )}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader>
                  <h5> {TEMPLE_TRUST.TEMPL_TRUST_DETAILS}</h5>
                </CardHeader>
                <CardBody>
                  <Form
                    className="needs-validation"
                    onSubmit={(e) => onSubmit(e)}
                  >
                    <Row>
                      <Col md="6 mb-4">
                        <Label
                          htmlFor="validationCustom01"
                          className={
                            error && templeName.length <= 0
                              ? "validation"
                              : "non-validation"
                          }
                        >
                          {error && templeName.length <= 0
                            ? "Trust Name* (is Required)"
                            : "Trust Name*"}
                        </Label>
                        <Input
                          className={`${
                            error && name.length <= 0 ? "inputValidation" : ""
                          } "form-control`}
                          name="templeName"
                          type="text"
                          placeholder="Temple Name"
                          id="validationCustom01"
                          onChange={(e) => setTempleName(e.target.value)}
                          value={templeName}
                        />
                        <div className="invalid-feedback">
                          {"Please provide a valid ORG Name."}
                        </div>
                      </Col>
                      <Col md="6 mb-4">
                        <Label htmlFor="validationCustom01">
                          {"Trust Reg No."}
                        </Label>
                        <Input
                          className={"form-control"}
                          name="mobile"
                          type="text"
                          id="validationCustome22"
                          placeholder="Trust Reg No."
                          onChange={(e) => setTrustRegNo(e.target.value)}
                          value={trustRegNo}
                        />
                        <div className="invalid-feedback">
                          {"Please provide a valid ORG Name."}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6 mb-4">
                        <Label htmlFor="validationCustom03">{"Pan No."}</Label>
                        <Input
                          className={"form-control"}
                          name="account_no"
                          type="text"
                          id="validationCustome22"
                          placeholder="Pan No."
                          onChange={(e) => setPanNo(e.target.value)}
                          value={panNo}
                        />
                      </Col>
                      <Col md="6 mb-4">
                        <Label htmlFor="validationCustom06">{"Cert No."}</Label>
                        <Input
                          className="form-control"
                          name="gstNumber"
                          id="validationCustom06"
                          type="text"
                          placeholder="Cert No."
                          onChange={(e) => setCertNo(e.target.value)}
                          value={certNo}
                        />
                        <div className="invalid-feedback">
                          {"Please provide a valid Address 1."}
                        </div>
                      </Col>
                    </Row>
                    <Row style={{ paddingTop: "30px" }}>
                      <button
                        className="btn btn-primary"
                        type="submit"
                        value="Submit"
                        disabled={loaderShow === true ? "disabled" : ""}
                      >
                        {"Submit"}
                      </button>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
};

export default TempleTrustAddModel;
