import axios from "axios";
import {
  ADD_DONAR_LIST,
  DONAR_DITAILS,
  DONAR_LIST,
  DONAR_STATEMENT,
  EDIT_DONAR_LIST,
  ENABLE_DESABLE_DONAR_LIST,
} from "../../constant/API constant";

export const DonarList = (paramsObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${DONAR_LIST}`, {
    headers: { Authorization: jwt_token },
    params: paramsObj,
  });
};

export const Add_Donar = (formData) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(
    `${process.env.REACT_APP_API_URL}${ADD_DONAR_LIST}`,
    formData,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

export const Donar_List_Details = (personId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${DONAR_DITAILS}`, {
    headers: { Authorization: jwt_token },
    params: { personId },
  });
};

export const Donar_List_Statement = (paramsObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${DONAR_STATEMENT}`, {
    headers: { Authorization: jwt_token },
    params: paramsObj,
  });
};

export const Edit_Donar_List = (formData, personId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${EDIT_DONAR_LIST}`,
    formData,
    {
      headers: { Authorization: jwt_token },
      params: { personId },
    }
  );
};

export const Donar_List_Enable_Desable = (formData) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${ENABLE_DESABLE_DONAR_LIST}`,
    formData,
    {
      headers: { Authorization: jwt_token },
    }
  );
};
