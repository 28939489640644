import { useContext } from 'react';
import { firebase_app } from '../data/config';
// import { socket } from "../socket";
import SocketContext from '../context/SocketContext/SocketContext';

const LogOut = (history) => {
  // const { socket } = useContext(SocketContext);

  localStorage.removeItem('profileURL');
  localStorage.removeItem('token');
  localStorage.removeItem('orgId');
  localStorage.removeItem('roleId');
  localStorage.removeItem('Name');
  localStorage.removeItem('orgImage');
  localStorage.removeItem('userImage');
  localStorage.removeItem('templeId');
  localStorage.removeItem('templeName');
  firebase_app.auth().signOut();
  // socket ?? socket?.disconnect();
  history(`${process.env.PUBLIC_URL}/login`);
};

export default LogOut;
