import React, { useContext } from "react";
import {
  Card,
  CardBody,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import { ACCOUNT_TYPE, EXPANCE, EXPANCE_FEILD } from "../../constant";
import NoteContext from "../../context/NotesContext/NoteContext";

const OrganizationDetailsModel = (props) => {
  const { transactionDetails, accountDetail, personDetail } = props;
  const { ahoeDataModel, setahoeDataModel, showDonation } =
    useContext(NoteContext);
  return (
    <>
      <Modal
        size="xl"
        isOpen={ahoeDataModel}
        toggle={() => setahoeDataModel(!ahoeDataModel)}
      >
        <ModalHeader toggle={() => setahoeDataModel(!ahoeDataModel)}>
          {EXPANCE.VIEW_EXPANCE}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col sm="12">
              <Card>
                <CardBody style={{ padding: "0" }}>
                  <Card>
                    <div className="table-responsive">
                      <Table>
                        <tbody>
                          <tr style={{ border: "none" }}>
                            <th scope="row" style={{ border: "none" }}>
                              {EXPANCE_FEILD.NAME}
                            </th>
                            <td style={{ border: "none" }}>
                              {personDetail.name === undefined
                                ? "--"
                                : personDetail.name}
                            </td>
                          </tr>
                          <tr style={{ border: "none" }}>
                            <th scope="row" style={{ border: "none" }}>
                              {EXPANCE_FEILD.DONATION}
                            </th>
                            <td style={{ border: "none" }}>
                              {accountDetail.name === undefined
                                ? "--"
                                : accountDetail.name}
                            </td>
                          </tr>
                          <tr style={{ border: "none" }}>
                            <th scope="row" style={{ border: "none" }}>
                              {EXPANCE_FEILD.TYPE}
                            </th>
                            <td style={{ border: "none" }}>
                              {accountDetail.type === ACCOUNT_TYPE.inward
                                ? ACCOUNT_TYPE.inward_name
                                : ACCOUNT_TYPE.outward_name}
                            </td>
                          </tr>
                          <tr style={{ border: "none" }}>
                            <th scope="row" style={{ border: "none" }}>
                              {EXPANCE_FEILD.AMOUNT}
                            </th>
                            <td style={{ border: "none" }}>
                              {showDonation.amount === undefined
                                ? "--"
                                : showDonation.amount}
                            </td>
                          </tr>
                          <tr style={{ border: "none" }}>
                            <th scope="row" style={{ border: "none" }}>
                              {EXPANCE_FEILD.DUE_AMOUNT}
                            </th>
                            <td style={{ border: "none" }}>
                              {showDonation.dueAmount === undefined
                                ? "--"
                                : showDonation.dueAmount}
                            </td>
                          </tr>
                          <tr style={{ border: "none" }}>
                            <th scope="row" style={{ border: "none" }}>
                              {EXPANCE_FEILD.NOTE}
                            </th>
                            <td style={{ border: "none" }}>
                              {showDonation.note === null
                                ? "--"
                                : showDonation.note}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                    {transactionDetails.length ? (
                      <div
                        className="table-responsive"
                        style={{ marginTop: "6rem" }}
                      >
                        <Table className="table-border-vertical">
                          <thead>
                            <tr>
                              <th scope="col" style={{ width: "10%" }}>
                                {EXPANCE_FEILD.SLIP_NO}
                              </th>
                              <th scope="col">{"Bank"}</th>
                              <th scope="col" style={{ width: "10%" }}>
                                {EXPANCE_FEILD.CREDIT}
                              </th>
                              <th scope="col" style={{ width: "10%" }}>
                                {EXPANCE_FEILD.DEBIT}
                              </th>
                              {/* <th scope="col">{"Balance"}</th> */}
                            </tr>
                          </thead>

                          <tbody>
                            {transactionDetails.map((elem, i) => (
                              <tr key={i}>
                                <th scope="row">{elem.slipNo}</th>
                                <td>{elem.bankDetail.name}</td>

                                <td
                                  style={{ fontWeight: "800", color: "green" }}
                                >
                                  {elem.type === ACCOUNT_TYPE.inward
                                    ? elem.amount
                                    : "0"}
                                </td>
                                <td style={{ fontWeight: "800", color: "red" }}>
                                  {elem.type === ACCOUNT_TYPE.outward
                                    ? elem.amount
                                    : "0"}
                                </td>
                                {/* <td>{elem.balance}</td> */}
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    ) : null}
                  </Card>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
};

export default OrganizationDetailsModel;
