import React, { useContext, useEffect } from "react";
import SocketContext from "../../context/SocketContext/SocketContext";
import QrModal from "./QrModal";
import { SOCKET_EVENT, SOCKET_STATUS } from "../../constant";

const SocketConnection = () => {
  const { setQrModal, setQr, connect, disconnect, setIsConnect } =
    useContext(SocketContext);

  let obj = {
    [SOCKET_STATUS.NOT_CONNECTED]: function (message) {
      this.statusOff();
      this.loadingOff();
      this.successQrOff();
      setQrModal(false);
      setQr("");
      this.whatsAppConnectLoaderOff();
      setIsConnect(false);
    },
    [SOCKET_STATUS.SESSION_CLOSE]: function (message) {
      this.statusOff();
      this.loadingOff();
      this.successQrOff();
      setQrModal(false);
      setQr("");
      this.whatsAppConnectLoaderOff();
      setIsConnect(false);
    },
    [SOCKET_STATUS.CONNECTED]: function (message) {
      this.statusOn();
      this.whatsAppConnectLoaderOff();
      setIsConnect(true);
    },
    [SOCKET_STATUS.ERROR]: function (message) {
      console.log("error >>", message);
      this.whatsAppConnectLoaderOff();
      setIsConnect(false);
    },
    [SOCKET_STATUS.DIS_CONNECTED]: function (message, socket) {
      this.statusOff();
      this.loadingOff();
      this.successQrOff();
      setQrModal(false);
      setQr("");
      this.whatsAppConnectLoaderOff();
      setIsConnect(false);
    },
    [SOCKET_STATUS.AUTH_FAILURE]: function (message, socket) {
      this.whatsAppConnectLoaderOff();
      setIsConnect(false);
    },
    [SOCKET_STATUS.AUTHENTICATED]: function (message) {
      setQr("");
      this.successQrOn();
      this.loadingOff();
      this.statusOff();
      this.whatsAppConnectLoaderOn();
      setIsConnect(true);
    },
    [SOCKET_STATUS.LOADING_SCREEN]: function (message) {
      setQr("");
      this.loadingOn();
      this.successQrOff();
      this.whatsAppConnectLoaderOn();
      setIsConnect(false);
    },
    [SOCKET_STATUS.READY]: function (message) {
      this.successQrOff();
      this.loadingOff();
      setQrModal(false);
      setQr("");
      this.statusOn();
      this.whatsAppConnectLoaderOff();
      setIsConnect(true);
    },
    [SOCKET_STATUS.QR]: function (message) {
      setQrModal(true);
      setQr(message.data.qr);
      this.loadingOff();
      this.successQrOff();
      this.whatsAppConnectLoaderOn();
      setIsConnect(false);
    },
    statusOn: () => {
      document.getElementById("online-status")?.classList.remove("d-none");
      document.getElementById("offline-status")?.classList.add("d-none");
    },
    statusOff: () => {
      document.getElementById("online-status")?.classList.add("d-none");
      document.getElementById("offline-status")?.classList.remove("d-none");
    },
    successQrOn: () => {
      document.getElementById("success_qr")?.classList.remove("d-none");
    },
    successQrOff: () => {
      document.getElementById("success_qr")?.classList.add("d-none");
    },
    loadingOn: () => {
      document.getElementById("loading_qr")?.classList.remove("d-none");
    },
    loadingOff: () => {
      document.getElementById("loading_qr")?.classList.add("d-none");
    },
    whatsAppConnectLoaderOff: () => {
      document.getElementById("show-whatsapp-send-loader").style.zIndex = -1;
    },
    whatsAppConnectLoaderOn: () => {
      document.getElementById("show-whatsapp-send-loader").style.zIndex = 1;
    },
  };

  useEffect(() => {
    if (localStorage.getItem("token") && +localStorage.getItem("isWpMsg")) {
      // Connect when the user is authenticated
      const socket = connect(localStorage.getItem("token"));

      socket.on("connect", () => console.log("connected"));

      socket.on("message", (message) => {
        obj[message.data.code](message, socket);
      });
      socket.emit("message", { code: SOCKET_EVENT.STATUS });
    } else {
      // Disconnect when the user is not authenticated
      disconnect();
    }

    return () => disconnect();
  }, []);

  return (
    <>
      <QrModal />{" "}
    </>
  );
};

export default SocketConnection;
