import * as React from "react";
import { useState } from "react";
import { toast, Zoom } from "react-toastify";
import { Donation_bill_List } from "../../api/DonationBillApi/DonationCillapi";
import TempleTrustContext from "./TempleTrustContext";
import html2pdf from "html2pdf-jspdf2";

const TempleTrustState = (props) => {
  const [formvalue, setformvalue] = useState([]); //API -->
  const [formvalueSlip, setformvalueSlip] = useState([]); //API -->

  /**Temple Master States */
  const [templeName, setTempleName] = useState(null);
  const [templeId, setTempleId] = useState("");
  const [trustRegNo, setTrustRegNo] = useState(null);
  const [panNo, setPanNo] = useState(null);
  const [certNo, setCertNo] = useState(null);
  const [options, setOptions] = useState([]);
  const [selectedValue, setSelectedValue] = useState("");

  /**Temple Master States */

  const [url, setUrl] = useState("");

  const [isfcCode, setIsfcCode] = useState("");
  const [accountNo, setAccountNo] = useState("");
  const [confirmAaccountNo, setConfirmAccountNo] = useState("");

  const [branch, setBranch] = useState("");
  const [bankId, setBankId] = useState("");

  const [name, setName] = useState("");
  const [personId, setPersonaId] = useState("");
  const [paymentMode, setPaymentMode] = useState("");

  const [contactNo, setContactNo] = useState("");
  const [village, setVillage] = useState("");
  const [pancard, setPanCard] = useState("");
  const [aadhaarCard, setAadhaarCard] = useState("");
  const [byHand, setByhand] = useState("");
  const [paymenName, setPaymentName] = useState("");
  const [note, setNote] = useState("");
  const [transectionNo, setTransectionNo] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [slipId, setSlipId] = useState("");
  const [slipData, setSlipData] = useState("");

  const [showHandle, setShowHanlde] = useState("");

  const [ahoeDataModel, setahoeDataModel] = useState(false); //SHOW ORG DETAILS MODEL -->
  const [modal, setModal] = useState(false); //ADD DATA MODEL -->
  const [model, setModel] = useState(false); //ADD DATA MODEL -->
  const [addbel, setAddBel] = useState(false); //ADD ORG BEL -->
  const [pageNumber, setpageNumber] = useState(1); //PAGINATION -->
  const [recordsPerPage, setRecordsPerPage] = useState(10); //PAGINATION -->
  const [sortOrder, setSortOrder] = useState(""); //SORTING -->
  const [sortBy, setSortBy] = useState("createdAt"); //SORTING -->
  const [filter, setFilter] = useState(""); //SEARCHING FILTER -->
  const [isHovering, setIsHovering] = useState(false); //HOVWER SORTING -->
  const [isSwitchOn, setIsSwitchOn] = useState(false); //TOGGLE SWITCH -->
  const [editIndex, seteditIndex] = useState(-1); //EDIT -->
  const [totalRecord, setTotalRecord] = useState(); //TOTAL RECORD -->
  const [isAddHandle, setIsAddHandle] = useState(true); //PASS HIDE OR UNHIDE -->
  const [progress, setProgress] = useState(0);
  const [togglesubmit, setToggle] = useState(false);

  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [record, setRecord] = useState(0);

  const getData = async () => {
    try {
      let paramsObj = {
        pageNumber: page,
        recordsPerPage: pageSize,
        sortOrder,
        sortBy,
      };
      const { data } = await Donation_bill_List(paramsObj);
      if (data.status === 200) {
        setformvalueSlip(data.payload.data);
        setRecord(data.pager.totalRecords);
      } else {
        setformvalue([]);
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  const Print = () => {
    // const printContents = document.getElementById("printablediv").innerHTML;
    // const originalContents = document.body.innerHTML;
    // document.body.innerHTML = printContents;
    // window.print();
    // document.body.innerHTML = originalContents;
    const invoice = document.getElementById("invoice");
    html2pdf().from(invoice).save();
    setModel(!model);
  };

  return (
    <>
      <TempleTrustContext.Provider
        value={{
          formvalue,
          setformvalue,
          isfcCode,
          setIsfcCode,
          accountNo,
          setAccountNo,
          branch,
          setBranch,
          confirmAaccountNo,
          setConfirmAccountNo,
          showHandle,
          setShowHanlde,
          formvalueSlip,
          setformvalueSlip,
          slipId,
          setSlipId,
          slipData,
          setSlipData,

          templeName,
          setTempleName,
          templeId,
          setTempleId,
          trustRegNo,
          setTrustRegNo,
          panNo,
          setPanNo,
          certNo,
          setCertNo,
          options,
          setOptions,
          selectedValue,
          setSelectedValue,

          name,
          setName,
          contactNo,
          setContactNo,
          village,
          setVillage,
          pancard,
          setPanCard,
          aadhaarCard,
          setAadhaarCard,
          byHand,
          setByhand,
          personId,
          setPersonaId,
          paymentMode,
          setPaymentMode,
          paymenName,
          setPaymentName,
          note,
          setNote,
          transectionNo,
          setTransectionNo,
          paymentType,
          setPaymentType,

          ahoeDataModel,
          setahoeDataModel,
          modal,
          setModal,
          model,
          setModel,
          addbel,
          setAddBel,
          pageNumber,
          setpageNumber,
          recordsPerPage,
          setRecordsPerPage,
          sortOrder,
          setSortOrder,
          sortBy,
          setSortBy,
          filter,
          setFilter,
          isHovering,
          setIsHovering,
          isSwitchOn,
          setIsSwitchOn,
          url,
          setUrl,
          editIndex,
          seteditIndex,
          totalRecord,
          setTotalRecord,
          isAddHandle,
          setIsAddHandle,
          progress,
          setProgress,
          togglesubmit,
          setToggle,
          bankId,
          setBankId,

          pageSize,
          setPageSize,
          page,
          setPage,
          record,
          setRecord,
          getData,
          Print,
        }}
      >
        {props.children}
      </TempleTrustContext.Provider>
    </>
  );
};

export default TempleTrustState;
