import React, { useState, useEffect } from "react";
import man from "../assets/images/dashboard/profile.jpg";
import {
  Container,
  Row,
  Col,
  Form,
  Input,
  Label,
  Button,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
} from "reactstrap";
import { useAuth0 } from "@auth0/auth0-react";
import { toast, Zoom } from "react-toastify";

import jwtImg from "../assets/images/jwt.svg";

import {
  Password,
  AUTH0,
  JWT,
  LoginWithJWT,
  // ENDPOINT_IMAGE,
  // Img,
} from "../constant";
import { useNavigate } from "react-router";
import { classes } from "../data/layouts";
import { Login } from "../api/Login Api/LoginApi";
import { Toast } from "./signinToast";
import { maxLengthCheck } from "../utils/helper";
import LoaderResoinse from "../loader/loader";

const Logins = () => {
  const { loginWithRedirect } = useAuth0();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [selected, setSelected] = useState("jwt");
  const [togglePassword, setTogglePassword] = useState(false);
  const [loaderShow, setLoaderShow] = useState(false);
  const history = useNavigate();
  const defaultLayoutObj = classes.find(
    (item) => Object.values(item).pop(1) === "compact-wrapper"
  );
  const layout =
    localStorage.getItem("layout") || Object.keys(defaultLayoutObj).pop();

  const [value, setValue] = useState(localStorage.getItem("profileURL" || man));

  useEffect(() => {
    localStorage.setItem("profileURL", value);
  }, [value]);

  const [error, setError] = useState(false);

  const login = async (e) => {
    e.preventDefault();
    setLoaderShow(!loaderShow);
    if (email.length === 0 || password.length === 0) {
      setError(true);
    } else {
      try {
        const { data } = await Login(email, password);
        if (data.status === 200) {
          setValue(man);
          localStorage.setItem(
            "roleId",
            data.payload.data.roleId && data.payload.data.roleId
          );
          localStorage.setItem("Name", data.payload.data.name);
          localStorage.setItem("orgId", data.payload.data.orgId);
          localStorage.setItem(
            "orgImage",
            data.payload.data.org
              ? data.payload.data.org.image
              : data.payload.data.image
          );
          localStorage.setItem("userImage", data.payload.data.image);
          localStorage.setItem(
            "templeId",
            data?.payload?.data?.userRoleDetails?.templeAdmin?.templeId
          );
          localStorage.setItem(
            "templeName",
            data?.payload?.data?.userRoleDetails?.templeAdmin?.templeName
          );
          localStorage.setItem(
            "templeLogo",
            data?.payload?.data?.userRoleDetails?.templeAdmin?.image
          );
          localStorage.setItem("token", `Bearer ${data.payload.token}`);
          localStorage.setItem(
            "isWpMsg",
            data.payload.data?.userRoleDetails?.templeAdmin?.isWpMsg
          );
          setTimeout(() => {
            history(`${process.env.PUBLIC_URL}/dashboard/ecommerce/${layout}`);
          }, 200);
          toast.success(data.message, {
            autoClose: 1000,
            transition: Zoom,
          });
          setLoaderShow(false);

          return data.payload;
        }
      } catch (error) {
        Toast.fire({
          icon: "error",
          title: error.response.data.message,
        });
        setLoaderShow(false);
      }
    }
  };

  // const maxLengthCheck = (object) => {
  //   if (object.target.value.length > object.target.maxLength) {
  //     object.target.value = object.target.value.slice(
  //       0,
  //       object.target.maxLength
  //     );
  //   }
  // };

  return (
    <>
      <Container fluid={true} className="p-0">
        <div style={{ position: "relative" }}>
          {loaderShow === true && <LoaderResoinse />}
          <Row>
            <Col xs="12">
              <div className="login-card">
                <div>
                  <div className="login-main login-tab">
                    <Nav className="border-tab flex-column" tabs>
                      <NavItem>
                        <NavLink
                          className={selected === "jwt" ? "active" : ""}
                          onClick={() => setSelected("jwt")}
                        >
                          <img src={jwtImg} alt="" />
                          <span>{JWT}</span>
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <TabContent
                      activeTab={selected}
                      className="content-login"
                      style={{ height: "auto" }}
                    >
                      <TabPane className="fade show" tabId="jwt">
                        <Form className="theme-form" onSubmit={(e) => login(e)}>
                          <h4>
                            {selected === "firebase" ? "Login" : "Login "}
                          </h4>
                          <p>{"Enter Mobile No. & password"}</p>

                          {error && email.length <= 0 ? (
                            <div className="mb-3">
                              <Label
                                className="col-form-label"
                                style={{ color: "red" }}
                              >
                                {"Mobile Number"}
                              </Label>
                              <Input
                                className="form-control no-spinner"
                                type="number"
                                onChange={(e) => setEmail(e.target.value)}
                                onWheel={(e) => e.target.blur()}
                                defaultValue={email}
                                placeholder="Enter a Valid Mobile No."
                                min={"0"}
                                maxLength={10}
                                onInput={maxLengthCheck}
                                style={{ border: "2px solid red" }}
                              />
                            </div>
                          ) : (
                            <div className="mb-3">
                              <Label className="col-form-label">
                                {"Mobile Number"}
                              </Label>

                              <Input
                                className="form-control no-spinner"
                                type="number"
                                onChange={(e) => setEmail(e.target.value)}
                                onWheel={(e) => e.target.blur()}
                                defaultValue={email}
                                placeholder="Enter a Valid Mobile No."
                                min={"0"}
                                maxLength={10}
                                onInput={maxLengthCheck}
                              />
                            </div>
                          )}

                          {error && password.length <= 0 ? (
                            <div className="mb-3 position-relative">
                              <Label
                                className="col-form-label"
                                style={{ color: "red" }}
                              >
                                {Password}
                              </Label>
                              <Input
                                className="form-control"
                                type={togglePassword ? "text" : "password"}
                                onChange={(e) => setPassword(e.target.value)}
                                defaultValue={password}
                                placeholder="Enter a Valid Password"
                                style={{ border: "2px solid red" }}
                              />
                              <div
                                className="show-hide"
                                onClick={() =>
                                  setTogglePassword(!togglePassword)
                                }
                              >
                                <span
                                  className={togglePassword ? "" : "show"}
                                ></span>
                              </div>
                            </div>
                          ) : (
                            <div className="mb-3 position-relative">
                              <Label className="col-form-label">
                                {Password}
                              </Label>
                              <Input
                                className="form-control"
                                type={togglePassword ? "text" : "password"}
                                onChange={(e) => setPassword(e.target.value)}
                                defaultValue={password}
                                placeholder="Enter a Valid Password"
                              />
                              <div
                                className="show-hide"
                                onClick={() =>
                                  setTogglePassword(!togglePassword)
                                }
                              >
                                <span
                                  className={togglePassword ? "" : "show"}
                                ></span>
                              </div>
                            </div>
                          )}

                          <div className="login-btn mb-0">
                            <div className="ms-3">
                              <Label className="text-muted" for="checkbox1">
                                {
                                  "Please Enter Velid Mobile Number and Password"
                                }
                              </Label>
                            </div>
                            <Button
                              color="primary"
                              type="submit"
                              value="submit"
                            >
                              {LoginWithJWT}
                            </Button>
                          </div>
                        </Form>
                      </TabPane>
                      <TabPane className="fade show" tabId="auth0">
                        <div className="auth-content">
                          <img
                            src={require("../assets/images/auth-img.svg")}
                            alt=""
                          />
                          <h4>{"Welcome to login with Auth0"}</h4>
                          <p>
                            {
                              "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy"
                            }
                          </p>
                          <Button
                            color="info"
                            onClick={loginWithRedirect}
                            disabled={loaderShow === true ? "desabled" : ""}
                          >
                            {AUTH0}
                          </Button>
                        </div>
                      </TabPane>
                    </TabContent>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};

// export default withRouter(Logins);
export default Logins;
