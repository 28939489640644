import React, { useContext } from 'react';
import { Edit } from 'react-feather';
import { Card, CardBody, CardHeader, Col, Form, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { ERROR_CODE, EXPANCE, EXPANCE_FEILD } from '../../constant';
import NoteContext from '../../context/NotesContext/NoteContext';
import OrganizationContext from '../../context/OrganizationContext/OrganizationContext';
import LoaderResoinse from '../../loader/loader';
import { Donation_bill_Details } from '../../api/DonationBillApi/DonationCillapi';
import LogOut from '../../utils/logout';
import { Zoom, toast } from 'react-toastify';
import { useNavigate } from 'react-router';

const OrganizationAddModel = (props) => {
  const { onSubmit, close } = props;
  const {
    modal,
    donar,
    setDonar,
    bank,
    setBank,
    donation,
    setDonation,
    amount,
    setAmount,
    paidamount,
    setPaidAmount,
    setNote,
    note,
    togglesubmit,
    accountDrop,
    bankDrop,
    dueAmount,
    setDouAmount,
    setTrustId,
    trustId,
    trustDrop,
    setPersonaId,
    personSearch,
    setPersonSearch
  } = useContext(NoteContext);
  const { loaderShow } = useContext(OrganizationContext);
  const history = useNavigate();

  const showHandle = async (mobile) => {
    try {
      const { data } = await Donation_bill_Details(mobile);
      if (data.status === 200) {
        let payload = data.payload.data;
        setPersonaId(payload && payload.personId ? payload.personId : '');
        setDonar(payload && payload.name ? payload.name : '');
      } else {
      }
    } catch (error) {
      if (error?.response?.data?.status === ERROR_CODE.UNAUTHORIZED) {
        LogOut(history);
      }
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom
      });
    }
  };

  const handleSearch = (e) => {
    setPersonSearch(e.target.value);
    if (e.target.value.length === 10) {
      showHandle(e.target.value);
    }
  };
  return (
    <>
      <Modal size="lg" isOpen={modal} toggle={() => close()}>
        {loaderShow === true && <LoaderResoinse />}
        <ModalHeader toggle={() => close()}>
          {togglesubmit ? (
            <span>{EXPANCE.ADD_EXPANCE}</span>
          ) : (
            <span>
              {EXPANCE.EDIT_EXPANCE} <span> </span> <Edit style={{ width: '50px', height: '20px', color: '#7366ff' }} />
            </span>
          )}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader>
                  <h5>{EXPANCE.EXPANCE_DETAILS}</h5>
                </CardHeader>
                <CardBody>
                  <Form className="needs-validation" onSubmit={(e) => onSubmit(e)}>
                    <Row>
                      <Col md="6 mb-4">
                        <Label htmlFor="validationCustom01">{EXPANCE_FEILD.TRUST}</Label>
                        <select
                          className="form-select"
                          name="orderStatus"
                          typeof="number"
                          value={trustId}
                          onChange={(e) => setTrustId(e.target.value)}
                          required
                        >
                          <option value="">--select--</option>
                          {trustDrop.map((elem) => (
                            <option value={elem.trustId} key={elem.trustId}>
                              {elem.trustName}
                            </option>
                          ))}
                        </select>
                        <div className="invalid-feedback">{'Please provide a valid ORG Name.'}</div>
                      </Col>
                      <Col md="6 mb-4">
                        <Label htmlFor="validationCustom01">{'Donar Search'}</Label>
                        <Input
                          className="form-control"
                          name="name"
                          type="text"
                          placeholder={'Donar Search'}
                          id="validationCustom01"
                          value={personSearch}
                          onChange={(e) => handleSearch(e)}
                          required
                        />
                        <div className="invalid-feedback">{'Please provide a valid ORG Name.'}</div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6 mb-4">
                        <Label htmlFor="validationCustom01">{'Donar Name'}</Label>
                        <Input
                          className="form-control"
                          name="name"
                          type="text"
                          placeholder={'Donar Name'}
                          id="validationCustom01"
                          value={donar}
                          onChange={(e) => setDonar(e)}
                          style={{ color: 'black' }}
                          disabled
                        />
                        <div className="invalid-feedback">{'Please provide a valid ORG Name.'}</div>
                      </Col>
                      <Col md="6 mb-4">
                        <Label htmlFor="validationCustom01">{EXPANCE_FEILD.EXPANCE}</Label>
                        <select
                          className="form-select"
                          name="orderStatus"
                          typeof="number"
                          value={donation}
                          onChange={(e) => setDonation(e.target.value)}
                          required
                        >
                          <option value="">--select--</option>
                          {accountDrop.map((elem) => (
                            <option value={elem.accountId} selected={elem.accountId === donation ? 'selected' : ''}>
                              {elem.name}
                            </option>
                          ))}
                        </select>
                        <div className="invalid-feedback">{'Please provide a valid ORG Name.'}</div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6 mb-4">
                        <Label htmlFor="validationCustom01">{EXPANCE_FEILD.AMOUNT}</Label>
                        <Input
                          className="form-control"
                          name="amount"
                          type="number"
                          placeholder="Amount"
                          id="validationCustom01"
                          onChange={(e) => {
                            setAmount(e.target.value);
                            setDouAmount(e.target.value);
                            setPaidAmount('');
                          }}
                          onWheel={(e) => e.target.blur()}
                          value={amount}
                          disabled={togglesubmit ? '' : 'disabled'}
                          required
                        />
                        <div className="invalid-feedback">{'Please provide a valid ORG Name.'}</div>
                      </Col>
                      <Col md="6 mb-4">
                        <Label htmlFor="validationCustom01">{EXPANCE_FEILD.DUE_AMOUNT}</Label>
                        <Input
                          className="form-control"
                          name="amount"
                          type="number"
                          placeholder="Due Amount"
                          id="validationCustom01"
                          // onChange={(e) => setDouAmount(e.target.value)}
                          value={togglesubmit !== false ? dueAmount : dueAmount - paidamount}
                          disabled
                        />
                        <div className="invalid-feedback">{'Please provide a valid ORG Name.'}</div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6 mb-4">
                        <Label htmlFor="validationCustom01">{EXPANCE_FEILD.PAID_AMOUNT}</Label>
                        <Input
                          className="form-control"
                          name="amount"
                          type="number"
                          placeholder="Paid Amount"
                          id="validationCustom01"
                          onChange={(e) => {
                            setPaidAmount(e.target.value);
                            if (togglesubmit !== false) {
                              setDouAmount(amount - e.target.value);
                            }
                          }}
                          onWheel={(e) => e.target.blur()}
                          value={paidamount}
                        />
                        <div className="invalid-feedback">{'Please provide a valid ORG Name.'}</div>
                      </Col>
                      <Col md="6 mb-4">
                        <Label htmlFor="validationCustom01">{EXPANCE_FEILD.BANK}</Label>
                        <select
                          className="form-select"
                          name="orderStatus"
                          typeof="number"
                          value={bank}
                          onChange={(e) => setBank(e.target.value)}
                          disabled={paidamount === ''}
                          required
                        >
                          <option value="">--select--</option>
                          {bankDrop.map((elem) => (
                            <option
                              value={elem.bankId}
                              selected={elem?.bankId === bank ? 'selected' : bank?.length === 0 ? '' : ''}
                            >
                              {elem.name}
                            </option>
                          ))}
                        </select>
                        <div className="invalid-feedback">{'Please provide a valid ORG Name.'}</div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12" mb="4">
                        <Label htmlFor="validationCustom03">{EXPANCE_FEILD.NOTE}</Label>
                        <Input
                          className="form-control"
                          name="Note"
                          type="textarea"
                          id="validationCustome22"
                          placeholder="Note"
                          rows="5"
                          onChange={(e) => setNote(e.target.value)}
                          value={note}
                        />
                      </Col>
                    </Row>

                    <Row style={{ paddingTop: '30px' }}>
                      <button className="btn btn-primary" type="submit" value="Submit">
                        {'Submit'}
                      </button>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
};

export default OrganizationAddModel;
