import React, { useContext, useEffect } from 'react';
import OrgContext from '../context/OrgContext/OrgContext';
import { GujaratiDays } from '../api/GujaratiMonthApi/GujaratiDayApi';
import { GujaratiMonths } from '../api/GujaratiMonthApi/GujaratiMonthApi';
import { Style } from '../constant/index';

const TithiDropDown = (props) => {
  const { deathBoard } = props;
  const {
    gujaratiMonth,
    gujaratiDate,
    setDodMonth,
    setdodPaksha,
    setDodDate,
    dodMonth,
    dodPaksha,
    dodDate,
    setGujaratiDate,
    setGujaratiMonth
  } = useContext(OrgContext);
  useEffect(() => {
    setGujaratiDate(GujaratiDays());
    setGujaratiMonth(GujaratiMonths());
  }, []);

  return (
    <div className="d-flex ">
      <div>
        <select name="" id="" className="form-select" value={dodMonth} onChange={(e) => setDodMonth(e.target.value)}>
          {' '}
          <option value="">--</option>
          {gujaratiMonth.map((ele) => {
            return <option value={ele.value}>{ele.month}</option>;
          })}
        </select>
      </div>
      <div>
        <select
          name=""
          id=""
          className="form-select"
          value={dodPaksha}
          onChange={(e) => setdodPaksha(e.target.value)}
          // setdodPaksha(e.target.value)
        >
          <option value="">--</option>
          <option value="S">સુદ</option>
          <option value="V">વદ</option>
        </select>
      </div>
      <div>
        <select
          name=""
          id=""
          className="form-select"
          value={dodDate}
          onChange={(e) => setDodDate(e.target.value)}
          //
        >
          <option value="">--</option>
          {gujaratiDate.map((ele) => {
            return (
              <option value={ele.value}>
                {ele.gujDate} - {ele.value}{' '}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
};

export default TithiDropDown;
