import axios from "axios";
import {
  ADD_ORGANIZATION_LIST,
  DETAILS_ORGANIZATION_LIST,
  EDIT_ORGANIZATION_LIST,
  ORGANIZATION_LIST,
  ORG_ENABLE_DESABLE,
} from "../../constant/API constant";

export const OrganizationList = (paramsObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${ORGANIZATION_LIST}`, {
    headers: { Authorization: jwt_token },
    params: paramsObj,
  });
};

export const Add_OrganizationList = (formData) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(
    `${process.env.REACT_APP_API_URL}${ADD_ORGANIZATION_LIST}`,
    formData,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

export const Detail_OrganizationList = (orgId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(
    `${process.env.REACT_APP_API_URL}${DETAILS_ORGANIZATION_LIST}`,
    {
      headers: { Authorization: jwt_token },
      params: { orgId },
    }
  );
};

export const Edit_OrganizationList = (formData, orgId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${EDIT_ORGANIZATION_LIST}`,
    formData,
    {
      headers: { Authorization: jwt_token },
      params: { orgId },
    }
  );
};

export const Org_Enable_Desable = (formData, orgId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${ORG_ENABLE_DESABLE}`,
    formData,
    {
      headers: { Authorization: jwt_token },
      params: { orgId },
    }
  );
};
