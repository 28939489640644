import moment from "moment";
import React, { useContext, useRef, useState } from "react";
import { Download } from "react-feather";
import { Button, Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { calculate_age, isEmpty } from "../../utils/helper";
import { ERROR_CODE, RoleAccess } from "../../constant";
import OrganizationAddModel from "../DonarMaster/donarAddModel";
import ProductContext from "../../context/ProductContext/ProductContext";
import OrganizationContext from "../../context/OrganizationContext/OrganizationContext";
import LogOut from "../../utils/logout";
import { Zoom, toast } from "react-toastify";
import { Add_Donar } from "../../api/Donar Api/DonarApi";
import { useNavigate } from "react-router";
import { classes } from "../../data/layouts";
import MuiButton from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import ReceiptIcon from "@mui/icons-material/Receipt";
import { sendWhatsAppMessage } from "../../api/Admin Deshboard/AdminDeshboardApi";
import SocketContext from "../../context/SocketContext/SocketContext";

const BirthDateDeshboard = (props) => {
  const {
    date,
    setTodayDate,
    setCurrentMonth,
    showBirthdayTemplate,
    setPageNumber,
    totalRecord,
    setBirthDate,
  } = props;
  const { loaderShow, setLoaderShow } = useContext(OrganizationContext);
  const history = useNavigate();
  const defaultLayoutObj = classes.find(
    (item) => Object.values(item).pop(1) === "compact-wrapper"
  );
  const layout =
    localStorage.getItem("layout") || Object.keys(defaultLayoutObj).pop();

  const {
    setModal,
    setToggle,
    modal,
    setName,
    name,
    contact,
    setContact,
    village,
    setVillage,
    address,
    setAddress,
    pancard,
    setPancard,
    adharcard,
    setAdharcard,
    setDate,
  } = useContext(ProductContext);
  const { isConnect } = useContext(SocketContext);
  const [error, setError] = useState(false);
  const [selectedValue, setSelectedValue] = useState("Today");
  const [loading, setLoading] = useState(false);
  const scrollDivRef = useRef(null);

  const emptyFeild = () => {
    setAddress(null);
    setContact(null);
    setName(null);
    setVillage(null);
    setAdharcard(null);
    setPancard(null);
    setAddress(null);
    setDate(null);
    setLoaderShow(false);
    setError(false);
  };

  /**
   * submit to edit model and add model and edit and add api call to function call
   */
  const onSubmit = async (e) => {
    e.preventDefault();
    if (name.length === 0 || contact.length === 0) {
      setError(true);
    } else {
      setLoaderShow(!loaderShow);
      let formData;
      formData = isEmpty({
        name: name,
        mobile: contact,
        village,
        panCardNo: pancard,
        aadhaarCard: adharcard,
        address: address,
        dob: moment(date).format("yyyy-MM-DD"),
      });

      try {
        const { data } = await Add_Donar(formData);
        if (data.status === 200) {
          toast.success(data.message, {
            autoClose: 1000,
            transition: Zoom,
          });
          setModal(false);
          history(`/DonarMaster/donar/${layout}`);
          emptyFeild();
        } else {
        }
      } catch (error) {
        if (error?.response?.data?.status === ERROR_CODE.UNAUTHORIZED) {
          LogOut(history);
        }
        toast.error(error?.response?.data?.message, {
          autoClose: 1000,
          transition: Zoom,
        });
        setLoaderShow(false);
      }
    }
  };

  /**
   * change month select value
   * @param {event} e
   */
  const handleChange = (e) => {
    const { value } = e.target;
    setSelectedValue(value);
    setPageNumber(1);
    setBirthDate([]);
    if (value === "Today") {
      setTodayDate(moment().format("DD"));
    } else if (value === "Tomorrow") {
      setTodayDate(moment().add("d", 1).format("DD"));
    } else if (value === "Yesterday") {
      setTodayDate(moment().subtract("d", 1).format("DD"));
    } else if (value === "Select") {
      setCurrentMonth(moment().format("MM"));
      setTodayDate("");
    }
  };

  // const message = "Many Many Happy Returns Of The Day";

  const message = (name) => {
    return `પ. ભ. શ્રી ${name} જન્મદિવસ ની ખુબ ખુબ શુભકામના`;
  };

  const handleSendMessage = async (personId) => {
    if (isConnect) {
      try {
        if (!window.confirm("Are you sure you want to send ?")) {
          return false;
        }
        setLoaderShow(true);
        const { data } = await sendWhatsAppMessage({ personIds: [personId] });
        if (data.status === 200) {
          toast.success(data.message, {
            autoClose: 1000,
            transition: Zoom,
          });
          setLoaderShow(false);
        }
      } catch (error) {
        setLoaderShow(false);
        toast.error(error?.response?.data?.message || error.message, {
          autoClose: 1000,
          transition: Zoom,
        });
      }
    } else {
      alert(
        "WhatsApp is not connected. Please connect to WhatsApp to send messages"
      );
    }
  };

  /**
   * donar popup close
   */
  const close = () => {
    emptyFeild();
    setModal(!modal);
  };
  /**
   * open to add model frction call
   */

  const AddModel = () => {
    setModal(true);
    setToggle(true);
  };

  const handleScroll = () => {
    if (scrollDivRef.current) {
      const { scrollTop, clientHeight, scrollHeight } = scrollDivRef.current;
      if (totalRecord > date.length) {
        if (scrollTop + clientHeight >= scrollHeight) {
          setPageNumber((prev) => prev + 1);
        }
      }
    }
  };

  return (
    <>
      {+localStorage.getItem("roleId") === RoleAccess.EVENT_COORDINATOR && (
        <div className="pb-4 text-end">
          <Button onClick={() => AddModel()}>Add Birthday Profile </Button>
        </div>
      )}
      <Col
        xl={`${
          +localStorage.getItem("roleId") === RoleAccess.EVENT_COORDINATOR
            ? "12"
            : "6"
        } xl-50`}
        className="appointment-sec box-col-6"
      >
        <Row>
          <Col xl="12" className="appointment">
            <Card>
              <CardHeader className="card-no-border">
                <div className="header-top">
                  <h5 className="m-0">{"Birthday in this month"}</h5>
                  <div className="card-header-right-icon">
                    <select
                      className="button btn btn-primary"
                      onChange={(e) => handleChange(e)}
                      value={selectedValue}
                    >
                      <option value={"Select"}>{"Select"}</option>
                      <option value={"Today"}>{"Today"}</option>
                      <option value={"Tomorrow"}>{"Tomorrow"}</option>
                      <option value={"Yesterday"}>{"Yesterday"}</option>
                    </select>
                  </div>
                </div>
              </CardHeader>
              <div
                className="pt-0 card-body"
                ref={scrollDivRef}
                style={{
                  height: "26rem",
                  overflow: "auto",
                  padding: "10px",
                }}
                onScroll={handleScroll}
              >
                <div className="appointment-table table-responsive">
                  <table className="table table-bordernone">
                    <tbody>
                      {date.map((elem) => {
                        return (
                          <tr
                            style={
                              moment(elem.dob).format("DD-MM") ===
                              moment().format("DD-MM")
                                ? {
                                    background: "#ebebeb",
                                    border: "1px solid #b5b5b585",
                                  }
                                : {
                                    background: "white",
                                  }
                            }
                          >
                            <td
                              style={{ paddingTop: "12px", paddingLeft: "2px" }}
                            >
                              <img
                                className="img-fluid img-40 rounded-circle mb-3"
                                src={require("../../assets/images/WhatsApp Image 2023-03-29 at 2.39.08 PM (2).jpeg")}
                                alt=""
                              />
                              {/* <div className="status-circle bg-primary"></div> */}
                            </td>
                            <td
                              className="img-content-box"
                              style={{ paddingTop: "12px" }}
                            >
                              <span className="d-block">{elem.name}</span>
                              <span className="font-roboto">{elem.mobile}</span>
                            </td>
                            <td style={{ paddingTop: "12px" }}>
                              <p className="m-0 font-primary">
                                {moment(elem.dob).format("DD-MM") ===
                                moment().format("DD-MM")
                                  ? "Today"
                                  : elem.dob}
                              </p>
                            </td>
                            <td style={{ paddingTop: "12px" }}>
                              <p className="m-0 font-primary">
                                {calculate_age(elem.dob)}
                              </p>
                            </td>
                            {/* <td
                              style={{
                                paddingTop: "12px",
                                paddingRight: "2px",
                              }}
                            >
                            
                              <p
                                className="m-0 font-success"
                                style={{
                                  backgroundColor: "#ecffe4",
                                  borderRadius: "15%",
                                  display: "flex",
                                  justifyContent: "center",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  handleSendMessage(elem.mobile, elem.name)
                                }
                              >
                                Send
                              </p>
                            </td> */}
                            <td
                              style={{
                                paddingTop: "12px",
                                paddingRight: "2px",
                                display: "flex",
                                justifyContent: "space-evenly",
                              }}
                            >
                              {/* <Tooltip title="Send"> */}
                              <MuiButton
                                size="small"
                                variant="outlined"
                                onClick={() => handleSendMessage(elem.personId)}
                                color="secondary"
                              >
                                <SendIcon style={{ width: "17px" }} />
                              </MuiButton>
                              {/* </Tooltip> */}
                              {/* <Tooltip title="Statement"> */}
                              <MuiButton
                                size="small"
                                variant="outlined"
                                onClick={() =>
                                  history(
                                    `/Statement/Statement/${elem.personId}/birthday/Hisab_Kitab`
                                  )
                                }
                                color="secondary"
                              >
                                <ReceiptIcon style={{ width: "17px" }} />
                              </MuiButton>
                              {/* </Tooltip> */}
                              {/* <Tooltip title="Download"> */}
                              <MuiButton
                                size="small"
                                variant="outlined"
                                onClick={() => showBirthdayTemplate(elem.name)}
                                color="secondary"
                              >
                                <Download style={{ width: "17px" }} />
                              </MuiButton>
                              {/* </Tooltip> */}
                              {/* <p
                                className="m-0 font-success"
                                style={{
                                  backgroundColor: "#ecffe4",
                                  borderRadius: "15%",
                                  display: "flex",
                                  justifyContent: "center",
                                  cursor: "pointer",
                                }}
                                onClick={() => showBirthdayTemplate(elem.name)}
                              >
                                {<Download style={{ width: "17px" }} />}
                              </p> */}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </Card>
          </Col>
        </Row>
      </Col>
      <OrganizationAddModel
        onSubmit={onSubmit}
        error={error}
        close={close}
        modal={modal}
      />
    </>
  );
};

export default BirthDateDeshboard;
