import axios from "axios";
import {
  ADD_NOTE_LIST,
  EDIT_NOTE_LIST,
  NOTE_DITAILS,
  NOTE_LIST,
} from "../../constant/API constant";

export const NotesList = (paramsObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${NOTE_LIST}`, {
    headers: { Authorization: jwt_token },
    params: paramsObj,
  });
};

export const Add_Notes = (formData) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(
    `${process.env.REACT_APP_API_URL}${ADD_NOTE_LIST}`,
    formData,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

export const Notes_List_Details = (noteId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${NOTE_DITAILS}`, {
    headers: { Authorization: jwt_token },
    params: { noteId },
  });
};

export const Notes_Edit_List = (formData, noteId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${EDIT_NOTE_LIST}`,
    formData,
    {
      headers: { Authorization: jwt_token },
      params: { noteId },
    }
  );
};
