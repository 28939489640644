import * as React from "react";
import { useState } from "react";
import { toast, Zoom } from "react-toastify";
import { Order_List } from "../../api/OrderMAsterApi/OrderMasterApi";
import OrderContext from "./OrderContext";

const OrderState = (props) => {
  const [formvalue, setformvalue] = useState([]); //API -->

  const [uuid, setUuid] = useState("");
  const [totalQty, setTotalQty] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [deliveryDate, setDeliveryDate] = useState("");
  const [orderStatus, setOrderStatus] = useState("");
  const [netAmount, setNetAmount] = useState("");
  const [gstAmount, setGstAmount] = useState("");
  const [discountAmount, setDiaCountAmount] = useState(0);
  const [orderType, setOrderType] = useState("");
  const [paidAmount, setPaidAmount] = useState("");
  const [dueAmount, setDueAmount] = useState("");
  const [note, setNote] = useState("");
  const [OrderItem, setOrderItem] = useState([]);

  const [ahoeDataModel, setahoeDataModel] = useState(false); //SHOW ORG DETAILS MODEL -->
  const [modal, setModal] = useState(false); //ADD DATA MODEL -->
  const [addbel, setAddBel] = useState(false); //ADD ORG BEL -->
  const [pageNumber, setpageNumber] = useState(1); //PAGINATION -->
  const [recordsPerPage, setRecordsPerPage] = useState(10); //PAGINATION -->
  const [sortOrder, setSortOrder] = useState(""); //SORTING -->
  const [sortBy, setSortBy] = useState("createdAt"); //SORTING -->
  const [filter, setFilter] = useState(""); //SEARCHING FILTER -->
  const [isHovering, setIsHovering] = useState(false); //HOVWER SORTING -->
  const [isSwitchOn, setIsSwitchOn] = useState(false); //TOGGLE SWITCH -->
  const [url, setUrl] = useState("");
  const [editIndex, seteditIndex] = useState(-1); //EDIT -->
  const [totalRecord, setTotalRecord] = useState(); //TOTAL RECORD -->
  const [isAddHandle, setIsAddHandle] = useState(true); //PASS HIDE OR UNHIDE -->
  const [progress, setProgress] = useState(0);
  const [togglesubmit, setToggle] = useState(false);
  const [val, setVal] = useState("");

  const [data, setData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [productDropData, setProductDropData] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [record, setRecord] = useState(0);

  const getData = async () => {
    try {
      let paramsObj = {
        pageNumber: page,
        recordsPerPage: pageSize,
        sortOrder,
        sortBy,
        search: val,
      };
      if (filter.length) {
        paramsObj["search"] = { name: filter };
      }
      const { data } = await Order_List(paramsObj);
      if (data.status === 200) {
        setformvalue(data.payload.data);
        setRecord(data.pager.totalRecords);
      } else {
        setformvalue([]);
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  return (
    <>
      <OrderContext.Provider
        value={{
          formvalue,
          setformvalue,

          totalQty,
          setTotalQty,
          totalAmount,
          setTotalAmount,
          deliveryDate,
          setDeliveryDate,
          orderStatus,
          setOrderStatus,
          netAmount,
          setNetAmount,
          gstAmount,
          setGstAmount,
          discountAmount,
          setDiaCountAmount,
          orderType,
          setOrderType,
          paidAmount,
          setPaidAmount,
          dueAmount,
          setDueAmount,
          OrderItem,
          setOrderItem,
          note,
          setNote,

          ahoeDataModel,
          setahoeDataModel,
          modal,
          setModal,
          addbel,
          setAddBel,
          pageNumber,
          setpageNumber,
          recordsPerPage,
          setRecordsPerPage,
          sortOrder,
          setSortOrder,
          sortBy,
          setSortBy,
          filter,
          setFilter,
          isHovering,
          setIsHovering,
          isSwitchOn,
          setIsSwitchOn,
          url,
          setUrl,
          editIndex,
          seteditIndex,
          totalRecord,
          setTotalRecord,
          isAddHandle,
          setIsAddHandle,
          progress,
          setProgress,
          togglesubmit,
          setToggle,
          uuid,
          setUuid,
          val,
          setVal,
          data,
          setData,
          productData,
          setProductData,
          getData,
          pageSize,
          setPageSize,
          page,
          setPage,
          record,
          setRecord,
          productDropData,
          setProductDropData,
        }}
      >
        {props.children}
      </OrderContext.Provider>
    </>
  );
};

export default OrderState;
