import React, { useState } from 'react';
import { ACCOUNT_TYPE, Style } from '../../constant';
import { Divider, Menu, MenuItem, Tooltip } from '@mui/material';
import { Button, CardHeader, Input, Label } from 'reactstrap';
import { X } from 'react-feather';
import { useNavigate } from 'react-router';

const StatementFilter = (props) => {
  const {
    endDateFilter,
    setEndDateFilter,
    startDateFilter,
    setStartDateFilter,
    setSearchFilter,
    searchFilter,
    redireact,
    report
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const history = useNavigate();
  const close = () => {
    history(redireact);
    setEndDateFilter('');
    setStartDateFilter('');
    setSearchFilter('');
  };
  return (
    <CardHeader>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Tooltip title="Filters">
          <Button
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            {'Filter'}
          </Button>
        </Tooltip>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0
              }
            }
          }}
          transformOrigin={{
            horizontal: 'right',
            vertical: 'top'
          }}
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'bottom'
          }}
        >
          <MenuItem>
            <div>
              <div>
                <Label htmlFor="validationCustom01">{'Search'}</Label>
              </div>
              <select
                className="form-select"
                name="orderStatus"
                typeof="number"
                value={searchFilter}
                onChange={(e) => setSearchFilter(e.target.value)}
              >
                <option value="">--select--</option>
                <option value={ACCOUNT_TYPE.inward}>Inward</option>
                <option value={ACCOUNT_TYPE.outward}>Outward</option>
              </select>
            </div>
          </MenuItem>
          <Divider />
          <MenuItem>
            <div>
              <div>
                <Label htmlFor="validationCustom01">{'Start Date'}</Label>
              </div>
              <div>
                <Input
                  className="form-control"
                  name="fristName"
                  type="date"
                  placeholder="Start Date"
                  id="validationCustom01"
                  onChange={(e) => setStartDateFilter(e.target.value)}
                  value={startDateFilter}
                />
              </div>
            </div>
          </MenuItem>
          <Divider />
          <MenuItem>
            <div>
              <div>
                <Label htmlFor="validationCustom01">{'End Date'}</Label>
              </div>
              <div>
                <Input
                  className="form-control"
                  name="fristName"
                  type="date"
                  placeholder="End Date"
                  id="validationCustom01"
                  onChange={(e) => setEndDateFilter(e.target.value)}
                  value={endDateFilter}
                />
              </div>
            </div>
          </MenuItem>
        </Menu>
        <div className="d-flex align-items-center">
          <div>
            {report?.map((ap) => {
              return (
                <div key={ap.type}>
                  {ap.type == 1 ? 'Credits' : 'Debits'}: {ap.totalAmount}
                </div>
              );
            })}
          </div>
          <div aria-label="add" className="btn btn-light ms-3" onClick={() => close()} style={Style}>
            <X style={{ color: 'black' }} />
          </div>
        </div>
      </div>
    </CardHeader>
  );
};

export default StatementFilter;
