import axios from "axios";
import {
  ADD_DONATION_MASTER_LIST,
  BIRTHDAY,
  DEATHDAY,
  DONATION_EXPORT,
  DONATION_MASTER_DITAILS,
  DONATION_MASTER_LIST,
  DONATION_MASTER_LIST_PUBLIC,
  DONATION_MASTER_STATEMENT,
  EDIT_DONATION_MASTER_LIST,
  ENABLE_DESABLE_LIST,
} from "../../constant/API constant";

export const Donation_MasterList = (paramsObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${DONATION_MASTER_LIST}`, {
    headers: { Authorization: jwt_token },
    params: paramsObj,
  });
};

export const Donation_Export = (paramsObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${DONATION_EXPORT}`, {
    headers: { Authorization: jwt_token },
    params: paramsObj,
    responseType: "arraybuffer",
  });
};

export const Birthday = (paramsObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${BIRTHDAY}`, {
    headers: { Authorization: jwt_token },
    params: paramsObj,
  });
};
export const DeathDay = (paramsObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${DEATHDAY}`, {
    headers: { Authorization: jwt_token },
    params: paramsObj,
  });
};
export const Donation_MasterList_Public = (paramsObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(
    `${process.env.REACT_APP_API_URL}${DONATION_MASTER_LIST_PUBLIC}`,
    {
      headers: { Authorization: jwt_token },
      params: paramsObj,
    }
  );
};

export const Donation_Master_Statement = (paramsObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(
    `${process.env.REACT_APP_API_URL}${DONATION_MASTER_STATEMENT}`,
    {
      headers: { Authorization: jwt_token },
      params: paramsObj,
    }
  );
};

export const Add_Donation_Master = (formData) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(
    `${process.env.REACT_APP_API_URL}${ADD_DONATION_MASTER_LIST}`,
    formData,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

export const Donation_Master_List_Details = (accountId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(
    `${process.env.REACT_APP_API_URL}${DONATION_MASTER_DITAILS}`,
    {
      headers: { Authorization: jwt_token },
      params: { accountId },
    }
  );
};

export const Donation_Master_Edit_List = (formData, accountId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${EDIT_DONATION_MASTER_LIST}`,
    formData,
    {
      headers: { Authorization: jwt_token },
      params: { accountId },
    }
  );
};

export const DonationMaster_List_Enable_Desable = (formData, accountId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${ENABLE_DESABLE_LIST}`,
    formData,
    {
      headers: { Authorization: jwt_token },
      params: { accountId },
    }
  );
};
