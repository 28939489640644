import { Box, IconButton, Tooltip } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Download, Plus } from 'react-feather';
import { toast, Zoom } from 'react-toastify';
import { Card, CardHeader, Col, Container, Input, Row, Table } from 'reactstrap';
import { Donation_Export, Donation_MasterList } from '../../api/DonationMasterApi/DonationMasterApi';
import { ACCOUNT_TYPE, ERROR_CODE, INCOME, Style } from '../../constant';
import NoteContext from '../../context/NotesContext/NoteContext';
import OrganizationContext from '../../context/OrganizationContext/OrganizationContext';
import DataNotFound from '../../DataNoteFound/DataNotFound';
import Breadcrumbs from '../../layout/breadcrumb';
import TableSkeleton from '../../loader/TableSkeleton';
import Actions from '../../utils/Actions';
import { currencyFormat, MuiTabelStyle } from '../../utils/helper';
import LogOut from '../../utils/logout';
import moment from 'moment';
import AdminDeshboardContext from '../../context/AdminDeshboardContext/AdminDeshboardContext';
import DonationPopup from '../dashboard/Donation/DonationPopup';
import { Temple_Trust_List } from '../../api/TempleTrustApi/TempleTrustApi';
import { BankMasterList } from '../../api/Bank Master api/BankMasterAPi';
import { useNavigate } from 'react-router';
import useTempleTrustList from '../../utils/Hooks/useTempleTrustList';

const OrganizationItem = (props) => {
  const {
    editHandle,
    showHandle,
    sorting,
    AddModel,
    setPage,
    setPageSize,
    pageSize,
    page,
    record,
    handleFilter,
    selected,
    setSelected
  } = props;
  const {
    formvalue,
    filter,
    setPayModel,

    startDate,
    setStartDate,
    endDate,
    setEndDate
  } = useContext(NoteContext);
  const { setIsIncome, setModal, setTrustDrop } = useContext(AdminDeshboardContext);
  const { responseLoader } = useContext(OrganizationContext);
  const [dropDown, setDropDown] = useState([]);
  const [inputChangeFilter, setInputChangeFilter] = useState('');
  const history = useNavigate();
  const trustDropdown = useTempleTrustList();

  const PayNow = () => {
    setPayModel(true);
  };

  const defaultColumns = [
    {
      flex: 0.2,
      minWidth: 300,
      field: 'name',
      headerName: 'Name',
      renderCell: (params) => (params.row.personDetail.name ? params.row.personDetail.name : '--')
    },
    {
      flex: 0.1,
      minWidth: 200,
      field: 'mobile',
      headerName: 'Phone',
      renderCell: (params) => (params.row.personDetail.mobile ? params.row.personDetail.mobile : '--')
    },
    {
      flex: 0.1,
      minWidth: 200,
      field: 'AccountDetail',
      headerName: 'Income Type',
      renderCell: (params) => (params.row.AccountDetail.name ? params.row.AccountDetail.name : '--')
    },
    {
      flex: 0.1,
      minWidth: 100,
      field: 'amount',
      headerName: 'Amount',
      renderCell: (params) => (
        <div style={{ color: '#578b57', fontWeight: 'bold' }}>
          {params.row.amount ? currencyFormat(params.row.amount) : '--'}
        </div>
      )
    },
    {
      flex: 0.15,
      minWidth: 100,
      field: 'dueAmount',
      headerName: 'Due Amount',
      renderCell: (params) => (
        <div
          style={{
            color: '#ff5f5f',
            fontWeight: 'bold'
          }}
        >
          {params.row.dueAmount ? currencyFormat(params.row.dueAmount) : '--'}
        </div>
      )
    },
    {
      flex: 0.15,
      minWidth: 200,
      field: 'note',
      headerName: 'Note',
      renderCell: (params) => (params.row.note ? params.row.note : '--')
    },
    {
      flex: 0.1,
      minWidth: 200,
      field: 'createdAt',
      headerName: 'Date',
      renderCell: (params) =>
        params.row.createdAt ? moment(params.row.createdAt).format('DD MMM YYYY, hh:mm A') : '--'
    },
    {
      flex: 0.08,
      minWidth: 150,
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      renderCell: (params) => (
        <>
          <Actions params={params} editHandle={editHandle} showHandle={showHandle} PayNow={PayNow} />
        </>
      )
    }
  ];

  const getData = async () => {
    try {
      let paramsObj = {
        sortOrder: 'ASC',
        search: { type: ACCOUNT_TYPE.inward },
        showAll: true
      };
      if (inputChangeFilter.length) {
        paramsObj['search'] = {
          name: inputChangeFilter,
          type: ACCOUNT_TYPE.inward
        };
      }
      const { data } = await Donation_MasterList(paramsObj);
      if (data.status === 200) {
        setDropDown(data.payload.data);
      } else {
        setDropDown([]);
      }
    } catch (error) {
      if (error?.response?.data?.status === ERROR_CODE.UNAUTHORIZED) {
        LogOut(history);
      }
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom
      });
    }
  };

  let newArray = dropDown.map((elem) => {
    return { label: elem.name };
  });

  useEffect(() => {
    getData();
  }, [page, pageSize, inputChangeFilter]);

  const handleChange = (e) => {
    setSelected(e);
  };
  const handleInputChange = (e) => {
    setInputChangeFilter(e);
  };

  const handleDownloadExcel = async () => {
    try {
      let paramObj = {
        search: {
          type: ACCOUNT_TYPE.inward
        }
      };
      if (
        startDate !== 'Invalid date' &&
        startDate !== null &&
        startDate !== undefined &&
        startDate !== '' &&
        startDate
      ) {
        paramObj['search'] = { ...paramObj.search, startDate };
      }
      if (endDate !== 'Invalid date' && endDate !== null && endDate !== undefined && endDate !== '' && endDate) {
        paramObj['search'] = { ...paramObj.search, endDate };
      }
      if (selected[0]) {
        paramObj['search'] = {
          ...paramObj.search,
          accountName: selected[0].label
        };
      }
      if (filter.length && typeof filter === 'string') {
        paramObj['search'] = {
          ...paramObj.search,
          type: ACCOUNT_TYPE.inward,
          name: filter
        };
      }
      if (filter >= 1 && typeof Number(filter) === 'number') {
        paramObj['search'] = {
          ...paramObj.search,
          type: ACCOUNT_TYPE.inward,
          mobile: +filter,
          name: ''
        };
      }
      const { data } = await Donation_Export(paramObj);
      // Convert response data to Blob
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });

      // Create a link element
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `Income-${moment().format('DD-MMM-YY-hh-mm')}.xlsx`;

      // Trigger the download
      link.click();
    } catch (error) {
      if (error?.response?.data?.status === ERROR_CODE.UNAUTHORIZED) {
        LogOut(history);
      }
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom
      });
    }
  };

  return (
    <>
      <Fragment>
        <Breadcrumbs parent={INCOME.INCOME_PARENT} />
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between'
                  }}
                >
                  <div>
                    <h3>{INCOME.INCOME_PARENT}</h3>
                    {formvalue?.report && (
                      <table style={{ marginTop: '5px' }}>
                        {Object.entries(formvalue?.report).map((tot, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                <b>{tot[0]}</b>
                              </td>{' '}
                              <td>&nbsp;:&nbsp;</td>
                              <td>{currencyFormat(tot[1])}</td>
                            </tr>
                          );
                        })}
                      </table>
                    )}
                  </div>
                  <div
                    aria-label="add"
                    className="btn btn-primary"
                    // onClick={AddModel}
                    onClick={() => {
                      setModal(true);
                      setIsIncome(true);
                      trustDropdown();
                    }}
                    style={Style}
                  >
                    <Plus />
                  </div>
                </CardHeader>
                <Table>
                  <thead>
                    <tr>
                      <th>
                        <div className="d-flex">
                          <Row className="w-100 ">
                            <Col md="2 mb-2">
                              <Input
                                className="form-control"
                                type="search"
                                placeholder="Income Search..."
                                value={filter}
                                onChange={(e) => handleFilter(e)}
                              />
                            </Col>
                            <Col md="2 mb-2">
                              <Typeahead
                                id="basic-example"
                                onInputChange={(e) => handleInputChange(e)}
                                onChange={(e) => handleChange(e)}
                                options={newArray}
                                placeholder="Income Type Search..."
                                selected={selected}
                                clearButton
                              />
                            </Col>
                            <Col md="2 mb-2">
                              <input
                                type="date"
                                className="form-control colorBlack"
                                value={startDate}
                                onChange={(e) => {
                                  setStartDate(moment(e.target.value).format('YYYY-MM-DD'));
                                }}
                              />
                            </Col>
                            <Col md="2 mb-2">
                              <input
                                type="date"
                                className="form-control colorBlack"
                                value={endDate}
                                onChange={(e) => {
                                  setEndDate(moment(e.target.value).format('YYYY-MM-DD'));
                                }}
                                min={startDate}
                              />
                            </Col>
                          </Row>
                          <div className="w-100 d-flex justify-content-end align-items-center  ">
                            <Tooltip title="Excel">
                              <IconButton
                                aria-label="edit"
                                style={{
                                  background: '#dcf1dc',
                                  marginRight: '5px'
                                }}
                                onClick={() => handleDownloadExcel()}
                              >
                                <Download
                                  style={{
                                    color: 'green',
                                    width: '19px',
                                    height: '19px'
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </div>
                      </th>
                    </tr>
                  </thead>
                </Table>
                <Box
                  sx={{
                    '& .even': {
                      backgroundColor: '#f1f1f1'
                    },
                    '& .odd': {
                      backgroundColor: 'white'
                    }
                  }}
                >
                  {responseLoader === true ? (
                    <TableSkeleton />
                  ) : formvalue?.data?.length ? (
                    <DataGrid
                      autoHeight
                      sx={MuiTabelStyle}
                      pagination
                      rows={formvalue?.data}
                      rowCount={record}
                      pageSize={pageSize}
                      getRowId={(row) => row.accountTransactionId}
                      columns={defaultColumns}
                      page={page - 1}
                      rowsPerPageOptions={[7, 10, 25, 50]}
                      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                      onPageChange={(newPageNo) => setPage(newPageNo + 1)}
                      paginationMode="server"
                      disableSelectionOnClick
                      checkboxSelection={false}
                      sortingMode="server"
                      onSortModelChange={sorting}
                      getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
                    />
                  ) : (
                    <DataNotFound />
                  )}
                </Box>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fragment>
      <DonationPopup />
    </>
  );
};

export default OrganizationItem;
