import React, { useContext } from "react";
import HashLoader from "react-spinners/HashLoader";
import "./loader.css";
import OrganizationContext from "../context/OrganizationContext/OrganizationContext";

const LoaderResoinse = () => {
  const { loaderShow } = useContext(OrganizationContext);
  return (
    loaderShow && (
      <div className="loader">
        {" "}
        <HashLoader
          color={"#883ea6"}
          loading={true}
          size={50}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    )
  );
};

export default LoaderResoinse;
