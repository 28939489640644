import * as React from "react";
import UserContext from "./userContext";
import { useState } from "react";

const UserState = (props) => {
  const [data, setData] = useState([]);
  const [pageNumber, setpageNumber] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [totalRecord, setTotalRecord] = useState();
  const [isHovering, setIsHovering] = useState(false); //HOVWER SORTING -->
  const [sortOrder, setSortOrder] = useState("ASC"); //SORTING -->
  const [sortBy, setSortBy] = useState("createdAt"); //SORTING -->
  const [progress, setProgress] = useState(0);
  const [isHover1, setIsHover1] = useState(false);
  const [isHover2, setIsHover2] = useState(false);
  const [isHover3, setIsHover3] = useState(false);
  const [isHover4, setIsHover4] = useState(false);

  return (
    <>
      <UserContext.Provider
        value={{
          data,
          setData,
          pageNumber,
          setpageNumber,
          recordsPerPage,
          setRecordsPerPage,
          totalRecord,
          setTotalRecord,
          isHovering,
          setIsHovering,
          sortOrder,
          setSortOrder,
          sortBy,
          setSortBy,
          progress,
          setProgress,
          isHover1,
          setIsHover1,
          isHover2,
          setIsHover2,
          isHover3,
          setIsHover3,
          isHover4,
          setIsHover4,
        }}
      >
        {props.children}
      </UserContext.Provider>
    </>
  );
};

export default UserState;
