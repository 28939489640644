import React, { useContext, useEffect, useState } from 'react';
import OrganizationItem from './expanceItem';
import OrganizationDetailsModel from './expanceDetailsModel';
import OrganizationAddModel from './expanceAddModel';
import { toast, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-phone-number-input/style.css';
import TopLoadingBar from '../topLoadingBar/topLoadingBar';
import NoteContext from '../../context/NotesContext/NoteContext';
import {
  Add_Donation,
  Donation_Edit_List,
  Donation_List,
  Donation_List_Details
} from '../../api/DonationApi/DonationApi';
import { ACCOUNT_TYPE, ERROR_CODE } from '../../constant';
import { Temple_Trust_List } from '../../api/TempleTrustApi/TempleTrustApi';
import OrganizationContext from '../../context/OrganizationContext/OrganizationContext';
import LogOut from '../../utils/logout';
import { isEmpty } from '../../utils/helper';
import { useNavigate } from 'react-router';

const Expace = () => {
  const {
    setformvalue,
    setahoeDataModel,
    setModal,
    modal,
    sortOrder,
    setSortOrder,
    sortBy,
    setSortBy,
    filter,
    editIndex,
    seteditIndex,
    accountId,
    setAccountId,
    setShowDonation,
    note,
    setNote,
    donar,
    setDonar,
    bank,
    setBank,
    donation,
    setDonation,
    amount,
    setAmount,
    paidamount,
    setPaidAmount,
    setDouAmount,
    setIsAddHandle,
    progress,
    setProgress,
    togglesubmit,
    setToggle,
    accountDropDown,
    personDropdown,
    bankDropdown,
    setTrustDrop,
    trustId,
    setFilter,
    setPersonaId,
    personId,
    setPersonSearch,
    setTrustId,

    startExpDate,
    setStartExpDate,
    endExpDate,
    setEndExpDate
  } = useContext(NoteContext);
  const { loaderShow, setLoaderShow, responseLoader, setResponseLoader } = useContext(OrganizationContext);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [record, setRecord] = useState(0);
  const [accountDetail, setAccountDetail] = useState('');
  const [personDetail, setPersonDetail] = useState('');
  const [transactionDetails, setTransactionDetails] = useState([]);
  const [selected, setSelected] = useState([]);
  const history = useNavigate();
  /**
   * empty Feild function call
   */

  const emptyFeild = () => {
    setNote(null);
    setBank(null);
    setDonation(null);
    setAmount(null);
    setPaidAmount(null);
    setDonar(null);
    setDouAmount(null);
    setLoaderShow(false);
    setPersonaId('');
    setPersonSearch('');
    setDouAmount('');
  };

  const fillFeild = () => {
    setNote(note);
    setBank(bank);
    setDonation(donation);
    setAmount(amount);
    setPaidAmount(paidamount);
    setDonar(donar);
  };

  /**
   * get donation list and function call and useEffect use to function call
   */

  const getData = async () => {
    if (sortOrder === 'ASC' || sortOrder === 'DSC' || filter.length) {
    } else {
      setResponseLoader(!responseLoader);
    }
    try {
      let paramsObj = {
        pageNumber: page,
        recordsPerPage: pageSize,
        sortOrder,
        sortBy,
        search: {
          type: ACCOUNT_TYPE.outward
        }
      };
      if (
        startExpDate !== 'Invalid date' &&
        startExpDate !== null &&
        startExpDate !== undefined &&
        startExpDate !== '' &&
        startExpDate
      ) {
        paramsObj['search'] = { ...paramsObj.search, startDate: startExpDate };
      }
      if (
        endExpDate !== 'Invalid date' &&
        endExpDate !== null &&
        endExpDate !== undefined &&
        endExpDate !== '' &&
        endExpDate
      ) {
        paramsObj['search'] = { ...paramsObj.search, endDate: endExpDate };
      }
      if (selected[0]) {
        paramsObj['search'] = {
          ...paramsObj.search,
          accountName: selected[0] ? selected[0].label : ''
        };
      }
      if (filter.length) {
        delete paramsObj['pageNumber'];
        delete paramsObj['recordsPerPage'];
        delete paramsObj['sortOrder'];
        delete paramsObj['sortBy'];
      }
      if (filter.length && typeof filter === 'string') {
        paramsObj['search'] = {
          ...paramsObj.search,
          type: ACCOUNT_TYPE.outward,
          name: filter,
          accountName: selected[0] ? selected[0].label : ''
        };
      }
      if (filter >= 1 && typeof Number(filter) === 'number') {
        paramsObj['search'] = {
          ...paramsObj.search,
          type: ACCOUNT_TYPE.outward,
          mobile: +filter,
          name: '',
          accountName: selected[0] ? selected[0].label : ''
        };
      }
      const { data } = await Donation_List(paramsObj);
      if (data.status === 200) {
        setformvalue({ data: data.payload.data, report: data.payload.report });
        setRecord(data.pager.totalRecords);
        setResponseLoader(false);
      } else {
        setformvalue([]);
      }
    } catch (error) {
      if (error?.response?.data?.status === ERROR_CODE.UNAUTHORIZED) {
        LogOut(history);
      }
      setResponseLoader(false);
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom
      });
    }
  };

  useEffect(() => {
    getData();
    setProgress(100);
  }, [page, pageSize, sortOrder, sortBy, selected, filter, startExpDate, endExpDate]);

  /**
   * add donation model and open popup function call
   */

  const TrustDopdown = async () => {
    try {
      const { data } = await Temple_Trust_List();
      if (data.status === 200) {
        setTrustDrop(data.payload.data);
      } else {
        setTrustDrop([]);
      }
    } catch (error) {
      if (error?.response?.data?.status === ERROR_CODE.UNAUTHORIZED) {
        LogOut(history);
      }
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom
      });
    }
  };

  const AddModel = () => {
    setModal(true);
    setToggle(true);
    TrustDopdown();
    accountDropDown({ type: ACCOUNT_TYPE.outward });
    personDropdown();
    bankDropdown();
  };

  /**
   * fill feild data get to edit time and function call
   */

  const editHandle = async (accountTransactionId) => {
    setAccountId(accountTransactionId);
    setLoaderShow(!loaderShow);
    try {
      const { data } = await Donation_List_Details(accountTransactionId);
      if (data.status === 200) {
        setToggle(false);
        setIsAddHandle(false);
        seteditIndex(accountTransactionId);
        await accountDropDown({ type: ACCOUNT_TYPE.outward });
        await personDropdown();
        await bankDropdown();
        await TrustDopdown();
        let payload = data.payload.data;
        setPersonSearch(payload.personDetail ? payload.personDetail.mobile : null);
        setDonar(payload.personDetail ? payload.personDetail.name : null);
        setPersonaId(payload.personId ? payload.personId : null);
        setDonation(payload.accountId ? payload.accountId : null);
        setDouAmount(payload.dueAmount ? payload.dueAmount : null);
        setTrustId(payload.trustId ? payload.trustId : null);
        setAmount(payload.amount ? payload.amount : null);
        setBank(payload.transactionDetails[0] ? payload.transactionDetails[0].bankId : null);
        setDouAmount(payload.dueAmount ? payload.dueAmount : null);
        setNote(payload.note ? payload.note : null);
        setLoaderShow(false);
        setModal(true);
      } else {
        setformvalue([]);
      }
    } catch (error) {
      if (error?.response?.data?.status === ERROR_CODE.UNAUTHORIZED) {
        LogOut(history);
      }
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom
      });
      setLoaderShow(false);
    }
  };

  /**
   * add and edit form submit and api call function call
   */

  const onSubmit = async (e) => {
    e.preventDefault();
    let formData;
    if (editIndex !== -1 && !togglesubmit) {
      setLoaderShow(!loaderShow);
      formData = isEmpty({
        accountId: donation,
        personId: personId,
        amount: +amount,
        paidAmount: +paidamount,
        bankId: bank,
        note: note,
        trustId: +trustId,
        templeId: +localStorage.getItem('templeId')
      });
      try {
        const { data } = await Donation_Edit_List(formData, accountId);
        if (data.status === 200) {
          toast.success(data.message, {
            autoClose: 1000
          });
          setModal(false);
          getData();
          emptyFeild();
        } else {
          fillFeild();
        }
      } catch (error) {
        if (error?.response?.data?.status === ERROR_CODE.UNAUTHORIZED) {
          LogOut(history);
        }
        toast.error(error?.response?.data?.message, {
          autoClose: 1000,
          transition: Zoom
        });
        setLoaderShow(false);
      }
    } else {
      setLoaderShow(!loaderShow);
      formData = isEmpty({
        accountId: donation,
        personId: personId,
        amount: +amount,
        paidAmount: +paidamount,
        bankId: +bank,
        note: note,
        trustId: +trustId,
        templeId: +localStorage.getItem('templeId')
      });
      try {
        const { data } = await Add_Donation(formData);
        if (data.status === 200) {
          toast.success(data.message, {
            autoClose: 1000,
            transition: Zoom
          });
          setModal(false);
          getData();
          emptyFeild();
        } else {
          fillFeild();
        }
      } catch (error) {
        if (error?.response?.data?.status === ERROR_CODE.UNAUTHORIZED) {
          LogOut(history);
        }
        toast.error(error?.response?.data?.message, {
          autoClose: 1000,
          transition: Zoom
        });
        setLoaderShow(false);
      }
    }
  };

  /**
   * show donation api call adn function call for data fatch
   */

  const showHandle = async (accountTransactionId) => {
    setAccountId(accountTransactionId);
    setLoaderShow(!loaderShow);
    try {
      const { data } = await Donation_List_Details(accountTransactionId);
      if (data.status === 200) {
        let payload = data.payload.data;
        setShowDonation(payload);
        setAccountDetail(payload.AccountDetail);
        setTransactionDetails(payload.transactionDetails);
        setPersonDetail(payload.personDetail);
        setLoaderShow(false);
        setahoeDataModel(true);
      } else {
        setformvalue([]);
      }
    } catch (error) {
      if (error?.response?.data?.status === ERROR_CODE.UNAUTHORIZED) {
        LogOut(history);
      }
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom
      });
      setLoaderShow(false);
    }
  };

  /**
   * sorting fucntion call
   */

  const sorting = (col) => {
    if (sortOrder === '') {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder('ASC');
    }
    if (sortOrder === 'ASC') {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder('DSC');
    }
    if (sortOrder === 'DSC') {
      setSortBy();
      setSortOrder('');
    }
  };

  /**
   * hanlde search to function call
   */

  const handleFilter = (e) => {
    setFilter(e.target.value);
  };
  const close = () => {
    setModal(!modal);
    emptyFeild();
  };

  return (
    <>
      <div>
        <TopLoadingBar progress={progress} setProgress={setProgress} />
        <OrganizationAddModel onSubmit={onSubmit} close={close} />
        <OrganizationDetailsModel
          transactionDetails={transactionDetails}
          accountDetail={accountDetail}
          personDetail={personDetail}
        />
        <OrganizationItem
          handleFilter={handleFilter}
          sorting={sorting}
          editHandle={editHandle}
          showHandle={showHandle}
          AddModel={AddModel}
          setPage={setPage}
          setPageSize={setPageSize}
          pageSize={pageSize}
          page={page}
          record={record}
          selected={selected}
          setSelected={setSelected}
        />
      </div>
    </>
  );
};
export default Expace;
