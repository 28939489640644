import React, { useContext, useEffect, useState } from "react";
import OrganizationItem from "./bankMasterItem";
import OrganizationDetailsModel from "./bankMasterDetailsModel";
import OrganizationAddModel from "./bankMasterAddModel";
import { toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-phone-number-input/style.css";
import TopLoadingBar from "../topLoadingBar/topLoadingBar";
import OrgContext from "../../context/OrgContext/OrgContext";
import { ERROR_CODE } from "../../constant";
import {
  Add_BankMaster,
  BankMasterList,
  BankMaster_List_Details,
  BankMaster_List_Enable_Desable,
  Edit_BankMaster_List,
} from "../../api/Bank Master api/BankMasterAPi";
import { Temple_Trust_List } from "../../api/TempleTrustApi/TempleTrustApi";
import OrganizationContext from "../../context/OrganizationContext/OrganizationContext";
import LogOut from "../../utils/logout";
import { isEmpty } from "../../utils/helper";
import { useNavigate } from "react-router";

const Organization = () => {
  const {
    setformvalue,
    address,
    setAddress,
    setahoeDataModel,
    setModal,
    modal,
    sortOrder,
    setSortOrder,
    sortBy,
    setSortBy,
    filter,
    editIndex,
    seteditIndex,
    bankId,
    setBankId,
    setIsAddHandle,
    progress,
    setProgress,
    togglesubmit,
    setToggle,
    setName,
    name,
    type,
    setType,
    setTrustId,
    isfcCode,
    setIsfcCode,
    accountNo,
    setAccountNo,
    branch,
    trustId,
    setBranch,
    confirmAaccountNo,
    setConfirmAccountNo,
    setShowHanlde,
    isSwitchOn,
    setIsSwitchOn,
    setTrustDrop,
    columnFilter,
    columnName,
  } = useContext(OrgContext);
  const { loaderShow, setLoaderShow, responseLoader, setResponseLoader } =
    useContext(OrganizationContext);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [record, setRecord] = useState(0);
  const [error, setError] = useState(false);
  const history = useNavigate();

  /**
   * get bank data api call and deta fatch and useaEffect use to call getData() function call
   */

  const getData = async (enableLoader) => {
    if (
      enableLoader === false ||
      sortOrder === "ASC" ||
      sortOrder === "DSC" ||
      columnFilter.length
    ) {
    } else {
      setResponseLoader(!responseLoader);
    }
    try {
      let paramsObj = {
        pageNumber: page,
        recordsPerPage: pageSize,
        sortOrder,
        sortBy,
      };
      if (filter.length || columnFilter.length) {
        delete paramsObj["pageNumber"];
        delete paramsObj["recordsPerPage"];
        delete paramsObj["sortOrder"];
        delete paramsObj["sortBy"];
      }
      if (columnFilter.length) {
        paramsObj["search"] = { [columnName]: columnFilter };
      }
      if (filter.length && typeof filter === "string") {
        paramsObj["search"] = { name: filter };
      }
      if (filter >= 1 && typeof Number(filter) === "number") {
        paramsObj["search"] = { accountNumber: +filter };
      }
      const { data } = await BankMasterList(paramsObj);
      if (data.status === 200) {
        setformvalue(data.payload.data);
        setRecord(data.pager.totalRecords);
        setResponseLoader(false);
      } else {
        setformvalue([]);
      }
    } catch (error) {
      if (error?.response?.data?.status === ERROR_CODE.UNAUTHORIZED) {
        LogOut(history);
      }
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
      setResponseLoader(false);
    }
  };
  useEffect(() => {
    getData();
    setProgress(100);
  }, [page, pageSize, sortOrder, sortBy, columnFilter]);

  /**
   * Empty Feild function
   */

  const EmptyFeild = () => {
    setIsfcCode(null);
    setName(null);
    setAddress(null);
    setAccountNo(null);
    setConfirmAccountNo(null);
    setBranch(null);
    setTrustId("");
    setType(null);
    setLoaderShow(false);
  };

  /**
   * Fill Feild function
   */

  const FillFeild = () => {
    setIsfcCode(isfcCode);
    setName(name);
    setAddress(address);
    setAccountNo(accountNo);
    setConfirmAccountNo(confirmAaccountNo);
    setBranch(branch);
    setTrustId(trustId);
    setType(type);
  };

  /**
   * Trust Dropdoen Function call
   */

  const TrustDopdown = async () => {
    try {
      const { data } = await Temple_Trust_List();
      if (data.status === 200) {
        setTrustDrop(data.payload.data);
      } else {
        setTrustDrop([]);
      }
    } catch (error) {
      if (error?.response?.data?.status === ERROR_CODE.UNAUTHORIZED) {
        LogOut(history);
      }
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  /**
   * Add Model to click to open model
   */

  const AddModel = () => {
    TrustDopdown();
    setModal(true);
    setToggle(true);
    setError(false);
  };

  /**
   * Edit time get data to call api function call
   */

  const editHandle = async (bankId) => {
    setBankId(bankId);
    TrustDopdown();
    setLoaderShow(!loaderShow);
    try {
      const { data } = await BankMaster_List_Details(bankId);
      if (data.status === 200) {
        setToggle(false);
        setIsAddHandle(false);
        seteditIndex(bankId);
        //ORG EDIT
        let payload = data.payload.data;
        setName(payload.name ? payload.name : null);
        setIsfcCode(payload.ifscCode ? payload.ifscCode : null);
        setAddress(payload.address ? payload.address : null);
        setAccountNo(payload.accountNumber ? payload.accountNumber : null);
        setConfirmAccountNo(
          payload.accountNumber ? payload.accountNumber : null
        );
        setBranch(payload.branch ? payload.branch : null);
        setType(payload.type ? payload.type : null);
        setTrustId(payload.trustId ? payload.trustId : null);
        setLoaderShow(false);
        setModal(true);
      } else {
        setformvalue([]);
      }
    } catch (error) {
      if (error?.response?.data?.status === ERROR_CODE.UNAUTHORIZED) {
        LogOut(history);
      }
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
      setLoaderShow(false);
    }
  };

  /**
   * Add bank master and edit bank master api call
   */
  const onSubmit = async (e) => {
    e.preventDefault();
    if (
      name.length === 0 ||
      accountNo.length === 0 ||
      confirmAaccountNo.length === 0
    ) {
      setError(true);
    } else {
      let formData;
      if (editIndex !== -1 && !togglesubmit) {
        try {
          setLoaderShow(!loaderShow);
          formData = isEmpty({
            ifscCode: isfcCode,
            name,
            accountNumber: accountNo,
            ConfirmAccountNumber: confirmAaccountNo,
            address: address,
            branch,
            trustId: +trustId,
            templeId: +localStorage.getItem("templeId"),
            type: +type,
          });

          const { data } = await Edit_BankMaster_List(formData, bankId);
          if (data.status === 200) {
            toast.success(data.message, {
              autoClose: 1000,
            });
            setModal(false);
            getData();
            EmptyFeild();
          } else {
            FillFeild();
          }
        } catch (error) {
          if (error?.response?.data?.status === ERROR_CODE.UNAUTHORIZED) {
            LogOut(history);
          }
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,
            transition: Zoom,
          });
          setLoaderShow(false);
        }
      } else {
        try {
          setLoaderShow(!loaderShow);
          formData = isEmpty({
            ifscCode: isfcCode,
            name,
            accountNumber: accountNo,
            ConfirmAccountNumber: confirmAaccountNo,
            address: address,
            branch,
            trustId: +trustId,
            templeId: +localStorage.getItem("templeId"),
            type: type,
          });
          const { data } = await Add_BankMaster(formData);
          if (data.status === 200) {
            toast.success(data.message, {
              autoClose: 1000,
              transition: Zoom,
            });
            setModal(false);
            getData();
            EmptyFeild();
          } else {
            FillFeild();
          }
        } catch (error) {
          if (error?.response?.data?.status === ERROR_CODE.UNAUTHORIZED) {
            LogOut(history);
          }
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,
            transition: Zoom,
          });
          setLoaderShow(false);
        }
      }
    }
  };

  /**
   *Show model open and details api call
   */

  const showHandle = async (bankId) => {
    setBankId(bankId);
    setLoaderShow(!loaderShow);
    try {
      const { data } = await BankMaster_List_Details(bankId);
      if (data.status === 200) {
        setShowHanlde(data.payload.data);
        setLoaderShow(false);
        setahoeDataModel(true);
      } else {
        setformvalue([]);
      }
    } catch (error) {
      if (error?.response?.data?.status === ERROR_CODE.UNAUTHORIZED) {
        LogOut(history);
      }
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
      setLoaderShow(false);
    }
  };

  /**
   * Enable and Desable bank master
   */

  const switch_onChange_handle = async (bankId, isActive) => {
    let formData = {
      bankId: bankId,
      isActive: isActive ? false : true,
    };
    try {
      const { data } = await BankMaster_List_Enable_Desable(formData);
      if (data.status === 200) {
        isActive
          ? toast.error(data.message, {
              autoClose: 1000,
              transition: Zoom,
            })
          : toast.success(data.message, {
              autoClose: 1000,
              transition: Zoom,
            });
        setIsSwitchOn(!isSwitchOn);
        let enableLoader = false;
        getData(enableLoader);
      } else {
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
      }
    } catch (error) {
      if (error?.response?.data?.status === ERROR_CODE.UNAUTHORIZED) {
        LogOut(history);
      }
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  /**
   * Shorting Function call
   */

  const sorting = (col) => {
    if (sortOrder === "") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("ASC");
    }
    if (sortOrder === "ASC") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("DSC");
    }
    if (sortOrder === "DSC") {
      setSortBy();
      setSortOrder("");
    }
  };

  /**
   * Search filter function call
   */

  const handleFilter = (e) => {
    if (e.key === "Enter" || (e.key === "Backspace" && filter.length <= 1)) {
      getData();
    }
  };

  /**
   * popup Close function call
   */

  const close = () => {
    setModal(!modal);
    EmptyFeild();
  };

  return (
    <>
      <div>
        <TopLoadingBar progress={progress} setProgress={setProgress} />
        <OrganizationAddModel onSubmit={onSubmit} close={close} error={error} />
        <OrganizationDetailsModel />
        <OrganizationItem
          handleFilter={handleFilter}
          sorting={sorting}
          editHandle={editHandle}
          showHandle={showHandle}
          AddModel={AddModel}
          setPage={setPage}
          setPageSize={setPageSize}
          pageSize={pageSize}
          page={page}
          record={record}
          switch_onChange_handle={switch_onChange_handle}
        />
      </div>
    </>
  );
};
export default Organization;
