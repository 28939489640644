import React, { useContext } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import { TEMPLE_MASTER } from "../../constant";
import TempleContext from "../../context/TempleContext/TempleContext";

const TempleDetailsModel = () => {
  const { ahoeDataModel, setahoeDataModel, showHandle } =
    useContext(TempleContext);

  return (
    <>
      <Modal
        size="lg"
        isOpen={ahoeDataModel}
        toggle={() => setahoeDataModel(!ahoeDataModel)}
      >
        <ModalHeader toggle={() => setahoeDataModel(!ahoeDataModel)}>
          {TEMPLE_MASTER.TEMPLE_MASTER_DETAILS}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col sm="12">
              <Card>
                <CardBody style={{ padding: "0" }}>
                  <Card>
                    <div className="table-responsive">
                      <Table>
                        <tbody>
                          <tr>
                            <th scope="row">{"Temple Name"}</th>
                            <td>{showHandle ? showHandle.templeName : "--"}</td>
                          </tr>
                          <tr>
                            <th scope="row">{"Mobile"}</th>
                            <td>{showHandle ? showHandle.mobile : "--"}</td>
                          </tr>
                          <tr>
                            <th scope="row">{"Email"}</th>
                            <td>{showHandle ? showHandle.email : "--"}</td>
                          </tr>
                          <tr>
                            <th scope="row">{"Pan Card No."}</th>
                            <td>{showHandle ? showHandle.panCardNo : "--"}</td>
                          </tr>
                          <tr>
                            <th scope="row">{"GST No."}</th>
                            <td>{showHandle ? showHandle.gstNumber : "--"}</td>
                          </tr>
                          <tr>
                            <th scope="row">{"City"}</th>
                            <td>{showHandle ? showHandle.city : "--"}</td>
                          </tr>
                          <tr>
                            <th scope="row">{"State"}</th>
                            <td>{showHandle ? showHandle.state : "--"}</td>
                          </tr>
                          <tr>
                            <th scope="row">{"Couuntry"}</th>
                            <td>{showHandle ? showHandle.country : "--"}</td>
                          </tr>
                          <tr>
                            <th scope="row">{"Address"}</th>
                            <td>{showHandle ? showHandle.address : "--"}</td>
                          </tr>
                        </tbody>

                        <CardHeader>{<h3>{"User Details"}</h3>}</CardHeader>
                        <tbody>
                          <tr>
                            <th scope="row">{"Name"}</th>
                            <td>
                              {showHandle.adminUser
                                ? showHandle.adminUser.name
                                : "--"}
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">{"Mobile"}</th>
                            <td>
                              {showHandle.adminUser
                                ? showHandle.adminUser.phone
                                : "--"}
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">{"Email"}</th>
                            <td>
                              {showHandle.adminUser
                                ? showHandle.adminUser.email
                                : "--"}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </Card>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
};

export default TempleDetailsModel;
