import React, { useContext, useEffect, useState } from 'react';
import { Card, CardBody, Col, Form, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import AdminDeshboardContext from '../../../context/AdminDeshboardContext/AdminDeshboardContext';
import { ACCOUNT_TYPE, DONATION_ADD, ERROR_CODE, PaymentDropDown, PaymentDropDownCaseOnly } from '../../../constant';
import DonationUserDatails, { PaymentTypeChackBoxes } from './DonationUserDatails';
import OrgContext from '../../../context/OrgContext/OrgContext';
import LogOut from '../../../utils/logout';
import { Zoom, toast } from 'react-toastify';
import { CreateTrasectionSlip, Show_Slip } from '../../../api/DonationBillApi/DonationCillapi';
import { isEmpty } from '../../../utils/helper';
import { useNavigate } from 'react-router';
import OrganizationContext from '../../../context/OrganizationContext/OrganizationContext';
import moment from 'moment';
import Select from 'react-select';
import { BankMasterList } from '../../../api/Bank Master api/BankMasterAPi';
import { Donation_MasterList } from '../../../api/DonationMasterApi/DonationMasterApi';
import { Add_Donation, Donation_List } from '../../../api/DonationApi/DonationApi';
import NoteContext from '../../../context/NotesContext/NoteContext';

const DonationPopup = ({ getExpanse = null, type = ACCOUNT_TYPE.inward, modalName = 'Donation' }) => {
  const {
    modal,
    setModal,
    accountId,
    setAccountId,
    amount,
    setAmount,
    trustDrop,
    accountDrop,
    bankDrop,
    isIncome,
    paidAmount,
    setPaidAmount,
    setIsIncome,
    setTrustDrop,
    setAccountDrop,
    setBankDrop
  } = useContext(AdminDeshboardContext);
  const history = useNavigate();

  const {
    address,
    setAddress,
    name,
    setName,
    contactNo,
    setContactNo,
    village,
    setVillage,
    pancard,
    setPanCard,
    aadhaarCard,
    setAadhaarCard,
    byHand,
    setByhand,
    setDob,
    dob,
    city,
    setCity,
    state,
    setState,
    country,
    setCountry,
    paymentType,
    setPaymentType,
    personId,
    setPersonaId,
    paymentMode,
    setPaymentMode,
    note,
    setNote,
    transectionNo,
    setTransectionNo,
    setTrustId,
    trustId,
    setSlipData,
    setModel,
    setIsOpen,
    dialCode,
    setIsAksharvashi,
    setContactNoKin,
    setDodDate,
    setDodMonth,
    setdodPaksha,
    isAksharvashi,
    dodMonth,
    dodPaksha,
    dodDate,
    dialCodeKin,
    contactNoKin
  } = useContext(OrgContext);
  const {
    pageSize,
    setPageSize,
    page,
    setPage,
    record,
    setRecord,
    setformvalue,
    sortOrder,
    setSortOrder,
    sortBy,
    setSortBy
  } = useContext(NoteContext);
  const { setLoaderShow, setResponseLoader } = useContext(OrganizationContext);

  const emptyFeilds = () => {
    setPersonaId('');
    setContactNo('');
    setName('');
    setVillage('');
    setPanCard('');
    setAadhaarCard('');
    setAddress('');
    setAccountId('');
    setAmount('');
    setPaymentMode('');
    setNote('');
    setTransectionNo('');
    setByhand('');
    setCity('');
    setState('');
    setCountry('');
    setPaymentType('');
    setDob('');
    setModal(false);
    setIsAksharvashi(false);
    setContactNoKin('');
    setDodDate('');
    setDodMonth('');
    setdodPaksha('');
    setPaidAmount('');
    setIsIncome(false);
  };
  /**
   * get transaction list
   */
  const getData = async () => {
    try {
      let paramsObj = {
        pageNumber: page,
        recordsPerPage: pageSize,
        sortOrder,
        sortBy,
        search: {
          type
        }
      };

      const { data } = await Donation_List(paramsObj);
      if (data.status === 200) {
        setformvalue(data.payload.data);
        setRecord(data.pager.totalRecords);
        setResponseLoader(false);
      } else {
        setformvalue([]);
      }
    } catch (error) {
      if (error?.response?.data?.status === ERROR_CODE.UNAUTHORIZED) {
        LogOut(history);
      }
      setResponseLoader(false);
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom
      });
    }
  };

  const accountDropDown = async (trustId) => {
    let paramsObj = {
      search: { type, trustId: +trustId },
      showAll: true
    };
    try {
      const { data } = await Donation_MasterList(paramsObj);
      if (data.status === 200) {
        setAccountDrop(data.payload.data);
      } else {
        setAccountDrop([]);
      }
    } catch (error) {
      if (error.response.data.status === ERROR_CODE.UNAUTHORIZED) {
        LogOut(history);
      }
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom
      });
    }
  };

  const bankMaster = async (trustId) => {
    let paramObj = {
      search: { trustId: +trustId },
      showAll: true
    };
    try {
      const { data } = await BankMasterList(paramObj);
      if (data.status === 200) {
        setBankDrop(data.payload.data);
      } else {
        setBankDrop([]);
      }
    } catch (error) {
      if (error.response.data.status === ERROR_CODE.UNAUTHORIZED) {
        LogOut(history);
      }
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom
      });
    }
  };

  const ShowSlip = async (slipId) => {
    try {
      const { data } = await Show_Slip(slipId);
      if (data.status === 200) {
        setSlipData(data.payload.data);
        setIsOpen(true);
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom
        });
      }
    } catch (error) {
      if (error.response.data.status === ERROR_CODE.UNAUTHORIZED) {
        LogOut(history);
      }
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom
      });
    }
  };

  const onSubmitHandler = async (formData) => {
    if (isIncome) {
      const { data } = await Add_Donation(formData);
      return { data };
    } else {
      const { data } = await CreateTrasectionSlip(formData);
      return { data };
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoaderShow(true);
    try {
      let newFormData = {
        personId: personId,
        mobile: `${dialCode} ${contactNo}`,
        name,
        village: village,
        panCardNo: pancard,
        aadhaarCard,
        address,
        note,
        byHand: byHand,
        city,
        state,
        country,
        dob: dob ? moment(dob).format('yyyy-MM-DD') : '',
        trustId: +trustId,
        templeId: +localStorage.getItem('templeId'),
        isDeath: isAksharvashi
      };

      if (paidAmount && isIncome) {
        newFormData['bankId'] = paymentMode.value;
        newFormData['paidAmount'] = paidAmount;
        newFormData['paymentType'] = paymentType;
        newFormData['transectionNo'] = transectionNo;
      } else if (isIncome === false) {
        newFormData['bankId'] = paymentMode.value;
        newFormData['transectionNo'] = transectionNo;
        newFormData['paymentType'] = paymentType;
        newFormData['donationArray'] = [{ accountId, amount }];
      }
      if (isIncome) {
        newFormData['accountId'] = accountId;
        newFormData['amount'] = amount;
      }
      let formData = isEmpty(newFormData);
      if (dob) formData.dob = moment(dob).format('yyyy-MM-DD');
      if (dodMonth && dodPaksha && dodDate) formData.dod = `${dodMonth}-${dodPaksha}-${dodDate}`;
      if (dialCodeKin && contactNoKin) formData.kinMobile = `${dialCodeKin} ${contactNoKin}`;

      const { data } = await onSubmitHandler(formData);
      if (data.status === 200) {
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom
        });
        setLoaderShow(false);
        if (isIncome == false) {
          await ShowSlip(data.payload.data.slipId);
          emptyFeilds();
          history(`${process.env.PUBLIC_URL}/Donation/income/Hisab_Kitab`);
        } else {
          getData();
          if (getExpanse !== null) {
            getExpanse();
          }
          emptyFeilds();
        }
      } else {
        setLoaderShow(false);
      }
    } catch (error) {
      if (error.response.data.status === ERROR_CODE.UNAUTHORIZED) {
        LogOut(history);
      }
      setLoaderShow(false);
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom
      });
    }
  };
  const paymentModeChange = (opt) => {
    setPaymentMode(opt);
    if (opt.type === 1) {
      setPaymentType('CASH');
    } else {
      setPaymentType('UPI');
    }
  };

  const handleChangeTrust = (e) => {
    const { value } = e.target;
    setTrustId(value);
    accountDropDown(value);
    bankMaster(value);
  };

  const handleChangeAccount = (e) => {
    const { value } = e.target;
    setAccountId(value);
    const amount = accountDrop.find((elem) => elem.accountId == value);
    setAmount(amount.defaultAmount);
  };
  return (
    <Modal size="xl" isOpen={modal} toggle={() => emptyFeilds()}>
      <ModalHeader toggle={() => emptyFeilds()}>{`Add ${modalName}`}</ModalHeader>
      <ModalBody>
        <Card>
          <CardBody>
            <Form className="needs-validation" onSubmit={(e) => onSubmit(e)}>
              <Row>
                <DonationUserDatails />
                <hr style={{ color: 'cadetblue' }} />
              </Row>
              <Row>
                <Col md="6 mb-4">
                  <Label htmlFor="validationCustom01">{'Trust'}</Label>
                  <select
                    className="form-select"
                    name="orderStatus"
                    value={trustId}
                    onChange={(e) => handleChangeTrust(e)}
                    disabled={isIncome === false}
                  >
                    <option value={''}>--select--</option>
                    {trustDrop.map((elem) => {
                      return (
                        <option value={elem.trustId} key={elem.trustId}>
                          {elem.trustName}
                        </option>
                      );
                    })}
                  </select>
                  <div className="invalid-feedback">{'Please provide a valid ORG Name.'}</div>
                </Col>
              </Row>
              <Row>
                <Col md="6 mb-4">
                  <Label htmlFor="validationCustom01">{DONATION_ADD.DONTION_TYPE}</Label>
                  <select
                    className="form-select"
                    name="orderStatus"
                    value={accountId}
                    onChange={(e) => handleChangeAccount(e)}
                    disabled={isIncome === false}
                  >
                    <option value={''}>--select--</option>
                    {accountDrop.map((elem) => {
                      return (
                        <option value={elem.accountId} key={elem.accountId}>
                          {elem.name}
                        </option>
                      );
                    })}
                  </select>
                  <div className="invalid-feedback">{'Please provide a valid ORG Name.'}</div>
                </Col>
                <Col md="6 mb-4">
                  <Label htmlFor="validationCustom01">{DONATION_ADD.AMOUNT}</Label>
                  <Input
                    className="form-control colorBlack"
                    name="accountId"
                    type="number"
                    id="accountId"
                    placeholder={DONATION_ADD.AMOUNT}
                    onWheel={(e) => e.target.blur()}
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                  />
                  <div className="invalid-feedback">{'Please provide a valid ORG Name.'}</div>
                </Col>
                {isIncome && (
                  <Col md="4 mb-4">
                    <Label htmlFor="validationCustom01">{'Paid Amount'}</Label>
                    <Input
                      className="form-control colorBlack"
                      name="paidAmount"
                      type="number"
                      id="paidAmount"
                      placeholder={'Paid Amount'}
                      onWheel={(e) => e.target.blur()}
                      value={paidAmount}
                      onChange={(e) => setPaidAmount(e.target.value)}
                      disabled={amount == ''}
                    />
                    <div className="invalid-feedback">{'Please provide a valid ORG Name.'}</div>
                  </Col>
                )}
                {((isIncome && paidAmount) || isIncome === false) && (
                  <>
                    {' '}
                    <Col md="4 mb-4">
                      <Label htmlFor="validationCustom01">{DONATION_ADD.PAYMENT_MODE}</Label>
                      <Select
                        aria-labelledby="aria-label"
                        inputId="aria-example-input"
                        name="aria-live-color"
                        onChange={(opt) => {
                          paymentModeChange(opt);
                        }}
                        options={bankDrop.map((elem, i) => {
                          return {
                            value: elem.bankId,
                            label: elem.name,
                            type: elem.type
                          };
                        })}
                        value={paymentMode}
                      />

                      <div className="invalid-feedback">{'Please provide a valid ORG Name.'}</div>
                    </Col>
                    <Col md="4 mb-4">
                      <Label htmlFor="validationCustom01">{DONATION_ADD.TRANSECTION_NO}</Label>
                      <Input
                        className="form-control colorBlack"
                        name="transactionNo"
                        type="text"
                        id="transactionNo"
                        placeholder={DONATION_ADD.TRANSECTION_NO}
                        value={transectionNo}
                        onChange={(e) => setTransectionNo(e.target.value)}
                      />
                      <div className="invalid-feedback">{'Please provide a valid ORG Name.'}</div>
                    </Col>
                  </>
                )}
              </Row>
              <PaymentTypeChackBoxes
                setPaymentType={setPaymentType}
                paymentType={paymentType}
                PaymentDropDown={
                  paymentMode.type == 2 ? PaymentDropDown : paymentMode.type == 1 ? PaymentDropDownCaseOnly : []
                }
              />

              <Row>
                <Col md="12 mb-4">
                  <Label htmlFor="validationCustom01">{DONATION_ADD.NOTE}</Label>
                  <Input
                    className="form-control colorBlack"
                    name="note"
                    type="textarea"
                    id="note"
                    placeholder={DONATION_ADD.NOTE}
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                  />
                  <div className="invalid-feedback">{'Please provide a valid ORG Name.'}</div>
                </Col>
              </Row>
              <Row style={{ paddingTop: '30px' }}>
                <button className="btn btn-primary" type="submit" value="Submit">
                  {'Submit'}
                </button>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </ModalBody>
    </Modal>
  );
};

export default DonationPopup;
