import React, { useContext } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Media,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import { ORGANIZATION } from "../../constant";
import OrganizationContext from "../../context/OrganizationContext/OrganizationContext";

const OrganizationDetailsModel = () => {
  const {
    ahoeDataModel,
    setahoeDataModel,
    userName,
    userPhone,
    userEmail,
    userurl,
    name,
    address,
    phone,
    gstNumber,
    email,
    city,
    state,
  } = useContext(OrganizationContext);
  return (
    <>
      <Modal
        size="lg"
        isOpen={ahoeDataModel}
        toggle={() => setahoeDataModel(!ahoeDataModel)}
      >
        <ModalHeader toggle={() => setahoeDataModel(!ahoeDataModel)}>
          View Organizarion
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col sm="12">
              <Card>
                <CardBody style={{ padding: "0" }}>
                  <Card>
                    <div className="table-responsive">
                      <Table>
                        <CardHeader>
                          <h5>{ORGANIZATION.ORG_DETAILS}</h5>
                        </CardHeader>
                        <tbody>
                          <tr>
                            <th scope="row">{ORGANIZATION.NAME}</th>
                            <td>{name}</td>
                          </tr>
                          <tr>
                            <th scope="row">{ORGANIZATION.ADDRESS}</th>
                            <td>{address}</td>
                          </tr>
                          <tr>
                            <th scope="row">{ORGANIZATION.PHONE}</th>
                            <td>{phone}</td>
                          </tr>
                          <tr>
                            <th scope="row">{ORGANIZATION.GST_NUMBER}</th>
                            <td>{gstNumber}</td>
                          </tr>
                          <tr>
                            <th scope="row">{ORGANIZATION.EMAIL}</th>
                            <td>{email}</td>
                          </tr>
                          <tr>
                            <th scope="row">{ORGANIZATION.CITY}</th>
                            <td>{city}</td>
                          </tr>
                          <tr>
                            <th scope="row">{ORGANIZATION.STATE}</th>
                            <td>{state}</td>
                          </tr>
                        </tbody>
                      </Table>
                      <Table>
                        <CardHeader
                          className="flex-shrink-1"
                          style={{ display: "flex", padding: "0" }}
                        >
                          <th>
                            <Media
                              body
                              alt=""
                              src={userurl}
                              style={{
                                width: "5rem",
                                height: "5rem",
                                borderRadius: "45rem",
                                border: "1px solid #f0f0f0",
                              }}
                              data-intro="This is Profile image"
                            />
                          </th>
                          <h5 style={{ paddingTop: "33px" }}>
                            {ORGANIZATION.USER_DETAILS}
                          </h5>
                        </CardHeader>
                        <tbody>
                          <tr>
                            <th scope="row">{ORGANIZATION.NAME}</th>
                            <td>{userName}</td>
                          </tr>
                          <tr>
                            <th scope="row">{ORGANIZATION.PHONE}</th>
                            <td>{userPhone}</td>
                          </tr>
                          <tr>
                            <th scope="row">{ORGANIZATION.EMAIL}</th>
                            <td>{userEmail}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </Card>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
};

export default OrganizationDetailsModel;
