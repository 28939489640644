import axios from "axios";
import {
  ADD_DONATION_LIST,
  DONATION_DITAILS,
  DONATION_LIST,
  EDIT_DONATION_LIST,
} from "../../constant/API constant";

export const Donation_List = (paramsObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${DONATION_LIST}`, {
    headers: { Authorization: jwt_token },
    params: paramsObj,
  });
};

export const Add_Donation = (formData) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(
    `${process.env.REACT_APP_API_URL}${ADD_DONATION_LIST}`,
    formData,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

export const Donation_List_Details = (accountTransactionId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${DONATION_DITAILS}`, {
    headers: { Authorization: jwt_token },
    params: { accountTransactionId },
  });
};

export const Donation_Edit_List = (formData, accountTransactionId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${EDIT_DONATION_LIST}`,
    formData,
    {
      headers: { Authorization: jwt_token },
      params: { accountTransactionId },
    }
  );
};
