import axios from "axios";
import {
  ADD_MODULE_LIST,
  DELETE_MODULE_LIST,
  DETAILS_MODULE_LIST,
  DITAILS_PRODUCT_LIST,
  EDIT_MODULE_LIST,
  MODULE_LIST,
} from "../../constant/API constant";

export const ModualList = (paramsObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${MODULE_LIST}`, {
    headers: { Authorization: jwt_token },
    params: paramsObj,
  });
};

export const Add_ModualList = (formData) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(
    `${process.env.REACT_APP_API_URL}${ADD_MODULE_LIST}`,
    formData,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

export const Detail_ModualList = (id) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${DETAILS_MODULE_LIST}`, {
    headers: { Authorization: jwt_token },
    params: { moduleId: id },
  });
};

export const Edit_ModualList = (formData) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${EDIT_MODULE_LIST}`,
    formData,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

export const Delete_ModualList = (formData) => {
  const jwt_token = localStorage.getItem("token");
  return axios.delete(`${process.env.REACT_APP_API_URL}${DELETE_MODULE_LIST}`, {
    headers: { Authorization: jwt_token },
    data: formData,
  });
};

export const Detail_ProductList = (uuid) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${DITAILS_PRODUCT_LIST}`, {
    headers: { Authorization: jwt_token },
    params: { uuid },
  });
};
