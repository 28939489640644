import React, { Fragment, useState } from "react";
import { IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const TableMenuItem = (props) => {
  const { params, editHandle, showHandle, StatementRedirect } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const RowOptions = () => {
    return (
      <>
        {params.row.bankId && (
          <>
            <MenuItem
              onClick={() => editHandle(params.row.bankId, handleClose())}
            >
              Edit
            </MenuItem>
            <MenuItem
              onClick={() => showHandle(params.row.bankId, handleClose())}
            >
              View
            </MenuItem>
            <MenuItem
              onClick={() =>
                StatementRedirect(params.row.bankId, handleClose())
              }
            >
              Statement
            </MenuItem>
          </>
        )}
        {params.row.accountId && (
          <>
            <MenuItem
              onClick={() => editHandle(params.row.accountId, handleClose())}
            >
              Edit
            </MenuItem>
            <MenuItem
              onClick={() => showHandle(params.row.accountId, handleClose())}
            >
              View
            </MenuItem>
            <MenuItem
              onClick={() =>
                StatementRedirect(params.row.accountId, handleClose())
              }
            >
              Statement
            </MenuItem>
          </>
        )}
        {params.row.personId && (
          <>
            <MenuItem
              onClick={() => editHandle(params.row.personId, handleClose())}
            >
              Edit
            </MenuItem>
            <MenuItem
              onClick={() => showHandle(params.row.personId, handleClose())}
            >
              View
            </MenuItem>
            <MenuItem
              onClick={() =>
                StatementRedirect(params.row.personId, handleClose())
              }
            >
              Statement
            </MenuItem>
          </>
        )}
        {params.row.templeId && params.row.templeName && (
          <>
            <MenuItem
              onClick={() => editHandle(params.row.templeId, handleClose())}
            >
              Edit
            </MenuItem>
            <MenuItem
              onClick={() => showHandle(params.row.templeId, handleClose())}
            >
              View
            </MenuItem>
          </>
        )}
        {params.row.trustId && params.row.trustName && (
          <>
            <MenuItem
              onClick={() => editHandle(params.row.trustId, handleClose())}
            >
              Edit
            </MenuItem>
            <MenuItem
              onClick={() => showHandle(params.row.trustId, handleClose())}
            >
              View
            </MenuItem>
          </>
        )}
      </>
    );
  };

  return (
    <Fragment>
      <div>
        <IconButton
          id="basic-button"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          {/* {params.row.bankId && ( */}
          {/* <MenuItem onClick={() => editHandle(params.row.bankId)}>
                Edit
              </MenuItem>
              <MenuItem onClick={() => showHandle(params.row.bankId)}>
                View
              </MenuItem>
              <MenuItem onClick={() => StatementRedirect(params.row.bankId)}>
                Statement
              </MenuItem> */}
          {/* )} */}
          <RowOptions />
        </Menu>
      </div>
    </Fragment>
  );
};

export default TableMenuItem;
