import React, { useContext, useEffect, useState } from 'react';
import { Col, Input, Label, Row } from 'reactstrap';
import { DateFormateChange, debouncing, maxLengthCheck } from '../../../utils/helper';
import { DONATION_ADD, ERROR_CODE } from '../../../constant';
import OrgContext from '../../../context/OrgContext/OrgContext';
import { Donation_bill_Details } from '../../../api/DonationBillApi/DonationCillapi';
import LogOut from '../../../utils/logout';
import { Zoom, toast } from 'react-toastify';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { DialCodeList } from '../../../api/DialCodeApi/DialCodeApi';
import { GujaratiDays } from '../../../api/GujaratiMonthApi/GujaratiDayApi';
import { GujaratiMonths } from '../../../api/GujaratiMonthApi/GujaratiMonthApi';
import { DonarList } from '../../../api/Donar Api/DonarApi';
import TithiDropDown from '../../../common/TithiDropDown';
import { useNavigate } from 'react-router';

const DonationUserDatails = ({ isHide, isEdit = false }) => {
  const {
    address,
    setAddress,
    name,
    setName,
    contactNo,
    setContactNo,
    village,
    setVillage,
    pancard,
    setPanCard,
    aadhaarCard,
    setAadhaarCard,
    byHand,
    setByhand,
    setDob,
    dob,
    setCity,
    setState,
    setCountry,
    setPersonaId,
    dialCode,
    setDialCode,
    isAksharvashi,
    setIsAksharvashi,
    dialCodeJson,
    setDialCodeJson,
    persons,
    setPersons,
    setGujaratiDate,
    setGujaratiMonth,
    dialCodeKin,
    setDialCodeKin,
    contactNoKin,
    setContactNoKin,
    setDodMonth,
    setdodPaksha,
    setDodDate,
    isWpNumber,
    setIsWpNumber
  } = useContext(OrgContext);
  const emptyFeilds = () => {
    setVillage('');
    setContactNo('');
    setPanCard('');
    setAadhaarCard('');
    setCity('');
    setState('');
    setCountry('');
    setAddress('');
    setDob('');
    setPersonaId('');
    setName('');
    setPersons([]);
    setIsAksharvashi(false);
  };
  const history = useNavigate();

  const showHandle = async ({ mobile, personId, isContact }) => {
    try {
      let paramObj = {};
      if (personId) {
        paramObj['personId'] = personId;
      } else {
        paramObj['mobile'] = mobile;
      }
      const { data } = await Donation_bill_Details(paramObj);
      if (data.status === 200) {
        let payload = data.payload.data;
        setIsWpNumber(
          mobile?.length || (payload?.mobile && payload?.mobile?.split(' ')[1]?.length) ? data.payload?.isWpNumber : ''
        );
        setPersonaId(payload && payload.personId ? payload.personId : '');
        setName(payload && payload.name ? payload.name : '');
        setVillage(payload && payload.village ? payload.village : '');
        setPanCard(payload && payload.panCardNo ? payload.panCardNo : '');
        setAadhaarCard(payload && payload.aadhaarCard ? payload.aadhaarCard : '');
        if (isContact) {
          setContactNo(payload?.mobile ? payload?.mobile?.split(' ')?.pop() : '');
        }
        setDob(payload && payload.dob ? new Date(DateFormateChange(payload.dob)) : '');
        setCity(payload && payload.city ? payload.city : '');
        setState(payload && payload.state ? payload.state : '');
        setCountry(payload && payload.country ? payload.country : '');
        setAddress(payload && payload.address ? payload.address : '');
        if (mobile) {
          setPersons([]);
        }
        setIsAksharvashi(payload && payload.isDeath ? payload.isDeath : false);
        if (payload && payload.isDeath) {
          const [month, paksha, day] = payload.dod.split('-');
          setDodMonth(month);
          setdodPaksha(paksha);
          setDodDate(day);
        }
      } else {
      }
    } catch (error) {
      if (error?.response?.data?.status === ERROR_CODE.UNAUTHORIZED) {
        LogOut(history);
      }
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom
      });
    }
  };
  useEffect(() => {
    setDialCodeJson(DialCodeList());
    setGujaratiDate(GujaratiDays());
    setGujaratiMonth(GujaratiMonths());
  }, []);

  const handleContactChange = async (e) => {
    setContactNo(e.target.value);
    if (!isEdit && e.target.value.length === 10) {
      let joinValue = `${dialCode} ${e.target.value}`;
      showHandle({ mobile: joinValue, isContact: false });
    } else if (!isEdit && e.target.value.length === 0) {
      setIsWpNumber('');
      emptyFeilds();
    }
  };
  const handlePersonClick = ({ name, personId, mobile }) => {
    showHandle({ personId: personId, isContact: true });
    setPersons([]);
  };

  const getData = async (person) => {
    try {
      let paramObj = {
        search: { name: person }
      };
      const { data } = await DonarList(paramObj);
      if (data.status === 200) {
        setPersons(data.payload.data);
      } else {
        setPersons([]);
      }
    } catch (error) {
      if (error?.response?.data?.status === ERROR_CODE.UNAUTHORIZED) {
        LogOut(history);
      }
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom
      });
    }
  };

  const handleChangeNameSearch = (e) => {
    let value = e.target.value;
    setName(value);
    if (!isEdit && e.target.value) {
      debouncing({ callback: getData, args: value, time: 700 });
    } else if (!isEdit) {
      emptyFeilds();
      setIsWpNumber('');
    }
  };

  const handleAksharvashi = (e) => {
    setIsAksharvashi(e.target.checked);
  };

  return (
    <>
      <Col md="4 mb-4">
        <Label htmlFor="validationCustom01" className={'d-flex gap-2'}>
          {DONATION_ADD.CONTACT_NO}{' '}
          <p style={{ color: isWpNumber ? 'green' : 'red' }}>
            {isWpNumber
              ? '(Number is registered on WhatsApp)'
              : isWpNumber === false
              ? '(Number is not registered on WhatsApp)'
              : ''}
          </p>
        </Label>
        <div className="d-flex">
          <select
            className="form-control"
            style={{ width: '67px' }}
            onChange={(e) => setDialCode(e.target.value)}
            value={dialCode}
          >
            <option value={''}>{'-- select --'}</option>{' '}
            {dialCodeJson.map((elem) => {
              return <option value={elem.code}>{elem.country}</option>;
            })}
          </select>
          <Input
            className="form-control"
            name="mobile"
            type="number"
            placeholder={DONATION_ADD.CONTACT_NO}
            id="contact-no"
            min={0}
            maxLength={10}
            onInput={maxLengthCheck}
            onWheel={(e) => e.target.blur()}
            value={contactNo}
            onChange={(e) => handleContactChange(e)}
          />
          <div className="invalid-feedback">{'Please provide a valid ORG Name.'}</div>
        </div>
      </Col>
      <Col md="4 mb-4">
        <div className="d-flex ">
          <Label htmlFor="validationCustom01">{DONATION_ADD.NAME}</Label>
          <div className="d-flex align-items-center px-3 pb-2">
            <input type="checkbox" className="form-checkbox" onChange={handleAksharvashi} checked={isAksharvashi} />
            <p className="px-2"> (અક્ષર નિવાસી)</p>
          </div>
        </div>
        <div className="searchable-input-container">
          <Input
            className="form-control"
            name="name"
            type="text"
            placeholder={DONATION_ADD.NAME}
            id="validationCustom01"
            value={name}
            onChange={(e) => {
              handleChangeNameSearch(e);
            }}
            autocomplete="off"
            required
          />
          <ul className="suggestions-list" style={{ display: persons?.length ? 'block' : 'none' }}>
            {persons.map((person, index) => (
              <li
                key={index}
                onClick={() =>
                  handlePersonClick({
                    name: person.name,
                    personId: person.personId,
                    mobile: person.mobile
                  })
                }
              >
                {person.name}
              </li>
            ))}
          </ul>
        </div>
        <div className="invalid-feedback">{'Please provide a valid ORG Name.'}</div>
      </Col>
      <Col md="4 mb-4">
        <Label htmlFor="validationCustom01">{DONATION_ADD.VILLAGE}</Label>
        <Input
          className="form-control"
          name="village"
          type="text"
          placeholder={DONATION_ADD.VILLAGE}
          id="validationCustom01"
          value={village}
          onChange={(e) => setVillage(e.target.value)}
        />
        <div className="invalid-feedback">{'Please provide a valid ORG Name.'}</div>
      </Col>
      <Col md="4 mb-4">
        <Label htmlFor="validationCustom01">{DONATION_ADD.RELATIVE_NO}</Label>
        <div className="d-flex">
          <select
            className="form-control"
            style={{ width: '67px' }}
            onChange={(e) => setDialCodeKin(e.target.value)}
            value={dialCodeKin}
          >
            <option value={''}>{'-- select --'}</option>{' '}
            {dialCodeJson.map((elem) => {
              return <option value={elem.code}>{elem.country}</option>;
            })}
          </select>
          <Input
            className="form-control"
            name="mobile"
            type="number"
            placeholder={DONATION_ADD.CONTACT_NO}
            id="validationCustom01"
            min={0}
            maxLength={10}
            onInput={maxLengthCheck}
            value={contactNoKin}
            onChange={(e) => setContactNoKin(e.target.value)}
            onWheel={(e) => e.target.blur()}
          />
          <div className="invalid-feedback">{'Please provide a valid ORG Name.'}</div>
        </div>
      </Col>
      <Col md="3 mb-4">
        <Label htmlFor="validationCustom01">{DONATION_ADD.PANCARD}</Label>
        <Input
          className="form-control"
          name="panCardNo"
          placeholder={DONATION_ADD.PANCARD}
          type="text"
          min={0}
          maxLength={10}
          onInput={maxLengthCheck}
          value={pancard}
          onChange={(e) => setPanCard(e.target.value)}
          id="panCardNo"
        />
        <div className="invalid-feedback">{'Please provide a valid ORG Name.'}</div>
      </Col>
      <Col md="3 mb-4">
        <Label htmlFor="validationCustom01">{DONATION_ADD.AADHAAR_CARD}</Label>
        <Input
          className="form-control colorBlack"
          name="aadhaarCard"
          type="number"
          placeholder={DONATION_ADD.AADHAAR_CARD}
          id="aadhaarCard"
          min={0}
          maxLength={12}
          onInput={maxLengthCheck}
          value={aadhaarCard}
          onChange={(e) => setAadhaarCard(e.target.value)}
          onWheel={(e) => e.target.blur()}
        />
        <div className="invalid-feedback">{'Please provide a valid ORG Name.'}</div>
      </Col>
      {!isHide && (
        <Col md="3 mb-4">
          <Label htmlFor="validationCustom01">{DONATION_ADD.BY_HAND}</Label>
          <Input
            className="form-control colorBlack"
            name="byHand"
            type="text"
            placeholder={DONATION_ADD.BY_HAND}
            id="byHand"
            value={byHand}
            onChange={(e) => setByhand(e.target.value)}
          />
          <div className="invalid-feedback">{'Please provide a valid ORG Name.'}</div>
        </Col>
      )}
      {isAksharvashi ? (
        <Col md={isHide ? '4 mb-4' : '3 mb-4'}>
          <Label htmlFor="validationCustom01">{DONATION_ADD.DOD}</Label>
          <TithiDropDown />
        </Col>
      ) : (
        <Col md="3 mb-4">
          <Label htmlFor="validationCustom01">{DONATION_ADD.DOB}</Label>
          <DatePicker
            className="form-control colorBlack"
            selected={dob}
            onChange={(date) => setDob(date)}
            dateFormat="dd-MM-yyyy"
            placeholderText="DD-MM-YYYY"
          />
          {/* <Input
          className="form-control colorBlack"
          name="date"
          type="date"
          placeholder={DONATION_ADD.DOB}
          id="byhand"
          value={dob}
          onChange={(e) => setDob(e.target.value)}
        /> */}
          <div className="invalid-feedback">{'Please provide a valid ORG Name.'}</div>
        </Col>
      )}

      <Col md="12 mb-4">
        <Label htmlFor="validationCustom01">{DONATION_ADD.ADDRESS}</Label>
        <Input
          className="form-control colorBlack"
          name="address"
          type="textarea"
          id="address"
          placeholder={DONATION_ADD.ADDRESS}
          value={address}
          onChange={(e) => setAddress(e.target.value)}
        />
        <div className="invalid-feedback">{'Please provide a valid ORG Name.'}</div>
      </Col>
    </>
  );
};

export default DonationUserDatails;

export const PaymentTypeChackBoxes = ({ setPaymentType, paymentType, PaymentDropDown }) => {
  return (
    <Row>
      <Col
        md="12 mb-4"
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '60px',
          marginTop: '15px'
        }}
      >
        <>
          {PaymentDropDown.map((elem) => {
            return (
              <Label className="d-block">
                <Input
                  className="radio_animated"
                  // id={`edo-ani-${elem.value}`}
                  type="radio"
                  name="rdo-ani"
                  value={elem.label}
                  checked={paymentType && paymentType === elem.label}
                  onChange={(e) => setPaymentType(elem.label)}
                  required
                />
                {elem.label}
              </Label>
            );
          })}
        </>
      </Col>
    </Row>
  );
};
