import axios from "axios";
import {
  GET_INCOME_EXPANSE_ANALYSIS,
  SEND_WHATSAPP_MSG,
  SUPER_ADMIN_DESHBOARD,
} from "../../constant/API constant";

export const Stock_Deshboard = () => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${SUPER_ADMIN_DESHBOARD}`, {
    method: "GET",
    headers: { Authorization: jwt_token },
  });
};

export const get_Income_Expanse_Analysis = (formData) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(
    `${process.env.REACT_APP_API_URL}${GET_INCOME_EXPANSE_ANALYSIS}`,
    {
      method: "GET",
      headers: { Authorization: jwt_token },
      params: formData,
    }
  );
};

export const sendWhatsAppMessage = ({ personIds }) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${SEND_WHATSAPP_MSG}`,
    { personIds: personIds },
    {
      headers: { Authorization: jwt_token },
    }
  );
};
