import axios from "axios";
import {
  ADD_ORDER_LIST,
  DETAIL_ORDER_LIST,
  EDIT_ORDER_LIST,
  ORDER_LIST,
  PRODUCT_LIST,
  SUPPLIAR_LIST,
} from "../../constant/API constant";

export const Order_List = (paramsObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${ORDER_LIST}`, {
    headers: { Authorization: jwt_token },
    params: paramsObj,
  });
};

export const SuppliarList = (paramsObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${SUPPLIAR_LIST}`, {
    headers: { Authorization: jwt_token },
    params: paramsObj,
  });
};

export const ProductList = (paramsObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${PRODUCT_LIST}`, {
    headers: { Authorization: jwt_token },
    params: paramsObj,
  });
};

export const Add_Order_List = (formData) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(
    `${process.env.REACT_APP_API_URL}${ADD_ORDER_LIST}`,
    formData,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

export const Detail_Order_List = (uuid) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${DETAIL_ORDER_LIST}`, {
    headers: { Authorization: jwt_token },
    params: { uuid },
  });
};

export const Edit_Order_List = (formData, uuid) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${EDIT_ORDER_LIST}`,
    formData,
    {
      headers: { Authorization: jwt_token },
      params: { uuid },
    }
  );
};
