import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { Fragment, useContext } from "react";
import { Plus } from "react-feather";
import Switch from "react-js-switch";
import {
  Card,
  CardHeader,
  Col,
  Container,
  Input,
  Row,
  Table,
} from "reactstrap";
import { BANK_MASTER, Style } from "../../constant";
import OrgContext from "../../context/OrgContext/OrgContext";
import DataNotFound from "../../DataNoteFound/DataNotFound";
import Breadcrumbs from "../../layout/breadcrumb";
import { useNavigate } from "react-router";
import { classes } from "../../data/layouts";
import DonationMasterContext from "../../context/DonatoinMasterContext/DonationMasterContext";
import TableMenuItem from "../../utils/TableMenuItem";
import ProductContext from "../../context/ProductContext/ProductContext";
import OrganizationContext from "../../context/OrganizationContext/OrganizationContext";
import TableSkeleton from "../../loader/TableSkeleton";
import { MuiTabelStyle } from "../../utils/helper";

const OrganizationItem = (props) => {
  const {
    editHandle,
    showHandle,
    sorting,
    AddModel,
    setPage,
    setPageSize,
    pageSize,
    page,
    record,
    switch_onChange_handle,
    handleFilter,
  } = props;
  const { setpageNumber, setAccountId } = useContext(DonationMasterContext);
  const { setPersonId } = useContext(ProductContext);
  const {
    formvalue,
    setBankId,
    filter,
    setFilter,
    setColumnFilter,
    setColumnName,
  } = useContext(OrgContext);
  const { responseLoader } = useContext(OrganizationContext);
  const history = useNavigate();
  const defaultLayoutObj = classes.find(
    (item) => Object.values(item).pop(1) === "compact-wrapper"
  );
  const layout =
    localStorage.getItem("layout") || Object.keys(defaultLayoutObj).pop();
  const StatementRedirect = (bankId) => {
    setAccountId("");
    setPersonId("");
    history(`/Statement/Statement/${bankId}/bank/${layout}`);
    setBankId(bankId);
    setpageNumber(1);
  };
  const defaultColumns = [
    {
      flex: 0.1,
      minWidth: 250,
      field: "name",
      headerName: "Name",
      renderCell: (params) => (params.row.name ? params.row.name : "--"),
    },
    {
      flex: 0.1,
      minWidth: 250,
      field: "accountNumber",
      headerName: "Account",
      renderCell: (params) =>
        params.row.accountNumber ? params.row.accountNumber : "--",
    },
    {
      flex: 0.1,
      minWidth: 250,
      field: "balance",
      headerName: "Balance",
      renderCell: (params) => (params.row.balance ? params.row.balance : "--"),
    },
    {
      flex: 0.1,
      minWidth: 250,
      field: "address",
      headerName: "Address",
      renderCell: (params) => (params.row.address ? params.row.address : "--"),
    },
    {
      flex: 0.1,
      minWidth: 130,
      field: "branch",
      headerName: "Branch",
      renderCell: (params) => (params.row.branch ? params.row.branch : "--"),
    },
    {
      flex: 0.1,
      minWidth: 100,
      field: "ifscCode",
      headerName: "IFSC",
      renderCell: (params) =>
        params.row.ifscCode ? params.row.ifscCode : "--",
    },
    {
      flex: 0.1,
      minWidth: 80,
      field: "isActive",
      headerName: "Status",
      renderCell: (params) => (
        <Switch
          value={params.row.isActive ? true : false}
          onChange={() =>
            switch_onChange_handle(params.row.bankId, params.row.isActive)
          }
        />
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      renderCell: (params) => (
        <>
          <TableMenuItem
            params={params}
            editHandle={editHandle}
            showHandle={showHandle}
            StatementRedirect={StatementRedirect}
          />
        </>
      ),
    },
  ];

  return (
    <>
      <Fragment>
        <Breadcrumbs parent={BANK_MASTER.BANK_MASTER_PERENT} />
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <h3>{BANK_MASTER.BANK_MASTER_PERENT}</h3>
                  <div
                    aria-label="add"
                    className="btn btn-primary"
                    onClick={AddModel}
                    style={Style}
                  >
                    <Plus />
                  </div>
                </CardHeader>
                <Table>
                  <thead>
                    <tr>
                      <th>
                        <Row>
                          <Col md="2 mb-2">
                            <Input
                              className="form-control no-outline"
                              type="text"
                              placeholder="Bank Search..."
                              value={filter}
                              onKeyDown={(e) => handleFilter(e)}
                              onChange={(e) => setFilter(e.target.value)}
                            />
                          </Col>
                        </Row>
                      </th>
                    </tr>
                  </thead>
                </Table>
                <Box
                  sx={{
                    "& .even": {
                      backgroundColor: "#f1f1f1",
                    },
                    "& .odd": {
                      backgroundColor: "white",
                    },
                  }}
                >
                  {responseLoader === true ? (
                    <TableSkeleton />
                  ) : formvalue.length ? (
                    <DataGrid
                      sx={MuiTabelStyle}
                      autoHeight
                      pagination
                      rows={formvalue}
                      rowCount={record}
                      pageSize={pageSize}
                      getRowId={(row) => row.bankId}
                      columns={defaultColumns}
                      page={page - 1}
                      rowsPerPageOptions={[7, 10, 25, 50]}
                      onPageSizeChange={(newPageSize) =>
                        setPageSize(newPageSize)
                      }
                      onPageChange={(newPageNo) => setPage(newPageNo + 1)}
                      paginationMode="server"
                      disableSelectionOnClick
                      checkboxSelection={false}
                      sortingMode="server"
                      onSortModelChange={sorting}
                      getRowClassName={(params) =>
                        params.indexRelativeToCurrentPage % 2 === 0
                          ? "even"
                          : "odd"
                      }
                      filterMode="server"
                      onFilterModelChange={(newValue) => {
                        setColumnName(newValue.items[0].columnField);
                        setColumnFilter(newValue.items[0].value);
                      }}
                    />
                  ) : (
                    <DataNotFound />
                  )}
                </Box>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fragment>
    </>
  );
};

export default OrganizationItem;
