import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";

const StatisButtons = (props) => {
  const { click, name, xl = 2 } = props;
  return (
    <Col
      xl={`${xl} xl-50 box-col-6`}
      md="6"
      onClick={() => click()}
      style={{ cursor: "pointer" }}
    >
      <Card className="ecommerce-widget">
        <CardBody className="support-ticket-font" style={{ padding: "10px" }}>
          <Row style={{ flexDirection: "column" }}>
            {/* <div> */}
            <p
              className="total-num counter"
              style={{
                fontSize: "12px",
                fontWeight: 600,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                textAlign: "center",
              }}
            >
              {name}
            </p>
            {/* </div> */}
            <div>
              <div>
                <ul>
                  <li style={{ textAlign: "center", paddingTop: "12px" }}>
                    <span className="product-stts txt-success ms-2 margin-zero">
                      <button className="btn btn-success">+</button>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};

export default StatisButtons;
