import moment from "moment";
import React, { useContext, useState } from "react";
import { Download } from "react-feather";
import { Button, Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { calculate_age, getFormattedDate, isEmpty } from "../../utils/helper";
import { ERROR_CODE, RoleAccess } from "../../constant";
import OrganizationAddModel from "../DonarMaster/donarAddModel";
import ProductContext from "../../context/ProductContext/ProductContext";
import OrganizationContext from "../../context/OrganizationContext/OrganizationContext";
import LogOut from "../../utils/logout";
import { Zoom, toast } from "react-toastify";
import { Add_Donar } from "../../api/Donar Api/DonarApi";
import { useNavigate } from "react-router";
import { classes } from "../../data/layouts";
import TithiDropDown from "../../common/TithiDropDown";

const DeathDateDashboard = (props) => {
  const { date, setTodayDate, setCurrentMonth, showBirthdayTemplate } = props;
  const { loaderShow, setLoaderShow } = useContext(OrganizationContext);
  const history = useNavigate();
  const defaultLayoutObj = classes.find(
    (item) => Object.values(item).pop(1) === "compact-wrapper"
  );
  const layout =
    localStorage.getItem("layout") || Object.keys(defaultLayoutObj).pop();

  const {
    setModal,
    setToggle,
    modal,
    setName,
    name,
    contact,
    setContact,
    village,
    setVillage,
    address,
    setAddress,
    pancard,
    setPancard,
    adharcard,
    setAdharcard,
    setDate,
  } = useContext(ProductContext);
  const [error, setError] = useState(false);

  const emptyFeild = () => {
    setAddress(null);
    setContact(null);
    setName(null);
    setVillage(null);
    setAdharcard(null);
    setPancard(null);
    setAddress(null);
    setDate(null);
    setLoaderShow(false);
    setError(false);
  };

  /**
   * submit to edit model and add model and edit and add api call to function call
   */
  const onSubmit = async (e) => {
    e.preventDefault();
    if (name.length === 0 || contact.length === 0) {
      setError(true);
    } else {
      setLoaderShow(!loaderShow);
      let formData;
      formData = isEmpty({
        name: name,
        mobile: contact,
        village,
        panCardNo: pancard,
        aadhaarCard: adharcard,
        address: address,
        dob: moment(date).format("yyyy-MM-DD"),
      });

      try {
        const { data } = await Add_Donar(formData);
        if (data.status === 200) {
          toast.success(data.message, {
            autoClose: 1000,
            transition: Zoom,
          });
          setModal(false);
          history(`/DonarMaster/donar/${layout}`);
          emptyFeild();
        } else {
        }
      } catch (error) {
        if (error?.response?.data?.status === ERROR_CODE.UNAUTHORIZED) {
           LogOut(history);
        }
        toast.error(error?.response?.data?.message, {
          autoClose: 1000,
          transition: Zoom,
        });
        setLoaderShow(false);
      }
    }
  };

  /**
   * change month select value
   * @param {event} e
   */
  const handleChange = (e) => {
    const { value } = e.target;
    if (value === "Today") {
      setTodayDate(moment().format("DD"));
    } else if (value === "Tomorrow") {
      setTodayDate(moment().add("d", 1).format("DD"));
    } else if (value === "Yesterday") {
      setTodayDate(moment().subtract("d", 1).format("DD"));
    } else if (value === "Select") {
      setCurrentMonth(moment().format("MM"));
      setTodayDate("");
    }
  };

  // const message = "Many Many Happy Returns Of The Day";

  const message = (name) => {
    return `પ. ભ. શ્રી ${name} જન્મદિવસ ની ખુબ ખુબ શુભકામના`;
  };

  const handleSendMessage = (mobile, name) => {
    window.open(
      `https://wa.me/${mobile.replace(/\s/g, "")}?text=${message(name)}`,
      "_blank",
      "width=1000,height=600,menubar=no,toolbar=no"
    );
  };

  /**
   * donar popup close
   */
  const close = () => {
    emptyFeild();
    setModal(!modal);
  };
  /**
   * open to add model frction call
   */

  const AddModel = () => {
    setModal(true);
    setToggle(true);
  };

  return (
    <>
      {+localStorage.getItem("roleId") === RoleAccess.EVENT_COORDINATOR && (
        <div className="pb-4 text-end">
          <Button onClick={() => AddModel()}>Add Birthday Profile </Button>
        </div>
      )}
      <Col
        xl={`${
          +localStorage.getItem("roleId") === RoleAccess.EVENT_COORDINATOR
            ? "12"
            : "6"
        } xl-50`}
        className="appointment-sec box-col-6"
      >
        <Row>
          <Col xl="12" className="appointment">
            <Card>
              <CardHeader className="card-no-border">
                <div className="d-flex justify-content-between">
                  <h5 className="m-0">{"Death (અક્ષર નિવાસી)"}</h5>
                  <TithiDropDown deathBoard={true} />
                </div>
              </CardHeader>
              <CardBody
                className="pt-0"
                style={{
                  height: "26rem",
                  overflow: "hidden",
                  overflowY: "scroll",
                }}
              >
                <div className="appointment-table table-responsive">
                  <table className="table table-bordernone">
                    <tbody>
                      <tr>
                        <th></th>
                        <th>Name</th>
                        <th>Relative No</th>
                        <th>Death Day(અક્ષર નિવાસ)</th>
                      </tr>
                      {date.map((elem) => {
                        return (
                          <tr style={{ background: "white" }}>
                            <td
                              style={{ paddingTop: "12px", paddingLeft: "2px" }}
                            >
                              <img
                                className="img-fluid img-40 rounded-circle mb-3"
                                src={require("../../assets/images/WhatsApp Image 2023-03-29 at 2.39.08 PM (2).jpeg")}
                                alt=""
                              />
                              {/* <div className="status-circle bg-primary"></div> */}
                            </td>
                            <td
                              className="img-content-box"
                              style={{ paddingTop: "12px" }}
                            >
                              <span className="d-block">{elem?.name}</span>
                              <span className="font-roboto">
                                {elem?.mobile}
                              </span>
                            </td>
                            <td style={{ paddingTop: "12px" }}>
                              {elem?.kinMobile}
                            </td>
                            <td style={{ paddingTop: "12px" }}>
                              {elem?.dod ? getFormattedDate(elem?.dod) : "--"}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Col>
      <OrganizationAddModel
        onSubmit={onSubmit}
        error={error}
        close={close}
        modal={modal}
      />
    </>
  );
};

export default DeathDateDashboard;
