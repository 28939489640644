import { Box, IconButton, Tooltip } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React, { Fragment, useContext } from 'react';
import { Download, Edit, Plus } from 'react-feather';

import { Card, CardHeader, Col, Container, Input, Row, Table } from 'reactstrap';
import { DONATION, ERROR_CODE, isWhatsAppPermission, PAYMENT_SLIP_STATUS, Style } from '../../constant';
import OrgContext from '../../context/OrgContext/OrgContext';
import DataNotFound from '../../DataNoteFound/DataNotFound';

import Breadcrumbs from '../../layout/breadcrumb';
import moment from 'moment';
import { Show_Slip } from '../../api/DonationBillApi/DonationCillapi';
import { toast, Zoom } from 'react-toastify';
import SlipDownloadeTemplate from '../SlipDownloadeTemplate/SlipDownloadeTemplate';
import { useNavigate, useParams } from 'react-router';
import { classes } from '../../data/layouts';
import Actions from '../../utils/Actions';
import OrganizationContext from '../../context/OrganizationContext/OrganizationContext';
import TableSkeleton from '../../loader/TableSkeleton';
import { DateFormateChange, inWords, MuiTabelStyle, randomStr } from '../../utils/helper';
import LogOut from '../../utils/logout';
import SlipDownloadeTemplatePrint from '../SlipDownloadeTemplate/SlipDownloadeTemplatePrint';
import { Print, Send } from '@material-ui/icons';
import SlipLandscapePrint from '../SlipDownloadeTemplate/SlipLandscapePrint';
import { Slip1, Slip2 } from './Slip';

const OrganizationItem = (props) => {
  const { sorting, setPage, setPageSize, pageSize, page, record, handleStatusChange, deleteDoantionSLip } = props;
  const {
    formvalueSlip,
    setModel,
    isOpen,
    setAddress,
    setName,
    setContactNo,
    setVillage,
    setPanCard,
    setAadhaarCard,
    setByhand,
    setDob,
    setCity,
    setState,
    setCountry,
    setPaymentType,
    setPersonaId,
    setPaymentMode,
    setNote,
    setTransectionNo,
    setSlipId,
    setSlipData,
    setTrustId,
    setDialCode,
    setIsOpen,
    setFeilds,
    setIsEdit,
    setIsAksharvashi,
    setDodMonth,
    setDodDate,
    setdodPaksha,
    setContactNoKin,
    setIdForOtp,
    slipIdFilter,
    setSlipIdFilter
  } = useContext(OrgContext);
  const params = useParams();
  const { responseLoader, loaderShow, setLoaderShow } = useContext(OrganizationContext);

  const handlePrintSlip = (slipData) => {
    // setTimeout(() => {
    document.getElementById('donationReceipt').innerHTML =
      +localStorage.getItem('templeId') === 47
        ? `${Slip2({
            slipData
          })}`
        : `${Slip1({
            slipData
          })}`;
    window.print();
    // }, 300);
  };

  const ShowSlip = async (slipId, openCondition, isResent) => {
    if (isResent && !window.confirm('Are you sure you want to resend ?')) {
      return false;
    }
    setLoaderShow(!loaderShow);
    try {
      const { data } = await Show_Slip(slipId, isResent);
      if (data.status === 200) {
        setSlipData(data.payload.data);
        setLoaderShow(false);
        if (openCondition === 'popupSlip') {
          setModel(true);
        } else if (openCondition === 'downloadSlip') {
          handlePrintSlip(data.payload.data);
        }

        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom
        });
      }
    } catch (error) {
      if (error?.response?.data?.status === ERROR_CODE.UNAUTHORIZED) {
        LogOut(history);
      }
      toast.error(error?.response?.data?.message || error?.message, {
        autoClose: 1000,
        transition: Zoom
      });
      setLoaderShow(false);
    }
  };

  const EditSlip = async (slipId) => {
    setIsAksharvashi(true);
    setLoaderShow(!loaderShow);
    try {
      const { data } = await Show_Slip(slipId);
      if (data.status === 200) {
        const payload = data.payload.data;
        setIsEdit(true);
        setSlipId(payload?.slipId);
        setContactNoKin(
          payload?.transactionDetails[0]?.accountTransactionDetail?.personDetail?.kinMobile?.split(' ')[1]
        );
        setDodMonth(payload?.transactionDetails[0]?.accountTransactionDetail?.personDetail?.dod?.split('-')[0]);
        setdodPaksha(payload?.transactionDetails[0]?.accountTransactionDetail?.personDetail?.dod?.split('-')[1]);
        setDodDate(payload?.transactionDetails[0]?.accountTransactionDetail?.personDetail?.dod?.split('-')[2]);
        setPersonaId(payload?.transactionDetails[0]?.accountTransactionDetail?.personDetail?.personId);
        setContactNo(payload?.transactionDetails[0]?.accountTransactionDetail?.personDetail?.mobile?.split(' ')?.pop());
        setDialCode(payload?.transactionDetails[0]?.accountTransactionDetail?.personDetail?.mobile?.split(' ')[0]);
        setName(payload?.transactionDetails[0]?.accountTransactionDetail?.personDetail?.name);
        setVillage(payload?.transactionDetails[0]?.accountTransactionDetail?.personDetail?.village);
        setIsAksharvashi(payload?.transactionDetails[0]?.accountTransactionDetail?.personDetail?.isDeath);
        setPanCard(payload?.transactionDetails[0]?.accountTransactionDetail?.personDetail?.panCardNo);
        setAadhaarCard(payload?.transactionDetails[0]?.accountTransactionDetail?.personDetail?.aadhaarCard);
        setAddress(payload?.transactionDetails[0]?.accountTransactionDetail?.personDetail?.address);
        setTrustId(payload.trustId);
        setIdForOtp(slipId);
        const newValue = payload?.transactionDetails.map((elem) => {
          return {
            id: randomStr(5),
            accountId: elem.accountTransactionDetail.accountId,
            amount: elem.amount,
            name: elem.accountTransactionDetail.personDetail.name
          };
        });
        setFeilds(newValue);
        setPaymentMode({
          value: payload?.transactionDetails[0]?.bankDetail?.bankId,
          label: payload?.transactionDetails[0]?.bankDetail?.name
        });
        setNote(payload?.transactionDetails[0]?.accountTransactionDetail?.note);
        setTransectionNo(payload?.transactionDetails?.transactionNo);
        setByhand(payload?.transactionDetails?.byHand);
        setCity(payload?.transactionDetails[0]?.accountTransactionDetail?.personDetail?.city);
        setState(payload?.transactionDetails[0]?.accountTransactionDetail?.personDetail?.state);
        setCountry(payload?.transactionDetails[0]?.accountTransactionDetail?.personDetail?.country);
        setPaymentType(payload.paymentType);
        setDob(new Date(DateFormateChange(payload.dob)));
        history(`/Donation/${params.type === 'expanse' ? 'ExpanseAdd' : 'DonationAdd'}/${layout}`);
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom
        });
        setLoaderShow(false);
      }
    } catch (error) {
      if (error?.response?.data?.status === ERROR_CODE.UNAUTHORIZED) {
        LogOut(history);
      }
      toast.error(error?.response?.data?.message || error?.message, {
        autoClose: 1000,
        transition: Zoom
      });
      setLoaderShow(false);
    }
  };

  const defaultColumns = [
    {
      flex: 0.1,
      minWidth: 150,
      field: 'actions',
      headerName: 'Actions',
      renderCell: (params) => (
        <>
          <Tooltip title="Download">
            <IconButton
              aria-label="view"
              style={{ background: '#dcf1dc', marginRight: '6px' }}
              onClick={() => ShowSlip(params.row.slipId, 'popupSlip')}
            >
              <Download style={{ color: 'green', width: '19px', height: '19px' }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Print">
            <IconButton
              aria-label="view"
              style={{ background: '#dcf1dc', marginRight: '6px' }}
              onClick={() => ShowSlip(params.row.slipId, 'downloadSlip')}
              // onClick={() => printReciept()}
            >
              <Print style={{ color: 'green', width: '19px', height: '19px' }} />
            </IconButton>
          </Tooltip>
          {+localStorage.getItem('isWpMsg') === isWhatsAppPermission && (
            <Tooltip title="Resend Slip">
              <IconButton
                aria-label="view"
                style={{ background: '#dcf1dc', marginRight: '6px' }}
                onClick={() => ShowSlip(params.row.slipId, null, true)}
              >
                <Send style={{ color: 'green', width: '19px', height: '19px' }} />
              </IconButton>
            </Tooltip>
          )}
        </>
      )
    },
    {
      flex: 0.1,
      minWidth: 120,
      field: 'slipNo',
      headerName: 'Slip No'
    },
    {
      flex: 0.1,
      minWidth: 150,
      field: 'createdAt',
      headerName: 'Date',
      renderCell: (params) => moment(params.row.createdAt).format('DD MMM, YYYY HH:mm')
    },
    {
      flex: 0.1,
      minWidth: 200,
      field: 'personDetail',
      headerName: 'Donar Name',
      renderCell: (params) =>
        params.row.transactionDetails.length ? (
          <div style={{ whiteSpace: 'normal' }}>
            {params.row.transactionDetails[0].accountTransactionDetail.personDetail.name}
          </div>
        ) : (
          '--'
        )
    },

    {
      flex: 0.1,
      minWidth: 130,
      field: 'PaymentType',
      headerName: 'Payment Type',
      renderCell: (params) => (params.row.PaymentType ? params.row.PaymentType : '--')
    },
    {
      flex: 0.1,
      minWidth: 100,
      field: 'amount',
      headerName: 'Total',
      renderCell: (params) => {
        let a = 0;
        for (let j of params.row.transactionDetails) {
          a += j.amount;
        }
        return <p style={{ color: 'green' }}>{a}</p>;
      }
    },
    // {
    //   flex: 0.1,
    //   minWidth: 150,
    //   field: 'paymentMode',
    //   headerName: 'Payment Mode',
    //   renderCell: (params) => (params.row.paymentMode ? params.row.paymentMode : '--')
    // },
    {
      flex: 0.1,
      minWidth: 200,
      field: 'transactionNo',
      headerName: 'Transection Number',
      renderCell: (params) => (params.row.transactionNo ? params.row.transactionNo : '--')
    },
    {
      flex: 0.1,
      minWidth: 200,
      field: 'byHand',
      headerName: 'By Hand',
      renderCell: (params) =>
        params.row.byHand ? <div style={{ whiteSpace: 'normal' }}>{params.row.byHand}</div> : '--'
    },
    {
      flex: 0.1,
      minWidth: 200,
      field: 'note',
      headerName: 'Note',
      renderCell: (params) =>
        params.row.transactionDetails.length && params.row.transactionDetails[0].accountTransactionDetail ? (
          <div style={{ whiteSpace: 'normal' }}> {params.row.transactionDetails[0].accountTransactionDetail.note}</div>
        ) : (
          '-'
        )
    },

    {
      flex: 0.1,
      minWidth: 200,
      field: 'creator',
      headerName: 'Creator',
      renderCell: (params) =>
        params.row.transactionDetails.length && params.row.transactionDetails[0].accountTransactionDetail.creator ? (
          <div style={{ whiteSpace: 'normal' }}>
            {params.row.transactionDetails[0].accountTransactionDetail.creator.name}
          </div>
        ) : (
          '-'
        )
    },
    {
      flex: 0.1,
      minWidth: 100,
      field: 'attachment',
      headerName: 'Img Link',
      renderCell: (params) =>
        params.row.attachment ? (
          <a href={params.row.attachment} target="_blank" rel="noreferrer">
            View Link
          </a>
        ) : (
          '-'
        )
    },
    {
      flex: 0.1,
      minWidth: 200,
      field: 'status',
      headerName: 'Status',
      renderCell: (params) =>
        params.row.status === PAYMENT_SLIP_STATUS.PENDING ? (
          <p
            style={{ fontWeight: 'bold', color: 'red', cursor: 'pointer' }}
            onClick={() => handleStatusChange(params.row.templeId, params.row.trustId, params.row.slipId)}
          >
            Panding
          </p>
        ) : params.row.status === PAYMENT_SLIP_STATUS.ACCEPTED ? (
          <p style={{ fontWeight: 'bold', color: 'green' }}>Accepted</p>
        ) : (
          '-'
        )
    },
    {
      flex: 0.1,
      minWidth: 200,
      field: 'delete',
      headerName: 'Delete / Edit',
      sortable: false,
      renderCell: (params) => (
        <>
          <Actions params={params} deleteDoantionSLip={deleteDoantionSLip} />
          <Tooltip title="Edit">
            <IconButton
              aria-label="view"
              style={{ background: '#dcf1dc', marginLeft: '12px' }}
              onClick={() => EditSlip(params.row.slipId)}
            >
              <Edit style={{ color: 'green', width: '19px', height: '19px' }} />
            </IconButton>
          </Tooltip>
        </>
      )
    }
  ];

  const history = useNavigate();

  const defaultLayoutObj = classes.find((item) => Object.values(item).pop(1) === 'compact-wrapper');
  const layout = localStorage.getItem('layout') || Object.keys(defaultLayoutObj).pop();

  const emptyFeilds = () => {
    setIsEdit(false);
    setPersonaId('');
    setContactNo('');
    setName('');
    setVillage('');
    setPanCard('');
    setAadhaarCard('');
    setAddress('');
    setFeilds([
      {
        id: randomStr(5),
        accountId: Number(''),
        amount: Number(''),
        name: ''
      }
    ]);
    setPaymentMode('');
    setNote('');
    setTransectionNo('');
    setByhand('');
    setCity('');
    setState('');
    setCountry('');
    setPaymentType('');
    setDob('');
    setLoaderShow(false);
    setDialCode('+91');
    setIsAksharvashi(false);
    setContactNoKin('');
    setDodDate('');
    setDodMonth('');
    setdodPaksha('');
  };

  const AddModel = () => {
    emptyFeilds();
    history(`/Donation/${params.type === 'expanse' ? 'ExpanseAdd' : 'DonationAdd'}/${layout}`);
  };

  return (
    <>
      <Fragment>
        <Breadcrumbs parent={DONATION.DONATION_PERENT} />
        <div id="donationReceipt"></div>
        <div id="hide-content">
          <Container fluid={true}>
            <SlipDownloadeTemplate />

            {isOpen && +localStorage.getItem('templeId') === 47 ? (
              <SlipDownloadeTemplatePrint />
            ) : (
              isOpen && <SlipLandscapePrint />
            )}
            <Row>
              <Col sm="12">
                <Card>
                  <CardHeader
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between'
                    }}
                  >
                    <h3>{params.type === 'expanse' ? DONATION.EXPANCE_PERENT : DONATION.DONATION_PERENT}</h3>
                    <div aria-label="add" className="btn btn-primary" onClick={AddModel} style={Style}>
                      <Plus />
                    </div>
                  </CardHeader>
                  <Table>
                    <thead>
                      <tr>
                        <th>
                          <Row>
                            <Col md="2 mb-2">
                              <Input
                                className="form-control no-outline"
                                type="text"
                                placeholder="Slip No Search..."
                                value={slipIdFilter}
                                onChange={(e) => setSlipIdFilter(e.target.value)}
                              />
                            </Col>
                          </Row>
                        </th>
                      </tr>
                    </thead>
                  </Table>
                  <Box
                    sx={{
                      '& .even': {
                        backgroundColor: '#f1f1f1'
                      },
                      '& .odd': {
                        backgroundColor: 'white'
                      }
                    }}
                  >
                    {responseLoader === true ? (
                      <TableSkeleton />
                    ) : formvalueSlip.length && formvalueSlip.length ? (
                      <DataGrid
                        autoHeight
                        sx={MuiTabelStyle}
                        pagination
                        rows={formvalueSlip}
                        rowCount={record}
                        pageSize={pageSize}
                        getRowId={(row) => row.slipId}
                        columns={defaultColumns}
                        page={page - 1}
                        rowsPerPageOptions={[7, 10, 25, 50]}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        onPageChange={(newPageNo) => setPage(newPageNo + 1)}
                        paginationMode="server"
                        disableSelectionOnClick
                        checkboxSelection={false}
                        sortingMode="server"
                        onSortModelChange={sorting}
                        getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
                      />
                    ) : (
                      <DataNotFound />
                    )}
                  </Box>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </Fragment>
    </>
  );
};

export default OrganizationItem;
