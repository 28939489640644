import axios from "axios";
import {
  ADD_BANK_MASTER_LIST,
  BANK_MASTER_DITAILS,
  BANK_MASTER_LIST,
  BANK_MASTER_STATEMENT,
  EDIT_BANK_MASTER_LIST,
  ENABLE_DESABLE_BANK_MASTER_LIST,
  FILE,
} from "../../constant/API constant";

export const BankMasterList = (paramsObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${BANK_MASTER_LIST}`, {
    headers: { Authorization: jwt_token },
    params: paramsObj,
  });
};

export const BankMasterStatement = (paramsObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${BANK_MASTER_STATEMENT}`, {
    headers: { Authorization: jwt_token },
    params: paramsObj,
  });
};

export const Add_BankMaster = (formData) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(
    `${process.env.REACT_APP_API_URL}${ADD_BANK_MASTER_LIST}`,
    formData,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

export const BankMaster_List_Details = (bankId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${BANK_MASTER_DITAILS}`, {
    headers: { Authorization: jwt_token },
    params: { bankId },
  });
};

export const Edit_BankMaster_List = (formData, bankId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${EDIT_BANK_MASTER_LIST}`,
    formData,
    {
      headers: { Authorization: jwt_token },
      params: { bankId },
    }
  );
};

export const BankMaster_List_Enable_Desable = (formData) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${ENABLE_DESABLE_BANK_MASTER_LIST}`,
    formData,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

export const FILE_API = (formData) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(`${process.env.REACT_APP_API_URL}${FILE}`, formData, {
    headers: { Authorization: jwt_token },
  });
};
