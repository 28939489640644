import moment from 'moment';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { toast, Zoom } from 'react-toastify';
import { Card, CardBody, Col, Container, Row, Table } from 'reactstrap';
import { BankMasterStatement } from '../../api/Bank Master api/BankMasterAPi';
import { Donation_Master_Statement } from '../../api/DonationMasterApi/DonationMasterApi';
import { ACCOUNT_TYPE, BANK_MASTER, ERROR_CODE } from '../../constant';
import DonationMasterContext from '../../context/DonatoinMasterContext/DonationMasterContext';
import Breadcrumbs from '../../layout/breadcrumb';
import StatementFilter from './StatementFilter';
import { Donar_List_Statement } from '../../api/Donar Api/DonarApi';
import OrganizationContext from '../../context/OrganizationContext/OrganizationContext';
import LogOut from '../../utils/logout';
import { currencyFormat } from '../../utils/helper';
import PaginationForTable from '../../utils/PaginationForTable';
import { useNavigate, useParams } from 'react-router';

const Statement = () => {
  const [formValue, setformvalue] = useState({});
  const [personStatement, setPersonStateMent] = useState([]);
  const [accountTransaction, setAccountTransaction] = useState([]);
  const [templeTotalAmount, setTempleTotalAmount] = useState([]);
  const [name, setName] = useState('');
  const [type, setType] = useState('');
  // const { bankId } = useContext(OrgContext);
  // const { personId } = useContext(ProductContext);
  const {
    searchFilter,
    setSearchFilter,
    startDateFilter,
    setStartDateFilter,
    endDateFilter,
    setEndDateFilter,
    pageNumber,
    recordsPerPage,
    setRecordsPerPage,
    setTotalRecords
  } = useContext(DonationMasterContext);
  const { loaderShow, setLoaderShow } = useContext(OrganizationContext);
  const { personId, statement } = useParams();
  const history = useNavigate();

  const getData = async () => {
    if (personId && statement === 'bank') {
      let paramsObj = {
        bankId: personId,
        sortOrder: 'ASC',
        pageNumber: pageNumber,
        recordsPerPage: recordsPerPage
      };
      if (searchFilter) {
        paramsObj['search'] = searchFilter;
      }
      if (startDateFilter && endDateFilter) {
        paramsObj['startDate'] = startDateFilter;
        paramsObj['endDate'] = endDateFilter;
      }
      setLoaderShow(!loaderShow);
      try {
        const { data } = await BankMasterStatement(paramsObj);
        if (data.status === 200) {
          setformvalue(data.payload);
          setLoaderShow(false);
          setTotalRecords(data.pager.totalRecords);
          setRecordsPerPage(data.pager.recordsPerPage);
        }
      } catch (error) {
        if (error?.response?.data?.status === ERROR_CODE.UNAUTHORIZED) {
          LogOut(history);
        }
        toast.error(error?.response?.data?.message, {
          autoClose: 1000,
          transition: Zoom
        });
        setLoaderShow(false);
      }
    }

    if (personId && statement === 'account') {
      let paramsObj = {
        accountId: personId,
        pageNumber: pageNumber,
        recordsPerPage: recordsPerPage
      };
      if (searchFilter) {
        paramsObj['search'] = { type: searchFilter };
      }
      if (startDateFilter && endDateFilter) {
        paramsObj['startDate'] = startDateFilter;
        paramsObj['endDate'] = endDateFilter;
      }
      setLoaderShow(!loaderShow);
      try {
        const { data } = await Donation_Master_Statement(paramsObj);
        if (data.status === 200) {
          const dataPayload = data.payload;
          setType(dataPayload.accountDetails.type);
          setName(dataPayload.accountDetails.name);
          setformvalue(dataPayload);
          setTotalRecords(data.pager.totalRecords);
          setRecordsPerPage(data.pager.recordsPerPage);
          setLoaderShow(false);
        } else {
        }
      } catch (error) {
        if (error?.response?.data?.status === ERROR_CODE.UNAUTHORIZED) {
          LogOut(history);
        }
        toast.error(error?.response?.data?.message, {
          autoClose: 1000,
          transition: Zoom
        });
        setLoaderShow(false);
      }
    }

    if (personId && (statement === 'person' || statement === 'birthday')) {
      let paramsObj = {
        personId: personId,
        pageNumber: pageNumber,
        recordsPerPage: recordsPerPage
      };
      if (searchFilter) {
        paramsObj['search'] = { type: searchFilter };
      }
      if (startDateFilter && endDateFilter) {
        paramsObj['startDate'] = startDateFilter;
        paramsObj['endDate'] = endDateFilter;
      }
      setLoaderShow(!loaderShow);
      try {
        const { data } = await Donar_List_Statement(paramsObj);
        if (data.status === 200) {
          const dataPayload = data.payload;
          setPersonStateMent(dataPayload.personDetails ? dataPayload.personDetails : []);
          setAccountTransaction(dataPayload.data ? dataPayload.data : []);
          setTempleTotalAmount(dataPayload.totalAmounts ? dataPayload.totalAmounts : []);
          setTotalRecords(data.pager.totalRecords);
          setRecordsPerPage(data.pager.recordsPerPage);
          setLoaderShow(false);
        } else {
          // setformvalue([]);
        }
      } catch (error) {
        if (error?.response?.data?.status === ERROR_CODE.UNAUTHORIZED) {
          LogOut(history);
        }
        toast.error(error?.response?.data?.message, {
          autoClose: 1000,
          transition: Zoom
        });
        setLoaderShow(false);
      }
    }
  };

  useEffect(() => {
    getData();
  }, [searchFilter, startDateFilter, endDateFilter, recordsPerPage, pageNumber]);

  return (
    <>
      <Fragment>
        <Breadcrumbs parent={BANK_MASTER.STATEMENT} />
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <CardBody style={{ padding: '0' }}>
                  <Card>
                    {/* Donation Master Statement  */}
                    {personId && statement === 'account' && (
                      <>
                        <div className="table-responsive">
                          <Table>
                            <tbody>
                              <tr style={{ border: 'none' }}>
                                <th scope="row" style={{ border: 'none' }}>
                                  {'Name'}
                                </th>
                                <td style={{ border: 'none' }}>{name ? name : '--'}</td>
                              </tr>
                              <tr style={{ border: 'none' }}>
                                <th scope="row" style={{ border: 'none' }}>
                                  {'Type'}
                                </th>
                                <td style={{ border: 'none' }}>
                                  {type === ACCOUNT_TYPE.inward ? ACCOUNT_TYPE.inward_name : ACCOUNT_TYPE.outward_name}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>

                        <div>
                          <hr />
                        </div>
                      </>
                    )}

                    {/* Donation Statement */}
                    {personId && statement === 'account' ? (
                      <>
                        <StatementFilter
                          endDateFilter={endDateFilter}
                          setEndDateFilter={setEndDateFilter}
                          startDateFilter={startDateFilter}
                          setStartDateFilter={setStartDateFilter}
                          setSearchFilter={setSearchFilter}
                          searchFilter={searchFilter}
                          redireact={'/DonationMaster/DonationMaster/Hisab_Kitab'}
                          report={formValue?.report}
                        />
                        <div className="table-responsive" style={{ marginTop: '1rem' }}>
                          <Table className="table-border-vertical">
                            <thead>
                              <tr>
                                <th scope="col" style={{ width: '11rem' }}>
                                  {'Date & Time'}
                                </th>

                                <th scope="col">{'Name'}</th>

                                <th scope="col">{'Note'}</th>
                                <th scope="col" style={{ width: '10rem' }}>
                                  {'Amount'}
                                </th>
                                <th scope="col" style={{ width: '10rem' }}>
                                  {'Due Amount'}
                                </th>
                                <th scope="col" style={{ width: '18rem' }}>
                                  {'Criator'}
                                </th>
                              </tr>
                            </thead>

                            <tbody>
                              {formValue?.data?.length
                                ? formValue?.data?.map((elem, i) => {
                                    return (
                                      <tr key={i}>
                                        <td>{moment(elem.createdAt).format('DD MMM, YYYY HH:mm')}</td>

                                        <td>{elem.personDetail ? elem.personDetail.name : '--'}</td>
                                        <td>{elem.note ? elem.note : '--'}</td>

                                        <td>
                                          <div
                                            style={{
                                              color: '#578b57',
                                              fontWeight: 'bold'
                                            }}
                                          >
                                            {elem.amount ? currencyFormat(elem.amount) : '0'}
                                          </div>
                                        </td>
                                        <td>
                                          <div
                                            style={{
                                              color: '#ff5f5f',
                                              fontWeight: 'bold'
                                            }}
                                          >
                                            {elem.dueAmount ? currencyFormat(elem.dueAmount) : '0'}
                                          </div>
                                        </td>
                                        <th scope="row">{elem.creator ? elem.creator.name : '--'}</th>
                                      </tr>
                                    );
                                  })
                                : null}
                            </tbody>
                          </Table>
                          <PaginationForTable />
                        </div>
                      </>
                    ) : // <DataNotFoundv1 />
                    null}
                    {/* BankMaster Statement  */}
                    {personId && statement === 'bank' ? (
                      <>
                        <StatementFilter
                          endDateFilter={endDateFilter}
                          setEndDateFilter={setEndDateFilter}
                          startDateFilter={startDateFilter}
                          setStartDateFilter={setStartDateFilter}
                          setSearchFilter={setSearchFilter}
                          searchFilter={searchFilter}
                          redireact={'/BankMaster/bankMaster/Hisab_Kitab'}
                          report={formValue?.report}
                        />
                        <div className="table-responsive">
                          <Table className="table-border-vertical">
                            <thead>
                              <tr>
                                <th scope="col" style={{ width: '11%' }}>
                                  {'Date'}
                                </th>
                                {/* <th scope="col" style={{ width: "11%" }}>
                                  {"Slip No."}
                                </th> */}
                                <th scope="col">{'Perticulars'}</th>

                                <th scope="col" style={{ width: '8%' }}>
                                  {'Credit'}
                                </th>
                                <th scope="col" style={{ width: '8%' }}>
                                  {'Debit'}
                                </th>
                                <th scope="col" style={{ width: '10%' }}>
                                  {'Balance'}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {formValue?.data?.map((elem, i) => {
                                return (
                                  <tr key={i}>
                                    <th scope="row">{moment(elem.createdAt).format('DD MMM, YYYY HH:mm')}</th>
                                    {/* <td>{elem.slipNo}</td> */}
                                    <td>
                                      {' '}
                                      {elem.slipNo ? elem.slipNo : '--'}
                                      <span style={{ 'font-size': 'smaller' }}>
                                        (
                                        {elem.accountTransactionDetail && elem.accountTransactionDetail.AccountDetail
                                          ? elem.accountTransactionDetail.AccountDetail.name
                                          : '--'}{' '}
                                        )
                                      </span>
                                      <br />
                                      {elem.accountTransactionDetail && elem.accountTransactionDetail.personDetail
                                        ? elem.accountTransactionDetail.personDetail.name
                                        : '--'}
                                    </td>

                                    <td>
                                      <div
                                        style={{
                                          color: '#578b57',
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                          fontWeight: 'bold'
                                        }}
                                      >
                                        {elem.type === ACCOUNT_TYPE.inward ? currencyFormat(elem.amount) : '0'}
                                      </div>
                                    </td>
                                    <td>
                                      <div
                                        style={{
                                          color: '#ff5f5f',
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                          fontWeight: 'bold'
                                        }}
                                      >
                                        {elem.type === ACCOUNT_TYPE.outward ? currencyFormat(elem.amount) : '0'}
                                      </div>
                                    </td>
                                    <td>{elem.balance ? elem.balance : '--'}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                          <PaginationForTable />
                        </div>
                      </>
                    ) : // <DataNotFoundv1 />
                    null}

                    {/* Donar Statement  */}
                    {personId && (statement === 'person' || statement === 'birthday') && (
                      <>
                        <Row>
                          <Col>
                            <div className="table-responsive">
                              <Table>
                                <tbody>
                                  <tr style={{ border: 'none' }}>
                                    <th scope="row" style={{ border: 'none' }}>
                                      {'Name'}
                                    </th>
                                    <td style={{ border: 'none' }}>
                                      {personStatement.name ? personStatement.name : '--'}
                                    </td>
                                  </tr>
                                  <tr style={{ border: 'none' }}>
                                    <th scope="row" style={{ border: 'none' }}>
                                      {'Mobile'}
                                    </th>
                                    <td style={{ border: 'none' }}>
                                      {personStatement.mobile ? personStatement.mobile : '--'}
                                    </td>
                                  </tr>
                                  <tr style={{ border: 'none' }}>
                                    <th scope="row" style={{ border: 'none' }}>
                                      {'Village'}
                                    </th>
                                    <td style={{ border: 'none' }}>
                                      {personStatement.village ? personStatement.village : '--'}
                                    </td>
                                  </tr>
                                  <tr style={{ border: 'none' }}>
                                    <th scope="row" style={{ border: 'none' }}>
                                      {'Address'}
                                    </th>
                                    <td style={{ border: 'none' }}>
                                      {personStatement.address ? personStatement.address : '--'}
                                    </td>
                                  </tr>
                                  <tr style={{ border: 'none' }}>
                                    <th scope="row" style={{ border: 'none' }}>
                                      {'Aadhaar Card'}
                                    </th>
                                    <td style={{ border: 'none' }}>
                                      {personStatement.aadhaarCard ? personStatement.aadhaarCard : '--'}
                                    </td>
                                  </tr>
                                  <tr style={{ border: 'none' }}>
                                    <th scope="row" style={{ border: 'none' }}>
                                      {'PanCard'}
                                    </th>
                                    <td style={{ border: 'none' }}>
                                      {personStatement.panCardNo ? personStatement.panCardNo : '--'}
                                    </td>
                                  </tr>
                                  <tr style={{ border: 'none' }}>
                                    <th scope="row" style={{ border: 'none' }}>
                                      {'Time'}
                                    </th>
                                    <td style={{ border: 'none' }}>
                                      {personStatement.createdAt
                                        ? moment(personStatement.createdAt).format('DD MMM, YYYY HH:mm')
                                        : '--'}
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </div>
                          </Col>
                          <Col>
                            <div className="table-responsive">
                              <Table className="table-border-vertical" style={{ borderLeft: '1px solid gray' }}>
                                <thead>
                                  <tr>
                                    <th scope="col">{'Temple Name'}</th>
                                    <th scope="col">{'Donation'}</th>
                                    <th scope="col">{'DueDonation'}</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {templeTotalAmount?.length
                                    ? templeTotalAmount?.map((ele) => {
                                        return (
                                          <tr>
                                            <td>
                                              {ele.temple.templeName} <br /> <small>({ele.temple.city})</small>
                                            </td>
                                            <td>{currencyFormat(ele.totalAmount)}</td>
                                            <td
                                              style={{
                                                color: '#ff5f5f'
                                              }}
                                            >
                                              {currencyFormat(ele.totalDueAmount)}
                                            </td>
                                          </tr>
                                        );
                                      })
                                    : null}
                                  <tr>
                                    <th>{'Total'}</th>
                                    <th>
                                      {currencyFormat(
                                        templeTotalAmount.reduce((acc, curr) => acc + curr.totalAmount, 0)
                                      )}
                                    </th>
                                    <th
                                      style={{
                                        color: '#ff5f5f'
                                      }}
                                    >
                                      {currencyFormat(
                                        templeTotalAmount.reduce((acc, curr) => acc + curr.totalDueAmount, 0)
                                      )}
                                    </th>
                                  </tr>
                                </tbody>
                              </Table>
                            </div>
                          </Col>
                        </Row>

                        <div>
                          <hr />
                        </div>

                        <StatementFilter
                          endDateFilter={endDateFilter}
                          setEndDateFilter={setEndDateFilter}
                          startDateFilter={startDateFilter}
                          setStartDateFilter={setStartDateFilter}
                          setSearchFilter={setSearchFilter}
                          searchFilter={searchFilter}
                          redireact={
                            statement === 'person'
                              ? '/DonarMaster/donar/Hisab_Kitab'
                              : statement === 'birthday'
                              ? '/dashboard/ecommerce/Hisab_Kitab'
                              : ''
                          }
                        />

                        <div className="table-responsive" style={{ marginTop: '1rem' }}>
                          <Table className="table-border-vertical">
                            <thead>
                              <tr>
                                <th scope="col" style={{ width: '11rem' }}>
                                  {'Date & Time'}
                                </th>
                                <th scope="col">{'Name'}</th>
                                <th scope="col">{'Note'}</th>
                                <th scope="col">{'Credit'}</th>
                                <th scope="col">{'Debit'}</th>
                                <th scope="col">{'Due Amount'}</th>
                              </tr>
                            </thead>

                            <tbody>
                              {accountTransaction?.length
                                ? accountTransaction?.map((elem, i) => {
                                    return (
                                      <tr key={i}>
                                        <td>{moment(elem.createdAt).format('DD MMM, YYYY HH:mm')}</td>
                                        <th scope="row">{elem.AccountDetail ? elem.AccountDetail.name : '--'}</th>
                                        <td>
                                          <div>{elem.note ? elem.note : '--'}</div>
                                        </td>
                                        <td>
                                          <div
                                            style={{
                                              color: '#578b57',
                                              fontWeight: 'bold'
                                            }}
                                          >
                                            {elem.AccountDetail.type === ACCOUNT_TYPE.inward
                                              ? currencyFormat(elem.amount)
                                              : '--'}
                                          </div>
                                        </td>
                                        <td>
                                          <div
                                            style={{
                                              color: '#ff5f5f',
                                              fontWeight: 'bold'
                                            }}
                                          >
                                            {elem.AccountDetail.type === ACCOUNT_TYPE.outward
                                              ? currencyFormat(elem.amount)
                                              : '--'}
                                          </div>
                                        </td>

                                        <td>
                                          <div>{elem.dueAmount ? elem.dueAmount : ''}</div>
                                        </td>
                                      </tr>
                                    );
                                  })
                                : null}
                            </tbody>
                          </Table>
                          <PaginationForTable />
                        </div>
                      </>
                    )}
                  </Card>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fragment>
    </>
  );
};

export default Statement;
