import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { Fragment, useContext } from "react";
import { Plus } from "react-feather";
import { Card, CardHeader, Col, Container, Row } from "reactstrap";
import { NOTES, Style } from "../../constant";
import NoteContext from "../../context/NoteContext/NoteContext";
import OrganizationContext from "../../context/OrganizationContext/OrganizationContext";
import DataNotFound from "../../DataNoteFound/DataNotFound";
import Breadcrumbs from "../../layout/breadcrumb";
import TableSkeleton from "../../loader/TableSkeleton";
import Actions from "../../utils/Actions";
import { MuiTabelStyle } from "../../utils/helper";

const NotesItem = (props) => {
  const {
    editHandle,
    showHandle,
    sorting,
    AddModel,
    setPage,
    setPageSize,
    pageSize,
    page,
    record,
  } = props;
  const { formvalue } = useContext(NoteContext);
  const { responseLoader } = useContext(OrganizationContext);
  const defaultColumns = [
    {
      flex: 0.1,
      minWidth: 250,
      field: "title",
      headerName: "Title",
    },
    {
      flex: 0.1,
      minWidth: 250,
      field: "notes",
      headerName: "Notes",
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      renderCell: (params) => (
        <>
          <Actions
            params={params}
            editHandle={editHandle}
            showHandle={showHandle}
          />
        </>
      ),
    },
  ];

  return (
    <>
      <Fragment>
        <Breadcrumbs parent={NOTES.NOTES_PERENTS} />
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <h3>{NOTES.NOTES_PERENTS}</h3>
                  <div
                    aria-label="add"
                    className="btn btn-primary"
                    onClick={AddModel}
                    style={Style}
                  >
                    <Plus />
                  </div>
                </CardHeader>
                <Box
                  sx={{
                    "& .even": {
                      backgroundColor: "#f1f1f1",
                    },
                    "& .odd": {
                      backgroundColor: "white",
                    },
                  }}
                >
                  {responseLoader === true ? (
                    <TableSkeleton />
                  ) : formvalue.length && formvalue.length ? (
                    <DataGrid
                      sx={MuiTabelStyle}
                      autoHeight
                      pagination
                      rows={formvalue}
                      rowCount={record}
                      pageSize={pageSize}
                      getRowId={(row) => row.noteId}
                      columns={defaultColumns}
                      page={page - 1}
                      rowsPerPageOptions={[7, 10, 25, 50]}
                      onPageSizeChange={(newPageSize) =>
                        setPageSize(newPageSize)
                      }
                      onPageChange={(newPageNo) => setPage(newPageNo + 1)}
                      paginationMode="server"
                      disableSelectionOnClick
                      checkboxSelection={false}
                      sortingMode="server"
                      onSortModelChange={sorting}
                      getRowClassName={(params) =>
                        params.indexRelativeToCurrentPage % 2 === 0
                          ? "even"
                          : "odd"
                      }
                    />
                  ) : (
                    <DataNotFound />
                  )}
                </Box>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fragment>
    </>
  );
};

export default NotesItem;
