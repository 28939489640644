import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { Fragment, useContext } from "react";
import { Plus } from "react-feather";
import {
  Card,
  CardHeader,
  Col,
  Container,
  Input,
  Row,
  Table,
} from "reactstrap";
import { BASE_URL_IMG, Style, TEMPLE_MASTER } from "../../constant";
import DataNotFound from "../../DataNoteFound/DataNotFound";
import Breadcrumbs from "../../layout/breadcrumb";
import TableMenuItem from "../../utils/TableMenuItem";
import TempleContext from "../../context/TempleContext/TempleContext";
import TableSkeleton from "../../loader/TableSkeleton";
import OrganizationContext from "../../context/OrganizationContext/OrganizationContext";
import { MuiTabelStyle } from "../../utils/helper";

const TempleItem = (props) => {
  const {
    editHandle,
    showHandle,
    sorting,
    AddModel,
    setPage,
    setPageSize,
    pageSize,
    page,
    record,
  } = props;
  const { formvalue, filter, setFilter } = useContext(TempleContext);
  const { responseLoader } = useContext(OrganizationContext);
  const defaultColumns = [
    {
      flex: 0.1,
      minWidth: 100,
      field: "image",
      headerName: "Logo",
      renderCell: (params) =>
        params.row.image ? (
          <img
            src={BASE_URL_IMG + params.row.image}
            alt=""
            style={{ width: "2rem" }}
          />
        ) : (
          "--"
        ),
    },
    {
      flex: 0.1,
      minWidth: 250,
      field: "templeName",
      headerName: "Temple Name",
      renderCell: (params) =>
        params.row.templeName ? params.row.templeName : "--",
    },
    {
      flex: 0.1,
      minWidth: 250,
      field: "email",
      headerName: "Email",
      renderCell: (params) => (params.row.email ? params.row.email : "--"),
    },
    {
      flex: 0.1,
      minWidth: 200,
      field: "mobile",
      headerName: "Mobile",
      renderCell: (params) => (params.row.mobile ? params.row.mobile : "--"),
    },
    {
      flex: 0.1,
      minWidth: 250,
      field: "gstNumber",
      headerName: "GST No.",
      renderCell: (params) =>
        params.row.gstNumber ? params.row.gstNumber : "--",
    },
    {
      flex: 0.1,
      minWidth: 250,
      field: "panCardNo",
      headerName: "Pan No.",
      renderCell: (params) =>
        params.row.panCardNo ? params.row.panCardNo : "--",
    },
    {
      flex: 0.1,
      minWidth: 250,
      field: "address",
      headerName: "Address",
      renderCell: (params) => (params.row.address ? params.row.address : "--"),
    },
    {
      flex: 0.1,
      minWidth: 100,
      field: "city",
      headerName: "City",
      renderCell: (params) => (params.row.city ? params.row.city : "--"),
    },
    {
      flex: 0.1,
      minWidth: 100,
      field: "state",
      headerName: "State",
      renderCell: (params) => (params.row.state ? params.row.state : "--"),
    },
    {
      flex: 0.1,
      minWidth: 100,
      field: "country",
      headerName: "Country",
      renderCell: (params) => (params.row.country ? params.row.country : "--"),
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      renderCell: (params) => (
        <>
          <TableMenuItem
            params={params}
            editHandle={editHandle}
            showHandle={showHandle}
          />
        </>
      ),
    },
  ];

  return (
    <>
      <Fragment>
        <Breadcrumbs parent={TEMPLE_MASTER.TEMPLE_MASTER_PERENT} />
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <h3>{TEMPLE_MASTER.TEMPLE_MASTER_PERENT}</h3>
                  <div
                    aria-label="add"
                    className="btn btn-primary"
                    onClick={AddModel}
                    style={Style}
                  >
                    <Plus />
                  </div>
                </CardHeader>
                <Table>
                  <thead>
                    <tr>
                      <th>
                        <Row>
                          <Col md="2 mb-2">
                            <Input
                              className="form-control no-outline"
                              type="text"
                              placeholder="Temple Master Search..."
                              value={filter}
                              onChange={(e) => setFilter(e.target.value)}
                            />
                          </Col>
                        </Row>
                      </th>
                    </tr>
                  </thead>
                </Table>
                <Box
                  sx={{
                    "& .even": {
                      backgroundColor: "#f1f1f1",
                    },
                    "& .odd": {
                      backgroundColor: "white",
                    },
                  }}
                >
                  {responseLoader === true ? (
                    <TableSkeleton />
                  ) : formvalue.length ? (
                    <DataGrid
                      sx={MuiTabelStyle}
                      autoHeight
                      pagination
                      rows={formvalue}
                      rowCount={record}
                      pageSize={pageSize}
                      getRowId={(row) => row.templeId}
                      columns={defaultColumns}
                      page={page - 1}
                      rowsPerPageOptions={[7, 10, 25, 50]}
                      onPageSizeChange={(newPageSize) =>
                        setPageSize(newPageSize)
                      }
                      onPageChange={(newPageNo) => setPage(newPageNo + 1)}
                      paginationMode="server"
                      disableSelectionOnClick
                      checkboxSelection={false}
                      sortingMode="server"
                      onSortModelChange={sorting}
                      getRowClassName={(params) =>
                        params.indexRelativeToCurrentPage % 2 === 0
                          ? "even"
                          : "odd"
                      }
                    />
                  ) : (
                    <DataNotFound />
                  )}
                </Box>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fragment>
    </>
  );
};

export default TempleItem;
